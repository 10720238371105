import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import {
  callApiGetCheckScanReportsPendingDomainData,
  callApiGetRenewSslsData,
} from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";
import NoSealsReport from "./noSealsReport";
import SealsGeneratedReport from "./sealsGeneratedReport";
import RegenerateAllSeals from "./regenerateSealsReport";
import RunningSslScanReport from "./runningSslScanReport";
import InvalidSslScanReport from "./invalidSslScanReport";
import PendingSslScanReport from "./pendingSslScanReport";
import ActiveSslExpiresReport from "./activeSslExpiresReport";
import CanceledSitesSslScanReport from "./canceledSitesSslScanReport";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class SslRenewalReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      businesses: [],
    };
  }

  async componentDidMount() {}

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.reportType !== this.props.reportType &&
      this.props.reportType === "ssl-renewals"
    ) {
      const businessData = await callApiGetRenewSslsData();

      if (businessData && businessData.data) {
        this.setState({
          businesses: businessData.data,
        });
      }
    }
  }

  render() {
    const { businesses } = this.state;

    return (
      <>
        <div style={{ margin: "15px" }}>
          <hr />
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Business</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Hosting Provider</th>
                  <th>Hosting Package</th>
                  <th>
                    <span className="accadmin-hover-1">
                      <span className="account-title1">
                        <span className="roleTooltip">
                          Server Type
                          <span className="roleTooltipText">
                            <div>
                              <small
                                style={{
                                  display: "block",
                                  clear: "both",
                                }}
                              >
                                What type of server do you have?
                              </small>
                            </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="accadmin-hover-1">
                      <span className="account-title1">
                        <span className="roleTooltip">
                          Convert Site ?
                          <span className="roleTooltipText">
                            <div>
                              <small
                                style={{
                                  display: "block",
                                  clear: "both",
                                }}
                              >
                                How should Secure My Website (SMW) convert your
                                site?
                              </small>
                            </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="accadmin-hover-1">
                      <span className="account-title1">
                        <span className="roleTooltip">
                          Discuss ?
                          <span className="roleTooltipText">
                            <div>
                              <small
                                style={{
                                  display: "block",
                                  clear: "both",
                                }}
                              >
                                Would you like to discuss anything else before
                                we convert your site?
                              </small>
                            </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </th>
                  <th>
                    {" "}
                    <span className="accadmin-hover-1">
                      <span className="account-title1">
                        <span className="roleTooltip">
                          SSH ?
                          <span className="roleTooltipText">
                            <div>
                              <small
                                style={{
                                  display: "block",
                                  clear: "both",
                                }}
                              >
                                Does your hosting plan come with SSH access?
                              </small>
                            </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </th>
                  <th>
                    <span className="accadmin-hover-1">
                      <span className="account-title1">
                        <span className="roleTooltip">
                          LastPass ?
                          <span className="roleTooltipText">
                            <div>
                              <small
                                style={{
                                  display: "block",
                                  clear: "both",
                                }}
                              >
                                Do you use LastPass Password Manager?
                              </small>
                            </div>
                          </span>
                        </span>
                      </span>
                    </span>
                  </th>
                  <th>Go to SMW</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="scan-status-list">
                {businesses && businesses.data && businesses.data.length > 0 ? (
                  businesses.data.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {v.business ? (
                            <button
                              className="tg-link"
                              onClick={() => {
                                setStorageItem(
                                  "businessId",
                                  cryptr.encrypt(v.businessId)
                                );
                                window.open("/");
                              }}
                            >
                              {v.business.businessName}
                            </button>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>{v.name}</td>
                        <td>{v.email}</td>
                        <td>{v.hostingProvider}</td>
                        <td>{v.hostingPackage}</td>
                        <td>{v.typeOfServer}</td>
                        <td>{v.smw}</td>
                        <td>{v.discussBefore}</td>
                        <td>{v.hostingPlanWithSSH}</td>
                        <td>{v.lastPassPasswordManager}</td>
                        <td>
                          <a
                            href="https://www.securemywebsite.net/trust-guard-start/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007bff", fontWeight: "bold" }}
                          >
                            Go to SMW
                          </a>
                        </td>
                        <td>
                          <ControlButtons businessId={v.businessId} />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8">No records.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

SslRenewalReports.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SslRenewalReports);
