const images = {
  calender: require("../static/images/ico/calendar-ico.png"),
  tgCertificateIcoHippa: require("../static/images/icons/tg-certificate-ico-hippa.jpg"),
  tgInsurance: require("../static/images/icons/tg-certificate-ico-insurance.jpg"),
  tgCertificateIcoMalware: require("../static/images/icons/tg-certificate-ico-malware.jpg"),
  tgCertificateIcoOwasp: require("../static/images/icons/tg-certificate-ico-owasp.jpg"),
  tgCertificateIcoSsl: require("../static/images/icons/tg-certificate-ico-ssl.jpg"),
  tgCertificateIcoStar: require("../static/images/icons/tg-certificate-ico-star.jpg"),
  tgCertificateIcoTg: require("../static/images/icons/tg-certificate-ico-tg.jpg"),
  accountIcon: require("../static/images/icons2/account.png"),
  dashIcon: require("../static/images/icons2/dash.png"),
  insuranceIcon: require("../static/images/icons2/insurance.png"),
  malwareIcon: require("../static/images/icons2/malware.png"),
  scanIcon: require("../static/images/icons2/scan.png"),
  sealIcon: require("../static/images/icons2/seal.png"),
  sslIcon: require("../static/images/icons2/ssl.png"),

  tgIntegration03: require("../static/images/integration/tg-integration_03.jpg"),
  tgIntegration05: require("../static/images/integration/tg-integration_05.jpg"),
  tgIntegration07: require("../static/images/integration/tg-integration_07.jpg"),
  tgIntegration13: require("../static/images/integration/tg-integration_13.jpg"),
  tgIntegration16: require("../static/images/integration/tg-integration_16.jpg"),
  tgIntegration18: require("../static/images/integration/tg-integration_18.jpg"),

  arrowDown: require("../static/images/arrow-down.png"),
  arrowLeftCert: require("../static/images/arrow-left-cert.jpg"),
  ccList: require("../static/images/cc-list.png"),
  loading: require("../static/images/loading.gif"),
  loading2: require("../static/images/loading2.gif"),
  pciScanTable: require("../static/images/pci-scan-table.png"),
  spin: require("../static/images/spin.gif"),
  tgBadgeDark: require("../static/images/tg-badge-dark.png"),
  logo: require("../static/images/tg-badge-light.png"),
  tgBanner01: require("../static/images/tg-banner_01.jpg"),
  tgBanner02: require("../static/images/tg-banner_02.jpg"),
  tgChartSample: require("../static/images/tg-chart-sample.jpg"),
  tgEvGuid: require("../static/images/tg-ev-guide.jpg"),
  tgWhiteLogo: require("../static/images/TG-logo-white-web.png"),
  tgProjectBg: require("../static/images/tg-project-bg.jpg"),
  tgRegularDark: require("../static/images/tg-regular-dark.png"),
  tgSealWhite: require("../static/images/tg-seal-white.jpg"),
  tgStackDark: require("../static/images/tg-stack-dark.png"),
  tgStackLight: require("../static/images/tg-stack-light.png"),
  tgStackWhite: require("../static/images/TG-stack-wht.png"),
  tgVulnerabilityGuide: require("../static/images/tg-vulnerability-guide.jpg"),
  trustGuardIcon: require("../static/images/trust-guard-icon.png"),
  loginBG: require("../static/images/login-bg-jpg.jpg"),
  loginBGFinal: require("../static/images/login-bg-server.jpg"),
  trustGuardSeal: require("../static/images/tg-sealv1.svg"),
  loginBGPNG: require("../static/images/login-bg.png"),
  tempItems03: require("../static/images/tg-dashboard-temp-items_03.jpg"),
  tempItems06: require("../static/images/tg-dashboard-temp-items_06.jpg"),
  tempItems08: require("../static/images/tg-dashboard-temp-items_08.jpg"),
  tempItems12: require("../static/images/tg-dashboard-temp-items_12.jpg"),
  tempItems15: require("../static/images/tg-dashboard-temp-items_15.jpg"),
  tgSealv1PNG: require("../static/images/tg-seal-v1.png"),
  notFound: require("../static/notfound/notFound.jpeg"),
  sslsecuremysite: require("../static/images/secure-my-website-logo.png"),
  thecoalition: require("../static/images/thecoalition.png"),
  thecoalitionblack: require("../static/images/thecoalition-black.png"),
  panelbg: require("../static/images/panel-btm-bg.png"),
  bucknerLogo: require("../static/images/buckner_logo.png"),
  bucknerLogoHd: require("../static/images/buckner_logohd.png"),
  rpsLogo: require("../static/images/rps-logo.png"),

  tgSealDefault: require("../static/images/rolling.gif"),
  rolling: require("../static/images/rolling.gif"),
  tgCertificateLogo: require("../static/images/certificate/tg-logo-cert.png"),
  tgCertificate256BitSsl: require("../static/images/certificate/tg-256bit-ssl.png"),
  tgCertificateBg: require("../static/images/certificate/tg-certificate-bg.png"),
  tgSecurityIco: require("../static/images/certificate/tg-security-ico.png"),
  tgMalwareIco: require("../static/images/certificate/tg-malware-ico.png"),
  tgSslIco: require("../static/images/certificate/tg-ssl-ico.png"),
  tgWebsiteBanner: require("../static/images/certificate/tg-totalwebsitebanner.png"),
  feedbackIcon: require("../static/images/ico/feedback-ico.png"),

  newTgSecurityScannedShieldIcon: require("../static/images/certificate/tg-security-scanned-shield-icon.png"),
  newTgMalwareIcon: require("../static/images/certificate/tg-malware-icon.png"),
  newTgSslCertIcon: require("../static/images/certificate/tg-ssl-cert-icon.png"),
  newTgGdprIcon: require("../static/images/certificate/tg-gdpr-icon.png"),

  tgWalkthrough0: require("../static/images/welcomePopup/tg-walkthrough-0.png"),
  tgWalkthrough1: require("../static/images/welcomePopup/tg-walkthrough-1.png"),
  tgWalkthrough2: require("../static/images/welcomePopup/tg-walkthrough-2.png"),
  tgWalkthrough2Icon: require("../static/images/welcomePopup/tg-walkthrough-2-icon.png"),
  tgWalkthrough3: require("../static/images/welcomePopup/tg-walkthrough-3.png"),
  tgWalkthrough3Icon: require("../static/images/welcomePopup/tg-walkthrough-3-icon.png"),
  tgWalkthrough4: require("../static/images/welcomePopup/tg-walkthrough-4.png"),
  tgWalkthrough4Icon: require("../static/images/welcomePopup/tg-walkthrough-4-icon.png"),
  tgWalkthrough5: require("../static/images/welcomePopup/tg-walkthrough-5.png"),
  tgWalkthrough5Icon: require("../static/images/welcomePopup/tg-walkthrough-5-icon.png"),
  tgWalkthrough6: require("../static/images/welcomePopup/tg-walkthrough-6.png"),
  tgWalkthrough6Icon: require("../static/images/welcomePopup/tg-walkthrough-6-icon.png"),
  tgWalkthrough7: require("../static/images/welcomePopup/tg-walkthrough-7.png"),
  tgWalkthrough7Icon: require("../static/images/welcomePopup/tg-walkthrough-7-icon.png"),
  tgWalkthrough8: require("../static/images/welcomePopup/tg-walkthrough-8-new.png"),
  tgWalkthrough8Icon: require("../static/images/welcomePopup/tg-walkthrough-8-icon.png"),
  tgWalkthroughStartscan: require("../static/images/welcomePopup/tg-walkthrough-startscan.png"),
  tgWalkthroughClosewindow: require("../static/images/welcomePopup/tg-walkthrough-closewindow.png"),
  tgWalkthroughMainBtn: require("../static/images/welcomePopup/tg-walkthrough-mainbtn.png"),
  tgWalkthroughBottomBg: require("../static/images/welcomePopup/tg-walkthrough-bottombg.png"),
  tgCloseWindow: require("../static/images/welcomePopup/tg-close-window.png"),
  tgFreescanUpgrade: require("../static/images/welcomePopup/tg-freescan-upgrade.png"),
  tgFreeScanLearnmore: require("../static/images/welcomePopup/tg-freescan-learnmore.png"),
  customPopupAvatar: require("../static/images/custom-popup-avatar.png"),
  playStore: require("../static/images/google-play-btn.svg"),
  appleStore: require("../static/images/apple-store-btn.svg"),
};

export { images };
