import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { callApiGet30DaysOldFreeActiveBusinesses } from "../../services/index";
import ControlButtons from "./controlButtons";
import { viewUserDashboard, camelize } from "../../helpers/general";
import { convertTimeTo } from "../../helpers/timeZone";
import AppContext from "../../components/context/appContext";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class ActiveFreeOldBusinessReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      isLoading: true,
      businesses: [],
      display: "none",
    };
  }

  getBusinessList = (display) => {
    this.setState(
      {
        display: display === "block" ? "none" : "block",
      },
      async () => {
        if (display === "none") {
          const tempBusinesses = await callApiGet30DaysOldFreeActiveBusinesses();

          this.setState({
            isLoading: false,
            businesses: tempBusinesses,
          });
        }
      }
    );
  };

  getPciStatus(pciStatus) {
    let pciScanStatus,
      pciScanIconClass,
      pciScanStyle,
      isPciScanRunning,
      pciScanProgressPercentage;

    switch (pciStatus) {
      case "fail":
        pciScanStatus = "Failed";
        pciScanIconClass = "fa fa-exclamation-circle fa-3x";
        pciScanStyle = { color: "#C01818", margin: "20px 0" };
        break;
      case "pending":
        pciScanStatus = "Pending";
        pciScanIconClass = "fa fa-clock fa-3x";
        pciScanStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      case "Never Scanned":
        pciScanIconClass = "fa fa-clock fa-3x";
        pciScanStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      case "running":
      case "finalizing":
        isPciScanRunning = true;
        pciScanStatus = pciScanProgressPercentage + " %";
        pciScanStyle = {};
        break;
      case "pass":
        pciScanStatus = "Passed";
        pciScanIconClass = "fa fa-check-circle fa-3x";
        pciScanStyle = { color: "#33981f", margin: "20px 0" };
        break;
      default:
        pciScanStatus = "Pending";
        pciScanIconClass = "fa fa-clock fa-3x";
        pciScanStyle = { color: "#7f7f7f", margin: "20px 0" };
    }

    return (
      <div>
        <div style={pciScanStyle}>
          <i className={pciScanIconClass} />
        </div>

        <span style={{ fontWeight: "bold", fontSize: "10px" }}>
          {pciScanStatus}
        </span>
      </div>
    );
  }

  getMalwareStatus(malwareStatus) {
    let malwareScanTextStatus, malwareScanIconClass, malwareScanStyle;

    switch (malwareStatus) {
      case "clean":
        malwareScanTextStatus = "Passed";
        malwareScanIconClass = "fa fa-check-circle fa-3x";
        malwareScanStyle = { color: "#33981f", margin: "20px 0" };
        break;
      case "":
        malwareScanTextStatus = "Failed";
        malwareScanIconClass = "fa fa-exclamation-circle fa-3x";
        malwareScanStyle = { color: "#C01818", margin: "20px 0" };
        break;
      default:
        malwareScanTextStatus = "Pending";
        malwareScanIconClass = "fa fa-clock fa-3x";
        malwareScanStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
    }

    return (
      <div>
        <div style={malwareScanStyle}>
          <i className={malwareScanIconClass} />
        </div>

        <span style={{ fontWeight: "bold", fontSize: "10px" }}>
          {malwareScanTextStatus}
        </span>
      </div>
    );
  }

  getSslStatus(sslStatus) {
    let sslCertificateTextStatus, scanSslIconClass, scanSslStyle;

    switch (sslStatus) {
      case "active":
        sslCertificateTextStatus = "Verified";
        scanSslIconClass = "fa fa-check-circle fa-3x";
        scanSslStyle = { color: "#33981f", margin: "20px 0" };
      case "passed":
        sslCertificateTextStatus = "Verified";
        scanSslIconClass = "fa fa-check-circle fa-3x";
        scanSslStyle = { color: "#33981f", margin: "20px 0" };
        break;
      case "failed":
        sslCertificateTextStatus = "Failed";
        scanSslIconClass = "fa fa-exclamation-circle fa-3x";
        scanSslStyle = { color: "#C01818", margin: "20px 0" };
        break;
      case "pending":
        sslCertificateTextStatus = "Scan Pending";
        scanSslIconClass = "fa fa-clock fa-3x";
        scanSslStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      default:
        break;
    }
    return (
      <div>
        <div style={scanSslStyle}>
          <i className={scanSslIconClass} />
        </div>

        <span style={{ fontWeight: "bold", fontSize: "10px" }}>
          {sslCertificateTextStatus}
        </span>
      </div>
    );
  }

  getInsuranceStatus(insuranceStatus) {
    let insuranceTextStatus, insuranceIconClass, insuranceStyle;

    switch (insuranceStatus) {
      case "completed":
        insuranceTextStatus = "Completed";
        insuranceIconClass = "fa fa-check-circle fa-3x";
        insuranceStyle = { color: "#33981f", margin: "20px 0" };
        break;
      case "submitted":
        insuranceTextStatus = "Submitted";
        insuranceIconClass = "fa fa-clock fa-3x";
        insuranceStyle = { color: "orange", margin: "20px 0" };
        break;
      case "pending":
        insuranceTextStatus = "Pending";
        insuranceIconClass = "fa fa-clock fa-3x";
        insuranceStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
      default:
        insuranceTextStatus = "Pending";
        insuranceIconClass = "fa fa-clock fa-3x";
        insuranceStyle = { color: "#7f7f7f", margin: "20px 0" };
        break;
    }

    return (
      <div>
        <div style={insuranceStyle}>
          <i className={insuranceIconClass} />
        </div>

        <span style={{ fontWeight: "bold", fontSize: "10px" }}>
          {insuranceTextStatus}
        </span>
      </div>
    );
  }

  render() {
    const { isLoading, businesses, display } = this.state;

    return (
      <>
        <div>
          <button
            className="btn-success"
            onClick={() => {
              this.getBusinessList(display);
            }}
          >
            Active Free Accounts Older than 30 Days.{" "}
            <b>
              ({businesses && businesses.data ? businesses.data.length : 0})
            </b>
          </button>
        </div>
        <p>Active Free Accounts Older than 30 Days.</p>

        <hr />

        <div className="table-responsive" style={{ display: display }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Business</th>
                <th>PCI</th>
                <th>Malware</th>
                <th>SSL</th>
                <th>Insurance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="scan-status-list">
              {isLoading === true ? (
                <tr>
                  <td colSpan="7">Loading...</td>
                </tr>
              ) : businesses &&
              businesses.data &&
              businesses.data.length > 0 ? (
                businesses.data.map((v, i) => {
                  return (
                    <tr>
                      <td>
                        {v.businessId !== "" ? (
                          <button
                            className="tg-link"
                            onClick={(e) => viewUserDashboard(v.businessId)}
                          >
                            {v && v.stat ? v.stat.primaryDomain : ""}
                          </button>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {this.getPciStatus(
                          v && v.stat ? v.stat.pciScanStatus : ""
                        )}
                      </td>
                      <td>
                        {this.getMalwareStatus(
                          v && v.stat ? v.stat.malwareScanStatus : ""
                        )}
                      </td>
                      <td>
                        {this.getSslStatus(
                          v && v.stat ? v.stat.sslScanStatus : ""
                        )}
                      </td>
                      <td>
                        {this.getInsuranceStatus(
                          v && v.stat ? v.stat.insuranceStatus : ""
                        )}
                      </td>
                      <td>
                        <ControlButtons businessId={v.businessId} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7">No records.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

ActiveFreeOldBusinessReport.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveFreeOldBusinessReport);
