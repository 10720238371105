import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";

import ReportsTabHeader from "./reportsTabHeader";
import ReportsTabBody from "./reportsTabBody";

class ReportsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: "",
    };
  }

  updateReportType = (reportType) => {
    this.setState({
      reportType: reportType,
    });
  };

  render() {
    const { reportType } = this.state;

    return (
      <div id="reports" className="tab-pane fade in active">
        <ReportsTabHeader updateReportType={this.updateReportType} />
        <ReportsTabBody reportType={reportType} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsTab);
