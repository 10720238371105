import React from "react";
import { Button } from "react-bootstrap/lib/InputGroup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { callApiFindEmailData } from "../../services/index";

class EmailTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emailTag: "",
      emailName: "",
      from: "support@trust-guard.com",
      subject: "",
      body: "",
      type: "",
      emails: [],
      emailId: "",
      edit: false,
      userId: "",
      sendSms: false,
      smsBody: "",
      usersList: [],
      selectedUserId: "",
      selectedUsersBusinesses: [],
      selectedUsersBusinessesId: "",
      sendUserNotification: false,
      userNotificationDetails: "",
      userNotificationRegarding: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.controlType !== this.props.controlType &&
        this.props.controlType === "email") ||
      this.props.fetchEmails === true
    ) {
      const emailData = await callApiFindEmailData();

      if (emailData && emailData.data && emailData.data.length > 0) {
        this.setState({
          emails: emailData.data,
        });
      }
    }
  }

  render() {
    const { emails } = this.state;

    return (
      <div style={{ display: "block", clear: "both" }}>
        <div style={{ display: "block", clear: "both", height: "65px" }}>
          &nbsp;
        </div>
        {emails.length > 0 ? (
          <div className="table-wrapper-scroll-y">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Email Tag</th>
                  <th scope="col">Email name</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Body</th>
                  <th scope="col" style={{ width: "15%" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {emails.map((email) => {
                  return (
                    <tr key={email._id}>
                      <td>{email.emailTag}</td>
                      <td>{email.name}</td>
                      <td>{email.subject}</td>
                      <td>{email.body}</td>
                      <td>
                        <button
                          className="text-left btn btn-secondary btn-md mr-2"
                          onClick={() => {
                            this.props.editEmail(email);
                          }}
                          style={{}}
                        >
                          <i className="fas fa-edit" />&nbsp; Edit
                        </button>

                        <button
                          className="text-left btn btn-danger btn-md"
                          onClick={() => {
                            this.props.deleteEmailConfirmModal(email);
                          }}
                        >
                          <i className="fas fa-times" />&nbsp; Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplates);
