import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../header';
import { Editor, EditorState, RichUtils, ContentState, CompositeDecorator } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';

import { getUserDecryptedData } from '../../helpers/general';
import {
  callCreatePrivacyPolicy,
  callGetPrivacyPolicyByUserId,
  callUpdatePrivacyPolicy
} from '../../services/privacyPolicy';

function Link(props) {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a
      href={url}
      style={{ color: '#3366CC', textDecoration: 'underline' }}
      target="_blank"
      rel="noopener noreferrer">
      {props.children}
    </a>
  );
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link
      }
    ]);

    let contentState;

    if (props.htmlContent) {
      const cleanHtml = props.htmlContent
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/<\/?p>/g, '')
        .trim();
      contentState = stateFromHTML(cleanHtml);
    } else {
      contentState = ContentState.createFromText(props.textContent || '');
    }

    this.state = {
      editorState: EditorState.createWithContent(contentState, decorator),
      showURLInput: false,
      urlValue: ''
    };
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  onChange = editorState => {
    this.setState({ editorState });
    const contentState = editorState.getCurrentContent();

    const options = {
      inlineStyles: {
        BOLD: { element: 'strong' },
        ITALIC: { element: 'em' },
        UNDERLINE: { element: 'u' }
      },
      entityStyleFn: entity => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
          const data = entity.getData();
          return {
            element: 'a',
            attributes: {
              href: data.url,
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          };
        }
      }
    };

    const htmlContent = stateToHTML(contentState, options);
    this.props.onChange(htmlContent);
  };

  toggleInlineStyle = style => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
  };

  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  promptForLink = e => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        showURLInput: true,
        urlValue: ''
      });
    }
  };

  confirmLink = e => {
    e.preventDefault();
    const { editorState, urlValue } = this.state;
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: urlValue });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });

    this.setState(
      {
        editorState: RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey),
        showURLInput: false,
        urlValue: ''
      },
      () => {
        setTimeout(() => this.editor.focus(), 0);
      }
    );
  };

  onURLChange = e => {
    this.setState({ urlValue: e.target.value });
  };

  removeLink = e => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null)
      });
    }
  };

  render() {
    const { editorState, showURLInput, urlValue } = this.state;

    let urlInput;
    if (showURLInput) {
      urlInput = (
        <div className="url-input-container">
          <input
            type="text"
            onChange={this.onURLChange}
            value={urlValue}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.confirmLink(e);
              }
            }}
            placeholder="Enter URL..."
            className="url-input"
          />
          <button className="btn btn-sm btn-success" onMouseDown={this.confirmLink}>
            Add
          </button>
          <button
            className="btn btn-sm btn-secondary ml-2"
            onMouseDown={e => {
              e.preventDefault();
              this.setState({ showURLInput: false });
            }}>
            Cancel
          </button>
        </div>
      );
    }

    return (
      <div className="rich-editor-container">
        <div className="rich-editor-controls">
          <button
            type="button"
            onClick={() => this.toggleInlineStyle('BOLD')}
            className="style-button">
            <strong>B</strong>
          </button>
          <button
            type="button"
            onClick={() => this.toggleInlineStyle('ITALIC')}
            className="style-button">
            <em>I</em>
          </button>
          <button
            type="button"
            onClick={() => this.toggleInlineStyle('UNDERLINE')}
            className="style-button">
            <u>U</u>
          </button>
          <button type="button" onClick={this.promptForLink} className="style-button">
            <span role="img" aria-label="Add link">
              🔗
            </span>
          </button>
          <button type="button" onClick={this.removeLink} className="style-button">
            <span role="img" aria-label="Remove link">
              🔗❌
            </span>
          </button>
          <button
            type="button"
            onClick={() => this.toggleBlockType('unordered-list-item')}
            className="style-button">
            • List
          </button>
          <button
            type="button"
            onClick={() => this.toggleBlockType('ordered-list-item')}
            className="style-button">
            1. List
          </button>
        </div>
        {urlInput}
        <div className="rich-editor-content">
          <Editor
            ref={ref => (this.editor = ref)}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: '',
      privacyPolicyData: [],
      allHistoryVersions: [],
      selectedHistoryIndex: 0,
      editableSections: ['Contact Us', 'Data Collection', 'Data Sharing'],
      editingSection: null,
      loading: false,
      lastUpdatedDate: new Date(),
      editedContent: '',
      isModified: false
    };
  }

  componentDidMount() {
    let userLsData = getUserDecryptedData();

    if (userLsData) {
      let userId = userLsData.userId;
      this.setState({
        userId
      });
      this.getData(userId);
    }
  }

  async getData(userId) {
    const privacyPolicyData = await callGetPrivacyPolicyByUserId(userId);
    let data = privacyPolicyData.data;
    if (Array.isArray(data) && data.length > 0) {
      let history = data[0].history;
      let lastEntry = history[history.length - 1];

      const historyVersions = history
        .map((entry, index) => ({
          version: entry.version,
          date: new Date(entry.date || data[0].updatedAt),
          content: entry.data
        }))
        .reverse();

      this.setState({
        privacyPolicyData: lastEntry.data,
        allHistoryVersions: historyVersions,
        selectedHistoryIndex: 0,
        lastUpdatedDate: historyVersions[0].date || data[0].updatedAt
      });
    }
  }

  handleHistoryChange = e => {
    const index = parseInt(e.target.value, 10);
    const selectedVersion = this.state.allHistoryVersions[index];

    if (selectedVersion) {
      this.setState({
        selectedHistoryIndex: index,
        privacyPolicyData: selectedVersion.content,
        lastUpdatedDate: selectedVersion.date,
        editingSection: null,
        isModified: false
      });
    }
  };

  async generatePrivacyPolicy() {
    let apiData = {
      userId: this.state.userId,
      purpose: 'generate_privacy_policy'
    };
    this.setState({ loading: true });
    const response = await callCreatePrivacyPolicy(apiData);
    this.setState({ loading: false });
    if (response.success) {
      this.getData(this.state.userId);
    }
  }

  startEditing(title) {
    const section = this.state.privacyPolicyData.find(section => section.title === title);

    let cleanedContent = '';
    if (section && section.description) {
      cleanedContent = section.description.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    }

    this.setState({
      editingSection: title,
      editedContent: cleanedContent
    });
  }

  handleEditorChange = htmlContent => {
    this.setState({
      editedContent: htmlContent
    });
  };

  async saveEdit(index) {
    const { editedContent, privacyPolicyData } = this.state;
    const updatedPolicyData = [...privacyPolicyData];

    updatedPolicyData[index].description = editedContent || updatedPolicyData[index].description;

    this.setState({
      privacyPolicyData: updatedPolicyData,
      editingSection: null,
      editedContent: '',
      isModified: true
    });
  }

  async updatedPolicyData() {
    this.setState({ loading: true });
    let apiData = {
      userId: this.state.userId,
      purpose: 'update_privacy_policy',
      privacyPolicy: this.state.privacyPolicyData
    };

    const response = await callUpdatePrivacyPolicy(apiData);
    this.setState({ loading: false, isModified: false });
    if (response.success) {
      this.getData(this.state.userId);
    }
  }

  async deletePolicyData(version) {
    let apiData = {
      userId: this.state.userId,
      purpose: 'delete_privacy_policy',
      version: version
    };
    const response = await callUpdatePrivacyPolicy(apiData);

    if (response.success) {
      this.getData(this.state.userId);
    }
  }

  cancelEdit() {
    this.setState({
      editingSection: null,
      editedContent: ''
    });
  }

  renderPrivacyPolicy() {
    const {
      privacyPolicyData,
      editableSections,
      editingSection,
      lastUpdatedDate,
      isModified,
      loading
    } = this.state;

    if (!privacyPolicyData || privacyPolicyData.length === 0) {
      return null;
    }

    const formatDate = dateString => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    return (
      <div className="privacy-policy-container">
        {privacyPolicyData.map((section, index) => (
          <div key={index} className="policy-section">
            <h3 className="section-title">{section.title}</h3>
            {editingSection === section.title ? (
              <div className="edit-section">
                <RichTextEditor
                  htmlContent={section.description}
                  onChange={this.handleEditorChange}
                />
                <div className="edit-buttons mt-2">
                  <button
                    className="btn btn-sm btn-success mr-2"
                    onClick={() => this.saveEdit(index)}>
                    Save
                  </button>
                  <button className="btn btn-sm btn-secondary" onClick={() => this.cancelEdit()}>
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div
                  className="section-description"
                  dangerouslySetInnerHTML={{ __html: section.description }}
                />
                {editableSections.includes(section.title) && (
                  <button
                    className="btn btn-sm btn-outline-primary edit-btn"
                    onClick={() => this.startEditing(section.title)}>
                    Edit
                  </button>
                )}
              </>
            )}
          </div>
        ))}
        <div className="footer-container">
          <div className="last-updated-container">
            <p className="last-updated-text">Last updated: {formatDate(lastUpdatedDate)}</p>
          </div>
          {isModified && (
            <div className="save-button-container">
              <button
                disabled={loading}
                className="btn btn-success"
                onClick={() => this.updatedPolicyData()}>
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderHistoryDropdown() {
    const { allHistoryVersions, selectedHistoryIndex } = this.state;

    if (!allHistoryVersions || allHistoryVersions.length <= 1) {
      return null;
    }

    const formatDate = date => {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    return (
      <div className="history-dropdown-container">
        <label htmlFor="historyVersion">Version History: </label>
        <select
          id="historyVersion"
          className="history-select"
          value={selectedHistoryIndex}
          onChange={this.handleHistoryChange}>
          {allHistoryVersions.map((version, index) => (
            <option key={index} value={index}>
              Version {version.version} - {formatDate(version.date)}
              {index === 0 ? ' (Current)' : ''}
            </option>
          ))}
        </select>
        {selectedHistoryIndex !== 0 && (
          <div className="history-actions">
            <span className="viewing-history-note">
              Viewing historical version.
              <button
                className="btn-link"
                onClick={() => this.handleHistoryChange({ target: { value: '0' } })}>
                Return to current
              </button>
            </span>
            <button
              className="btn btn-sm btn-danger ml-3 delete-history-btn"
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this version?')) {
                  this.deletePolicyData(allHistoryVersions[selectedHistoryIndex].version);
                }
              }}>
              Delete This Version
            </button>
          </div>
        )}
      </div>
    );
  }

  render() {
    const screenHeight = window.innerHeight;

    return (
      <>
        <Header titlePage="Privacy Policy" bgColor="#F5F6F8" />

        <div id="mcontent-wrapper" style={!0 ? { height: screenHeight } : {}}>
          <div id="control-panel" className="">
            <ToastContainer />
            <button
              className="btn-sm btn-tglight2 mr-2 btn"
              onClick={() => this.generatePrivacyPolicy()}>
              {this.state.loading ? 'Generating...' : 'Generate Privacy Policy'}
            </button>
          </div>

          {this.renderHistoryDropdown()}

          <div className="privacy-policy-wrapper mt-4">{this.renderPrivacyPolicy()}</div>
        </div>
        <style jsx>{`
          .privacy-policy-container {
            background-color: white;
            border-radius: 8px;
            padding: 20px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            margin-bottom: 30px;
          }
          .policy-main-title {
            margin-bottom: 20px;
            color: #333;
            font-weight: 600;
          }
          .policy-section {
            margin-bottom: 25px;
            padding-bottom: 15px;
            // border-bottom: 1px solid #eee;
            position: relative;
          }
          .section-title {
            font-size: 18px;
            font-weight: 600;
            color: #444;
            margin-bottom: 10px;
          }
          .section-description {
            font-size: 14px;
            line-height: 1.6;
            white-space: pre-line;
          }
          .edit-btn {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            padding: 3px 10px;
          }
          .edit-buttons {
            text-align: right;
          }
          .last-updated-container {
            flex: 1;
            text-align: left;
          }
          .last-updated-text {
            font-size: 12px;
            color: #777;
            font-style: italic;
            margin-bottom: 0;
          }
          .footer-container {
            margin-top: 30px;
            padding-top: 15px;
            border-top: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .save-button-container {
            text-align: right;
          }
          .rich-editor-container {
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-bottom: 10px;
            overflow: hidden;
          }
          .rich-editor-controls {
            border-bottom: 1px solid #ddd;
            background: #f7f7f7;
            padding: 5px;
          }
          .rich-editor-controls button {
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 3px;
            margin-right: 5px;
            padding: 2px 8px;
            cursor: pointer;
          }
          .rich-editor-controls button:hover {
            background: #f3f3f3;
          }
          .rich-editor-content {
            padding: 10px;
            min-height: 200px;
          }
          .url-input-container {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
          .url-input {
            flex: 1;
            padding: 5px;
            margin-right: 5px;
            border: 1px solid #ddd;
            border-radius: 3px;
          }
          .history-dropdown-container {
            margin: 20px 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background-color: white;
            padding: 10px 15px;
            border-radius: 8px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
          }
          .history-select {
            margin: 0 10px;
            padding: 5px;
            border: 1px solid #ddd;
            border-radius: 4px;
            min-width: 250px;
            background-color: white;
          }
          .history-actions {
            display: flex;
            align-items: center;
            margin-left: 10px;
          }
          .viewing-history-note {
            font-size: 13px;
            color: #777;
            display: flex;
            align-items: center;
          }
          .delete-history-btn {
            font-size: 12px;
            padding: 3px 8px;
          }
          .btn-link {
            background: none;
            border: none;
            padding: 0;
            margin-left: 8px;
            color: #0275d8;
            cursor: pointer;
            text-decoration: underline;
            font-size: 13px;
          }
          .btn-link:hover {
            color: #014c8c;
          }
        `}</style>
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy);
