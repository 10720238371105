import React, { Component } from "react";
import ToastMessage from "../../components/toast/toastMessage";
import {
  callOnGetBusinessById,
  callOnUpdateBusinessPrimaryDomain,
} from "../../services/businessServiceCopy";
class PrimaryDomainUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: null,
      primaryDomain: "",
      businessName: "",
      scanVendor: null,
      submitted: false,
    };
  }

  async componentWillReceiveProps(newProps) {
    if (newProps.hasOwnProperty("businessId")) {
      if (newProps.businessId !== undefined) {
        if (newProps.businessId !== null) {
          const business = await callOnGetBusinessById(newProps.businessId);
          if (business !== undefined) {
            this.setState({
              businessId: newProps.businessId,
              primaryDomain: business.primaryDomain,
              scanVendor: business.scanVendor,
              businessName: business.businessName,
            });
          }
        }
      }
    }
  }

  updatePrimaryDomain = async () => {
    console.log("update domain!");
    if (this.state.businessId) {
      await callOnUpdateBusinessPrimaryDomain({
        _id: this.state.businessId,
        primaryDomain: this.state.primaryDomain,
        scanVendor: this.state.scanVendor,
        businessName: this.state.businessName,
      });

      this.setState({ submitted: true });

      setInterval(() => this.setState({ submitted: false }), 2000);
    }
  };

  primaryDomainHandler = (field) => {
    this.setState({ primaryDomain: field.target.value });
  };

  render() {
    const { primaryDomain, submitted } = this.state;

    let alert = null;

    if (submitted) {
      alert = (
        <ToastMessage
          type="success"
          notifyMessage="Primary domain updated successfully"
        />
      );
    }
    return (
      <>
        {alert}

        <div className="form-group">
          <label htmlFor="primaryDomain" className="sr-only">
            Primay Domain
          </label>
          <input
            type="text"
            className="form-control col-12"
            id="primarydomain"
            onChange={this.primaryDomainHandler}
            value={primaryDomain}
          />
        </div>
        <div className="form-group">
          <button
            className="btn btn-sm btn-success pull-right"
            onClick={() => this.updatePrimaryDomain()}
            data-dismiss="modal"
            aria-label="Close">
            Update Domain
          </button>
        </div>
      </>
    );
  }
}

export default PrimaryDomainUpdate;
