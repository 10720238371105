import React from "react";
import Spinner from "../../../ui/spinner/spinnerRectScan";
const subDomainsContent = ({ subdomains, onSetEditDomain, setSubDomainId }) => {
  let subDomains = null;
  if (subdomains.length > 0) {
    subDomains = subdomains.map((domain) => {
      return (
        <>
          <div className="flex-inner-container">
            <div>
              <button
                className="tg-dashboard-link"
                style={{ cursor: "pointer" }}
                data-toggle="modal"
                data-target="#dash-edit-modal"
                onClick={() => onSetEditDomain(domain._id)}>
                <i className="fas fa-pencil-alt" />
              </button>{" "}
            </div>
            <div>
              <button
                className="pull-right tg-dashboard-link"
                style={{ cursor: "pointer", color: "rgb(192, 24, 24)" }}
                data-toggle="modal"
                data-target="#dash-delete-modal"
                onClick={() => setSubDomainId(domain._id)}>
                <span className="fas fa-minus-circle" />
              </button>{" "}
            </div>
            <div class="subdomainHost">{domain.host} </div>
          </div>
        </>
      );
    });
  }

  return subDomains;
};

export default subDomainsContent;
