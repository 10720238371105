import React, { useState, useEffect } from "react";
import { validateDomain } from "../../../../helpers/general";
import {
  callOnUpdateDomainAndScanVendor,
  callOnUpdateBusinessPrimaryDomain,
} from "../../../../services/index";
function domainValidateHandler(e) {
  const isValidURL = validateDomain(e.target.value);
}

function pciSlidePanelInvalidDomain({ primaryDomain, businessId }) {
  const [isDomainUpdateSubmitted, setDomainSubmit] = useState(false);
  const [domainValue, setDomainValue] = useState("");
  const [isValidURL, setValidUrl] = useState(false);

  useEffect(
    () => {
      //   const isValidURL = validateDomain(domainValue);
      setValidUrl(isValidURL);
      if (isValidURL) {
        const data = {
          businessId: businessId,
          domain: domainValue,
        };

        (async () => await callOnUpdateDomainAndScanVendor(data))();
      }
    },
    [isDomainUpdateSubmitted, domainValue]
  );
  let scanContent = null;

  scanContent = (
    <table
      className="table table-borderless"
      style={{
        border: "0px solid rgb(239, 239, 239)",
        borderCollapse: "collapse",
        borderRadius: "5px",
        overflow: "hidden",
      }}>
      <thead className="thead-dark" />
      <tbody
        className="text-center"
        style={{ border: "6px solid rgb(239, 239, 239)" }}>
        <tr>
          <td>
            {" "}
            <p>Please make sure the domain is valid.</p>
            <br />
            <form className="form-inline">
              <div className="form-group mb-2" style={{ marginRight: "10px" }}>
                <label className="sr-only">URL:</label>
                <input
                  type="text"
                  name="primarydomain"
                  className="form-control-plaintext"
                  onChange={(e) => setDomainValue(e.target.value)}
                />
              </div>
              <button
                className="btn btn-success mb-2"
                disabled={
                  !isDomainUpdateSubmitted && !isValidURL ? false : true
                }
                onClick={async () => {
                  setDomainSubmit(true);
                  await callOnUpdateBusinessPrimaryDomain({
                    _id: businessId,
                    primaryDomain: domainValue,
                  });
                  window.location.reload();
                }}
                type="button">
                {isDomainUpdateSubmitted && isValidURL
                  ? "Updating Please Wait..."
                  : "Update Domain"}
              </button>
            </form>
            <br />
            <a
              href="/"
              style={{ color: "#6055fc", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                // this.props.skipValidation(
                //   null,
                //   this.props.businessDetail._id
                // );
              }}>
              {primaryDomain} is the correct domain
            </a>
          </td>
        </tr>
      </tbody>
      <thead className="thead-dark" />
    </table>
  );

  return scanContent;
}

export default pciSlidePanelInvalidDomain;
