import React, { Component } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import Modal from "../../../ui/modal/modal";
import SpinnerRingBig from "../../../ui/spinner/spinnerRing";

class ModalDownloadOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetailedReports: false,
      showDownloadReport: true,
    };
  }

  setShowDetailedReportsHandler = (e) => {
    e.preventDefault();
    this.setState({ showDetailedReports: true, showDownloadReport: false });
  };
  setShowDownloadReportsHandler = (e) => {
    e.preventDefault();
    this.setState({ showDetailedReports: false, showDownloadReport: true });
  };
  render() {
    const {
      targetID,
      defaultValueType,
      reportTypeHandler,
      reports,
      showRemediation,
      downloadReport,
      defaultValueFormat,
      hostScanResults,
      vendor,
    } = this.props;

    const { showDetailedReports, showDownloadReport } = this.state;

    const viewHostDetailReport = () => {
      if (hostScanResults.length > 0) {
        return hostScanResults.map((result, i) => (
          <div class="card">
            <div class="card-header">
              {" "}
              <strong>{result.DNS}</strong>
            </div>
            <div class="card-body">
              <p class="card-text">
                <strong>Affected Host:</strong> {result.AffectedHost} <br />
                <strong>Affected Port:</strong> {result.ServiceandPort} <br />
                <strong>Risk Factor:</strong> {result.RiskFactorName} <br />
                <strong>Scan Date:</strong> {result.ScanDate} <br />
                <strong>VulnerabilityName:</strong> {result.VulnerabilityName}{" "}
                <br />
                <strong>Summary:</strong> {result.SummaryOriginal} <br />
              </p>
            </div>
          </div>
        ));
      }
    };

    return (
      <Modal targetID={targetID} title="PCI Detailed Reports">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={showDownloadReport ? "nav-link active" : "nav-link"}
              href=""
              onClick={(e) => this.setShowDownloadReportsHandler(e)}
            >
              Download PCI Reports
            </a>
          </li>
          {/* <li className="nav-item">
            <a
              className={showDetailedReports ? "nav-link active" : "nav-link"}
              href=""
              onClick={(e) => this.setShowDetailedReportsHandler(e)}>
              Detailed Reports
            </a>
          </li> */}
        </ul>

        <div
          id="detailedReportView"
          style={{ display: showDetailedReports ? "block" : "none" }}
        >
          {hostScanResults.length > 0 ? (
            <div className="scrollbar" id="style-1">
              <div className="force-overflow"> {viewHostDetailReport()}</div>
            </div>
          ) : (
            <center>
              <SpinnerRingBig />
            </center>
          )}
        </div>
        <div
          id="downloadReportView"
          style={{ display: showDownloadReport ? "block" : "none" }}
        >
          {vendor !== "frontline" ? (
            <div className="mt-4">
              <Row className="show-grid">
                <Col xs={6} md={6}>
                  <strong>Select the Report Type</strong>
                  <FormGroup>
                    <select
                      name="reportType"
                      value={defaultValueType}
                      onChange={reportTypeHandler}
                    >
                      <option value="executivesummary">Executive</option>
                      <option value="pci">PCI</option>
                      <option value="pcidss">PCI Attestation</option>
                      <option value="pcidss-summary">
                        PCI Attestation Summary
                      </option>
                      <option value="detailed">Detailed</option>
                      {reports}
                      <option value="iso27001">ISO27001</option>
                      <option value="sox">SOX</option>
                      {showRemediation ? (
                        <option value="remediation">Remediation</option>
                      ) : null}
                    </select>
                  </FormGroup>
                </Col>
                <Col xs={6} md={6}>
                  <strong>Select the Format</strong>
                  <FormGroup>
                    <select
                      defaultValue={defaultValueFormat}
                      name="reportFormat"
                      onChange={reportTypeHandler}
                    >
                      <option value="csv">CSV</option>
                      <option value="xml">XML</option>
                      <option value="html">HTML</option>
                      <option value="pdf">PDF</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          ) : (
            <h5>
              Generating report could take awhile, come back in 2 to 5 minutes
              to download your report.
            </h5>
          )}

          <br />
          <div className="text-right px-0 pt-4 modal-footer">
            <button
              type="button"
              className="btn-tglight2 btn-140-width btn-md mr-2"
              data-dismiss="modal"
              style={{ marginRight: "7px" }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-success btn-140-width btn-md  "
              data-dismiss="modal"
              onClick={downloadReport}
            >
              Download
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalDownloadOption;
