import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { convertTimeTo } from "../../../helpers/timeZone";
import NonExistentScans from "../nonExistentScanReport";
import ToastMessage from "../../../components/toast/toastMessage";
import Spinner from "../../../components/ui/spinner/spinnerRectScan";
import ControlButtons from "../../../components/control-panel/controlButtons";
import FrequencyModal from "../../../components/ui/modal/frequencyModal";
import Config from "../../../helpers/config";
import { getStorageItem } from "../../../helpers/storage";
import { getUserDecryptedData } from "../../../helpers/general";
import PCIVulnerability from "./pciVulnerability";
import {
  callOnGetBusinessReport,
  callOnGetReportCancelledSites,
  callOnGetNonExistentScans,
  callOnUpdateBSTestScan,
  callOnMarkDeleteBSTestScan,
  callOnUpdateBusinessFrequency,
  callOnGetBusinessById,
  callOnGetUserByBusinessId,
  callOnUpdateBusinessFalsePositive,
} from "../../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class reportTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleScanIndex: null,
      toggleScanId: null,
      toggleRowId: null,
      showLabelModal: false,
      labelText: "",
      falsepositiveReport: null,
      collapseDrawer: null,
      selectedStatus: null,
      fpData: [],
      processScanInfo: {
        businessId: null,
        scanHistoryId: null,
      },
      missingScans: [],
      failScans: [],
      nonExistentScans: [],
      exceededScans: [],
      vulnerabilities: [],
      scanSchedules: [],
      scanRetriving: false,
      scanRedirect: false,
      clickedIndex: null,
      scanReportCounts: {
        failScan: 0,
        missingScan: 0,
        runningScan: 0,
        runningStuck: 0,
        cancelledScan: 0,
        freeScan: 0,
        falsePositives: 0,
        nonExistentScans: 0,
        exceededScans: 0,
        vulnerabilities: 0,
        scanSchedules: 0,
      },
      findDomain: null,
      filterActive: null,
      filterScanType: null,
      filteredMissingScans: [],
      filteredFailScans: [],
      filteredNonExistentScans: [],
      showFrequencyModal: false,
      userTimeZone: "America/Denver",
      beyondSecurityScanInfo: "",
      userScanTime: "",
      frequencySave: false,
      invalidTimeZone: false,
    };
  }

  findDomainHandler = (event) => {
    let filteredScans = null;
    if (this.state.filterScanType === "missingScan") {
      const missingScans = [...this.state.missingScans];
      filteredScans = missingScans.filter((d) =>
        d.data.businessName.includes(event.target.value)
      );
      this.setState({ filteredMissingScans: filteredScans });
    }

    if (this.state.filterScanType === "failScan") {
      const failScans = [...this.state.failScans];
      filteredScans = failScans.filter((d) =>
        d.data.primaryDomain.includes(event.target.value)
      );
      this.setState({ filteredFailScans: filteredScans });
    }

    if (this.state.filterScanType === "failScan") {
      const failScans = [...this.state.failScans];
      filteredScans = failScans.filter((d) =>
        d.data.primaryDomain.includes(event.target.value)
      );
      this.setState({ filteredFailScans: filteredScans });
    }

    if (this.state.filterScanType === "nonExistentScans") {
      const nonExistentScans = [...this.state.nonExistentScans];
      filteredScans = nonExistentScans.filter((d) =>
        d.data.primaryDomain.includes(event.target.value)
      );
      this.setState({ filteredNonExistentScans: filteredScans });
    }

    if (this.state.filterScanType === "vulnerabilities") {
      const vulnerabilities = [...this.state.vulnerabilities];
      filteredScans = vulnerabilities.filter((d) =>
        d.data.primaryDomain.includes(event.target.value)
      );
      this.setState({ vulnerabilities: filteredScans });
    }
  };

  clearDomainHandler = (event) => {
    this.setState({ filterScanType: event, filterActive: event });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      state.missingScans.length === 0 ||
      state.nonExistentScans === 0 ||
      state.failScans.length === 0 ||
      state.fpData === 0 ||
      state.exceededScans.length === 0 ||
      state.vulnerabilities.length === 0 ||
      state.scanSchedules.lenth === 0
    ) {
      return {
        missingScans: props.data.missingScan,
        nonExistentScans: props.data.nonExistentScans,
        failScans: props.data.failScan,
        fpData: props.data.falsePositives,
        scanSchedules: props.data.scanSchedules,
      };
    } else {
      if (
        props.data.missingScan !== state.missingScans ||
        props.data.failScan !== state.failScans ||
        props.data.nonExistentScans !== state.nonExistentScans ||
        props.data.falsePositives !== state.fpData ||
        props.data.exceededScans !== state.exceededScans ||
        props.data.vulnerabilities !== state.vulnerabilities ||
        props.data.scanSchedules !== state.scanSchedules
      ) {
        return {
          missingScans: state.missingScans,
          scanSchedules: state.scanSchedules,
          nonExistentScans: state.nonExistentScans,
          failScans: state.failScans,
          fpData: state.fpData,
        };
      }
    }
    return null;
  }

  async componentDidMount() {
    this.setState({
      fpData: this.props.data.falsePositives,
    });
    let params = "";
    switch (this.props.type) {
      case "failScan":
        params = "fail";
        break;
      case "missingScan":
        params = "missing";
        break;
      case "cancelledScan":
        params = "cancel";
        break;
      case "freeScan":
        params = "freescans";
        break;
      case "runningStuck":
        params = "runningstuck";
        break;
      case "falsePositives":
        params = "falsepositives";
        break;
      case "nonExistentScans":
        params = "nonexistentscans";
        break;
      case "vulnerabilities":
        params = "vulnerabilities";
        break;
      default:
        params = "running";
        break;
    }

    let result = null;
    if (this.props.type === "nonExistentScans") {
      result = await callOnGetNonExistentScans();
      this.setState({ nonExistentScans: result.data });
    } else {
      const resultData = await callOnGetBusinessReport(params);
      result = await resultData;
    }
    const scanReportCounts = { ...this.state.scanReportCounts };
    scanReportCounts[this.props.type] =
      result.data !== undefined ? result.data.length : 0;
    // if (this.props.type === "exceededScans") {
    //   const newResultData = _.filter(
    //     result.data,
    //     (r) => r.data.scanInfo.length >= 2
    //   );
    //   scanReportCounts[this.props.type] = newResultData.length;
    //   this.setState({
    //     exceededScans: newResultData,
    //   });
    // }

    if (this.props.type === "vulnerabilities") {
      this.setState({ vulnerabilities: result.data });
    }
    this.setState({ scanReportCounts });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.fpData !== this.state.fpData) {
      this.setState({ fpData: this.props.data.falsePositives });
    }
    if (
      prevState.selectedBusinessId !== this.state.selectedBusinessId ||
      prevState.submitted !== this.state.submitted
    ) {
      if (this.state.selectedBusinessId !== null) {
        const userInfo = await callOnGetUserByBusinessId(
          this.state.selectedBusinessId
        );
        const user = userInfo.data;
        if (user.length > 0) {
          const userId = userInfo.data.length > 0 ? userInfo.data[0]._id : null;
          const userData = user[0];
          const businessInfo = await callOnGetBusinessById(
            this.state.selectedBusinessId
          );
          this.setState({
            business: businessInfo,
            userTimeZone:
              userData.timezone === null
                ? "America/Denver"
                : businessInfo.pciScanTimeZone !== null
                  ? businessInfo.pciScanTimeZone
                  : userData.timezone,
            userScanTime:
              userData.pciScanTime === null
                ? "24"
                : businessInfo.pciScanTime !== null
                  ? businessInfo.pciScanTime
                  : userData.pciScanTime,
            invalidTimeZone:
              userData.timezone === 0 || userData.timezone === null
                ? true
                : false,
            userId: userId,
          });
        }
      }
    }
  }

  updateStatus = async (indexMain, index, event, id) => {
    event.persist();
    await callOnUpdateBusinessFalsePositive(
      this.state.falsepositiveReport,
      id,
      event.target.value
    );
    const fpData = [...this.state.fpData];
    fpData[indexMain].data.falsePositives[index].status = event.target.value;
    this.setState({ fpData });
  };

  processRetrieveMissingScans = async (
    index,
    businessId,
    scanHistoryId,
    domainId,
    daysMissed
  ) => {
    this.setState({ scanRetriving: true, clickedIndex: index });
    await this.props.processMissingScans({
      businessID: businessId,
      scanHistoryID: scanHistoryId,
      domainID: domainId,
      daysMissed: daysMissed,
    });

    this.setState((prevState) => ({
      missingScans: prevState.missingScans.filter((_, i) => i !== index),
      clickedIndex: null,
    }));
    this.setState({ scanRedirect: true });
  };

  populateNonExistentScans = async () => {
    const result = await callOnGetNonExistentScans();
    this.setState({ nonExistentScans: result });
  };

  addLabelHandler = (index, scanId, id) => {
    this.setState({
      toggleScanIndex: index,
      toggleScanId: scanId,
      toggleRowId: id,
    });
  };

  confirmAddLabelHandler = async () => {
    const nonExistentScans = [...this.state.nonExistentScans];
    nonExistentScans[this.state.toggleScanIndex].label = this.state.labelText;
    await callOnUpdateBSTestScan(this.state.toggleRowId, this.state.labelText);
    this.setState({
      nonExistentScans,
      labelText: "",
      toggleScanIndex: null,
      toggleScanId: null,
      toggleRowId: null,
    });
  };

  markDeleteHandler = async (id, index) => {
    const nonExistentScans = this.state.nonExistentScans.filter(
      (el) => el._id !== id
    );
    await callOnMarkDeleteBSTestScan(id);
    this.setState({
      nonExistentScans,
    });
  };

  labelHandler = (event) => {
    event.preventDefault();
    const textLabel = event.target.value;
    this.setState({ labelText: textLabel });
  };

  userBusinessData = () => {
    let userData = getStorageItem("userData");
    if (userData) {
      const userString = cryptr.decrypt(userData);
      const user = JSON.parse(userString);
      return user;
    }
  };

  showFrequencyModal = async (index, businessId) => {
    await callOnGetBusinessById(businessId);
    this.setState({ showFrequencyModal: true, selectedBusinessId: businessId });
  };

  hideFrequencyModalHandler = () => {
    this.setState({ showFrequencyModal: false });
  };

  handleScanTime = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTime"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
    this.setState({ userScanTime: input.target.value });
  };

  handleScanTimeZone = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTimeZone"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
    this.setState({ userTimeZone: input.target.value });

    if (input.target.value === 0) {
      this.setState({ invalidTimeZone: true });
    } else {
      this.setState({ invalidTimeZone: false });
    }
  };

  updateFrequencyHandler = async () => {
    this.setState({ frequencySave: true });
    this.userBusinessData();
    const businessId = this.state.selectedBusinessId;
    const userData = getUserDecryptedData();

    const extraData = { ...this.state.business };
    extraData["action"] = "update_bs_frequency";
    extraData["timeZone"] = this.state.userTimeZone;
    extraData["timeHour"] =
      this.state.beyondSecurityScanInfo.scanTime === null
        ? 24
        : this.state.beyondSecurityScanInfo.scanTime;

    let frequency = extraData.scanFrequency;
    switch (extraData.scanFrequency) {
      case "daily":
        frequency = "days";
        break;
      case "weekly":
        frequency = "weeks";
        break;
      case "monthly":
        frequency = "months";
        break;
      case "quarterly":
        frequency = "quarters";
        break;
      default:
        break;
    }

    await callOnUpdateBusinessFrequency({
      _id: businessId,
      userId: userData.isAdmin ? this.state.userId : userData._id,
      scanFrequency: extraData.scanFrequency,
      timeZone: this.state.userTimeZone,
      timeHour: this.state.beyondSecurityScanInfo.scanTime,
      pciNextScheduleScanDate: moment()
        .add(1, frequency)
        .add(this.state.beyondSecurityScanInfo.scanTime, "hours"),
      business: extraData,
    });

    fetch(Config.urls.linkUrl + "/trustseal-service/" + businessId, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {});

    this.setState({ submitted: true });
    setTimeout(() => {
      this.setState({
        showFrequencyModal: false,
        frequencySave: false,
        submitted: false,
      });
    }, 2000);
  };

  render() {
    const {
      labelText,
      fpData,
      missingScans,
      nonExistentScans,
      scanSchedules,
      // exceededScans,
      vulnerabilities,
      scanRetriving,
      scanRedirect,
      clickedIndex,
      scanReportCounts,
      findDomain,
      filterActive,
      filteredMissingScans,
      filteredFailScans,
      showFrequencyModal,
      userTimeZone,
      beyondSecurityScanInfo,
      userScanTime,
      frequencySave,
      invalidTimeZone,
    } = this.state;

    const {
      type,
      state,
      index,
      populateBusinessUsersData,
      populateDelayModalData,
      userId,
      setSelectedBusinessId,
      viewUserAccountDashboard,
      toggle,
      reportName,
      description,
      timezone,
      data,
    } = this.props;

    let modalFrequency = null;

    if (showFrequencyModal) {
      modalFrequency = (
        <FrequencyModal
          onHandlerTimeZone={this.handleScanTimeZone}
          onHandlerTimeHour={this.handleScanTime}
          timezone={userTimeZone === null ? "America/Denver" : userTimeZone}
          time={
            beyondSecurityScanInfo.scanTime !== null
              ? beyondSecurityScanInfo.scanTime
              : userScanTime
          }
          show={showFrequencyModal}
          close={this.hideFrequencyModalHandler}
          save={this.updateFrequencyHandler}
          onSave={frequencySave}
          title="Scan Options"
          message={Config.messages.changeFrequency}
          type="pci"
          invalidTimeZone={invalidTimeZone}
        />
      );
    }
    let notify = "";
    if (scanRetriving) {
      notify = (
        <ToastMessage
          type="success"
          notifyMessage="Please wait while we retrieve missing scans"
        />
      );
    }

    if (scanRedirect) {
      notify = (
        <ToastMessage
          type="success"
          notifyMessage="Please wait while we redirect you to dashboard"
          autoClose="5000"
        />
      );
    }

    let count = 0;

    switch (this.props.type) {
      case "failScan":
        count = scanReportCounts.failScan;
        break;
      case "missingScan":
        count = scanReportCounts.missingScan;
        break;
      case "runningStuck":
        count = scanReportCounts.runningStuck;
        break;
      case "cancelledScan":
        count = scanReportCounts.cancelledScan;
        break;
      case "runningScan":
        count = scanReportCounts.runningScan;
        break;
      case "freeScan":
        count = scanReportCounts.freeScan;
        break;
      case "nonExistentScans":
        count = nonExistentScans.length;
        break;
      // case "exceededScans":
      //   count = scanReportCounts.exceededScans;
      //   break;
      case "vulnerabilities":
        count = scanReportCounts.vulnerabilities;
        break;
      case "scanSchedules":
        count = scanReportCounts.scanSchedules;
        break;

      default:
        count = scanReportCounts.falsePositives;
    }

    const head6Style = { width: "200px" };

    return (
      <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
        <div className=" tab-content">
          {modalFrequency}
          {notify}
          <div
            id={type + "Reports"}
            className="tab-pane fade in active reportsTab">
            <div className="reportButton">
              <button
                className="btn-success btn-sm"
                onClick={() => toggle(type, index)}>
                {reportName + " (" + count + ")"}
              </button>
              <div className="clearfix" />
            </div>
            <p>{description}.</p>
            <hr />

            {state[index] && index !== 7 ? (
              <>
                {index === 0 || index === 1 ? (
                  <>
                    <form className="form-inline">
                      <div className="form-group mb-2">
                        <label htmlFor="staticEmail2">
                          Filter by domain: &nbsp;
                        </label>
                        <input
                          type="text"
                          onChange={this.findDomainHandler}
                          onClick={() => this.clearDomainHandler(type)}
                          value={findDomain}
                          className="form-control"
                        />
                      </div>
                    </form>
                    <hr />
                  </>
                ) : null}

                <table className="tg table table-bordered table-hover">
                  <thead>
                    {type === "runningScan" || type === "runningStuck" ? (
                      <tr>
                        <th />
                        <th>Company Name</th>
                        <th>Device (ip/url)</th>
                        <th>Frequency</th>
                        <th>Scan start</th>
                        {this.props.type === "runningStuck" ? null : (
                          <th>Progress Status</th>
                        )}
                        <th style={head6Style}>Email Client</th>
                      </tr>
                    ) : type === "cancelledScan" ? (
                      <tr>
                        <th />
                        <th>Company Name</th>
                        <th>Device (ip/url)</th>
                        <th>Frequency</th>
                        <th>Last Complete Scan</th>
                        <th>Canceled Date</th>
                        <th>Email Client</th>
                      </tr>
                    ) : type === "falsePositives" ? (
                      <tr>
                        <th>
                          Device (ip/url) {"     "} &nbsp; &nbsp;/ &nbsp;
                          &nbsp;&nbsp; Message
                        </th>
                        <th>Status</th>
                        <th>Date Submitted</th>
                        <th>Action</th>
                        <th>Email Client</th>
                      </tr>
                    ) : type === "missingScan" ? (
                      <tr>
                        <th />
                        <th>Device (ip/url)</th>
                        <th>Frequency</th>
                        <th>Days not scanned</th>
                        <th />
                        <th />
                      </tr>
                    ) : type === "nonExistentScans" ? null : this.props.type ===
                    "vulnerabilities" ? (
                      <tr>
                        <th />
                        <th>Vulnerability Name</th>
                        <th>Vulnerability ID</th>
                      </tr>
                    ) : (
                      <tr>
                        <th />
                        <th>Device (ip/url)</th>
                        <th>Status</th>
                        <th>Frequency</th>
                        <th>Last Complete Scan</th>
                        <th># of Hrs from Last Scan</th>
                        <th>Email Client</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {type === "failScan"
                      ? filterActive !== null && filteredFailScans.length > 0
                        ? filteredFailScans.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <span
                                    className="a-pointer"
                                    onClick={(e) => {
                                      viewUserAccountDashboard(
                                        e,
                                        result.data._id
                                      );
                                    }}>
                                    {result.data.primaryDomain}
                                  </span>
                                </td>
                                <td>Failed</td>
                                <td>
                                  {_.upperFirst(result.data.scanFrequency)}
                                </td>
                                <td>
                                  {convertTimeTo(
                                    result.data.lastPciScanDate,
                                    timezone
                                  )}
                                </td>
                                <td>
                                  {moment()
                                    .utc()
                                    .diff(
                                      moment(
                                        convertTimeTo(
                                          result.data.lastPciScanDate,
                                          timezone
                                        )
                                      ),
                                      "hours"
                                    )}{" "}
                                  hr(s)
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <button
                                    style={{
                                      float: "left",
                                      marginRight: "10px",
                                    }}
                                    className="btn-success"
                                    data-toggle="modal"
                                    data-target="#failScanPci"
                                    onClick={() => {
                                      setSelectedBusinessId(result.data._id);
                                    }}>
                                    <i className="fa fa-envelope" />
                                  </button>
                                  <div style={{ float: "left" }}>
                                    <ControlButtons
                                      business={result.data}
                                      userId={userId}
                                      populateDelayModalData={
                                        populateDelayModalData
                                      }
                                      populateBusinessUsersData={
                                        populateBusinessUsersData
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : data.failScan.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <span
                                    className="a-pointer"
                                    onClick={(e) => {
                                      viewUserAccountDashboard(
                                        e,
                                        result.data._id
                                      );
                                    }}>
                                    {result.data.primaryDomain}
                                  </span>
                                </td>
                                <td>Failed</td>
                                <td>
                                  {_.upperFirst(result.data.scanFrequency)}
                                </td>
                                <td>
                                  {convertTimeTo(
                                    result.data.lastPciScanDate,
                                    timezone
                                  )}
                                </td>
                                <td>
                                  {moment()
                                    .utc()
                                    .diff(
                                      moment(
                                        convertTimeTo(
                                          result.data.lastPciScanDate,
                                          this.props.timezone
                                        )
                                      ),
                                      "hours"
                                    )}{" "}
                                  hr(s)
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <button
                                    style={{
                                      float: "left",
                                      marginRight: "10px",
                                    }}
                                    className="btn-success"
                                    data-toggle="modal"
                                    data-target="#failScanPci"
                                    onClick={() => {
                                      setSelectedBusinessId(result.data._id);
                                    }}>
                                    <i className="fa fa-envelope" />
                                  </button>
                                  <div style={{ float: "left" }}>
                                    <ControlButtons
                                      business={result.data}
                                      userId={userId}
                                      populateDelayModalData={
                                        populateDelayModalData
                                      }
                                      populateBusinessUsersData={
                                        populateBusinessUsersData
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                      : null}

                    {type === "runningScan"
                      ? data.runningScan.map((result, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <span
                                  className="a-pointer"
                                  onClick={(e) => {
                                    viewUserAccountDashboard(
                                      e,
                                      result.data._id
                                    );
                                  }}>
                                  {result.data.businessName}
                                </span>
                              </td>
                              <td>{result.data.primaryDomain}</td>
                              <td>
                                {_.upperFirst(result.data.businessFrequency)}
                              </td>
                              <td>
                                {moment(result.data.createdAt).format(
                                  "MM-DD-YYYY HH:MM"
                                )}
                              </td>
                              <td>
                                {_.capitalize(result.data.status)}
                                {result.data.status !== "pending"
                                  ? result.data.progress !== null
                                    ? ` - ${result.data.progress}%`
                                    : ""
                                  : null}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <button
                                  style={{ float: "left", marginRight: "10px" }}
                                  className="btn-success"
                                  data-toggle="modal"
                                  data-target="#runningScanPci"
                                  onClick={() => {
                                    setSelectedBusinessId(result.data._id);
                                  }}>
                                  <i className="fa fa-envelope" />
                                </button>
                                <div style={{ float: "left" }}>
                                  <ControlButtons
                                    business={result.data}
                                    userId={userId}
                                    populateDelayModalData={
                                      populateDelayModalData
                                    }
                                    populateBusinessUsersData={
                                      populateBusinessUsersData
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}

                    {type === "runningStuck"
                      ? data.runningStuck.map((result, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <span
                                  className="a-pointer"
                                  onClick={(e) => {
                                    viewUserAccountDashboard(
                                      e,
                                      result.data._id
                                    );
                                  }}>
                                  {result.data.businessName}
                                </span>
                              </td>
                              <td>{result.data.primaryDomain}</td>
                              <td>{_.upperFirst(result.data.scanFrequency)}</td>
                              <td>
                                {moment(result.data.dateLastScanned).format(
                                  "MM-DD-YYYY HH:MM"
                                )}
                              </td>

                              <td style={{ textAlign: "right" }}>
                                <button
                                  style={{ float: "left", marginRight: "10px" }}
                                  className="btn-success"
                                  data-toggle="modal"
                                  data-target="#runningScanPci"
                                  onClick={() => {
                                    setSelectedBusinessId(result.data._id);
                                  }}>
                                  <i className="fa fa-envelope" />
                                </button>
                                <div style={{ float: "left" }}>
                                  <ControlButtons
                                    business={result.data}
                                    userId={userId}
                                    populateDelayModalData={
                                      populateDelayModalData
                                    }
                                    populateBusinessUsersData={
                                      populateBusinessUsersData
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}

                    {type === "freeScan"
                      ? data.freeScan.map((result, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <span
                                  className="a-pointer"
                                  onClick={(e) => {
                                    viewUserAccountDashboard(
                                      e,
                                      result.data._id
                                    );
                                  }}>
                                  {result.data.businessName}
                                </span>
                              </td>
                              <td>{result.data.primaryDomain}</td>
                              <td>
                                {_.upperFirst(result.data.businessFrequency)}
                              </td>
                              <td>
                                {moment(result.data.dateLastScanned).format(
                                  "MM-DD-YYYY HH:MM"
                                )}
                              </td>
                              <td>
                                {moment().diff(
                                  moment(result.data.dateLastScanned),
                                  "hours"
                                )}{" "}
                                hr(s)
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <button
                                  style={{ float: "left", marginRight: "10px" }}
                                  className="btn-success"
                                  data-toggle="modal"
                                  data-target="#freeScanPci"
                                  onClick={() => {
                                    setSelectedBusinessId(result.data._id);
                                  }}>
                                  <i className="fa fa-envelope" />
                                </button>
                                <div style={{ float: "left" }}>
                                  <ControlButtons
                                    business={result.data}
                                    userId={userId}
                                    populateDelayModalData={
                                      populateDelayModalData
                                    }
                                    populateBusinessUsersData={
                                      populateBusinessUsersData
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}

                    {type === "cancelledScan"
                      ? data.cancelledScan !== null
                        ? data.cancelledScan.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <span
                                    className="a-pointer"
                                    onClick={(e) => {
                                      viewUserAccountDashboard(
                                        e,
                                        result.data._id
                                      );
                                    }}>
                                    {result.businessName}
                                  </span>
                                </td>
                                <td>{result.primaryDomain}</td>
                                <td>{_.upperFirst(result.frequency)}</td>
                                <td>
                                  {moment(result.dateLastScanned).format(
                                    "MM-DD-YYYY HH:MM"
                                  )}
                                </td>
                                <td>
                                  {moment(result.canceledAt).format(
                                    "MM-DD-YYYY HH:MM"
                                  )}{" "}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <button
                                    style={{
                                      float: "left",
                                      marginRight: "10px",
                                    }}
                                    className="btn-success"
                                    data-toggle="modal"
                                    data-target="#cancelledScanPci"
                                    onClick={() => {
                                      setSelectedBusinessId(result.data._id);
                                    }}>
                                    <i className="fa fa-envelope" />
                                  </button>
                                  <div style={{ float: "left" }}>
                                    <ControlButtons
                                      business={result.data}
                                      userId={userId}
                                      populateDelayModalData={
                                        populateDelayModalData
                                      }
                                      populateBusinessUsersData={
                                        populateBusinessUsersData
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null
                      : null}

                    {type === "scanSchedules"
                      ? data.scanSchedules !== null
                        ? data.scanSchedules.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <span
                                    className="a-pointer"
                                    onClick={(e) => {
                                      viewUserAccountDashboard(
                                        e,
                                        result.data._id
                                      );
                                    }}>
                                    {result.businessName}
                                  </span>
                                </td>
                                <td>{result.primaryDomain}</td>
                                <td>{_.upperFirst(result.frequency)}</td>
                                <td>
                                  {moment(result.dateLastScanned).format(
                                    "MM-DD-YYYY HH:MM"
                                  )}
                                </td>
                                <td>
                                  {moment(result.canceledAt).format(
                                    "MM-DD-YYYY HH:MM"
                                  )}{" "}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <button
                                    style={{
                                      float: "left",
                                      marginRight: "10px",
                                    }}
                                    className="btn-success"
                                    data-toggle="modal"
                                    data-target="#cancelledScanPci"
                                    onClick={() => {
                                      setSelectedBusinessId(result.data._id);
                                    }}>
                                    <i className="fa fa-envelope" />
                                  </button>
                                  <div style={{ float: "left" }}>
                                    <ControlButtons
                                      business={result.data}
                                      userId={userId}
                                      populateDelayModalData={
                                        populateDelayModalData
                                      }
                                      populateBusinessUsersData={
                                        populateBusinessUsersData
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null
                      : null}

                    {type === "missingScan"
                      ? filterActive !== null && filteredMissingScans.length > 0
                        ? filteredMissingScans.map((result, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <span
                                    className="a-pointer"
                                    onClick={(e) => {
                                      viewUserAccountDashboard(
                                        e,
                                        result.data._id
                                      );
                                    }}>
                                    {result.data.businessName}
                                  </span>
                                </td>
                                <td>{result.data.scanFrequency}</td>
                                <td>
                                  {result.data.daysNotScanned}{" "}
                                  {result.data.daysNotScanned > 1
                                    ? "days"
                                    : "day"}
                                </td>

                                <td>
                                  {scanRetriving && index === clickedIndex ? (
                                    <Spinner />
                                  ) : (
                                    <button
                                      className="primary btn-success"
                                      onClick={() =>
                                        this.processRetrieveMissingScans(
                                          index,
                                          result.data._id,
                                          result.data.scanHistoryId,
                                          result.data.domainId,
                                          result.data.daysNotScanned
                                        )
                                      }>
                                      <i className="fa fa-cloud-download-alt" />{" "}
                                      Retrive Results
                                    </button>
                                  )}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <button
                                    style={{
                                      float: "left",
                                      marginRight: "10px",
                                    }}
                                    className="btn-success"
                                    data-toggle="modal"
                                    data-target="#missingScanPci"
                                    onClick={() => {
                                      setSelectedBusinessId(result.data._id);
                                    }}>
                                    <i className="fa fa-envelope" />
                                  </button>
                                  <div style={{ float: "left" }}>
                                    <ControlButtons
                                      business={result.data}
                                      userId={userId}
                                      populateDelayModalData={
                                        populateDelayModalData
                                      }
                                      populateBusinessUsersData={
                                        populateBusinessUsersData
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : missingScans.length > 0
                          ? missingScans.map((result, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <span
                                      className="a-pointer"
                                      onClick={(e) => {
                                        viewUserAccountDashboard(
                                          e,
                                          result.data._id
                                        );
                                      }}>
                                      {result.data.businessName}
                                    </span>
                                  </td>
                                  <td>{result.data.scanFrequency}</td>
                                  <td>
                                    {result.data.daysNotScanned}{" "}
                                    {result.data.daysNotScanned > 1
                                      ? "days"
                                      : "day"}
                                  </td>
                                  <td>
                                    {scanRetriving && index === clickedIndex ? (
                                      <Spinner />
                                    ) : (
                                      <>
                                        <button
                                          className="primary btn-success"
                                          onClick={() =>
                                            this.processRetrieveMissingScans(
                                              index,
                                              result.data._id,
                                              result.data.scanHistoryId,
                                              result.data.domainId,
                                              result.data.daysNotScanned
                                            )
                                          }>
                                          <i className="fa fa-cloud-download-alt" />{" "}
                                          Retrive Results
                                        </button>
                                        {"  "}&nbsp;&nbsp;
                                        <button
                                          className="primary btn-success"
                                          onClick={() =>
                                            this.showFrequencyModal(
                                              index,
                                              result.data._id
                                            )
                                          }>
                                          <i className="fa fa-clock" />{" "}
                                          Re-schedule
                                        </button>
                                      </>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      style={{
                                        float: "left",
                                        marginRight: "10px",
                                      }}
                                      className="btn-success"
                                      data-toggle="modal"
                                      data-target="#missingScanPci"
                                      onClick={() => {
                                        setSelectedBusinessId(result.data._id);
                                      }}>
                                      <i className="fa fa-envelope" />
                                    </button>
                                    <div style={{ float: "left" }}>
                                      <ControlButtons
                                        business={result.data}
                                        userId={userId}
                                        populateDelayModalData={
                                          populateDelayModalData
                                        }
                                        populateBusinessUsersData={
                                          populateBusinessUsersData
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : null
                      : null}

                    {type === "falsePositives"
                      ? data.falsePositives !== null
                        ? fpData.map((result, indexMain) => {
                            return result.data.falsePositives.map(
                              (fp, index) => {
                                return fp.status === "pending" ? (
                                  <tr key={index}>
                                    <td>{fp.message}</td>
                                    <td style={{ verticalAlign: "top" }}>
                                      {fp.status}
                                    </td>
                                    <td style={{ verticalAlign: "top" }}>
                                      {moment(fp.date).format(
                                        "MM-DD-YYYY HH:MM"
                                      )}
                                    </td>
                                    <td style={{ verticalAlign: "top" }}>
                                      <select
                                        onChange={(event) =>
                                          this.updateStatus(
                                            indexMain,
                                            index,
                                            event,
                                            fp._id
                                          )
                                        }>
                                        <option
                                          value="pending"
                                          selected={fp.status === "pending"}>
                                          Pending
                                        </option>
                                        <option
                                          value="accepted"
                                          selected={fp.status === "accepted"}>
                                          Accept
                                        </option>
                                        <option
                                          value="rejected"
                                          selected={fp.status === "rejected"}>
                                          Reject
                                        </option>
                                      </select>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      <button
                                        style={{
                                          float: "left",
                                          marginRight: "10px",
                                        }}
                                        className="btn-success"
                                        data-toggle="modal"
                                        data-target="#falsePositivesPci"
                                        onClick={() => {
                                          setSelectedBusinessId(fp._id);
                                        }}>
                                        <i className="fa fa-envelope" />
                                      </button>
                                      <div style={{ float: "left" }}>
                                        <ControlButtons
                                          business={result.data}
                                          userId={userId}
                                          populateDelayModalData={
                                            populateDelayModalData
                                          }
                                          populateBusinessUsersData={
                                            populateBusinessUsersData
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ) : null;
                              }
                            );
                          })
                        : null
                      : null}

                    {type === "vulnerabilities"
                      ? vulnerabilities.length > 0
                        ? vulnerabilities.map((v, i) => (
                            <PCIVulnerability
                              key={v.data.vulnerabilityId}
                              name={v.data.name}
                              id={v.data.vulnerabilityId}
                              count={v.data.count}
                            />
                          ))
                        : null
                      : null}
                  </tbody>
                </table>
              </>
            ) : (
              ""
            )}

            {type === "nonExistentScans" && state[index] && index === 7 ? (
              data.nonExistentScans !== null ? (
                <NonExistentScans
                  scans={nonExistentScans}
                  addLabelHandler={this.addLabelHandler}
                  markDeleteHandler={this.markDeleteHandler}
                  labelText={labelText}
                  labelHandler={this.labelHandler}
                  confirmAddLabelHandler={this.confirmAddLabelHandler}
                />
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default reportTab;
