import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  callApiGetBusinessUsersList,
  callApiUpdateUserProfileData,
  callOnGetMissingScanResults,
} from "../../services";

class LckUlckUsrCnfMdl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      populdateUserData: false,
    };
  }

  lockUnlockUser = async () => {
    if (this.props.userData) {
      let payload = {
        purpose: "status",
        userStatus:
          this.props.userData.userStatus === "active" ? "locked" : "active",
      };

      await callApiUpdateUserProfileData(this.props.userData.userId, payload);

      let usrMsg =
        this.props.userData.userStatus === "locked" ? "unlocked" : "locked";
      toast.success("User account " + usrMsg + " successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });

      this.props.rePopulateUserData();

      document.getElementById("btnOpnAccScrtMdl").click();
    }
  };

  render() {
    const { userData } = this.props;

    return (
      <div className="modal fade" id="lckUlckUsrCnfMdl" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">
                {userData.userStatus === "locked" ? "Unlock " : "Lock "}{" "}
                {userData.email}
              </h4>
              <button
                id="btnClsRstPasCnfMdl"
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  document.getElementById("btnOpnAccScrtMdl").click();
                }}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="pt-2 pb-4">
                <h5>
                  Are you sure to{" "}
                  {userData.userStatus === "locked" ? "unlock " : "lock "}
                  {userData.email}?
                </h5>
              </div>
              <div className="modal-footer text-right pt-4 px-0 pb-3">
                <button
                  type="button"
                  className="btn-tglight2 btn-140-width btn-md  mr-2"
                  data-dismiss="modal"
                  onClick={() => {
                    document.getElementById("btnOpnAccScrtMdl").click();
                  }}
                >
                  No
                </button>

                <button
                  type="button"
                  className="btn-success btn-140-width btn-md "
                  data-dismiss="modal"
                  onClick={() => {
                    this.lockUnlockUser();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LckUlckUsrCnfMdl);
