import React from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Aux from "../../../components/hoc/containerAux";
import FalsePositive from "../../../views/home/partials/falsePositive";
import { callOnGetLatestScanData } from "../../../services/scanHistoryService";
import FalsePositiveNotificationBar from "../../../views/home/partials/falsePositiveNotificationBar";
import AppContext from "../../context/appContext";

class BusinessHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanHistory: {},
      insuranceEnabledBusinesses: [
        JSON.stringify("5d6bf0195f92ba0f48ba3f9a"),
        JSON.stringify("5e32e8765d502934714422fd"),
        JSON.stringify("5e559744cffc8a78d38b301b"),
        JSON.stringify("5e9f2ebf0a41632259924ef7"),
        //JSON.stringify("5f3d886091184253adb5cfa4"),
        JSON.stringify("5f62873cf0bba6752ce3f0f6"),
        //JSON.stringify("6192c03372a2d84fd3cb9c1e"),
        JSON.stringify("62b337fe76e9b97159abf88a"),
        JSON.stringify("62fc4945c5a5da059aef74a5"),
      ],
    };
  }

  componentWillReceiveProps(newProps) {}
  async componentDidMount() {
    const latestData = await callOnGetLatestScanData(
      null,
      this.props.businessId
    );
    if (latestData.data.length > 0) {
      this.setState({ scanHistory: latestData.data[0] });
    }
  }

  render() {
    const { insuranceEnabledBusinesses } = this.state;
    const { businessId } = this.props;
    return (
      <Aux>
        <div
          className="row"
          style={{
            marginBottom: "20px",
            borderBottom: "1px solid #cfd2d2",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div className="col-11">
            <div className="row">
              <div
                className={
                  (insuranceEnabledBusinesses.indexOf(
                    JSON.stringify(businessId)
                  ) != -1 &&
                    "col-2") ||
                  "col-4"
                }
              >
                <strong>Business</strong>
              </div>
              <div className="col-2 text-center">
                <strong>PCI Scan</strong>
              </div>
              <div className="col-2 text-center">
                <strong>Malware Scan</strong>
              </div>
              <div className="col-2 text-center">
                <strong>SSL Certificate</strong>
              </div>
              {insuranceEnabledBusinesses.indexOf(JSON.stringify(businessId)) !=
                -1 && (
                <div className="col-2 text-center">
                  <strong>Cyber Insurance</strong>
                </div>
              )}
              <div className="col-2 text-center">
                <strong>Trust Seal</strong>
              </div>
            </div>
          </div>
          <div className="col-1 text-center" />
        </div>
      </Aux>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
BusinessHeader.contextType = AppContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessHeader);
