import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import {
  callApiGetBusinessData,
  callApiModifyBusinessScanStatus,
} from "../../services";
import AppContext from "../../components/context/appContext";
import { convertTimeTo } from ".././../helpers/timeZone";

class ModifyScanDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: "",
      businessId: "",
      scanStatus: null,
      scanNote: "",
      scanDetailsLog: null,
      scanTimeZone: "America/Denver",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState(
        {
          userId: userData._id,
          businessId: currentBusinessId,
        },
        () => {
          this.populateBusinessData();
        }
      );
    }
  }

  populateBusinessData = async () => {
    const business = await callApiGetBusinessData(this.state.businessId);

    if (business) {
      this.setState({
        businessId: this.state.businessId,
        scanStatus: business.pciStatus,
        scanTimeZone:
          business.pciScanTimeZone !== null
            ? business.pciScanTimeZone
            : "America/Denver",
        scanDetailsLog: business.hasOwnProperty("scanDetailsLog")
          ? business.scanDetailsLog.sort((a, b) => {
              let da = new Date(a.updatedAt),
                db = new Date(b.updatedAt);
              return db - da;
            })
          : null,
      });
    }
  };

  noteHandler = (e) => {
    this.setState({
      scanNote: e.target.value,
    });
  };

  scanStatusHandler = (e) => {
    this.setState({
      scanStatus: e.target.value,
    });
  };

  modifyScanDetailHandler = async () => {
    await callApiModifyBusinessScanStatus(
      this.state.businessId,
      this.state.scanStatus,
      this.state.scanNote,
      this.state.userId
    );

    await this.populateBusinessData(this.state.businessId);

    this.setState({
      businessId: null,
      scanStatus: null,
      scanNote: "",
      scanDetailsLog: null,
    });

    toast.success("Business scan status modified successfully.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500,
    });
  };

  render() {
    const {
      businessId,
      scanStatus,
      scanNote,
      scanDetailsLog,
      scanTimeZone,
    } = this.state;

    return (
      <div
        id="modifyScanDetailsModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title f800">Modify Scan Details</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="scanStatus"
                      value="pass"
                      checked={scanStatus === "pass" ? true : false}
                      onChange={(e) => this.scanStatusHandler(e)}
                    />&nbsp;&nbsp;
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Pass Scan Status
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      className="form-check-input"
                      type="radio"
                      name="scanStatus"
                      value="fail"
                      onChange={(e) => this.scanStatusHandler(e)}
                      checked={scanStatus === "fail" ? true : false}
                    />&nbsp;&nbsp;
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Fail Scan Status
                    </label>
                  </div>

                  <div className="form-check" />
                </div>
                <div className="col">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="put your note here..."
                      value={scanNote}
                      onChange={this.noteHandler}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" />
                      <th scope="col">Current Status</th>
                      <th scope="col">Note</th>
                      <th scope="col">Updated by</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scanDetailsLog !== null
                      ? scanDetailsLog.length > 0
                        ? scanDetailsLog.map((detail, index) => {
                            return (
                              index <= 2 && (
                                <tr key={index}>
                                  <th scope="row" />
                                  <td>{detail.currentStatus}</td>
                                  <td>{detail.notes}</td>
                                  <td>{detail.changedByUser}</td>
                                  <td>
                                    {convertTimeTo(
                                      detail.updatedAt,
                                      scanTimeZone
                                    )}
                                  </td>
                                </tr>
                              )
                            );
                          })
                        : null
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn btn-danger btn-140-width btn-md"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-success btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.modifyScanDetailHandler();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModifyScanDetailsModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToDashboard: () => push("/"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyScanDetailsModal);
