import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-toastify/dist/ReactToastify.css";
import Table from "../../components/ui/table/table";
import config from "../../helpers/config";
import { removeStorageItem } from "../../helpers/storage";
import AppContext from "../../components/context/appContext";
import {
  createCssHash,
  createJsHash,
  cssJsHashList,
} from "../../modules/actions/index";

class SealScriptsTab extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: "",
      cssVersion: "",
      jsVersion: "",
      sealScripts: null,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps && newProps.loggedInUser) {
      console.log("NP", newProps);
      this.setState({
        userId: newProps.loggedInUser._id,
      });
    }

    if (newProps && newProps.sealScripts) {
      this.setState(
        {
          sealScripts: newProps.sealScripts,
        },
        () => {
          this.setState({
            cssVersion: "",
            jsVersion: "",
          });
        }
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;
    
    if (
      prevProps.controlType !== this.props.controlType &&
      this.props.controlType === "seal-scripts"
    ) {
      this.props.cssJsHashList();
    }
  }

  onChangeHandelInput = (evt) => {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState({
      [name]: value,
    });
  };

  onClickUploadCss = () => {
    // console.log(this.context.userData);
    if (this.state.cssVersion) {
      const updateData = {
        type: "CSS",
        cssVersion: this.state.cssVersion,
        createdBy: this.context.userData._id,
      };

      this.props.createCssHash(updateData);
    }
  };

  onClickUploadJs = () => {
    if (this.state.jsVersion) {
      const updateData = {
        type: "JS",
        jsVersion: this.state.jsVersion,
        createdBy: this.context.userData._id,
      };

      this.props.createJsHash(updateData);
    }
  };

  render() {
    const { cssVersion, jsVersion, sealScripts } = this.state;

    const cssHashListHeader = [
      { name: "Css Version" },
      { name: "CSS SRI HASH" },
    ];

    const jsHashListHeader = [{ name: "JS Version" }, { name: "JS SRI HASH" }];

    removeStorageItem("businessID");
    return (
      <div id="upload-scripts" className="tab-pane fade">
        <h4 className="">Css</h4>
        <div>
          <span>Css Version</span>
          <br />
          <input
            type="text"
            className="form-control p-sm"
            placeholder="Enter css version"
            name="cssVersion"
            value={cssVersion}
            onChange={this.onChangeHandelInput}
          />
        </div>

        <div
          className="alert alert-info"
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "5px solid #31708f",
          }}
        >
          The current stylesheet located at certstyle.js will be updated to
          version {cssVersion}
        </div>

        <div
          style={{
            display: "block",
            width: "100%",
            marginTop: "25px",
          }}
        >
          <button
            className="btn-success btn-md btn-140-width pull-right"
            onClick={() => this.onClickUploadCss()}
          >
            Upload
          </button>
        </div>

        <br />

        <h4 className="">JS</h4>
        <div>
          <span>JS Version</span>
          <br />
          <input
            type="text"
            className="form-control p-sm"
            placeholder="Enter js version"
            name="jsVersion"
            value={jsVersion}
            onChange={this.onChangeHandelInput}
          />
        </div>

        <div
          className="alert alert-info"
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "5px solid #31708f",
          }}
        >
          The current script located at tgscript.js will be updated to version{" "}
          {jsVersion}
        </div>

        <div
          style={{
            display: "block",
            width: "100%",
            marginTop: "25px",
          }}
        >
          <button
            className="btn-success btn-md btn-140-width pull-right"
            onClick={() => this.onClickUploadJs()}
          >
            Upload
          </button>
        </div>

        <br />
        <br />
        <br />
        <br />

        <h4 className="">CSS List</h4>
        <Table headers={cssHashListHeader} trclassName="bg-torq-light">
          <tbody className="scan-status-list">
            {sealScripts &&
            sealScripts.total &&
            sealScripts.data[0].css.length > 0 ? (
              sealScripts.data[0].css.map((v, i) => {
                return (
                  <tr key={i}>
                    <td style={{ width: "50%" }}>
                      <button
                        className="btn btn-sm"
                        onClick={() =>
                          window.open(
                            "https://" +
                              config.seals.s3Bucket +
                              ".s3.amazonaws.com/cert-style-v" +
                              v.version +
                              ".css"
                          )
                        }
                      >
                        <strong>{v.version}</strong>
                      </button>
                    </td>
                    <td>{v.hash}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="2">
                  <div className="alert alert-info">No result.</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <h4 className="">JS List</h4>
        <Table headers={jsHashListHeader} trclassName="bg-torq-light">
          <tbody className="scan-status-list">
            {sealScripts &&
            sealScripts.total &&
            sealScripts.data[0].js.length > 0 ? (
              sealScripts.data[0].js.map((v, i) => {
                return (
                  <tr key={i}>
                    <td style={{ width: "50%" }}>
                      <button
                        className="btn btn-sm"
                        onClick={() =>
                          window.open(
                            "https://" +
                              config.seals.s3Bucket +
                              ".s3.amazonaws.com/tgscript-v" +
                              v.version +
                              ".js"
                          )
                        }
                      >
                        <strong>{v.version}</strong>
                      </button>
                    </td>
                    <td>{v.hash}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="2">
                  <div className="alert alert-info">No result.</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.accountStore.loggedInUser,
    sealScripts: state.accountStore.sealScripts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createCssHash, createJsHash, cssJsHashList },
    dispatch
  );
};

SealScriptsTab.contextType = AppContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealScriptsTab);
