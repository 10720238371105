import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TableMalwareContent from "../tableMalwareContents";
import {
  callMalwareSearchByBusinessId,
  callOnMalwareScan,
  callGetMalwareHistory,
  callFindMalwareVulnerabilityByReportUrl,
} from "../../../../services/index";
import AppContext from "../../../../components/context/appContext";
import MalwareReportButton from "./buttons/malwareReportButton";
import ModalMalwareVulnerability from "../modals/modalMalwareVunerabilityInfo";
import MalwareDomainsRow from "./_partials/malwareDomainsRow";
import MalwareRescanModal from "../../../../components/dashboard/partials/modals/modalRescan";
import { malwareRowStatus } from "../../../../helpers/general";
import moment from "moment";
import momentz from "moment-timezone";

class MalwareSlidePanelData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      malwarehistories: [],
      malwareInfoData: [],
      malwareInfoDataNotClean: [],
      scanFrequency: "",
      domainRows: [],
      business: "",
      selectedMalwareHistoryId: null,
    };
  }

  getUserBusinessData() {
    const { index } = this.props;
    const business = this.context.userBusinessData[index];
    return business;
  }

  hasMalwareRules() {
    const business = this.getUserBusinessData();
    let hasMalwareRuleActive = false;
    if (business.hasOwnProperty("malwareRules")) {
      if (business.malwareRules.length > 0) {
        hasMalwareRuleActive = true;
      }
    }

    return hasMalwareRuleActive;
  }

  async componentDidMount() {
    const business = this.getUserBusinessData();
    const status = business.lastMalwareScanResult;
    const businessId = business._id;
    const malwareData = await callMalwareSearchByBusinessId(businessId);
    this.setState({
      malwarehistories: malwareData.data,
      scanFrequency: business.scanFrequency,
      business: business,
    });

    const rows = [];
    const domains = [];
    for (let j = 0; j < malwareData.data.length; j++) {
      rows.push({ selectedRow: null, active: false });
    }
    this.setState({ domainRows: rows });
  }

  getMalwareHistoryInfo = async (malwareHistoryID) => {
    // START MALWARE GET DATA
    const business = this.getUserBusinessData();
    this.setState({ selectedMalwareHistoryId: malwareHistoryID });
    const results = await callFindMalwareVulnerabilityByReportUrl(
      malwareHistoryID,
      this.props.businessId
    );
    const domains = results.data;

    this.setState({ malwareInfoData: domains });
    // END MALWARE GET DATA
  };

  showInnerTable = async (index) => {
    const domainRows = [...this.state.domainRows];
    domainRows[index].active = !domainRows[index].active;
    domainRows[index].selectedRow = index;
    this.setState({
      domainRows,
    });
  };

  startMalwareScan = (businessID) => {
    let businessId = businessID;
    if (businessID === undefined) {
      const business = this.getUserBusinessData();
      businessId = business._id;
    }
    let createMalwareScan = {
      action: "create_malware_scan",
      businessID: businessId,
      scanType: "manual",
    };
    callOnMalwareScan(createMalwareScan).then((response) => {
      window.location.reload();
    });
  };

  render() {
    const {
      malwarehistories,
      scanFrequency,
      malwareInfoData,
      domainRows,
      selectedMalwareHistoryId,
      business,
    } = this.state;
    const { index } = this.props;
    let styleStatus = null;
    let iconStatus = null;
    return (
      <TableMalwareContent>
        {malwarehistories.map((malware, i) => {
          const status = malware.scanStatus;
          const malwareStatus = malwareRowStatus(status);

          const modalMalwareRescanButton =
            i === 0 ? (
              <MalwareRescanModal
                index={i}
                businessId={malware.businessId}
                rescan={this.startMalwareScan}
                status={status}
              />
            ) : null;

          return (
            <>
              <tr>
                <td />
                <td>
                  {malware.scanStatus !== "undef"
                    ? momentz(malware.scanStart)
                        .tz(
                          business.pciScanTimeZone !== undefined &&
                          business.pciScanTimeZone !== null &&
                          business.pciScanTimeZone !== ""
                            ? business.pciScanTimeZone
                            : "America/Chicago"
                        )
                        .format("MM/DD/YYYY hh:mm a")
                    : "N/A"}
                </td>
                <td style={{ textTransform: "capitalize" }}>{scanFrequency}</td>
                <td>
                  <span style={malwareStatus.styleStatus}>
                    <i class={malwareStatus.iconStatus} /> &nbsp;
                    {malware.scanStatus !== "undef"
                      ? malware.scanStatus
                      : "N/A"}
                  </span>
                </td>
                <td>
                  <MalwareReportButton
                    status={malware.scanStatus}
                    targetID={malware._id}
                    getMalwareInfo={this.getMalwareHistoryInfo}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  {malware.domains.length}
                </td>
                <td class="text-center text-success">
                  {/* {malware.domains.length >= 2 ? (
                    <span
                      class="pointer"
                      style={{ display: "block" }}
                      onClick={() => this.showInnerTable(i)}>
                      {domainRows[i].selectedRow === i && domainRows[i].active
                        ? "View Less"
                        : "View More"}
                    </span>
                  ) : null} */}
                </td>
                <td>
                  {malware.scanStatus === "running" ||
                  malware.scanStatus === "undef"
                    ? null
                    : modalMalwareRescanButton}
                </td>
              </tr>
              <ModalMalwareVulnerability
                malwareInfoData={malwareInfoData}
                targetID={malware._id}
                sentForReview={malware.sentForReview}
                index={index}
                selectedMalwareHistoryId={selectedMalwareHistoryId}
              />
            </>
          );
        })}
      </TableMalwareContent>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
MalwareSlidePanelData.contextType = AppContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareSlidePanelData);
