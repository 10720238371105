import React from "react";
import ControlButtons from "../controlButtons";
import Spinner from "../../../components/ui/spinner/spinnerRect";
function MissingScans({
  index,
  isLoading,
  missingScans,
  getMissingScans,
  goToDashboard,
  isRetrievingScans,
  selectedIndex,
  retrieveMissingScans,
  missingScanActive,
}) {
  return (
    <>
      <div className="reportButton">
        <button
          disabled={isLoading}
          className="btn-success btn-sm"
          onClick={() => getMissingScans()}>
          {isLoading ? "Loading..." : "MISSING SCANS"}
        </button>
        <div className="clearfix" />
      </div>
      <p>Sites that have not been scanned with their scheduled time</p>
      <hr />
      {missingScanActive && !isLoading && missingScans.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th />
                <th>Device (ip/url)</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {missingScans.map((scan, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <span
                      className="a-pointer"
                      onClick={() => {
                        goToDashboard(scan.businessId);
                      }}>
                      {scan.businessName}
                    </span>
                  </td>

                  <td>
                    {isRetrievingScans && selectedIndex === index ? (
                      <Spinner />
                    ) : (
                      <button
                        className="primary btn-success"
                        onClick={() =>
                          retrieveMissingScans(
                            scan.businessId,
                            scan.domain,
                            scan.daysNotScanned,
                            index
                          )
                        }>
                        <i className="fa fa-cloud-download-alt" /> Retrive
                        Results
                      </button>
                    )}
                  </td>
                  <td>
                    <ControlButtons businessId={scan.businessId} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default MissingScans;
