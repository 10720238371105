import React from 'react';
import { Alert } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  callApiGetBusinessUsersList,
  callApiUpdateUserProfileData,
  callOnGetMissingScanResults
} from '../../services';

class SealGoogleTagManagerCnfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { toggleGoogleTagManager } = this.props;

    return (
      <div id="sealGoogleTagManagerCnfModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content col-md-12 seal-preset-google-tag-container">
            <div className="seal-preset-create-header">
              <h4 className="seal-preset-create-title">Google Tag Manager</h4>
              <button
                type="button"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="seal-preset-create-content">
              Note: Google Tag Manager does not support SRI hashes, which is an extra security
              feature that we use that allows us to force the code to break if it is ever
              compromised. While this scenario is very unlikely, it's important that you fully
              understand the risks involved if you choose to use our code with GTM.
            </div>

            <div className="seal-preset-create-action">
              <button
                id="hideModalOnSuccess"
                type="button"
                className="action-btn btn-cancel"
                data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="action-btn btn-create"
                data-dismiss="modal"
                onClick={() => toggleGoogleTagManager(true)}
                disabled={this.state.isDisabled}>
                I agree
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealGoogleTagManagerCnfModal);
