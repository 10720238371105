import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _, { set } from "lodash";
import config from "../../helpers/config";
import {
  callApiGetBusinessUsersList,
  callApiUpdateUserProfileData,
  callOnGetMissingScanResults,
} from "../../services";
import AppContext from "../../components/context/appContext";
import LockUnlockUser from "../../components/lockUnlockUser/lockUnlockUser";
import logout from "../../components/logout/logout";

class AccountSecurityModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isLoading: true,
      businessId: "",
      businessUsersList: [],
      selectedUserId: null,
      selectedUserData: null,
      editUser: false,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      timezone: "",
      userAccessRole: "",
      isPrimaryUser: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState(
        {
          businessId: currentBusinessId,
        },
        async () => {
          const businessUsersList = await callApiGetBusinessUsersList({
            businessId: this.state.businessId,
          });

          if (prevState.businessUsersList.length === 0) {
            if (
              businessUsersList &&
              prevState.businessUsersList.length !==
                businessUsersList.data.length
            ) {
              this.setState({
                isLoading: false,
                businessUsersList: businessUsersList.data,
              });
            }
          } else {
            if (
              businessUsersList &&
              JSON.stringify(prevState.businessUsersList[0]._id) !==
                JSON.stringify(businessUsersList.data[0]._id)
            ) {
              this.setState({
                isLoading: false,
                businessUsersList: businessUsersList.data,
              });
            }
          }
        }
      );
    }

    if (prevProps.rePopulateUserData !== this.props.rePopulateUserData) {
      await this.populateBusinessUserData();
    }
  }

  editUser(user) {
    this.setState(
      {
        selectedUserId: user._id,
        selectedUserData: user,
        editUser: true,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        timezone: user.timezone,
        userAccessRole: user.userBusinessRole,
        isPrimaryUser: user.primaryUser,
      },
      () => {}
    );
  }

  onChangeHandelUserInput = (evt) => {
    let name = evt.target.name;
    let value = evt.target.value;

    this.setState({
      [name]: value,
    });
  };

  async onClickUpdateUser() {
    let targetUserBusinessDetails = this.state.selectedUserData.businessDetails;

    targetUserBusinessDetails.map((v, i) => {
      if (v.businessId === this.state.businessId) {
        v.groupType = this.state.userAccessRole;
      }
      return null;
    });

    let payload = {
      purpose: "profile",
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      timezone: this.state.timezone,
      businessDetails: targetUserBusinessDetails,
    };

    await callApiUpdateUserProfileData(this.state.selectedUserId, payload);
    await this.populateBusinessUserData();

    this.setState(
      {
        editUser: false,
      },
      () => {
        toast.success("User updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  }

  updateUserStatus = async (user) => {
    // Close account security modal
    document.getElementById("btnClsAccScrtMdl").click();

    // Open lock/unlock user confirmation modal
    document.getElementById("btnOpnLckUlckUsrCnfMdl").click();

    this.props.setUserData({
      userId: user._id,
      userStatus: user.userStatus,
      email: user.email,
    });
  };

  populateBusinessUserData = async () => {
    const businessUsersList = await callApiGetBusinessUsersList({
      businessId: this.state.businessId,
    });

    this.setState({
      businessUsersList: businessUsersList.data,
    });
  };

  resetUsersPassword = async (email) => {
    if (email) {
      // Close account security modal
      document.getElementById("btnClsAccScrtMdl").click();

      // Open reset password confirmation modal
      document.getElementById("btnOpnRstPasCnfMdl").click();

      this.props.setEmailForResettingPassword(email);
    }
  };

  viewSentEmails = async (user) => {
    if (user) {
      // Close account security modal
      document.getElementById("btnClsAccScrtMdl").click();

      // Open reset password confirmation modal
      document.getElementById("btnOpnSntEmlMdl").click();

      this.props.setUserForEmailList(user._id);
    }
  };

  render() {
    const {
      isLoading,
      businessId,
      businessUsersList,
      selectedUserId,
      selectedUserData,
      editUser,
      firstName,
      lastName,
      phone,
      email,
      timezone,
      userAccessRole,
      isPrimaryUser,
    } = this.state;

    return (
      <div
        id="accountSecurityModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">
                {editUser
                  ? "Edit User - " + firstName + " " + lastName
                  : "Account Security - Users"}
              </h4>
              <button
                type="button"
                id="btnClsAccScrtMdl"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                style={editUser ? { display: "block" } : { display: "none" }}
              >
                <div className="col-md-6">
                  <p>
                    Firstname:
                    <input
                      value={firstName}
                      type="text"
                      className="form-control"
                      name="firstName"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    Lastname:{" "}
                    <input
                      value={lastName}
                      type="text"
                      className="form-control"
                      name="lastName"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    Email:{" "}
                    <input
                      value={email}
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    Phone:{" "}
                    <input
                      value={phone}
                      type="text"
                      className="form-control"
                      name="phone"
                      onChange={this.onChangeHandelUserInput}
                    />
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    Timezone:
                    <select
                      className="form-control"
                      onChange={(evt) => {
                        this.setState({
                          timezone: evt.target.value,
                        });
                      }}
                      name="timezone"
                      value={timezone}
                    >
                      <option>Select Timezone</option>
                      {config.timezones.map((v, i) => {
                        return (
                          <option key={i} value={v.timezone}>
                            {v.timezone}
                          </option>
                        );
                      })}
                    </select>
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    Security Access Level:
                    <select
                      className="form-control"
                      value={userAccessRole}
                      name="groupType"
                      onChange={(evt) => {
                        this.setState({
                          userAccessRole: evt.target.value,
                        });
                      }}
                      disabled={isPrimaryUser}
                    >
                      <option>Select A Role For User</option>
                      <option value="Account Admin">Account Admin</option>
                      <option value="Account Manager">Account Manager</option>
                      <option value="Scan Report Access">
                        Scan Report Access
                      </option>
                    </select>
                  </p>
                </div>
                <div className="col-sm-12">
                  <p className="text-right">
                    <button
                      id="cancelButton"
                      type="button"
                      className="btn-tglight2 btn-md btn-140-width mr-2"
                      style={{ marginRight: "7px" }}
                      onClick={() => {
                        this.setState({
                          editUser: false,
                        });
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-success btn-md btn-140-width mt-2"
                      onClick={() => {
                        this.onClickUpdateUser(selectedUserId);
                      }}
                    >
                      Save
                      <br />
                    </button>
                  </p>
                </div>
              </div>

              <div
                className="col-lg-12 col-md-12"
                style={editUser ? { display: "none" } : { display: "block" }}
              >
                {isLoading === true ? (
                  "Loading ..."
                ) : (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          <label>Name</label>
                        </th>
                        <th>
                          <label>Email</label>
                        </th>
                        <th>
                          <label>Security Access Level</label>
                        </th>
                        <th>
                          <label>Status</label>
                        </th>
                        <th>
                          <label>Action</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessUsersList && businessUsersList.length ? (
                        businessUsersList.map((eachValue, eachIndex) => {
                          return (
                            <tr key={eachIndex}>
                              <td>
                                {eachValue.firstName + " " + eachValue.lastName}
                              </td>
                              <td>{eachValue.email}</td>
                              <td>
                                {eachValue.businessDetails.map((v, i) => {
                                  return v.businessId === businessId
                                    ? v.groupType
                                    : "";
                                })}
                              </td>
                              <td>{eachValue.userStatus}</td>
                              <td>
                                <td>
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-danger dropdown-toggle btn-sm"
                                      type="button"
                                      data-toggle="dropdown"
                                    >
                                      Option
                                      <span className="caret" />
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li className="pointer">
                                        <button
                                          data-toggle="modal"
                                          data-target="#confirmModal"
                                          onClick={(e) => {
                                            this.updateUserStatus(eachValue);
                                          }}
                                          className="tg-dashboard-link"
                                        >
                                          {eachValue.userStatus === "locked"
                                            ? "Unlock Account"
                                            : "Lock Account"}
                                        </button>
                                      </li>
                                      <li className="pointer">
                                        <button
                                          className="tg-dashboard-link"
                                          onClick={(e) => {
                                            this.resetUsersPassword(
                                              eachValue.email
                                            );
                                          }}
                                        >
                                          Reset Password
                                        </button>
                                      </li>
                                      <li className="pointer">
                                        <button
                                          className="tg-dashboard-link"
                                          onClick={(e) => {
                                            this.viewSentEmails(eachValue);
                                          }}
                                        >
                                          Sent Emails
                                        </button>
                                      </li>
                                      <li className="pointer">
                                        <button
                                          className="tg-dashboard-link"
                                          onClick={(e) => {
                                            this.editUser(eachValue);
                                          }}
                                        >
                                          Edit User Details
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5">No records.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountSecurityModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToDashboard: () => push("/"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSecurityModal);
