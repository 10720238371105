import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Aux from '../hoc/containerAux';

class ToastMessage extends React.Component {
  notification = type => {
    const customToastId = '8PMFx:.74VzhG_+M';

    switch (type) {
      case 'info':
        if (this.props.autoClose) {
          toast.info(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: this.props.autoClose
          });
        } else {
          toast.info(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        break;
      case 'success':
        if (!toast.isActive(this.toastId)) {
          this.toastId = null;
        }

        if (this.props.autoClose) {
          toast.success(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: customToastId,
            autoClose: this.props.autoClose
          });
        } else {
          toast.success(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: customToastId
          });
        }

        break;
      case 'warning':
        if (this.props.autoClose) {
          toast.warn(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: this.props.autoClose
          });
        } else {
          toast.warn(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        break;
      case 'error':
        if (!toast.isActive(this.toastId)) {
          this.toastId = null;
        }

        if (this.props.autoClose) {
          toast.error(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: customToastId,
            autoClose: this.props.autoClose
          });
        } else {
          toast.error(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: customToastId
          });
        }

        break;
      default:
        if (this.props.autoClose) {
          toast.warn(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: this.props.autoClose
          });
        } else {
          toast.warn(this.props.notifyMessage, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
    }
  };
  render() {
    return (
      <Aux>
        123
        <ToastContainer autoClose={this.props.autoClose !== null ? this.props.autoClose : 2000} />
        {this.props.type ? this.notification(this.props.type) : ''}
      </Aux>
    );
  }
}

export default ToastMessage;
