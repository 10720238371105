import { api } from "./api";
import { callApi } from "../helpers/index";

export const callApiFindEmailData = async () => {
  return callApi().then((token) => {
    return api.service("emails").find({});
  });
};

export const callApiCreateEmailData = async (data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("emails").create(data);
    } else {
      return null;
    }
  });
};

export const callApiUpdateEmailData = async (id, data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("emails").patch(id, data);
    } else {
      return null;
    }
  });
};

export const callApiDeleteEmailData = async (id) => {
  return callApi().then((token) => {
    if (id) {
      return api.service("emails").remove(id);
    } else {
      return null;
    }
  });
};

export function callSendPenetrationTest(data) {
  return callApi().then((token) => {
    return api
      .service("general")
      .create(data)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  });
}
