import React from "react";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import Switch from "react-switch";
import ContentLoader from "react-content-loader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";

import config from "../../helpers/config";
import { images } from "../../helpers/images";
import { getStorageItem } from "../../helpers/storage";
import Aux from "../../components/hoc/containerAux";
import Spinner from "../../components/ui/spinner/spinnerRectScan";
import AppContext from "../../components/context/appContext";
import TrustSealsInfo from "../modals/trustSealsInfo";
import TrustSealsCreatePreset from "../modals/trustSealsCreatePreset";
import ColorPicker from "../../components/color/colorPicker";
import SealGoogleTagManagerCnfModal from "../modals/sealGoogleTagManagerCnfModal";

import {
  callApiGetBusinessData,
  callApiGetTrustSealsData,
  callApiCreateTrustSealsData,
  callApiUpdateTrustSealsData,
  callApiPatchTrustSealsData,
  callApiCreateMissingTrustSeals,
  callApiGetCssJsHashList,
} from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

const sliderStyle = {
  position: "relative",
  width: "95%",
  height: 80,
  border: "none",
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 8,
  marginTop: 35,
  borderRadius: 2,
  backgroundColor: "#fff",
};

export function Handle({
  handle: { id, value, percent },
  getHandleProps,
  sealType = null,
}) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: 0,
        marginTop: 30,
        zIndex: 2,
        width: 18,
        height: 18,
        border: 0,
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "50%",
        backgroundColor: "#28a745",
        color: "#333",
      }}
      {...getHandleProps(id)}
    >
      <div style={{ fontFamily: "Roboto", fontSize: 11, marginTop: -35 }}>
        {(sealType === "textShield" &&
          ((value === 0 && "Small") ||
            (value === 2 && "Medium") ||
            (value === 4 && "Large"))) ||
          value + "px"}
      </div>
    </div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: "#28a745",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}
class SealComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      businessId: null,
      codeGenerated: false,
      businessData: "",
      certificateURL: "CERTIFICATE_PATH/certificate/PRIMARY_DOMAIN",
      sealCode: {
        /*eslint-disable*/
        fixed:
          '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART <div _ID class="_TG_SEALCODE_CLASS _ONE"_POSITION>_STANDALONE_ANCHOR_START<img alt="Trust Guard Security Scanned" _IMG_ID class="tgfloat-inner" src="_THREE" style="border: 0; _SIZE " _ONCONTEXTMENU />_STANDALONE_ANCHOR_END</div> _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',

        custom:
          '<a name="trustlink" href="_CERTIFICATE_URL" rel="nofollow" target="_blank" onclick="var nonwin=navigator.appName!=\'Microsoft Internet Explorer\'?\'yes\':\'no\'; window.open(this.href,\'welcome\',\'location=\'+nonwin+\',scrollbars=yes,width=517,height=\'+screen.availHeight+\',menubar=no,toolbar=no\'); return false;" oncontextmenu="var d = new Date(); alert(\'Copying Prohibited by Law - This image and all included logos are copyrighted by trust-guard \\251 \'+d.getFullYear()+\'.\'); return false;" > <img name="trustseal" alt="Security Seals" style="border: 0;" src="_BUSINESS_SEAL" /></a>',
        /*eslint-enable*/
        textboxCode: "",
      },
      trustSealsData: null,
      presetType: null,
      presetName: "",
      label: "",
      color: "green",
      sealType: "outer",
      floatSide: "left",
      width: "225",
      distanceFromEdge: "0",
      colorSettings: {
        green: {
          placeholderShieldOuter: "#333333",
          placeholderShieldInner: "#FFFFFF",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#33981f",
          placeholderBodyRight: "#43b12a",
          placeholderStatusColor: "#ffffff",
          placeholderDateColor: "#ffffff",
          placeholderLabelColor: "#ffffff",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#ffffff",
          placeholderSecuredText: "#ffffff",
          placeholderShieldBg: "#33981F",
          placeholderShieldTop: "#33981F",
          placeholderPrimaryLayer: "#ffffff",
          placeholderSecondaryLayer: "#ffffff",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#ffffff",
          placeholderSealBorder: "#33981F",

          placeholderInsuranceBorder: "#e5e5e5",
          placeholderInsuredText: "#25aae1",
          placeholderGeneralText: "#333333",
          placeholderActiveText: "#404041",
          placeholderInsuranceSeparator: "#e5e5e5",
          placeholderInsuranceInfoBorder: "#25aae1",
          placeholderInnerShieldLeftTop: "#98d6f0",
          placeholderInnerShieldLeftBottom: "#7fcced",
          placeholderInnerShieldRightBottom: "#29aae1",
          placeholderInnerShieldRightTop: "#0c6d94",
          placeholderInnerShieldMiddleOne: "#84de65",
          placeholderInnerShieldMiddleTwo: "#7fcced",
        },
        black: {
          placeholderShieldOuter: "#ffffff",
          placeholderShieldInner: "#333333",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#333333",
          placeholderBodyRight: "#434343",
          placeholderStatusColor: "#ffffff",
          placeholderDateColor: "#ffffff",
          placeholderLabelColor: "#ffffff",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#ffffff",
          placeholderSecuredText: "#ffffff",
          placeholderShieldBg: "#333333",
          placeholderShieldTop: "#333333",
          placeholderPrimaryLayer: "#ffffff",
          placeholderSecondaryLayer: "#ffffff",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#333333",
          placeholderSealBorder: "#333333",

          placeholderInsuranceBorder: "#e5e5e5",
          placeholderInsuredText: "#25aae1",
          placeholderGeneralText: "#333333",
          placeholderActiveText: "#404041",
          placeholderInsuranceSeparator: "#e5e5e5",
          placeholderInsuranceInfoBorder: "#25aae1",
          placeholderInnerShieldLeftTop: "#98d6f0",
          placeholderInnerShieldLeftBottom: "#7fcced",
          placeholderInnerShieldRightBottom: "#29aae1",
          placeholderInnerShieldRightTop: "#0c6d94",
          placeholderInnerShieldMiddleOne: "#84de65",
          placeholderInnerShieldMiddleTwo: "#7fcced",
        },
        white: {
          placeholderShieldOuter: "#333333",
          placeholderShieldInner: "#FFFFFF",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#f2f2f2",
          placeholderBodyRight: "#ffffff",
          placeholderStatusColor: "#333333",
          placeholderDateColor: "#333333",
          placeholderLabelColor: "#333333",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#333333",
          placeholderSecuredText: "#33981f",
          placeholderShieldBg: "#FFFFFF",
          placeholderShieldTop: "#FFFFFF",
          placeholderPrimaryLayer: "#333333",
          placeholderSecondaryLayer: "#333333",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#ffffff",
          placeholderSealBorder: "#f5f5f5",

          placeholderInsuranceBorder: "#e5e5e5",
          placeholderInsuredText: "#25aae1",
          placeholderGeneralText: "#333333",
          placeholderActiveText: "#404041",
          placeholderInsuranceSeparator: "#e5e5e5",
          placeholderInsuranceInfoBorder: "#25aae1",
          placeholderInnerShieldLeftTop: "#98d6f0",
          placeholderInnerShieldLeftBottom: "#7fcced",
          placeholderInnerShieldRightBottom: "#29aae1",
          placeholderInnerShieldRightTop: "#0c6d94",
          placeholderInnerShieldMiddleOne: "#84de65",
          placeholderInnerShieldMiddleTwo: "#7fcced",
        },
        grey: {
          placeholderShieldOuter: "#333333",
          placeholderShieldInner: "#FFFFFF",
          placeholderShieldLeft: "#66CC3E",
          placeholderShieldRight: "#33981F",
          placeholderBodyLeft: "#f2f2f2",
          placeholderBodyRight: "#ffffff",
          placeholderStatusColor: "#333333",
          placeholderDateColor: "#333333",
          placeholderLabelColor: "#333333",
          placeholderKeyHole: "#ffffff",
          placeholderTrustGuardText: "#333333",
          placeholderSecuredText: "#333333",
          placeholderShieldBg: "#FFFFFF",
          placeholderShieldTop: "#FFFFFF",
          placeholderPrimaryLayer: "#333333",
          placeholderSecondaryLayer: "#333333",
          placeholderCheckoutSeparator: "#e6e6e5",
          placeholderShieldInnerBorder: "#ffffff",
          placeholderSealBorder: "#f5f5f5",

          placeholderInsuranceBorder: "#e5e5e5",
          placeholderInsuredText: "#25aae1",
          placeholderGeneralText: "#333333",
          placeholderActiveText: "#404041",
          placeholderInsuranceSeparator: "#e5e5e5",
          placeholderInsuranceInfoBorder: "#25aae1",
          placeholderInnerShieldLeftTop: "#98d6f0",
          placeholderInnerShieldLeftBottom: "#7fcced",
          placeholderInnerShieldRightBottom: "#29aae1",
          placeholderInnerShieldRightTop: "#0c6d94",
          placeholderInnerShieldMiddleOne: "#84de65",
          placeholderInnerShieldMiddleTwo: "#7fcced",
        },
      },
      selectedPresetId: null,
      newPresetName: "",
      isSealSaved: false,
      trustSealPath: null,
      demoTrustSealPath: null,
      trustSealPathForWebsiteCode: null,
      isTextElementChanged: false,
      isCopied: false,
      trustsealHost: config.seals.sealsHost,
      displayBlock: "none",
      changingSealParameter: "",
      conditionalNotificationParameters: [
        "sealType",
        "floatSide",
        "distanceFromEdge",
      ],
      sealScriptsCss: "",
      sealScriptsJs: "",
      disableFloatSide: false,
      displayOnChangeSealModal: true,
      sealMaxWidth: 175,
      sealMaxWidthOnMobile: 145,
      showMobileSeal: false,
      userInfoText: null,
      sealFontFamily: "roboto",
      sealFontSize: "16",
      includeGoogleRobotoFont: true,
      sealFontColor: "#0066C0",
      isGoogleTagManagerEnabled: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.saveSealFontColor = this.saveSealFontColor.bind(this);
  }

  async componentDidMount() {
    await this.getTrustsealsData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    let currentUrl = window.location.href;
    let targetPage = currentUrl.split("/");

    if (targetPage[targetPage.length - 1] !== "trust-seals") {
      if (
        currentBusinessId !== null &&
        prevState.businessId !== currentBusinessId
      ) {
        await this.getTrustsealsData();
      }
    } else {
    }
  }

  getTrustsealsData = async () => {
    // console.log("calling getTrustsealsData");
    let businessId = null;
    const { currentBusinessId } = this.context;

    if (currentBusinessId) {
      businessId = currentBusinessId;
    } else {
      businessId = getStorageItem("businessId")
        ? cryptr.decrypt(getStorageItem("businessId"))
        : null;
    }

    if (businessId === null || businessId === undefined) {
      this.props.goToDashboard();
    }

    if (businessId) {
      const businessData = await callApiGetBusinessData(businessId);

      if (
        businessData &&
        (businessData.accountType !== "free" &&
          businessData.accountType !== "monthly-scan")
      ) {
        this.setState({
          businessId: businessId,
          businessData: businessData,
        });

        let trustSealsData = await callApiGetTrustSealsData(businessId);

        if (trustSealsData.data === null) {
          const sealData = this.populateSealsData();
          trustSealsData = await callApiCreateTrustSealsData({
            businessId,
            sealData,
          });

          toast.success("Preset created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }

        const cssJsHashList = await callApiGetCssJsHashList();

        if (
          cssJsHashList &&
          cssJsHashList.data &&
          cssJsHashList.data.length > 0
        ) {
          let cssData =
            cssJsHashList.total > 0 ? cssJsHashList.data[0].css : null;
          let jsData =
            cssJsHashList.total > 0 ? cssJsHashList.data[0].js : null;

          this.setState({
            sealScriptsCss: cssData[cssData.length - 1],
            sealScriptsJs: jsData[jsData.length - 1],
          });
        }

        this.setTrustSealsUiData(trustSealsData);
      } else {
        this.props.goToDashboard();
      }
    }
  };

  setTrustSealsUiData = (trustSealsData) => {
    // console.log("setTrustSealsUiData", trustSealsData);
    let certificatePathUrl = config.seals.certificateHost;
    let targetIndex = null;
    let selectedPresetId = null;

    targetIndex = trustSealsData
      ? trustSealsData.data.sealSettings.length - 1
      : 0;
    selectedPresetId = trustSealsData
      ? trustSealsData.data.sealSettings[targetIndex]._id
      : null;

    if (this.state.selectedPresetId) {
      selectedPresetId = this.state.selectedPresetId;

      if (trustSealsData && trustSealsData.data.sealSettings.length) {
        for (let i = 0; i < trustSealsData.data.sealSettings.length; i++) {
          if (
            trustSealsData.data.sealSettings[i]._id ===
            this.state.selectedPresetId
          ) {
            targetIndex = i;
          }
        }
      }
    } else {
      /* console.log(
        "Else selectedPresetId",
        selectedPresetId,
        trustSealsData.data.sealSettings[targetIndex].sealFontFamily
      ); */
    }

    /* console.log(
      "trustSealsData.data.sealSettings[targetIndex].sealFontColor",
      trustSealsData.data.sealSettings[targetIndex].sealFontColor
    ); */

    // console.log("trustSealsData", trustSealsData);

    /* if (
      trustSealsData.data.sealWidth &&
      trustSealsData.data.sealWidth.desktopWidth
    ) {
      console.log(
        "trustSealsData.data.sealWidth.desktopWidth",
        trustSealsData.data.sealWidth.desktopWidth
      );
    } else {
      console.log("ELSE", trustSealsData.data.sealSettings[targetIndex]);
    } */

    this.setState(
      {
        trustSealsData: trustSealsData.data,
        selectedPresetId: selectedPresetId,
        trustSealPath: null,
        demoTrustSealPath: null,
        presetType: trustSealsData.data.sealSettings[targetIndex].presetType,
        presetName: trustSealsData.data.sealSettings[targetIndex].presetName,
        label: trustSealsData.data.sealSettings[targetIndex].label,
        color: trustSealsData.data.sealSettings[targetIndex].color.title,
        sealType: trustSealsData.data.sealSettings[targetIndex].sealType,
        floatSide: trustSealsData.data.sealSettings[targetIndex].floatSide,
        width: trustSealsData.data.sealSettings[targetIndex].width,
        distanceFromEdge:
          trustSealsData.data.sealSettings[targetIndex].distanceFromEdge,
        isSealSaved: false,
        certificateURL: this.state.certificateURL.replace(
          "PRIMARY_DOMAIN",
          this.state.businessData.primaryDomain
        ),
        sealMaxWidth:
          trustSealsData.data.sealSettings[targetIndex].sealWidth &&
          trustSealsData.data.sealSettings[targetIndex].sealWidth.desktopWidth
            ? trustSealsData.data.sealSettings[targetIndex].sealWidth
                .desktopWidth
            : (trustSealsData.data.sealSettings[targetIndex].sealType ===
                "textShield" &&
                0) ||
              175,
        sealMaxWidthOnMobile:
          trustSealsData.data.sealSettings[targetIndex].sealWidth &&
          trustSealsData.data.sealSettings[targetIndex].sealWidth.mobileWidth
            ? trustSealsData.data.sealSettings[targetIndex].sealWidth
                .mobileWidth
            : 145,
        sealFontFamily:
          (trustSealsData.data.sealSettings[targetIndex].sealFontFamily &&
            trustSealsData.data.sealSettings[targetIndex].sealFontFamily) ||
          "",
        sealFontSize:
          (trustSealsData.data.sealSettings[targetIndex].sealFontSize &&
            trustSealsData.data.sealSettings[targetIndex].sealFontSize) ||
          "16",
        sealFontColor:
          (trustSealsData.data.sealSettings[targetIndex].sealFontColor &&
            trustSealsData.data.sealSettings[targetIndex].sealFontColor) ||
          "#0066C0",
        includeGoogleRobotoFont:
          ((trustSealsData.data.sealSettings[targetIndex]
            .includeGoogleRobotoFont &&
            trustSealsData.data.sealSettings[targetIndex]
              .includeGoogleRobotoFont) === true &&
            true) ||
          null,
      },
      () => {
        // console.log("state ...", this.state);
      }
    );

    setTimeout(() => {
      let tempPresetSealImage =
        trustSealsData.data.sealSettings[targetIndex]._id +
        ".svg?v=" +
        new Date().getTime();

      let tempPresetSealImageForWebsiteCode =
        trustSealsData.data.sealSettings[targetIndex]._id + ".svg";

      this.setState(
        {
          trustSealPath:
            this.state.trustsealHost +
            "/sites/" +
            this.state.businessData.primaryDomain +
            "/" +
            tempPresetSealImage,
          trustSealPathForWebsiteCode:
            this.state.trustsealHost +
            "/sites/" +
            this.state.businessData.primaryDomain +
            "/" +
            tempPresetSealImageForWebsiteCode,
          demoTrustSealPath:
            this.state.trustsealHost +
            "/sites/" +
            this.state.businessData.primaryDomain +
            "/" +
            "demo-" +
            tempPresetSealImage,
          certificateURL: this.state.certificateURL.replace(
            "CERTIFICATE_PATH",
            certificatePathUrl
          ),
        },
        () => {
          this.generateSealWebsiteCode();
        }
      );
    }, 2500);
  };

  handleSwitchChange(event) {
    this.setState({ showMobileSeal: !this.state.showMobileSeal });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    /* if (name === "includeGoogleRobotoFont") {
      console.log("includeGoogleRobotoFont");
      console.log("value", value);
      console.log("name", name);
    } */

    let thisUserInfoText = "";

    if (name === "sealType") {
      this.setState({
        sealMaxWidth: (this.state.sealType === "textShield" && 0) || 175,
      });
    }

    if (
      name === "sealType" &&
      (value === "checkout" || value === "textShield")
    ) {
      this.setState({
        floatSide: "right",
      });
    }

    if (name === "sealType" && value === "insurance") {
      this.setState(
        {
          disableFloatSide: true,
          floatSide: "none",
          sealMaxWidth: 225,
        },
        () => {
          // console.log("textShield", this.state.floatSide);
        }
      );
    } else {
      this.setState({
        disableFloatSide: false,
      });
    }

    if (this.state.conditionalNotificationParameters.indexOf(name) !== -1) {
      thisUserInfoText =
        "Your seal has been updated. Please note you must update the code on your website for the seal to display properly.";
    }

    if (name === "color" && (value === "black" || value === "white")) {
      this.setState({
        sealFontColor: "#ffffff",
      });
    } else {
      this.setState({
        sealFontColor: "#0066C0",
      });
    }

    this.setState(
      {
        [name]: value,
        userInfoText: thisUserInfoText,
        isTextElementChanged: true,
        changingSealParameter: name,
      },
      () => {
        // console.log("this.state", this.state);
        switch (name) {
          case "color":
          case "sealType":
          case "sealFontFamily":
          case "sealFontSize":
          case "floatSide":
            this.saveSeal();
            break;
          default:
            break;
        }

        if (name === "sealType" || name === "floatSide") {
          document.getElementById("btnDisplayModalSealInfo").click();
        }
      }
    );
  }

  showActivePresetContent = (sealSettings) => {
    // console.log("showActivePresetContent sealSettings", sealSettings);
    var ul = document.getElementById("sealPresetUl");
    var items = ul.getElementsByTagName("li");

    for (var i = 0; i < items.length; ++i) {
      items[i].classList.remove("active");
    }

    this.setState(
      {
        presetType: sealSettings.presetType,
        presetName: sealSettings.presetName,
        trustSealPath:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessData.primaryDomain +
          "/" +
          sealSettings._id +
          ".svg?v=" +
          new Date().getTime(),
        trustSealPathForWebsiteCode:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessData.primaryDomain +
          "/" +
          sealSettings._id +
          ".svg",
        demoTrustSealPath:
          this.state.trustsealHost +
          "/sites/" +
          this.state.businessData.primaryDomain +
          "/" +
          "demo-" +
          sealSettings._id +
          ".svg",
        selectedPresetId: sealSettings._id,
        label: sealSettings.label,
        color: sealSettings.color.title,
        sealType: sealSettings.sealType,
        floatSide: sealSettings.floatSide,
        width: sealSettings.width,
        distanceFromEdge: sealSettings.distanceFromEdge,
        // SET SEAL MAX WIDTH
        sealMaxWidth:
          sealSettings.sealWidth && sealSettings.sealWidth.desktopWidth,
        sealFontFamily:
          (sealSettings.sealFontFamily && sealSettings.sealFontFamily) ||
          "arial",
        sealFontSize:
          (sealSettings.sealFontSize && sealSettings.sealFontSize) || "16",
        sealFontColor:
          (sealSettings.sealFontColor && sealSettings.sealFontColor) ||
          "#0066C0",
        includeGoogleRobotoFont:
          (sealSettings.includeGoogleRobotoFont &&
            sealSettings.includeGoogleRobotoFont) ||
          false,
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );

    document.getElementById(sealSettings._id).classList.add("active");
  };

  generateSealWebsiteCode = () => {
    /* console.log(
      "generateSealWebsiteCode this.state.sealMaxWidth",
      this.state.sealMaxWidth
    ); */
    let websiteSealCode = null;

    switch ("fixed") {
      case "fixed":
        websiteSealCode = this.state.sealCode.fixed;
        let sealOffset = "";
        let tgScript = "tgscript-v" + this.state.sealScriptsJs.version;
        let tgScriptHash = "sha384-" + this.state.sealScriptsJs.hash;

        let tgCss = config.seals.seal_css_version.replace(
          "_STYLE",
          "cert-style-v" + this.state.sealScriptsCss.version + ".css"
        );
        let tgCssHash = "sha384-" + this.state.sealScriptsCss.hash;

        let sealPlacement = "";

        /* console.log("====================================");
        console.log(this.state.floatSide);
        console.log("===================================="); */

        if (
          (this.state.floatSide === "left" ||
            this.state.floatSide === "right") &&
          this.state.floatSide !== "standAlone" &&
          this.state.sealType !== "textShield"
        ) {
          //console.log("IF");
          /* if (this.state.sealType !== "checkout") { */
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART",
            '<link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous"><style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx !important; }}</style>'
          );
          /* } */
          websiteSealCode = websiteSealCode.replace(
            "_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART",
            '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" _INTEGRITY_SCRIPT crossorigin="anonymous"></script><script>var tg_div = document.createElement("div");tg_div.innerHTML = _TG_SCRIPT_FOR_DOCUMENT_WRITE;document.body.appendChild(tg_div);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script>'
          );
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT",
            tgScript + ".js"
          );
          /* websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_HASH",
            tgScriptHash
          ); */
          websiteSealCode = websiteSealCode.replace(
            "_BUSINESS_ID",
            this.state.businessId
          );

          websiteSealCode = websiteSealCode.replace("_TG_CSS", tgCss);
          //websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);
          websiteSealCode = websiteSealCode.replace("_CERTIFICATE_URL", "");

          let floatSideType = this.state.floatSide;
          let documentScript = "";
          let placementType = "";

          switch (this.state.sealType) {
            case "outer":
              if (this.state.floatSide === "left") {
                floatSideType = "bleft";
                sealPlacement = "tgBleft";
                documentScript = "tg_script_bottom_left";
                placementType = "bottom_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "bright";
                sealPlacement = "tgBright";
                documentScript = "tg_script_bottom_right";
                placementType = "bottom_right";
              }

              break;
            case "inner":
              if (this.state.floatSide === "left") {
                floatSideType = "bleft";
                sealPlacement = "tgBleft";
                documentScript = "tg_script_bottom_left";
                placementType = "bottom_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "bright";
                sealPlacement = "tgBright";
                documentScript = "tg_script_bottom_right";
                placementType = "bottom_right";
              }

              break;

            case "side":
              if (this.state.floatSide === "left") {
                floatSideType = "left";
                sealPlacement = "tgLeft";
                documentScript = "tg_script_side_left";
                placementType = "side_left";
              } else if (this.state.floatSide === "right") {
                floatSideType = "right";
                sealPlacement = "tgRight";
                documentScript = "tg_script_side_right";
                placementType = "side_right";
              }

              websiteSealCode = websiteSealCode.replace(
                "_IMG_ID",
                'id="tg_1-' + floatSideType + '"'
              );

              break;

            default:
              break;
          }

          websiteSealCode = websiteSealCode.replace(
            "_PLACEMENT_TYPE",
            placementType
          );
          /*eslint-disable*/

          /* websiteSealCode = websiteSealCode.replace(
            "_IMG_ID",
            'id="tg_1-' + floatSideType + '"'
          ); */

          websiteSealCode = websiteSealCode.replace("_IMG_ID", "");

          if (this.state.sealType !== "side") {
            websiteSealCode = websiteSealCode.replace(
              'class="tgfloat-inner"',
              'class="tgfloat-inner ' + "tg_1-" + floatSideType + '"'
            );
          }
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_FOR_DOCUMENT_WRITE",
            documentScript
          );
        } else {
          //console.log("ELSE");
          if (
            this.state.sealType !== "checkout" &&
            this.state.sealType !== "textShield"
          ) {
            // console.log("INSIDE TEXT SHIELD 1");
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ",
              '<style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx; }}</style>'
            );
            /*eslint-enable*/
          } else {
            if (
              this.state.sealType === "checkout" ||
              this.state.sealType === "textShield"
            ) {
              // console.log("INSIDE TEXT SHIELD ****");

              /*eslint-disable*/
              // <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto">
              if (
                this.state.sealType !== "checkout" &&
                this.state.includeGoogleRobotoFont
              ) {
                // console.log("Inside includeGoogleRobotoFont includeGoogleRobotoFont");
                websiteSealCode = websiteSealCode.replace(
                  "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART",
                  '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
                    this.state.sealFontFamily.charAt(0).toUpperCase() +
                    this.state.sealFontFamily.slice(1) +
                    '"><link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous">'
                );
              } else {
                websiteSealCode = websiteSealCode.replace(
                  "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART",
                  '<link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous">'
                );
              }

              websiteSealCode = websiteSealCode.replace(
                "_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART",
                '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" _INTEGRITY_SCRIPT crossorigin="anonymous"></script><script>var tg_div = document.createElement("div");tg_div.innerHTML = _TG_SCRIPT_FOR_DOCUMENT_WRITE;document.body.appendChild(tg_div);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script>'
              );

              websiteSealCode = websiteSealCode.replace(
                " _IMG_ID",
                ' id="tgfloat-img"'
              );
              /*eslint-enable*/
            } else {
              websiteSealCode = websiteSealCode.replace(
                "_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ",
                ""
              );
            }
          }

          websiteSealCode = websiteSealCode.replace(
            " _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART",
            ""
          );

          websiteSealCode = websiteSealCode.replace(
            "_CERTIFICATE_URL",
            this.state.certificateURL
          );

          websiteSealCode = websiteSealCode.replace(" _IMG_ID", "");
        }

        if (this.state.sealType !== "checkout") {
          websiteSealCode = websiteSealCode.replace(
            "_SEAL_WIDTH_FOR_MOBILE",
            this.state.sealMaxWidthOnMobile
          );
        }

        if (this.state.sealType !== "side") {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, "width");
        } else {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, "height");
        }

        websiteSealCode = websiteSealCode.replace("_ONE", sealPlacement);

        if (this.state.floatSide === "left") {
          websiteSealCode = websiteSealCode.replace("_TWO", "left");
          websiteSealCode = websiteSealCode.replace(
            "_THREE",
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace("_FOUR", "left:");
          websiteSealCode = websiteSealCode.replace(
            "_FIVE",
            this.state.distanceFromEdge + "px;"
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIX",
            this.state.width + "px;"
          );

          sealOffset = "left:" + this.state.distanceFromEdge + "px;";
        } else {
          websiteSealCode = websiteSealCode.replace("_TWO", "right");
          websiteSealCode = websiteSealCode.replace(
            "_THREE",
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace("_FOUR", "right:");
          websiteSealCode = websiteSealCode.replace(
            "_FIVE",
            this.state.distanceFromEdge + "px;"
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIX",
            this.state.width + "px;"
          );

          sealOffset = "right:" + this.state.distanceFromEdge + "px;";
        }

        if (
          (this.state.sealType === "outer" ||
            this.state.sealType === "inner") &&
          this.state.floatSide === "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
        }

        if (
          (this.state.sealType === "outer" ||
            this.state.sealType === "inner" ||
            this.state.sealType === "checkout") &&
          this.state.floatSide !== "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );

          if (this.state.sealType !== "checkout") {
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              "_POSITION",
              ' style="position:fixed;bottom:0px;z-index: 999999;text-align:left;' +
                sealOffset +
                '"'
            );
            /*eslint-enable*/
          }
        }

        if (
          this.state.sealType === "side" &&
          this.state.floatSide !== "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "height: " + this.state.sealMaxWidth + "px"
          );
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_POSITION",
            ' style="position:fixed;top:150px;z-index:999999;text-align:left;' +
              sealOffset +
              '"'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            ""
          );
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            ""
          );
        }

        if (
          this.state.sealType === "side" &&
          this.state.floatSide === "standAlone"
        ) {
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "height: " + this.state.sealMaxWidth + "px"
          );
        }

        if (this.state.floatSide === "standAlone") {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );

          websiteSealCode = websiteSealCode.replace(
            "_TG_SEALCODE_CLASS",
            "tgbanner"
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            "</a>"
          );
          websiteSealCode = websiteSealCode.replace("_POSITION", "");
          websiteSealCode = websiteSealCode.replace("_SIZE ", "");
        } else {
          /* if (this.state.sealType === "textShield") {
            console.log("Changing class textShield", this.state.floatSide);
            websiteSealCode = websiteSealCode.replace(
              "_TG_SEALCODE_CLASS",
              "tgfloat tgStackSeal tgB" + this.state.floatSide
            );

            // TODO - replace font family and font size
            websiteSealCode = websiteSealCode.replace("_POSITION", "");
          } else {
            websiteSealCode = websiteSealCode.replace(
              "_TG_SEALCODE_CLASS",
              "tgfloat"
            );
          } */
        }

        // console.log("BEFORE", websiteSealCode);

        if (
          this.state.sealType === "checkout" ||
          this.state.sealType === "textShield"
        ) {
          // console.log( "INSIDE textShield",this.state.sealType,this.state.floatSide);

          // console.log("Changing class textShield", this.state.floatSide);

          let fSide;

          if (this.state.floatSide !== "none") {
            fSide = this.state.floatSide;
          } else {
            fSide = "right";
          }

          websiteSealCode = websiteSealCode.replace(
            "_TG_SEALCODE_CLASS",

            "tgfloat tgStackSeal tgB" + fSide + " tg_1-b" + fSide
          );

          //let testShieldID = "id=" + '"tg_1-b' + this.state.floatSide + '"';
          // console.log("testShieldID", testShieldID);

          /* websiteSealCode = websiteSealCode.replace(
            "_ID",
            testShieldID)
          ); */
          websiteSealCode = websiteSealCode.replace("_ID", "");
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            '<table class="tgBlockText" cellspacing="0" cellpadding="0" style="display:none;"><tr><td class="tgBlockImg">'
          );

          websiteSealCode = websiteSealCode.replace("_TG_CSS", tgCss);
          // websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT",
            tgScript + ".js"
          );
          // websiteSealCode = websiteSealCode.replace( "_TG_SCRIPT_HASH", tgScriptHash);

          websiteSealCode = websiteSealCode.replace(
            "_BUSINESS_ID",
            this.state.businessId
          );

          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_FOR_DOCUMENT_WRITE",
            "tg_script_bottom_" + fSide
          );

          websiteSealCode = websiteSealCode.replace(
            '_PLACEMENT_TYPE")',
            "bottom_" +
              fSide +
              '");tgCheckSeal' +
              fSide.charAt(0).toUpperCase() +
              fSide.slice(1) +
              "()"
          );

          if (this.state.sealType !== "checkout") {
            websiteSealCode = websiteSealCode.replace(
              "_STANDALONE_ANCHOR_END",
              '</td><td class="tgBlockSecure" style="font-family: ' +
                this.state.sealFontFamily +
                "; font-size:" +
                this.state.sealFontSize +
                "px; color:" +
                this.state.sealFontColor +
                '">Secure transaction </td></tr/></table>'
            );

            websiteSealCode = websiteSealCode.replace(
              "_SIZE",
              "max-width: " +
                parseInt(
                  parseInt(this.state.sealMaxWidth) +
                    parseInt(this.state.sealFontSize)
                ) +
                "px;"
            );
          }

          websiteSealCode = websiteSealCode.replace("_POSITION", "");
        } else {
          websiteSealCode = websiteSealCode.replace(
            "_TG_SEALCODE_CLASS",
            "tgfloat"
          );
          websiteSealCode = websiteSealCode.replace("_ID", "");
        }

        if (
          /* this.state.sealType === "checkout" || */
          this.state.sealType === "insurance"
        ) {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_START",
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace(
            "_STANDALONE_ANCHOR_END",
            "</a>"
          );
          websiteSealCode = websiteSealCode.replace("_POSITION", "");
          websiteSealCode = websiteSealCode.replace(
            "_SIZE",
            "width: " + this.state.sealMaxWidth + "px;"
          );
        }

        if (this.state.isGoogleTagManagerEnabled === true) {
          websiteSealCode = websiteSealCode.replace("_ONCONTEXTMENU", "");
          websiteSealCode = websiteSealCode.replace("_INTEGRITY_CSS", "");
          websiteSealCode = websiteSealCode.replace("_INTEGRITY_SCRIPT", "");
        } else {
          websiteSealCode = websiteSealCode.replace(
            "_INTEGRITY_CSS",
            'integrity="_TG_CSS_HASH"'
          );
          websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);

          websiteSealCode = websiteSealCode.replace(
            "_INTEGRITY_SCRIPT",
            'integrity="_TG_SCRIPT_HASH"'
          );
          websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_HASH",
            tgScriptHash
          );

          websiteSealCode = websiteSealCode.replace(
            "_ONCONTEXTMENU",
            "oncontextmenu=\"var d = new Date(); alert('Copying Prohibited by Law - This image and all included logos are copyrighted by Trust Guard '+d.getFullYear()+'.'); return false;\""
          );
        }

        this.setState({
          sealCode: { ...this.state.sealCode, textboxCode: websiteSealCode },
        });

        break;
      case "custom":
        break;

      default:
        break;
    }
  };

  generateSealCode = async () => {
    await callApiPatchTrustSealsData(this.state.trustSealsData._id, {
      lastGeneratedDate: Date.now(),
      businessId: this.state.businessId,
    });

    this.setState({
      codeGenerated: true,
    });

    toast.success("TrustSeal code generated successfully.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500,
    });
  };

  createSealPreset = async (newPresetName) => {
    if (newPresetName && typeof newPresetName !== "undefined") {
      const newSealsData = this.populateSealsData(newPresetName, "create");
      document.getElementById("hideModalOnSuccess").click();
      this.setState({
        selectedPresetId: "",
      });

      const updatednewSealsData = await callApiUpdateTrustSealsData(
        this.state.trustSealsData._id,
        {
          businessId: this.state.businessId,
          sealData: newSealsData,
        }
      );

      toast.success("Preset created successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });

      this.setTrustSealsUiData(updatednewSealsData);
    } else {
      toast.error("Please enter preset name.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    }
  };

  saveSeal = async () => {
    this.setState({
      isSealSaved: true,
      trustSealPath: null,
    });

    const updatableSealData = this.populateSealsData(
      this.state.presetName,
      "update",
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(
      this.state.trustSealsData._id,
      {
        businessId: this.state.businessId,
        sealData: updatableSealData,
      }
    );

    toast.success("Preset updated successfully.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500,
    });

    this.setTrustSealsUiData(updatedSealsData);
  };

  removeSeal = async () => {
    let confirm = window.confirm("Are you sure to remove this preset?");

    if (confirm) {
      const removableSealData = this.populateSealsData(
        this.state.presetName,
        "remove",
        this.state.selectedPresetId
      );

      const currentSealData = await callApiUpdateTrustSealsData(
        this.state.trustSealsData._id,
        {
          businessId: this.state.businessId,
          sealData: removableSealData,
          removableSealSettingsId: this.state.selectedPresetId,
        }
      );

      toast.success("Preset removed successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });

      this.setTrustSealsUiData(currentSealData);
    }
  };

  populateSealsData(presetName = null, purpose = null, sealSettingsId = null) {
    // console.log("populateSealsData", presetName, purpose, sealSettingsId);
    let trustSealId = null;
    let sealData = null;
    let presetSettings = {
      presetType: null,
      presetName: "Default",
      label: this.state.businessData
        ? this.state.businessData.primaryDomain.toUpperCase()
        : "",
      sealType: "outer",
      floatSide: "left",
      width: "225",
      distanceFromEdge: 0,
      color: {
        title: "green",
        placeholderShieldOuter: "#333333",
        placeholderShieldInner: "#FFFFFF",
        placeholderShieldLeft: "#66CC3E",
        placeholderShieldRight: "#33981F",
        placeholderBodyLeft: "#33981f",
        placeholderBodyRight: "#43b12a",
        placeholderStatusColor: "#ffffff",
        placeholderDateColor: "#ffffff",
        placeholderLabelColor: "#ffffff",
        placeholderKeyHole: "#ffffff",
        placeholderTrustGuardText: "#ffffff",
        placeholderSecuredText: "#ffffff",
        placeholderShieldBg: "#33981F",
        placeholderShieldTop: "#33981F",
        placeholderPrimaryLayer: "#ffffff",
        placeholderSecondaryLayer: "#ffffff",
        placeholderCheckoutSeparator: "#e6e6e5",
        placeholderShieldInnerBorder: "#ffffff",
        placeholderSealBorder: "#33981F",

        placeholderInsuranceBorder: "#e5e5e5",
        placeholderInsuredText: "#25aae1",
        placeholderGeneralText: "#333333",
        placeholderActiveText: "#404041",
        placeholderInsuranceSeparator: "#e5e5e5",
        placeholderInsuranceInfoBorder: "#25aae1",
        placeholderInnerShieldLeftTop: "#98d6f0",
        placeholderInnerShieldLeftBottom: "#7fcced",
        placeholderInnerShieldRightBottom: "#29aae1",
        placeholderInnerShieldRightTop: "#0c6d94",
        placeholderInnerShieldMiddleOne: "#84de65",
        placeholderInnerShieldMiddleTwo: "#7fcced",
      },
      sealWidth: {
        desktopWidth: this.state.sealMaxWidth,
        mobileWidth: this.state.sealMaxWidthOnMobile,
      },
      sealFontFamily: this.state.sealFontFamily,
      sealFontSize: this.state.sealFontSize,
      sealFontColor: "#0066C0",
      includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
      updateDate: new Date().toISOString,
    };

    let sealSettingsData = null;

    if (this.state.sealType === "checkout") {
      this.setState({
        floatSide: "none",
      });
    }

    if (this.state.sealType === "insurance") {
      this.setState({
        floatSide: "none",
      });
    }

    if (this.state.sealType === "textShield") {
      this.setState({
        floatSide: this.state.floatSide,
      });
    }

    // console.log("populateSealsData purpose", purpose);

    switch (purpose) {
      case "create":
        trustSealId = this.state.trustSealsData._id;
        presetSettings.presetName = presetName;
        sealSettingsData = [
          ...this.state.trustSealsData.sealSettings,
          presetSettings,
        ];
        break;

      case "update":
        let sealLabel = this.state.label;
        if (sealLabel === "") {
          sealLabel = this.state.businessData.primaryDomain;
        }

        let tgSealOuterShieldColor = null;
        if (
          this.state.floatSide === "right" &&
          this.state.sealType === "side" &&
          (this.state.color === "black" || this.state.color === "whiteAlt")
        ) {
          tgSealOuterShieldColor = "#dddddd";
        } else {
          tgSealOuterShieldColor = this.state.colorSettings[this.state.color]
            .placeholderShieldOuter;
        }

        trustSealId = this.state.trustSealsData
          ? this.state.trustSealsData._id
          : null;
        sealSettingsData =
          this.state.trustSealsData && this.state.trustSealsData.sealSettings
            ? this.state.trustSealsData.sealSettings.map((v, i) => {
                if (v._id === sealSettingsId) {
                  v.presetName = this.state.presetName;
                  v.label = sealLabel.toUpperCase();
                  v.sealType = this.state.sealType;
                  v.floatSide =
                    this.state.sealType !== "checkout" &&
                    this.state.sealType !== "insurance"
                      ? this.state.floatSide !== "none"
                        ? this.state.floatSide
                        : "left"
                      : "none";
                  v.width = "225";
                  v.distanceFromEdge = this.state.distanceFromEdge;
                  v.color = {
                    title: this.state.color,
                    placeholderShieldOuter: tgSealOuterShieldColor,
                    placeholderShieldInner: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldInner,
                    placeholderShieldLeft: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldLeft,
                    placeholderShieldRight: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldRight,
                    placeholderBodyLeft: this.state.colorSettings[
                      this.state.color
                    ].placeholderBodyLeft,
                    placeholderBodyRight: this.state.colorSettings[
                      this.state.color
                    ].placeholderBodyRight,
                    placeholderStatusColor: this.state.colorSettings[
                      this.state.color
                    ].placeholderStatusColor,
                    placeholderDateColor: this.state.colorSettings[
                      this.state.color
                    ].placeholderDateColor,
                    placeholderLabelColor: this.state.colorSettings[
                      this.state.color
                    ].placeholderLabelColor,
                    placeholderKeyHole: this.state.colorSettings[
                      this.state.color
                    ].placeholderKeyHole,
                    placeholderTrustGuardText: this.state.colorSettings[
                      this.state.color
                    ].placeholderTrustGuardText,
                    placeholderSecuredText: this.state.colorSettings[
                      this.state.color
                    ].placeholderSecuredText,

                    placeholderShieldBg: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldBg,

                    placeholderShieldTop: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldTop,

                    placeholderPrimaryLayer: this.state.colorSettings[
                      this.state.color
                    ].placeholderPrimaryLayer,

                    placeholderSecondaryLayer: this.state.colorSettings[
                      this.state.color
                    ].placeholderSecondaryLayer,
                    placeholderCheckoutSeparator: this.state.colorSettings[
                      this.state.color
                    ].placeholderCheckoutSeparator,
                    placeholderShieldInnerBorder: this.state.colorSettings[
                      this.state.color
                    ].placeholderShieldInnerBorder,
                    placeholderSealBorder: this.state.colorSettings[
                      this.state.color
                    ].placeholderSealBorder,

                    placeholderInsuranceBorder: this.state.colorSettings[
                      this.state.color
                    ].placeholderInsuranceBorder,

                    placeholderInsuredText: this.state.colorSettings[
                      this.state.color
                    ].placeholderInsuredText,

                    placeholderGeneralText: this.state.colorSettings[
                      this.state.color
                    ].placeholderGeneralText,

                    placeholderActiveText: this.state.colorSettings[
                      this.state.color
                    ].placeholderActiveText,

                    placeholderInsuranceSeparator: this.state.colorSettings[
                      this.state.color
                    ].placeholderInsuranceSeparator,

                    placeholderInsuranceInfoBorder: this.state.colorSettings[
                      this.state.color
                    ].placeholderInsuranceInfoBorder,

                    placeholderInnerShieldLeftTop: this.state.colorSettings[
                      this.state.color
                    ].placeholderInnerShieldLeftTop,

                    placeholderInnerShieldLeftBottom: this.state.colorSettings[
                      this.state.color
                    ].placeholderInnerShieldLeftBottom,

                    placeholderInnerShieldRightBottom: this.state.colorSettings[
                      this.state.color
                    ].placeholderInnerShieldRightBottom,

                    placeholderInnerShieldRightTop: this.state.colorSettings[
                      this.state.color
                    ].placeholderInnerShieldRightTop,

                    placeholderInnerShieldMiddleOne: this.state.colorSettings[
                      this.state.color
                    ].placeholderInnerShieldMiddleOne,

                    placeholderInnerShieldMiddleTwo: this.state.colorSettings[
                      this.state.color
                    ].placeholderInnerShieldMiddleTwo,
                  };
                  v.sealWidth = {
                    desktopWidth: this.state.sealMaxWidth,
                    mobileWidth: this.state.sealMaxWidthOnMobile,
                  };
                  v.includeGoogleRobotoFont = this.state.includeGoogleRobotoFont;
                  v.sealFontFamily = this.state.sealFontFamily;
                  v.sealFontSize = this.state.sealFontSize;
                  v.sealFontColor = this.state.sealFontColor;
                  v.updateDate = new Date().toISOString();
                }

                // console.log("v", v);

                return v;
              })
            : null;
        break;

      case "remove":
        trustSealId = this.state.trustSealsData
          ? this.state.trustSealsData._id
          : null;
        sealSettingsData = this.state.trustSealsData
          ? this.state.trustSealsData.sealSettings.filter((eachData) => {
              return eachData._id !== sealSettingsId;
            })
          : null;
        break;

      default:
        // console.log("populateSealsData default switch", presetSettings);
        presetSettings.presetType = "Default";
        sealSettingsData = presetSettings;
        break;
    }

    sealData = {
      businessId: this.state.businessId,
      lastGeneratedDate: null,
      nextGenerateDate: null,
      status: "active",
      sealSettings: sealSettingsData,
    };

    // console.log("Seal Data", sealData);
    return sealData;
  }

  onUpdate = (values) => {
    // console.log("Updating...");
    this.setState(
      {
        sealMaxWidth: values[0],
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onUpdateMobile = (values) => {
    this.setState(
      {
        sealMaxWidthOnMobile: values[0],
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onSealSizeUpdate = async (prevState) => {
    const onUpdateSealData = this.populateSealsData(
      this.state.presetName,
      "update",
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(
      this.state.trustSealsData ? this.state.trustSealsData._id : null,
      {
        businessId: this.state.businessId,
        sealData: onUpdateSealData,
      }
    );
  };

  saveSealFontColor = async (color) => {
    // console.log("saveSealFontColor color", color);

    this.setState({
      sealFontColor: color,
      isSealSaved: true,
      trustSealPath: null,
    });

    const updatableSealData = this.populateSealsData(
      this.state.presetName,
      "update",
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(
      this.state.trustSealsData._id,
      {
        businessId: this.state.businessId,
        sealData: updatableSealData,
      }
    );

    this.setTrustSealsUiData(updatedSealsData);
  };

  toggleGoogleTagManager = async (status) => {
    this.setState(
      {
        isGoogleTagManagerEnabled: status,
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );
  };

  render() {
    const {
      businessId,
      codeGenerated,
      businessData,
      certificateURL,
      sealCode,
      trustSealsData,
      presetType,
      presetName,
      label,
      color,
      sealType,
      floatSide,
      width,
      distanceFromEdge,
      colorSettings,
      selectedPresetId,
      newPresetName,
      isSealSaved,
      trustSealPath,
      demoTrustSealPath,
      trustSealPathForWebsiteCode,
      isTextElementChanged,
      isCopied,
      trustsealHost,
      displayBlock,
      changingSealParameter,
      conditionalNotificationParameters,
      sealScriptsCss,
      sealScriptsJs,
      disableFloatSide,
      displayOnChangeSealModal,
      sealMaxWidth,
      sealMaxWidthOnMobile,
      showMobileSeal,
      userInfoText,
      sealFontFamily,
      sealFontSize,
      includeGoogleRobotoFont,
      sealFontColor,
      isGoogleTagManagerEnabled,
    } = this.state;

    let businessPciStatus = null;

    switch (businessData.pciStatus) {
      case "pass":
        businessPciStatus = businessData.pciStatus;
        break;
      case "fail":
        businessPciStatus = businessData.pciStatus;
        break;
      default:
        businessPciStatus = businessData.lastPciStatus;
        break;
    }

    return (
      <Aux>
        <div className="seal-content-wrapper">
          <h4 style={{ textTransform: "uppercase" }}>
            SEAL OPTIONS
            <b>
              {this.state.businessData
                ? " - " + this.state.businessData.primaryDomain
                : ""}
            </b>
          </h4>
          <p style={{ marginBottom: "25px" }}>
            Below you will find the options to control the position of your
            Trust Guard trust seal. Use the controls to modify the position and
            copy the code when you are done and simply paste it into the desired
            webpage or in the universal footer to show on all pages.
          </p>
          <p>
            Note: You can use presets to display different seals on different
            pages.
          </p>
        </div>

        <div id="dash-malware-table">
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <div className="tab-content">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 tg-seals-well">
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-xxs-12 left">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-center header">
                    <button
                      className="btn-tgdark2 btn-md"
                      data-toggle="modal"
                      data-target="#modalCreatePreset"
                    >
                      <b className="fas fa-plus" /> Create New Preset
                    </button>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 menu-list">
                    {trustSealsData !== null ? (
                      <ul id="sealPresetUl" className="list-group">
                        {trustSealsData.hasOwnProperty("sealSettings")
                          ? trustSealsData.sealSettings
                              .map((v, i) => {
                                return (
                                  <li
                                    id={v._id}
                                    key={i}
                                    onClick={() => {
                                      this.showActivePresetContent(v);
                                    }}
                                    className={
                                      v._id === selectedPresetId
                                        ? "list-group-item active"
                                        : "list-group-item"
                                    }
                                  >
                                    {v.presetName}
                                  </li>
                                );
                              })
                              .reverse()
                          : ""}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-xxs-12 right">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 header">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12 title">
                      <h4>{presetName}</h4>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-xxs-12 text-right save-button">
                      {presetType === null ? (
                        <button
                          className="btn-danger btn-md"
                          onClick={() => {
                            this.removeSeal();
                          }}
                          style={{}}
                        >
                          <b className="fas fa-trash" />&nbsp; Remove Preset
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 body">
                    <div className="col-lg-7 body-left">
                      <div className="title">Seals</div>
                      <div
                        className="well well-lg"
                        style={{
                          background: "rgb(255, 255, 255)",
                          fontWeight: "normal",
                          fontSize: "13px",
                          color: "#dc3545",
                          borderBottomLeftRadius: "0px",
                          borderTopLeftRadius: "0px",
                          borderLeft: "6px solid #dc3545",
                          borderColor: "#dc3545",
                          padding: "12px 24px",
                          display: displayBlock,
                        }}
                      >
                        Your seals are currently inactive and will not be
                        displayed on your website until you've passed your PCI,
                        Malware and SSL scans. <br />While your seals are
                        inactive we have created a placeholder seal for you to
                        preview. <br />Note that your active seal will display
                        the website name you enter below instead of "Demo".
                      </div>
                      <div
                        className="well well-lg"
                        style={{ background: "#dddddd" }}
                      >
                        <div className="row">
                          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 mt-2 pr-0">
                            <p>
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Color
                              </label>
                              <input
                                name="color"
                                className="greenRadio sealRadio"
                                type="radio"
                                style={{ marginRight: "12px" }}
                                value="green"
                                checked={color === "green" ? true : ""}
                                onChange={this.handleInputChange}
                              />
                              <input
                                name="color"
                                className="blackRadio sealRadio"
                                type="radio"
                                style={{ marginRight: "12px" }}
                                value="black"
                                checked={color === "black" ? true : ""}
                                onChange={this.handleInputChange}
                              />
                              <input
                                name="color"
                                className="whiteRadio sealRadio"
                                type="radio"
                                style={{ marginRight: "12px" }}
                                value="white"
                                checked={color === "white" ? true : ""}
                                onChange={this.handleInputChange}
                              />
                              <input
                                name="color"
                                className="greyRadio sealRadio"
                                type="radio"
                                style={{ marginRight: "12px" }}
                                value="grey"
                                checked={color === "grey" ? true : ""}
                                onChange={this.handleInputChange}
                              />
                            </p>

                            <p>
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Type
                              </label>
                              <select
                                name="sealType"
                                value={sealType}
                                onChange={this.handleInputChange}
                              >
                                <option value="outer">'Bump Out' Shield</option>
                                <option value="inner">Standard Shield</option>
                                <option value="checkout">
                                  Banner 'Checkout' Shield
                                </option>
                                <option value="side">Side Shield</option>

                                {businessData.hasOwnProperty("details4X") &&
                                businessData.details4X ? (
                                  <option value="insurance">
                                    Buyer Protection Banner
                                  </option>
                                ) : (
                                  ""
                                )}
                                <option value="textShield">
                                  Secure Transaction
                                </option>
                              </select>
                            </p>

                            <p
                              style={{
                                display:
                                  sealType === "checkout" ||
                                  sealType === "insurance"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                {(sealType === "textShield" &&
                                  "Popup Placement") ||
                                  "Placement"}
                              </label>
                              <select
                                id="selFloatSide"
                                name="floatSide"
                                value={floatSide}
                                onChange={this.handleInputChange}
                                disabled={disableFloatSide ? true : false}
                              >
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                                {sealType !== "textShield" && (
                                  <option value="standAlone">
                                    Stand Alone
                                  </option>
                                )}
                              </select>
                            </p>

                            <p
                              style={{
                                display:
                                  sealType === "textShield" ? "block" : "none",
                              }}
                            >
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Font Color
                              </label>
                              {/* <input
                                type="color"
                                name="sealFontColor"
                                value={sealFontColor}
                                onChange={(evt) =>
                                  console.log(evt.target.value)
                                }
                              /> */}
                              <ColorPicker
                                saveSealFontColor={this.saveSealFontColor}
                                color={sealFontColor}
                              />
                            </p>

                            <p
                              style={{
                                display:
                                  sealType === "textShield" ? "block" : "none",
                              }}
                            >
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Font
                              </label>
                              <select
                                id="selFontFamily"
                                name="sealFontFamily"
                                value={sealFontFamily}
                                onChange={this.handleInputChange}
                              >
                                <option value="roboto">Roboto</option>
                                <option value="arial">Arial</option>
                              </select>
                            </p>

                            <p
                              style={{
                                display:
                                  sealType === "textShield" &&
                                  sealFontFamily === "roboto"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <input
                                type="checkbox"
                                name="includeGoogleRobotoFont"
                                value={includeGoogleRobotoFont}
                                checked={includeGoogleRobotoFont}
                                onClick={() => {
                                  this.setState(
                                    {
                                      includeGoogleRobotoFont: !includeGoogleRobotoFont,
                                    },
                                    () => {
                                      this.saveSeal();
                                    }
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              />{" "}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      includeGoogleRobotoFont: !includeGoogleRobotoFont,
                                    },
                                    () => {
                                      this.saveSeal();
                                    }
                                  );
                                }}
                              >
                                {" "}
                                &nbsp;&nbsp;&nbsp; Include this font directly
                                from google.
                              </span>
                              <i
                                style={{
                                  display: "block",
                                  clear: "both",
                                  fontSize: "10px",
                                }}
                              >
                                (Note if the font is already used on your site
                                this is not necessary).
                              </i>
                            </p>

                            <p
                              style={{
                                display:
                                  sealType === "textShield" ? "block" : "none",
                              }}
                            >
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                Font Size
                              </label>
                              <select
                                id="selSealFontSize"
                                name="sealFontSize"
                                value={sealFontSize}
                                onChange={this.handleInputChange}
                              >
                                <option value="12">12 px</option>
                                <option value="13">13 px</option>
                                <option value="14">14 px</option>
                                <option value="15">15 px</option>
                                <option value="16">16 px</option>
                                <option value="17">17 px</option>
                                <option value="18">18 px</option>
                                <option value="20">20 px</option>
                              </select>
                            </p>

                            <div
                              style={{
                                display:
                                  sealType === "checkout" ||
                                  sealType === "insurance" ||
                                  sealType === "textShield"
                                    ? "none"
                                    : "block",
                                margin: "0 0 10px",
                              }}
                            >
                              <label className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left">
                                {showMobileSeal
                                  ? "Mobile Version"
                                  : "Dektop Version"}
                              </label>
                              <Switch
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onChange={this.handleSwitchChange}
                                checked={showMobileSeal}
                                height={20}
                                width={60}
                              />
                            </div>

                            <div
                              style={{
                                margin: "0 0 10px",
                              }}
                            >
                              <label
                                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 no-pad text-left"
                                style={{
                                  display:
                                    showMobileSeal &&
                                    (sealType !== "checkout" ||
                                      sealType !== "insurance")
                                      ? "none"
                                      : "block",
                                }}
                              >
                                {sealType === "side"
                                  ? "Max Height"
                                  : (sealType === "textShield" &&
                                      "Shield Size") ||
                                    "Max Width"}
                              </label>
                            </div>

                            <div
                              className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-pad text-left"
                              style={{
                                display:
                                  showMobileSeal &&
                                  (sealType !== "checkout" ||
                                    sealType !== "insurance")
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <Slider
                                rootStyle={sliderStyle}
                                domain={
                                  sealType === "checkout" ||
                                  sealType === "insurance"
                                    ? [225, 400]
                                    : (sealType === "textShield" && [0, 4]) || [
                                        120,
                                        250,
                                      ]
                                }
                                step={(sealType === "textShield" && 2) || 1}
                                mode={2}
                                values={[sealMaxWidth]}
                                onUpdate={this.onUpdate}
                              >
                                <Rail>
                                  {({ getRailProps }) => (
                                    <div
                                      style={railStyle}
                                      {...getRailProps()}
                                    />
                                  )}
                                </Rail>
                                <Handles>
                                  {({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                      {handles.map((handle) => (
                                        <Handle
                                          key={handle.id}
                                          handle={handle}
                                          getHandleProps={getHandleProps}
                                          sealType={sealType}
                                        />
                                      ))}
                                    </div>
                                  )}
                                </Handles>
                                <Tracks right={false}>
                                  {({ tracks, getTrackProps }) => (
                                    <div className="slider-tracks">
                                      {tracks.map(({ id, source, target }) => (
                                        <Track
                                          key={id}
                                          source={source}
                                          target={target}
                                          getTrackProps={getTrackProps}
                                        />
                                      ))}
                                    </div>
                                  )}
                                </Tracks>
                              </Slider>
                            </div>

                            {sealType !== "checkout" ||
                            sealType !== "insurance" ||
                            sealType !== "textShield" ? (
                              <div
                                style={{
                                  display: showMobileSeal ? "block" : "none",
                                  margin: "0 0 10px",
                                }}
                              >
                                <label className="col-lg-6 col-md-6 col-sm-6 col-xs-6 no-pad text-left">
                                  {sealType === "side"
                                    ? "Max Height on Mobile"
                                    : "Max Width on Mobile"}
                                </label>
                              </div>
                            ) : (
                              ""
                            )}

                            {sealType !== "checkout" ||
                            sealType !== "insurance" ||
                            sealType !== "textShield" ? (
                              <div
                                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-pad text-left"
                                style={{
                                  display: showMobileSeal ? "block" : "none",
                                  margin: "0 0 10px",
                                }}
                              >
                                <Slider
                                  rootStyle={sliderStyle}
                                  domain={[120, 175]}
                                  step={1}
                                  mode={2}
                                  values={[30]}
                                  onUpdate={this.onUpdateMobile}
                                >
                                  <Rail>
                                    {({ getRailProps }) => (
                                      <div
                                        style={railStyle}
                                        {...getRailProps()}
                                      />
                                    )}
                                  </Rail>
                                  <Handles>
                                    {({ handles, getHandleProps }) => (
                                      <div className="slider-handles">
                                        {handles.map((handle) => (
                                          <Handle
                                            key={handle.id}
                                            handle={handle}
                                            getHandleProps={getHandleProps}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </Handles>
                                  <Tracks right={false}>
                                    {({ tracks, getTrackProps }) => (
                                      <div className="slider-tracks">
                                        {tracks.map(
                                          ({ id, source, target }) => (
                                            <Track
                                              key={id}
                                              source={source}
                                              target={target}
                                              getTrackProps={getTrackProps}
                                            />
                                          )
                                        )}
                                      </div>
                                    )}
                                  </Tracks>
                                </Slider>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div
                            className="col-lg-5 col-md-12 col-sm-12 col-xs-12 text-center my-2"
                            style={{
                              display:
                                sealType === "checkout" ||
                                sealType === "insurance" ||
                                sealType === "textShield"
                                  ? "none"
                                  : "flex",
                              alignItems: "center",
                            }}
                          >
                            <p className="previewLabel">Preview</p>

                            {/* Non mobile seal */}

                            <div style={{ width: "100%" }}>
                              {trustSealPath ? (
                                <>
                                  <img
                                    className="
                                            my-2
                                          "
                                    src={trustSealPath}
                                    /* src={
                                      businessPciStatus !== "pass"
                                        ? demoTrustSealPath
                                        : trustSealPath
                                    } */
                                    alt="Seal"
                                    style={
                                      sealType === "side"
                                        ? {
                                            maxHeight: "250px",
                                            minHeight: sealMaxWidth + "px",
                                            cursor: "pointer",
                                            display: showMobileSeal
                                              ? "none"
                                              : "block",
                                          }
                                        : sealType === "checkout" ||
                                          sealType === "insurance" ||
                                          sealType === "textShield"
                                          ? {
                                              display: "none",
                                            }
                                          : {
                                              maxWidth: sealMaxWidth + "px",
                                              minHeight: "175px",
                                              cursor: "pointer",
                                              display: showMobileSeal
                                                ? "none"
                                                : "block",
                                            }
                                    }
                                    onClick={() =>
                                      window.open(certificateURL, "_blank")
                                    }
                                  />
                                </>
                              ) : (
                                <div
                                  style={{
                                    display: showMobileSeal ? "none" : "block",
                                  }}
                                >
                                  <Spinner />
                                </div>
                              )}
                            </div>

                            {/* Mobile seal */}

                            {trustSealPath ? (
                              <img
                                className="
                                            my-2
                                          "
                                src={trustSealPath}
                                /* src={
                                  businessPciStatus !== "pass"
                                    ? demoTrustSealPath
                                    : trustSealPath
                                } */
                                alt="Seal"
                                onError={() =>
                                  this.setState({
                                    trustSealPath: images.tgSealDefault,
                                  })
                                }
                                style={
                                  sealType === "side"
                                    ? {
                                        maxHeight: "250px",
                                        minHeight: sealMaxWidthOnMobile + "px",
                                        cursor: "pointer",
                                        display: showMobileSeal
                                          ? "block"
                                          : "none",
                                      }
                                    : sealType === "checkout" ||
                                      sealType === "insurance" ||
                                      sealType === "textShield"
                                      ? {
                                          display: "none",
                                        }
                                      : {
                                          maxWidth: sealMaxWidthOnMobile + "px",
                                          minHeight: "175px",
                                          cursor: "pointer",
                                          display: showMobileSeal
                                            ? "block"
                                            : "none",
                                        }
                                }
                                onClick={() =>
                                  window.open(certificateURL, "_blank")
                                }
                              />
                            ) : (
                              <div
                                style={{
                                  display: showMobileSeal ? "block" : "none",
                                }}
                              >
                                <Spinner />
                              </div>
                            )}
                          </div>

                          {/* Checkout and Banner seal */}

                          <div
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center my-2"
                            style={{
                              display:
                                sealType === "checkout" ||
                                sealType === "insurance" ||
                                sealType === "textShield"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {sealType === "textShield" && trustSealPath ? (
                              <div
                                style={{
                                  width: "235px",
                                  padding: "10px",
                                  borderRadius: "3px",
                                  background:
                                    ((color === "black" || color === "white") &&
                                      "#000000") ||
                                    "#ffffff",
                                }}
                              >
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                >
                                  <tr>
                                    <td
                                      width={
                                        parseInt(sealMaxWidth) +
                                        parseInt(sealFontSize) +
                                        "px"
                                      }
                                    >
                                      <img
                                        className="
                                        my-2
                                      "
                                        src={trustSealPath}
                                        /* src={
                                        businessPciStatus !== "pass"
                                          ? demoTrustSealPath
                                          : trustSealPath
                                      } */
                                        alt="Seal"
                                        style={
                                          sealType === "side"
                                            ? {
                                                maxHeight: "250px",
                                                minHeight: sealMaxWidth + "px",
                                                cursor: "pointer",
                                                display: showMobileSeal
                                                  ? "none"
                                                  : "block",
                                              }
                                            : sealType === "checkout" ||
                                              sealType === "insurance"
                                              ? {
                                                  display: "none",
                                                }
                                              : sealType === "textShield"
                                                ? {
                                                    maxWidth:
                                                      parseInt(sealMaxWidth) +
                                                      parseInt(sealFontSize) +
                                                      "px",
                                                    paddingBottom:
                                                      ((sealFontSize === "12" ||
                                                        sealFontSize === "16" ||
                                                        sealFontSize ===
                                                          "18") &&
                                                        "2px") ||
                                                      "0",
                                                  }
                                                : {
                                                    maxWidth:
                                                      sealMaxWidth + "px",
                                                    minHeight: "175px",
                                                    cursor: "pointer",
                                                    display: showMobileSeal
                                                      ? "none"
                                                      : "block",
                                                  }
                                        }
                                        onClick={() =>
                                          window.open(certificateURL, "_blank")
                                        }
                                      />
                                    </td>
                                    <td
                                      style={{
                                        paddingLeft: "5px",
                                        fontSize: sealFontSize + "px",
                                        verticalAlign: "middle",
                                        color: sealFontColor,
                                      }}
                                    >
                                      Secure transaction
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            ) : trustSealPath ? (
                              <>
                                <img
                                  className="my-2"
                                  src={trustSealPath}
                                  /* src={
                                    businessPciStatus !== "pass"
                                      ? demoTrustSealPath
                                      : trustSealPath
                                  } */
                                  alt="Seal"
                                  onError={() =>
                                    this.setState({
                                      trustSealPath: images.tgSealDefault,
                                    })
                                  }
                                  onClick={() =>
                                    window.open(certificateURL, "_blank")
                                  }
                                  style={{
                                    maxWidth:
                                      sealMaxWidth + parseInt(100) + "px",
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                  }}
                                />{" "}
                              </>
                            ) : (
                              <div>
                                <Spinner />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <p>
                        <label> Preset Name</label>
                        <input
                          type="text"
                          name="presetName"
                          value={presetName}
                          onChange={this.handleInputChange}
                          onBlur={(evt) => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false,
                              });
                            }
                          }}
                        />
                      </p>
                      <p
                        style={{
                          display: sealType === "textShield" ? "none" : "block",
                        }}
                      >
                        <label> Website Name on Trust Seal</label>
                        <input
                          type="text"
                          name="label"
                          value={label}
                          onChange={this.handleInputChange}
                          onBlur={(evt) => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false,
                              });
                            }
                          }}
                          style={{ textTransform: "uppercase" }}
                        />
                      </p>
                      <p
                        style={
                          floatSide === "standAlone" ||
                          sealType === "textShield"
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <label> Offset Placement ( px )</label>
                        <input
                          type="text"
                          name="distanceFromEdge"
                          value={distanceFromEdge}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false,
                              });

                              document
                                .getElementById("btnDisplayModalSealInfo")
                                .click();
                            }
                          }}
                        />
                      </p>
                      {userInfoText ? (
                        <div
                          className="well well-lg info"
                          style={{
                            background: "rgb(255, 255, 255)",
                            fontWeight: "normal",
                            fontSize: "13px",
                            color: "#2e6da4",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px",
                            borderLeft: "6px solid #2e6da4",
                            borderColor: "#337ab7",
                            padding: "12px 24px",
                          }}
                        >
                          {userInfoText}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-5 body-right">
                      <div className="title">Code</div>
                      {!codeGenerated ? (
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            this.generateSealCode();
                          }}
                        >
                          <i className="fa fa-code" /> Generate Seal Code
                        </button>
                      ) : (
                        <>
                          <div
                            className="well well-lg"
                            style={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <textarea
                              value={sealCode.textboxCode}
                              readOnly={true}
                              style={{
                                height: "290px",
                                border: "none",
                              }}
                            />
                          </div>

                          <div className="mb-3" style={{ width: "100%" }}>
                            <div style={{ width: "50%", float: "left" }}>
                              {(isGoogleTagManagerEnabled === true && (
                                <button
                                  className="btn-tglight2 btn-md"
                                  onClick={() =>
                                    this.toggleGoogleTagManager(false)
                                  }
                                >
                                  <b className="fas fa-undo" />&nbsp; Back
                                </button>
                              )) || (
                                <button
                                  className="btn-tglight2 btn-md"
                                  data-toggle="modal"
                                  data-target="#sealGoogleTagManagerCnfModal"
                                >
                                  <b className="fas fa-tag" />&nbsp; Google Tag
                                  Manager
                                </button>
                              )}
                            </div>

                            <div style={{ width: "50%", float: "right" }}>
                              <CopyToClipboard
                                text={sealCode.textboxCode}
                                onCopy={() =>
                                  this.setState(
                                    {
                                      isCopied: true,
                                      // notificationType: "success",
                                      // notificationMessage:
                                      //   "Code copied to clipboard.",
                                    },
                                    () => {
                                      this.setState({
                                        isCopied: false,
                                      });
                                    }
                                  )
                                }
                              >
                                <button className="btn-tglight2 btn-md">
                                  <b className="fas fa-copy" />&nbsp; Copy to
                                  Clipboard
                                </button>
                              </CopyToClipboard>
                            </div>
                          </div>

                          {/* <div className="text-right mb-3">
                            <CopyToClipboard
                              text={sealCode.textboxCode}
                              onCopy={() =>
                                this.setState(
                                  {
                                    isCopied: true,
                                    // notificationType: "success",
                                    // notificationMessage:
                                    //   "Code copied to clipboard.",
                                  },
                                  () => {
                                    this.setState({
                                      isCopied: false,
                                    });
                                  }
                                )
                              }
                            >
                              <button className="btn-tglight2 btn-md">
                                <b className="fas fa-copy" />&nbsp; Copy to
                                Clipboard
                              </button>
                            </CopyToClipboard>
                          </div> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TrustSealsInfo />

        <TrustSealsCreatePreset createSealPreset={this.createSealPreset} />

        <button
          id="btnDisplayModalSealInfo"
          data-toggle="modal"
          data-target="#modalSealInfo"
          style={{ display: "none" }}
        >
          Seal Info
        </button>

        <SealGoogleTagManagerCnfModal
          toggleGoogleTagManager={this.toggleGoogleTagManager}
        />
      </Aux>
    );
  }
}

SealComponent.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      goToDashboard: () => push("/"),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealComponent);
