import React from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment/moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Table from "../../components/ui/table/table";
import Aux from "../../components/hoc/containerAux";
import { removeStorageItem } from "../../helpers/storage";
import { getInsuranceHistories } from "../../modules/actions/index";
import {
  callApiGetYearlySignupStatData,
  callApiGetMonthlySignupStatData,
} from "../../services/index";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const yearlyLabels = moment.months();
const monthlyLabels = [];

const yearlyData = {
  labels: yearlyLabels,
  datasets: [
    {
      label: "Free",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(255,69,0, 0.5)",
    },
    {
      label: "Ultimate Website Protection",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(218,165,32, 0.5)",
    },
    {
      label: "Total Website Protection",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(154,205,50, 0.5)",
    },
    {
      label: "60 Days Trial",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(60,179,113, 0.5)",
    },
    {
      label: "90 Days Trial",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(70,130,180, 0.5)",
    },
  ],
};

const monthlyData = {
  labels: null,
  datasets: [
    {
      label: "Free",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(255,69,0, 0.5)",
    },
    {
      label: "Ultimate Website Protection",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(218,165,32, 0.5)",
    },
    {
      label: "Total Website Protection",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(154,205,50, 0.5)",
    },
    {
      label: "60 Days Trial",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(60,179,113, 0.5)",
    },
    {
      label: "90 Days Trial",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: "rgba(70,130,180, 0.5)",
    },
  ],
};

class SignupStatisticsReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      yearlyStat: null,
      monthlyStat: null,
    };
  }

  async componentDidMount() {
    let totalMonthDays = moment().daysInMonth();

    for (let i = 1; i <= totalMonthDays; i++) {
      monthlyLabels.push(i);
    }

    monthlyData.labels = monthlyLabels;

    const apiData = await callApiGetYearlySignupStatData();

    this.setState(
      {
        yearlyStat: {
          labels: apiData.data.months,
          datasets: apiData.data.monthlyStatData,
        },
      },
      () => {
        // console.log(this.state);
      }
    );
  }

  async getMothlySignupStat() {
    const apiData = await callApiGetMonthlySignupStatData();

    this.setState(
      {
        monthlyStat: {
          labels: apiData.data.days,
          datasets: apiData.data.monthlyStatData,
        },
      },
      () => {
        // console.log(this.state);
      }
    );
  }

  async componentDidUpdate(prevProps, prevState) {}

  render() {
    const { yearlyStat, monthlyStat } = this.state;

    return (
      <Aux>
        <div>
          <h4> Signup Statistics</h4>
          <div className="row">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item active">
                <a
                  className="nav-link"
                  id="yearly-statistics-tab"
                  data-toggle="tab"
                  href="#yearly-statistics"
                  role="tab"
                  aria-controls="yearly-statistics-tab"
                  aria-selected="true"
                >
                  Yearly
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  id="monthly-statistics-tab"
                  data-toggle="tab"
                  href="#monthly-statistics"
                  role="tab"
                  aria-controls="monthly-statistics-tab"
                  aria-selected="true"
                  onClick={() => this.getMothlySignupStat()}
                >
                  Monthly
                </a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div
              className="tab-content"
              id="signupStatisticsTabContent"
              style={{ width: "100%" }}
            >
              <div
                className="tab-pane fade in active"
                id="yearly-statistics"
                role="tabpanel"
                aria-labelledby="yearly-statistics-tab"
              >
                <div className="">
                  <Bar
                    options={options}
                    data={(yearlyStat && yearlyStat) || yearlyData}
                  />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="monthly-statistics"
                role="tabpanel"
                aria-labelledby="monthly-statistics-tab"
              >
                <div className="">
                  <Bar
                    options={options}
                    data={(monthlyStat && monthlyStat) || monthlyData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insuranceHistories: state.accountStore.insuranceHistories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getInsuranceHistories,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupStatisticsReport);
