import React from "react";
import ControlButtons from "../controlButtons";
import moment from "moment";
import _ from "lodash";
import { convertTimeTo } from "../../../helpers/timeZone";
function sslDates({
  index,
  isLoadingSSLDates,
  sslDatesActive,
  sslDates,
  getSSLDates,
  goToDashboard,
  getRefreshSealDates,
  isRefreshingSeals,
}) {
  return (
    <>
      <div className="reportButton">
        <button
          disabled={isLoadingSSLDates}
          className="btn-success btn-sm"
          onClick={() => getSSLDates()}>
          {isLoadingSSLDates ? "Loading..." : "SSL DATE"}
        </button>
        <div className="clearfix" />
      </div>
      <p>
        Businesses that seal dates were not updated and empty date fields{" "}
        {sslDates.length > 0 ? (
          <button
            className={
              isRefreshingSeals ? "btn-info btn-sm" : "btn-warning btn-sm"
            }
            onClick={() => {
              getRefreshSealDates();
            }}
            disabled={isRefreshingSeals ? true : false}>
            {isRefreshingSeals ? "Refreshing..." : "Refresh Seal Dates"}
          </button>
        ) : null}
      </p>
      <hr />
      {sslDatesActive && !isLoadingSSLDates && sslDates.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th />
                <th>Device (ip/url)</th>
                <th>Website Seal Date</th>
                <th>PCI Scan Date</th>
              </tr>
            </thead>
            <tbody>
              {sslDates.map((scan, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <span
                      className="a-pointer"
                      onClick={() => {
                        goToDashboard(scan.businessId);
                      }}>
                      {scan.primaryDomain}
                    </span>
                  </td>
                  <td>
                    {scan.lastPciScanDate
                      ? convertTimeTo(scan.lastPciScanDate, "America/Denver")
                      : "N/A"}{" "}
                  </td>
                  <td>{convertTimeTo(scan.pciScanDate, "America/Denver")} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default sslDates;
