import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import AppContext from "../../components/context/appContext";
import BpStatistics from "./bpStatistics";
import BpImplementation from "./bpImplementation";

class BuyerProtectionStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBusinessData: [],
      businessId: "",
    };
  }

  render() {
    return (
      <>
        <div id="mcontent-wrapper" style={{ padding: "40px 40px 0px" }}>
          <div
            id="mcontent-top"
            className="pull-left"
            style={{ background: "transparent", padding: "5px" }}
          >
            <ul id="dashTab" className="nav menu-tab" role="tablist">
              <li role="presentation" className="active">
                <a
                  data-toggle="tab"
                  href="#statistics"
                  aria-controls="statistics"
                  role="tab"
                >
                  Statistics
                </a>
              </li>
              <li role="presentation">
                <a
                  data-toggle="tab"
                  href="#implementation"
                  aria-controls="implementation"
                  role="tab"
                >
                  Implementation
                </a>
              </li>
              <li role="presentation">
                <a
                  data-toggle="tab"
                  href="#sealCode"
                  aria-controls="sealCode"
                  role="tab"
                >
                  Seal Code
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content" style={{ marginTop: "120px" }}>
            <div
              role="tabpanel"
              className="tab-pane active"
              id="statistics"
              style={{ margin: "0 15px" }}
            >
              <BpStatistics businessId={this.props.businessId} />
            </div>

            <div
              role="tabpanel"
              className="tab-pane fade"
              id="implementation"
              style={{ margin: "0 15px" }}
            >
              <BpImplementation businessId={this.props.businessId} />
            </div>

            <div
              role="tabpanel"
              className="seal-content-wrapper tab-pane fade"
              id="sealCode"
              style={{ margin: "0 15px" }}
            >
              <SealComponent />
            </div>
          </div>
        </div>
      </>
    );
  }
}

BuyerProtectionStats.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerProtectionStats);
