import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../../helpers/config';
import { images } from '../../helpers/images';
import { getStorageItem } from '../../helpers/storage';
import AppContext from '../../components/context/appContext';
import TrustGuardAnimation from '../../components/animation/trustGuardAnimation';

import { callApiGetBusinessData, callApiUpdateBusinessData } from '../../services';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('trustguardapiapp');

class CertificateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: null,
      businessData: null,
      certificateURL: 'CERTIFICATE_PATH/certificate/PRIMARY_DOMAIN',
      businessCertificateData: [
        {
          title: 'Website is Security Scanned',
          subTitle: 'Security Scan Passed: 01/02/2019',
          icon: 'tg-security-scanned-shield-icon',
          tag: 'securityScannedActive',
          slug: 'website-security',
          image: 'tgSecurityIco',
          status: false
        },
        {
          title: 'No Malware Detected',
          subTitle: 'Malware Scan Passed: 01/02/2019',
          icon: 'tg-malware-icon',
          tag: 'malwareOrPhishingActive',
          slug: 'no-malware',
          image: 'tgMalwareIco',
          status: false
        },
        {
          title: 'Active SSL Certificate',
          subTitle: 'Encrypted By: CloudFlare, Inc.',
          icon: 'tg-ssl-cert-icon',
          tag: 'sslCertificateActive',
          slug: 'active-ssl',
          image: 'tgSslIco',
          status: false
        }
      ],
      companyParagraph: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      contactPhone: '',
      contactEmail: '',
      supportUrl: '',
      supportEmail: '',
      isDisabled: false
    };
  }

  async componentDidMount() {
    let businessId = null;
    const { currentBusinessId } = this.context;

    if (currentBusinessId) {
      businessId = currentBusinessId;
    } else {
      businessId = getStorageItem('businessId')
        ? cryptr.decrypt(getStorageItem('businessId'))
        : null;
    }

    if (businessId) {
      const businessData = await callApiGetBusinessData(businessId);

      this.setState(
        {
          businessId: businessId,
          businessData: businessData,
          companyParagraph:
            businessData &&
            businessData.certificateContact &&
            businessData.certificateContact.companyParagraph
              ? businessData.certificateContact.companyParagraph
              : '',
          address:
            businessData &&
            businessData.certificateContact &&
            businessData.certificateContact.address
              ? businessData.certificateContact.address
              : '',
          address2:
            businessData &&
            businessData.certificateContact &&
            businessData.certificateContact.address2
              ? businessData.certificateContact.address2
              : '',
          city:
            businessData && businessData.certificateContact && businessData.certificateContact.city
              ? businessData.certificateContact.city
              : '',
          state:
            businessData && businessData.certificateContact && businessData.certificateContact.state
              ? businessData.certificateContact.state
              : '',
          zip:
            businessData && businessData.certificateContact && businessData.certificateContact.zip
              ? businessData.certificateContact.zip
              : '',
          country:
            businessData &&
            businessData.certificateContact &&
            businessData.certificateContact.country
              ? businessData.certificateContact.country
              : '',
          contactPhone:
            businessData &&
            businessData.certificateContact &&
            businessData.certificateContact.contactPhone
              ? businessData.certificateContact.contactPhone
              : '',
          contactEmail:
            businessData &&
            businessData.certificateContact &&
            businessData.certificateContact.contactEmail
              ? businessData.certificateContact.contactEmail
              : '',
          supportUrl:
            businessData &&
            businessData.certificateContact &&
            businessData.certificateContact.supportUrl
              ? businessData.certificateContact.supportUrl
              : '',
          certificateURL: this.state.certificateURL.replace(
            'PRIMARY_DOMAIN',
            businessData.primaryDomain
          )
        },
        () => {
          this.setState({
            certificateURL: this.state.certificateURL.replace(
              'CERTIFICATE_PATH',
              config.seals.certificateHost
            )
          });
        }
      );

      const { businessCertificateData } = this.state;

      businessCertificateData.forEach(element => {
        element.status = false;
        const thisCertificate = businessData.certificate.filter(certificateElement => {
          return certificateElement.slug === element.slug;
        });
        if (thisCertificate.length > 0) {
          element.status = true;
        }
      });

      this.setState({ businessCertificateData });
    }
  }

  onSaveCertificateContact = async () => {
    this.setState({
      isDisabled: true
    });
    let certificateContact = {
      companyParagraph: this.state.companyParagraph,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
      contactPhone: this.state.contactPhone,
      contactEmail: this.state.contactEmail,
      supportUrl: this.state.supportUrl
    };

    await callApiUpdateBusinessData(this.state.businessId, {
      certificateContact: certificateContact,
      purpose: 'certificateContact'
    });

    toast.success('Certificate has been updated.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });

    setTimeout(() => {
      this.setState({
        isDisabled: false
      });
    }, 2500);
  };

  onClickPushCheckBoxValue = async (slug, currentStatus) => {
    let selectedBusinessCertificate = [];
    const { businessCertificateData } = this.state;

    businessCertificateData.filter(certificate => {
      if (certificate.slug === slug) {
        certificate.status = !currentStatus;
      }

      return true;
    });

    this.setState({ businessCertificateData });

    for (let i = 0; i < businessCertificateData.length; i++) {
      if (businessCertificateData[i].status === true) {
        selectedBusinessCertificate.push({
          slug: businessCertificateData[i].slug,
          status: true
        });
      }
    }

    await callApiUpdateBusinessData(this.state.businessId, {
      certificate: selectedBusinessCertificate,
      purpose: 'certificate'
    });

    toast.success('Certificate has been updated.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });
  };

  render() {
    const {
      businessData,
      businessCertificateData,
      companyParagraph,
      address,
      address2,
      city,
      state,
      zip,
      country,
      contactPhone,
      contactEmail,
      supportUrl,
      certificateURL
    } = this.state;

    return (
      <>
        {/* <h4 style={{ textTransform: "uppercase" }}>
          CERTIFICATE CUSTOMIZATION{" "}
          <b>
            {businessData && businessData.primaryDomain
              ? " - " + businessData.primaryDomain
              : ""}
          </b>
        </h4>
        <p style={{ marginBottom: "25px" }}>
          
        </p> */}

        <div className="top-header-wrapper">
          <div class="top-header-title">
            <a
              onClick={() =>
                this.props.goToDashboard({
                  accountAdminView: false
                })
              }>
              <img src={images.keyboardReturn} />
            </a>

            <h4>CERTIFICATE OPTIONS</h4>
          </div>

          <div class="top-header-intro">
            <div class="top-header-intro-details">
              <h2 class="top-header-intro-domain-name">
                {this.state.businessData ? this.state.businessData.primaryDomain : ''}
              </h2>
              <p className="top-header-intro-text">
                When your customers click on your seal they will see the popup certificate. The
                certificate tells them about the different security measures and other services you
                have initiated on their behalf. Customizing your certificate is easy. Simply select
                from the options on the right by checking the box, to add or remove them from your
                popup and full certificate. Also, below you may add certain information about your
                business that will be displayed on your full certificate page.
              </p>
              <p className="top-header-intro-subtext">
                Note: You can use presets to display different seals on different pages.
              </p>
            </div>

            <img className="top-header-intro-image" src={images.secureGroup} />
          </div>
        </div>

        <div id="dash-malware-table" className="mt-35">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 no-pad">
              <div className="row py-5">
                <div className="col-md-5 col-md-offset-1 col-sm-5 col-xs-12 col-xxs-12">
                  <div
                    className="text-center"
                    style={{
                      boxShadow: 'none',
                      fontSize: '18px',
                      paddingBottom: '20px'
                    }}>
                    Popup Certificate Preview
                  </div>

                  <div
                    id="sec-scanning"
                    className="well-tgcert text-center"
                    style={{
                      margin: '0 auto'
                    }}>
                    <a
                      target="_blank"
                      onClick={() => window.open(certificateURL, '_blank')}
                      href="https://app.trustguard.com/certificate/shopperapproved.com"
                      id="tg_moreDetails"
                      rel="noopener noreferrer">
                      More Details <i className="tg-arrow-right" />
                    </a>
                    <img id="tg-cert-logo" src={images.tgRegularDark} alt="logo" />
                    <span id="cert-domain" className="cert-domain">
                      Demosite.com
                    </span>
                    <span className="cert-title">SECURED 01-02-2019</span>
                    <ul id="sortable" className="list-unstyled">
                      {businessCertificateData
                        ? businessCertificateData.map((element, index) => {
                            if (element.status === true) {
                              return (
                                <TrustGuardAnimation key={index} element={element} index={index} />
                              );
                            }

                            return null;
                          })
                        : ''}
                    </ul>
                    <p id="tg_certFooterNote">
                      <span className="tg_domain_name">localreviews.com</span> has successfully
                      completed and passed a recent PCI DSS Security Scan, an Internal Malware Scan,
                      and has had their SSL Certificate Validated by Trust Guard.
                    </p>
                  </div>
                </div>

                <div className="col-md-5 col-sm-5 col-xs-12 col-xxs-12 no-pad text-center">
                  <div
                    id="sec-scanning"
                    className="well-tgcert-params text-center"
                    style={{
                      margin: '55px 0 45px 0'
                    }}>
                    <ul className="list-unstyled">
                      {businessCertificateData.length > 0
                        ? businessCertificateData.map((element, index) => {
                            return (
                              <li
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                <div
                                  className={
                                    element.status === true
                                      ? 'tg-check-square fa fa-check-square checkBoxColor'
                                      : 'tg-check-square fa fa-check-square'
                                  }
                                  onClick={() => {
                                    this.onClickPushCheckBoxValue(element.slug, element.status);
                                  }}
                                  style={{
                                    fontSize: '38px',
                                    marginTop: '-15px'
                                  }}
                                />
                                <div
                                  style={{
                                    padding: '0px 5px',
                                    width: '485px',
                                    height: 'auto',
                                    border: '1px solid #e5e5e5',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '35px',
                                    marginBottom: '15px'
                                  }}>
                                  <div
                                    style={{
                                      float: 'left'
                                    }}>
                                    <img
                                      src={images[element.image]}
                                      alt="Certificate"
                                      className="cert-badge"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      float: 'left',
                                      fontSize: '18px'
                                    }}>
                                    {element.title}
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        : ''}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-pad text-center">
              <button
                className="btn btn-link btn-link-custom"
                style={{
                  boxShadow: 'none',
                  color: '#33981f',
                  fontSize: '24px',
                  fontWeight: 'bold'
                }}
                onClick={() => window.open(certificateURL, '_blank')}>
                Click here to see your full certificate page.
              </button>
              <hr />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Company Paragraph:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="companyParagraph"
                    value={companyParagraph}
                    onChange={evt =>
                      this.setState({
                        companyParagraph: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Address:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={address}
                    onChange={evt =>
                      this.setState({
                        address: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Address2:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="address2"
                    value={address2}
                    onChange={evt =>
                      this.setState({
                        address2: evt.target.value
                      })
                    }
                  />
                </div>

                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>City:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={city}
                    onChange={evt =>
                      this.setState({
                        city: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>State:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={state}
                    onChange={evt =>
                      this.setState({
                        state: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Zip:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="zip"
                    value={zip}
                    onChange={evt =>
                      this.setState({
                        zip: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Country:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    value={country}
                    onChange={evt =>
                      this.setState({
                        country: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Contact Phone:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="contactPhone"
                    value={contactPhone}
                    onChange={evt =>
                      this.setState({
                        contactPhone: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Contact Email:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="contactEmail"
                    value={contactEmail}
                    onChange={evt =>
                      this.setState({
                        contactEmail: evt.target.value
                      })
                    }
                  />
                </div>
                <br />
                <br />
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <label>Support Url:</label>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control"
                    name="supportUrl"
                    value={supportUrl}
                    onChange={evt =>
                      this.setState({
                        supportUrl: evt.target.value
                      })
                    }
                  />
                  <button
                    data-toggle="modal"
                    data-target="#email-ver"
                    className="btn-success btn-md btn-140-width pull-right mt-4"
                    onClick={() => {
                      this.onSaveCertificateContact();
                    }}
                    disabled={this.state.isDisabled}>
                    Save
                  </button>
                </div>
                <br />
                <br />
              </div>

              <br />
              <br />
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" />
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

CertificateComponent.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      goToDashboard: () => push('/')
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateComponent);
