import React, { useReducer } from 'react';
import { push } from 'connected-react-router';
import { isMobile } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import { images } from '../helpers/images';
import { getStorageItem, removeAllStorage } from '../helpers/storage';
import { getUserDecryptedData } from '../helpers/general';
import Modal from '../components/ui/modal/modal';
import { api, callOnMalwareCreateEmailHistory } from '../services';
import AppContext from '../components/context/appContext';
import ClearCache from 'react-clear-cache';
import FeedbackModal from './modals/feedbackModal';
import FeedbackAlertModal from './modals/feedbackAlertModal';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('trustguardapiapp');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userData: {},
      userBusinesssData: [],
      sideBarText: true,
      collapsed: false,
      logoutFont: 'none',
      isAdmin: false,
      displayMob1: 'block',
      displayMob2: 'none',
      displayForMobile: 'main-full slide-onmobile main-sidebar',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item',
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedPrivacy: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      feedback: '',
      showSideBarText: true,
      showSideBarWindowSize: '',
      showAccountMenu: false,
      showBuyerProtectionMenu: false,
      bpBusinessId: null
    };
  }

  async componentDidMount() {
    let userLsData = getUserDecryptedData();

    if (userLsData && userLsData.isAdmin == true) {
      this.setState({
        isAdmin: userLsData.isAdmin
      });
    }

    if (userLsData && userLsData.isAdmin !== true) {
      this.setState({
        sideMenuSelectedDashboard: 'menu-item side-menu-selected'
      });
    }

    if (
      userLsData &&
      userLsData.hasOwnProperty('bpBusinessId') &&
      userLsData.bpBusinessId &&
      userLsData.bpBusinessId.length > 0
    ) {
      this.setState({
        bpBusinessId: userLsData.bpBusinessId[0],
        showBuyerProtectionMenu: true
      });
    } else {
      this.setState({
        showBuyerProtectionMenu: false
      });
    }

    this.resize();
    window.addEventListener('resize', this.resize);

    let currentPage = window.location.href.split('/');
    switch (currentPage[currentPage.length - 1]) {
      case 'control-panel':
        let targetElement = document.getElementsByClassName('side-menu-selected');
        if (targetElement.length > 0) {
          targetElement[0].classList.remove('side-menu-selected');
        }
        break;
      case 'notifications':
        this.selectNotificationsPage();
        break;
      case 'user-profile':
        this.selectProfilePage();
        break;
      case 'billing':
        this.selectBillingPage();
        break;
      case 'account-settings':
        this.selectAccountPage();
      case 'buyer-protection':
        this.selectBuyeProtectionPage();
        break;
      default:
        this.selectDashboardPage();
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;
    if (prevState.userData !== userData && prevState.userBusinessData !== userBusinessData) {
      this.setState({
        userData: userData,
        userBusinessData: userBusinessData
      });
    }
  }

  collapseMenu(typeOfView) {
    if (!this.state.collapsed === false) {
      setTimeout(() => {
        this.sideBarText();
      }, 400);
      let mainContainer = document.getElementById('main-container');
      mainContainer.style.padding = '0px 0px 0px 250px';
      if (this.state.showSideBarText === false) {
        this.setState({
          showSideBarText: true
        });
      }
      if (typeOfView === 'mobile') {
        this.setState({ displayMob1: 'none', displayMob2: 'block' });
        this.setState({
          displayForMobile: 'main-full slide-onmobile-out main-sidebar',
          showSideBarWindowSize: 'none'
        });
      } else {
        this.setState({
          displayForMobile: 'main-full slide-onmobile main-sidebar',
          showSideBarWindowSize: 'block'
        });
      }
    } else {
      this.sideBarText();
      let mainContainer = document.getElementById('main-container');
      mainContainer.style.padding = '0px 0px 0px 70px';
      if (this.state.showSideBarText === true) {
        this.setState({
          showSideBarText: false
        });
      }

      if (typeOfView === 'mobile') {
        this.setState({ displayMob1: 'block', displayMob2: 'none' });
        this.setState({
          displayForMobile: 'main-full slide-onmobile-in main-sidebar collapsed',
          showSideBarWindowSize: 'block'
        });
      } else {
        this.setState({
          displayForMobile: 'main-full slide-onmobile main-sidebar collapsed',
          showSideBarWindowSize: 'none'
        });
      }
    }

    this.setState({ collapsed: !this.state.collapsed });
  }

  resize = () => {
    let windowWidth = window.innerWidth;

    if (windowWidth <= 1310) {
      this.setState({
        showSideBarText: false
      });
      this.setStateForWindowResizeMobile();
    } else {
      this.setState({
        showSideBarText: true
      });
      this.setStateForWindowResizeDesktop();
    }
  };

  setStateForWindowResizeMobile() {
    let mainContainer = document.getElementById('main-container');
    mainContainer.style.padding = '0px 0px 0px 70px';

    this.setState({
      displayForMobile: 'main-full slide-onmobile main-sidebar collapsed',
      showSideBarWindowSize: 'none'
    });
  }

  setStateForWindowResizeDesktop() {
    let mainContainer = document.getElementById('main-container');
    mainContainer.style.padding = '0px 0px 0px 250px';

    this.setState({
      displayForMobile: 'main-full slide-onmobile main-sidebar',
      showSideBarWindowSize: 'block',
      sideBarText: true
    });
  }

  sideBarText() {
    this.setState({
      sideBarText: !this.state.sideBarText
    });
  }

  onClickLogOut = () => {
    api.logout();
    removeAllStorage();
    this.props.goToLogin();
  };

  setLogoutFontColor = () => {
    this.setState({
      logoutFont: '#333333'
    });
  };

  removeLogoutFontColor = () => {
    this.setState({
      logoutFont: 'none'
    });
  };

  selectDashboardPage = () => {
    this.setState({
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedDashboard: 'menu-item side-menu-selected',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item',
      sideMenuSelectedPrivacy: 'menu-item'
    });
  };

  selectNotificationsPage = () => {
    this.setState({
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item side-menu-selected',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item',
      sideMenuSelectedPrivacy: 'menu-item'
    });
  };

  selectProfilePage = () => {
    this.setState({
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item side-menu-selected',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item',
      sideMenuSelectedPrivacy: 'menu-item'
    });
  };

  selectBillingPage = () => {
    this.setState({
      sideMenuSelectedBilling: 'menu-item side-menu-selected',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item',
      sideMenuSelectedPrivacy: 'menu-item'
    });
  };

  selectPrivacyPage = () => {
    this.setState({
      sideMenuSelectedPrivacy: 'menu-item side-menu-selected',
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item'
    });
  };

  selectAccountPage = () => {
    this.setState({
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item side-menu-selected',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item',
      sideMenuSelectedPrivacy: 'menu-item'
    });
  };

  selectBuyeProtectionPage = () => {
    this.setState({
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item side-menu-selected'
    });
  };

  selectControlPanelPage = () => {
    this.setState({
      sideMenuSelectedBilling: 'menu-item',
      sideMenuSelectedDashboard: 'menu-item',
      sideMenuSelectedNotifications: 'menu-item',
      sideMenuSelectedProfile: 'menu-item',
      sideMenuSelectedAccount: 'menu-item',
      sideMenuSelectedReport: 'menu-item',
      sideMenuSelectedBuyerProtection: 'menu-item'
    });
  };

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    this.setState({ [name]: value, editable: true });
  }

  render() {
    let feedBackPosition = 'feedbackDiv';
    if (isMobile) {
      feedBackPosition = 'feedbackDivMobile';
    }

    const {
      isAdmin,
      sideBarText,
      logoutFont,
      displayMob1,
      displayMob2,
      displayForMobile,
      sideMenuSelectedDashboard,
      sideMenuSelectedNotifications,
      sideMenuSelectedProfile,
      sideMenuSelectedBilling,
      sideMenuSelectedAccount,
      sideMenuSelectedBuyerProtection,
      sideMenuSelectedPrivacy,
      showSideBarText,
      showAccountMenu,
      showBuyerProtectionMenu,
      bpBusinessId
    } = this.state;

    return (
      <>
        <div>
          <div className={displayForMobile}>
            <div className="sidebar-inner h4">
              <div
                className="sidebar-menu col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12"
                style={{ marginTop: 0 }}>
                <ul className="sidebar-mlist list-unstyled">
                  <li
                    className="menu-item"
                    style={{
                      backgroundColor: '#111111'
                    }}
                    onClick={this.collapseMenu.bind(this, 'desktop')}>
                    <div className="menu-ico col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className="fa fa-ellipsis-v h3 icons-sidebar-size"
                        style={{
                          position: 'relative',
                          top: '-10px',
                          left: '10px',
                          paddingTop: '10px',
                          paddingBottom: '8px'
                        }}
                      />
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div style={{ paddingTop: '10px', paddingBottom: '8px' }}>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad text-center"
                          style={{ paddingRight: 0 }}>
                          <img
                            src={images.logo}
                            alt="logo"
                            style={{
                              width: '45px',
                              height: '45px',
                              position: 'relative',
                              top: '-15px'
                            }}
                            onClick={() =>
                              this.props.goToPageDashboard({
                                accountAdminView: false
                              })
                            }
                          />
                        </div>
                        <div
                          style={{ display: 'none' }}
                          className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      <div style={{ paddingTop: '10px', paddingBottom: '8px' }} />
                    )}
                  </li>
                  <li
                    className={sideMenuSelectedDashboard}
                    id="dashboardSidebar"
                    onClick={() => {
                      this.props.goToPageDashboard({
                        accountAdminView: false
                      });
                      this.selectDashboardPage();
                      this.context.updateUserData();
                      this.context.updateUserBusinessData();
                    }}>
                    <div className="menu-ico col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className="fas fa-home-alt h3 icons-sidebar-size"
                        style={{ position: 'relative', top: '-10px' }}
                      />
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad-l text-left"
                          style={{ paddingRight: 0 }}>
                          Dashboard &nbsp;
                        </div>
                        <div className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                  <li
                    id="notificationsSidebar"
                    className={sideMenuSelectedNotifications}
                    onClick={() => {
                      this.props.goToPageNotifications();
                      this.selectNotificationsPage();
                      this.context.updateUserData();
                      this.context.updateUserBusinessData();
                      this.context.updateUserNotificationData();
                    }}>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className={'fas fa-bell h3 icons-sidebar-size'}
                        style={{ position: 'relative', top: '-10px' }}
                      />
                      <span>
                        <NotificationBadge
                          count={this.context.notificationsCount}
                          style={{
                            top: '-50px',
                            right: '-5px',
                            padding: '4px 7px',
                            transform: 'none'
                          }}
                          effect={Effect.SCALE}
                        />
                      </span>
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad-l text-left"
                          style={{ paddingRight: 0 }}>
                          Notifications
                        </div>
                        <div className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                  <li id="hrSidebar">
                    <hr style={{ marginTop: '5px', marginBottom: '-10px' }} />
                  </li>
                  <li
                    id="profileSidebar"
                    className={sideMenuSelectedProfile}
                    onClick={() => {
                      this.props.goToPageProfile();
                      this.selectProfilePage();
                      this.context.updateUserData();
                      this.context.updateUserBusinessData();
                    }}>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className="fa fa-user-circle h3 icons-sidebar-size"
                        style={{ position: 'relative', top: '-10px' }}
                      />
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad-l text-left"
                          style={{ paddingRight: 0 }}>
                          Profile
                        </div>
                        <div className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                  <li
                    id="billingSidebar"
                    className={sideMenuSelectedBilling}
                    onClick={() => {
                      this.props.goToPageBilling();
                      this.selectBillingPage();
                      this.context.updateUserData();
                      this.context.updateUserBusinessData();
                    }}>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className="fa fa-file-invoice h3 icons-sidebar-size"
                        style={{ position: 'relative', top: '-10px' }}
                      />
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad-l text-left"
                          style={{ paddingRight: 0 }}>
                          Billing
                        </div>
                        <div className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                  <li
                    id="privacyPolicySidebar"
                    className={sideMenuSelectedPrivacy}
                    onClick={() => {
                      this.selectPrivacyPage();
                      this.props.goToPagePrivacyPolicy();
                    }}>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className="fa fa-user-secret h3 icons-sidebar-size"
                        style={{ position: 'relative', top: '-10px' }}
                      />
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad-l text-left"
                          style={{ paddingRight: 0 }}>
                          Privacy Policy
                        </div>
                        <div className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                  <li
                    id="accountSidebar"
                    className={sideMenuSelectedAccount}
                    onClick={() => {
                      this.props.goToPageAccountSettings();
                      this.selectAccountPage();
                      this.context.updateUserData();
                      this.context.updateUserBusinessData();
                    }}
                    style={{
                      display:
                        getStorageItem('businessId') !== null && isAdmin
                          ? 'block'
                          : isAdmin === false
                            ? 'block'
                            : 'none'
                    }}>
                    <div className="menu-ico col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className="fas fa-cog h3 icons-sidebar-size"
                        style={{ position: 'relative', top: '-10px' }}
                      />
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad-l text-left"
                          style={{ paddingRight: 0 }}>
                          Account
                        </div>
                        <div className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                  <li
                    id="buyerProtectionSidebar"
                    className={sideMenuSelectedBuyerProtection}
                    onClick={() => {
                      this.props.gotoBuyerProtectionPage();
                      this.selectBuyeProtectionPage();
                      this.context.updateUserData();
                      this.context.updateUserBusinessData();
                      this.context.updateCurrentBusinessId(bpBusinessId);
                    }}
                    style={showBuyerProtectionMenu ? { display: 'block' } : { display: 'none' }}>
                    <div className="menu-ico col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-3">
                      <i
                        className="fas fa-shopping-cart h3 icons-sidebar-size"
                        style={{ position: 'relative', top: '-10px' }}
                      />
                    </div>
                    {sideBarText && showSideBarText ? (
                      <div>
                        <div
                          className="menu-label col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad-l text-left"
                          style={{ paddingRight: 0, marginTop: '-10px' }}>
                          Buyer Protection
                        </div>
                        <div className="menu-arrow col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xxs-2 text-center">
                          <i className="menu-item fa fa-chevron-right" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </li>
                </ul>
              </div>
              <ClearCache>
                {({ emptyCacheStorage }) => {
                  return (
                    <>
                      <div
                        id="logOutDiv"
                        className={
                          (sideBarText &&
                            showSideBarText &&
                            'col-lg-12 col-md-6 col-sm-6 col-xs-6 col-xxs-6 no-pad') ||
                          ''
                        }
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          width: '100%'
                        }}>
                        {(sideBarText &&
                          showSideBarText && (
                            <div
                              className
                              style={{
                                border: '0px solid #000',
                                background: logoutFont
                              }}
                              onMouseEnter={() => this.setLogoutFontColor()}
                              onMouseLeave={() => this.removeLogoutFontColor()}
                              onClick={() => {
                                this.onClickLogOut();
                                emptyCacheStorage();
                              }}>
                              <a
                                href="/"
                                onClick={e => e.preventDefault()}
                                className="btn text-muted"
                                data-testid="btnAppLogout">
                                <h4 style={{ color: '#ffffff' }}>
                                  <i
                                    className="fa fa-sign-out"
                                    aria-hidden="true"
                                    style={{
                                      transform: 'rotate(180deg)',
                                      marginLeft: 14,
                                      marginRight: 32
                                    }}
                                  />
                                  Logout
                                </h4>
                              </a>
                            </div>
                          )) || (
                          <div style={{ padding: '0 15px', cursor: 'pointer' }}>
                            <h4 className="text-center">
                              <a
                                onClick={() => {
                                  this.onClickLogOut();
                                  emptyCacheStorage();
                                }}>
                                <i
                                  className="fa fa-sign-out text-white"
                                  aria-hidden="true"
                                  style={{
                                    transform: 'rotate(180deg)'
                                  }}
                                />
                              </a>
                            </h4>

                            <hr />

                            <h4 className="text-center">
                              <a
                                href="https://play.google.com/store/apps/details?id=com.trustguard.app"
                                target="_blank">
                                <i
                                  style={{ fontSize: '20px' }}
                                  className="fab fa-google-play text-white"
                                  aria-hidden="true"
                                />
                              </a>
                            </h4>

                            <h4 className="text-center">
                              <a
                                href="https://apps.apple.com/al/app/trust-guard/id1546055472"
                                target="_blank">
                                <i
                                  style={{ fontSize: '28px' }}
                                  className="fab fa-apple text-white"
                                  aria-hidden="true"
                                />
                              </a>
                            </h4>
                          </div>
                        )}

                        {0 && sideBarText && showSideBarText ? (
                          <div
                            style={{
                              padding: '0 15px'
                            }}>
                            <hr />
                            <div
                              style={{
                                fontSize: '12px',
                                margin: '7px 0 17px 0',
                                border: '0px solid #000'
                              }}>
                              Download the Trust Guard App:
                            </div>

                            <div
                              style={{
                                margin: '10px 0',
                                border: '0px solid #000'
                              }}>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.trustguard.app"
                                target="_blank">
                                <img src={images.playStore} alt="" />
                              </a>
                            </div>

                            <div
                              style={{
                                margin: '10px 0',
                                border: '0px solid #000'
                              }}>
                              <a
                                href="https://apps.apple.com/al/app/trust-guard/id1546055472"
                                target="_blank">
                                <img src={images.appleStore} alt="" />
                              </a>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {isAdmin === false ? (
                          <>
                            <div
                              id={feedBackPosition}
                              className="pointer"
                              data-toggle="modal"
                              data-target="#userFeedbackModal"
                              style={{ display: 'block', color: '#268226' }}>
                              <img
                                src={images.feedbackIcon}
                                alt="feedback"
                                className="img-fluid"
                                style={{ width: '20px', height: '20px' }}
                              />{' '}
                              <strong>FEEDBACK</strong>
                            </div>
                            <FeedbackModal />
                            <button
                              id="feedbackAlert"
                              data-toggle="modal"
                              data-target="#userFeedbackAlertModal"
                              style={{ display: 'none' }}>
                              Feeddback Alert!
                            </button>
                            <FeedbackAlertModal />
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  );
                }}
              </ClearCache>
            </div>
          </div>
          <div id="mobile-mbar" className="col-xs-12 col-xxs-12 visible-xs">
            <div
              onClick={this.collapseMenu.bind(this, 'mobile')}
              className="col-xs-4 col-xxs-4 text-center h4">
              <button
                id="mobile-menu"
                style={{ display: displayMob1 }}
                className="tg-dashboard-link mbar-links">
                <b className="fa fa-bars" />
              </button>
              <button
                id="mobile-menu-hide"
                style={{ display: displayMob2 }}
                className="tg-dashboard-link mbar-links">
                <b className="fa fa-arrow-left" />
              </button>
            </div>

            <div
              onClick={() => this.props.goToPageAccountSettings()}
              className="col-xs-4 col-xxs-4 text-center h4">
              <button id="setting-menu" className="tg-dashboard-link mbar-links">
                <b className="fa fa-cog" />
              </button>
            </div>
            <div
              onClick={() => this.props.goToPageProfile()}
              className="col-xs-4 col-xxs-4 text-center h4">
              <button id="account-menu" className="tg-dashboard-link mbar-links">
                <b className="fa fa-user" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Sidebar.contextType = AppContext;

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToPageDashboard: (param = {}) => push('/', param),
      goToPageScan: () => push('/pci-scan'),
      goToPageMalware: () => push('/malware'),
      goToPageSSL: () => push('/ssl'),
      goToPageInsurance: () => push('/insurance'),
      goToPageTrustSeals: () => push('/trust-seals'),
      goToPageAccountSettings: () => push('/account-settings'),
      goToPageGdpr: () => push('/gdpr'),
      goToPageOwasp: () => push('/owasp'),
      goToPageProfile: () => push('/user-profile'),
      goToPagePrivacyPolicy: () => push('/privacy-policy'),
      goToPageReports: () => push('reports'),
      goToPageNotifications: () => push('/notifications'),
      goToPageBilling: () => push('/billing'),
      gotoBuyerProtectionPage: () => push('/buyer-protection'),
      goToLogin: () => push('/login')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
