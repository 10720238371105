import React from "react";

const ScanHistoryContext = React.createContext({
  pci: {
    scanHistories: [],
    isScanMounted: false,
    hasPciScan: false,
    isLoading: true,
  },
  showPciScanResultHandler: (toggledIndex) => {},
});

export default ScanHistoryContext;
