import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from '../../helpers/config';

class trustSealsDeletePresetConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: '',
      isDisabled: true
    };
  }

  enableDelete(value) {
    if (value.toUpperCase() === 'DELETE') {
      this.setState({
        isDisabled: false
      });
    }
  }

  removeSeal() {
    this.setState({
      confirmDelete: ''
    });
    this.props.removeSeal();
  }

  render() {
    const { confirmDelete } = this.state;
    return (
      <div id="modalDeletePreset" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content col-md-12 seal-preset-delete-container">
            <div className="seal-preset-create-header">
              <h4 className="seal-preset-create-title">Remove Preset</h4>
              <button
                type="button"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="">Are you sure you want to delete preset?</div>

            <div className="seal-preset-create-content">
              <input
                type="text"
                className="seal-preset-input seal-preset-delete-input"
                name="confirmDelete"
                value={confirmDelete}
                placeholder="Type DELETE here..."
                onChange={evt => {
                  this.setState({ confirmDelete: evt.target.value });
                  this.enableDelete(evt.target.value);
                }}
              />
            </div>

            <div className="seal-preset-create-action">
              <button
                id="hideModalOnSuccess"
                type="button"
                className="action-btn btn-cancel"
                data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="action-btn btn-create"
                data-dismiss="modal"
                onClick={() => {
                  this.removeSeal();
                }}
                disabled={this.state.isDisabled}>
                Delete Preset
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(trustSealsDeletePresetConfirmation);
