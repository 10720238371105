import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateEmail from "../../components/createEmail/createEmail";
import DeleteEmailTemplateConfirmationModal from "../modals/deleteEmailTemplateConfirmationModal";
import EmailTemplateForm from "./emailTemplateForm";
import EmailTemplates from "./emailTemplates";
import { deleteEmailData } from "../../modules/actions";

class EmailTab extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emailData: {},
      fetchEmails: false,
    };
  }

  editEmail = (emailData) => {
    this.setState({
      emailData: emailData,
    });
  };

  deleteEmailConfirmModal = (emailData) => {
    document.getElementById("btnDeleteEmailTemplate").click();

    this.setState({
      emailData: emailData,
    });
  };

  deleteEmail = () => {
    let { emailData } = this.state;
    this.props.deleteEmailData(emailData._id);
    this.reFetchEmails();
  };

  reFetchEmails = () => {
    this.setState(
      {
        fetchEmails: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            fetchEmails: false,
          });
        }, 5);
      }
    );
  };

  render() {
    const { controlType } = this.props;
    const { emailData, fetchEmails } = this.state;
    return (
      <div id="create-notifications" className="tab-pane fade">
        <EmailTemplateForm
          emailData={emailData}
          reFetchEmails={this.reFetchEmails}
        />
        <EmailTemplates
          controlType={controlType}
          fetchEmails={fetchEmails}
          editEmail={this.editEmail}
          deleteEmailConfirmModal={this.deleteEmailConfirmModal}
        />
        <DeleteEmailTemplateConfirmationModal deleteEmail={this.deleteEmail} />

        <button
          id="btnDeleteEmailTemplate"
          data-toggle="modal"
          data-target="#deleteEmailTemplateConfirmationModal"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ deleteEmailData }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTab);
