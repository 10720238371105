import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-toastify/dist/ReactToastify.css";
import Table from "../../components/ui/table/table";
import config from "../../helpers/config";
import { removeStorageItem } from "../../helpers/storage";
import AppContext from "../../components/context/appContext";
import { callApiBusinessFrontlineMigration } from "../../services/businessService";
import { callCreateBeyondScan } from "../../services";

import {
  createCssHash,
  createJsHash,
  cssJsHashList,
} from "../../modules/actions/index";
import { callApiBusinessAllNotMigrated } from "../../services/businessService";

class FrontlineMigrationTab extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: "",
      cssVersion: "",
      jsVersion: "",
      sealScripts: null,
      businesses: [],
      isMigrating: false,
      currentIndexClicked: null,
    };
  }

  async componentDidMount() {
    const businesses = await callApiBusinessAllNotMigrated();

    if (businesses.data.length > 0) {
      this.setState({ businesses: businesses.data });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps && newProps.loggedInUser) {
      this.setState({
        userId: newProps.loggedInUser._id,
      });
    }

    if (newProps && newProps.sealScripts) {
      this.setState(
        {
          sealScripts: newProps.sealScripts,
        },
        () => {
          this.setState({
            cssVersion: "",
            jsVersion: "",
          });
        }
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;

    if (
      prevProps.controlType !== this.props.controlType &&
      this.props.controlType === "frontline-migration"
    ) {
      this.props.cssJsHashList();
    }
  }

  onChangeHandelInput = (evt) => {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState({
      [name]: value,
    });
  };

  onClickUploadCss = () => {
    // console.log(this.context.userData);
    if (this.state.cssVersion) {
      const updateData = {
        type: "CSS",
        cssVersion: this.state.cssVersion,
        createdBy: this.context.userData._id,
      };

      this.props.createCssHash(updateData);
    }
  };

  onClickUploadJs = () => {
    if (this.state.jsVersion) {
      const updateData = {
        type: "JS",
        jsVersion: this.state.jsVersion,
        createdBy: this.context.userData._id,
      };

      this.props.createJsHash(updateData);
    }
  };

  onClickMigrate = async (id, index) => {
    this.setState({ isMigrating: true, currentIndexClicked: index });
    const businesses = [...this.state.businesses];
    console.log("index: ", businesses[index]);
    const isMigrated = await callApiBusinessFrontlineMigration(
      businesses[index]
    );

    const business = businesses[index];

    this.setState({
      businesses: businesses.filter((business) => business._id != id),
      isMigrating: false,
      currentIndexClicked: null,
    });
  };

  render() {
    const { cssVersion, jsVersion, sealScripts, businesses } = this.state;

    const cssHashListHeader = [
      { name: "Css Version" },
      { name: "CSS SRI HASH" },
    ];

    const jsHashListHeader = [{ name: "JS Version" }, { name: "JS SRI HASH" }];

    removeStorageItem("businessID");
    return (
      <>
        {" "}
        <div id="front-line" className="tab-pane fade">
          <h4 className="">Fronline Migrations</h4>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Domain</th>
                  <th>Account Type</th>
                  <th>Frequency</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {businesses.map((business, index) => (
                  <tr>
                    <td>{business.primaryDomain}</td>
                    <td>{business.accountType}</td>
                    <td>{business.scanFrequency}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => this.onClickMigrate(business._id, index)}
                        disabled={
                          this.state.currentIndexClicked === index &&
                          this.state.isMigrating
                        }
                      >
                        {this.state.currentIndexClicked === index &&
                        this.state.isMigrating
                          ? "Migrating..."
                          : "Migrate"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedInUser: state.accountStore.loggedInUser,
    sealScripts: state.accountStore.sealScripts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createCssHash, createJsHash, cssJsHashList },
    dispatch
  );
};

FrontlineMigrationTab.contextType = AppContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrontlineMigrationTab);
