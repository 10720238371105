import { api } from "./api";
import { callApi } from "../helpers/index";
import { validateDomain } from "../helpers/general";

export const callApiCreateAccountScan = async (data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("api/frontline").create(data);
    } else {
      return null;
    }
  });
};

export const callDownloadFLreport = (data) => {
  return callApi().then((token) => {
    return api.service("api/frontline").find({ query: data });
  });
};
