import React, { useState, useEffect } from "react";
import config from "../../helpers/config";
function scanScheduleModal(props) {
  const {
    domains,
    updateScheduleHandler,
    editScheduleHandler,
    cancelScheduleHandler,
  } = props;
  let sites = domains;
  const [updateText, setUpdateText] = useState("Edit");
  const [results, setResults] = useState([{}]);
  const [timezone, setTimezone] = useState("");
  const [scantime, setScanTime] = useState("");

  const onHandlerTimeZone = (input) => {
    setTimezone(input.target.value);
  };

  const onHandlerTimeHour = (input) => {
    setScanTime(input.target.value);
  };

  return (
    <div
      id="scanScheduleReportModal"
      className="modal"
      tabIndex="-1"
      role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title f800">Scan Schedule for timezone</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="scrollbar" id="style-1">
              <div className="force-overflow">
                <div className="col-md-12 col-sm-12 col-xs-12 text-left">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Domain</th>
                        <th>Scan Time</th>
                        <th>Scan Time Zone</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {sites.length > 0
                        ? sites.map((scan, index) => {
                            return (
                              <tr key={scan._id}>
                                <td>{scan.primaryDomain}</td>
                                <td>
                                  <div className="form-group">
                                    <select
                                      className="form-control col-sm-12"
                                      onChange={onHandlerTimeHour}
                                      id="timeZoneSelect"
                                      disabled={!scan.isReadyForUpdate}
                                      value={
                                        !scan.isReadyForUpdate
                                          ? scan.pciScanTime
                                          : scantime
                                      }>
                                      <option value="24">12:00 AM</option>
                                      <option value="1">01:00 AM</option>
                                      <option value="2">02:00 AM</option>
                                      <option value="3">03:00 AM</option>
                                      <option value="4">04:00 AM</option>
                                      <option value="5">05:00 AM</option>
                                      <option value="6">06:00 AM</option>
                                      <option value="7">07:00 AM</option>
                                      <option value="8">08:00 AM</option>
                                      <option value="9">09:00 AM</option>
                                      <option value="10">10:00 AM</option>
                                      <option value="11">11:00 AM</option>
                                      <option value="12">12:00 PM</option>
                                      <option value="13">01:00 PM</option>
                                      <option value="14">02:00 PM</option>
                                      <option value="15">03:00 PM</option>
                                      <option value="16">04:00 PM</option>
                                      <option value="17">05:00 PM</option>
                                      <option value="18">06:00 PM</option>
                                      <option value="19">07:00 PM</option>
                                      <option value="20">08:00 PM</option>
                                      <option value="21">09:00 PM</option>
                                      <option value="22">10:00 PM</option>
                                      <option value="23">11:00 PM</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="timeZoneSelect"
                                      onChange={onHandlerTimeZone}
                                      value={
                                        !scan.isReadyForUpdate
                                          ? scan.pciScanTimeZone
                                          : timezone
                                      }
                                      disabled={!scan.isReadyForUpdate}>
                                      <option value="0">
                                        Please Select Timezone
                                      </option>
                                      {config.timezones.map((v, i) => {
                                        return (
                                          <option
                                            key={
                                              i +
                                              scan._id +
                                              scan.pciScanTimeZone +
                                              (Math.random() + 1)
                                                .toString(36)
                                                .substring(7)
                                            }
                                            value={v.timezone}>
                                            {v.timezone}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  {!scan.isReadyForUpdate ? (
                                    <button
                                      onClick={() =>
                                        editScheduleHandler(index, scan._id)
                                      }
                                      className="btn btn-warning">
                                      Edit
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        onClick={() =>
                                          updateScheduleHandler(
                                            index,
                                            scan._id,
                                            scantime,
                                            timezone
                                          )
                                        }
                                        className="btn btn-danger">
                                        Update
                                      </button>
                                      <button
                                        onClick={() =>
                                          cancelScheduleHandler(index, scan._id)
                                        }
                                        className="btn btn-success">
                                        Cancel
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer text-right">
            <button
              id="btnCancelDelayFunctionModal"
              type="button"
              className="btn-tglight2 btn-140-width btn-md"
              data-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default scanScheduleModal;
