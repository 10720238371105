import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { isArray } from "util";
import moment from "moment";
import momentz from "moment-timezone";
import Slide from "../../../animation/slide";
import Modal from "../../../../components/ui/modal/modal";
import FrequencyModal from "../../../../components/ui/modal/frequencyModal";
import Config from "../../../../helpers/config";
import { ToastContainer } from "react-toastify";
import timeZoneData from "../../../../helpers/timeZoneData.json";
import { convert24HourFormat } from "../../../../helpers/timeZone";
import { getStorageItem } from "../../../../helpers/storage";
import {
  callOnUpdateSubDomainById,
  callOnGetBusinessById,
  callOnGetAdditionalScanInformation,
  callOnGetDomainDataUsingBusinessPrimaryDomain,
  callIsHostReachable,
} from "../../../../services";
import {
  resetStatus,
  onClickRemoveTrustSealsSettingsDate,
  updateBusiness,
  updateBusinessReportOption,
  getBusiness,
  addNewDomain,
  getDomainsByBusinessId,
  deleteSubDomain,
  updateDomainPingDetails,
} from "../../../../modules/actions";
import SlidePanelContext from "../../../../components/context/slidePanelContexts";
import ScanTableContent from "./scanTableContent";
import ScanTableData from "./scanTableData";
import _ from "lodash";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class ScanSetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      invalidTimeZone: false,
      subdomains: {},
      isLoading: true,
      info: {
        host: "",
        alias: "",
      },
      validate: {
        valid: false,
        error: "",
      },
      errorMessage: null,
      errorAddDomain: null,
      open: false,
      updated: false,
      userTimeZone: null,
      userScanTime: "24",
      beyondSecurityScanInfo: {
        scanTime: null,
        scanTimeZone: null,
      },
      scanOptions: {
        externalScan: {
          showModal: false,
          hideModal: true,
          confirm: false,
          save: false,
          value: "action",
        },
        scanReport: {
          gdpr: false,
          hippa: false,
          owasp: false,
          pentest: false,
          ping: false,
        },
        scanActive: {
          pci: true,
          malware: true,
          ssl: true,
        },
      },

      malwareScanOptions: {
        malwareFreqScan: {
          showModal: false,
          hideModal: true,
          confirm: false,
          save: false,
          value: "action",
        },
      },
      businessId: null,
      userId: null,
      subDomainId: null,
      pciScanned: false,
      hasCloneUser: true,
      accountType: null,
      notifyType: "",
      notifyMessage: "",
      displayed: false,
      businessData: null,
      additionalScanInfo: null,
      isHostReachable: "",
      viewSubDomains: false,
      isFetchingData: false,
    };

    this.textInput = React.createRef();
    momentz.tz.add(timeZoneData);
  }

  hardRefreshSeal = () => {
    let query = {
      action: "refresh-seals",
      businessId: this.state.businessId,
    };
    this.props.onClickRemoveTrustSealsSettingsDate(query);
  };

  handleScanTime = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };

    beyondSecurityScanInfo["scanTime"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
    this.setState({ userScanTime: input.target.value });
  };

  handleScanTimeZone = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTimeZone"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
    this.setState({ userTimeZone: input.target.value });

    if (input.target.value === 0) {
      this.setState({ invalidTimeZone: true });
    } else {
      this.setState({ invalidTimeZone: false });
    }
  };

  userBusinessData = () => {
    let userData = getStorageItem("userData");
    if (userData) {
      const userString = cryptr.decrypt(userData);
      const user = JSON.parse(userString);
      return user;
    }
  };

  getBusinessIdHandler = async () => {
    if (this.props.index >= 0) {
      const business = this.userBusinessData();
      const businessId = business.businessDetails[this.props.index].businessId;
      return businessId;
    }
  };

  onSetUpdateDomain = async () => {
    this.setState({ updated: true });
    const data = {
      host: this.state.info.host,
      alias: this.state.info.alias,
      enableSecurityScan: this.state.scanOptions.scanActive.pci,
      enableMalware: this.state.scanOptions.scanActive.malware,
      enableSSL: this.state.scanOptions.scanActive.ssl,
    };
    const subDomainId = this.state.subDomainId;
    const info = {
      businessId: this.props.businessId,
    };
    await callOnUpdateSubDomainById(subDomainId, data, info);
    await this.getDomainsHandler(this.props.businessId);
    setTimeout(() => this.setState({ updated: false }), 10000);
  };

  resetValues = () => {
    const scanOptions = { ...this.state.scanOptions };
    scanOptions["scanActive"].pci = true;
    scanOptions["scanActive"].malware = true;
    scanOptions["scanActive"].ssl = true;
    this.setState({ scanOptions });
  };

  savePingDetails = async (host) => {
    let domainQuery = {
      query: {
        host: host,
      },
    };

    const domainData = await callOnGetDomainDataUsingBusinessPrimaryDomain(
      domainQuery
    );

    if (domainData && domainData.data && domainData.data.length > 0) {
      await this.props.updateDomainPingDetails(domainData.data[0]._id, {
        pingDetails: {
          lastPing: null,
          nextPing: null,
          status: "pending",
        },
      });
      const businessInfo = await callOnGetBusinessById(this.props.businessId);
      this.setState({
        businessData: businessInfo,
      });
    }
  };

  switchToggleHandler = async (reportType) => {
    const scanOptions = { ...this.state.scanOptions };
    const business = this.userBusinessData();
    const businessDetails = _.filter(
      business.businessDetails,
      (b) => b.businessData.status !== "canceled"
    );
    const businessId = businessDetails[this.props.index].businessId;

    switch (reportType) {
      case "gdpr":
        scanOptions["scanReport"].gdpr = !this.state.scanOptions.scanReport
          .gdpr;
        break;
      case "pentest":
        scanOptions["scanReport"].pentest = !this.state.scanOptions.scanReport
          .pentest;
        break;
      case "hippa":
        scanOptions["scanReport"].hippa = !this.state.scanOptions.scanReport
          .hippa;
        break;
      case "owasp":
        scanOptions["scanReport"].owasp = !this.state.scanOptions.scanReport
          .owasp;
        break;
      case "pci":
        scanOptions["scanActive"].pci = !this.state.scanOptions.scanActive.pci;
        break;
      case "malware":
        scanOptions["scanActive"].malware = !this.state.scanOptions.scanActive
          .malware;
        break;
      case "ssl":
        scanOptions["scanActive"].ssl = !this.state.scanOptions.scanActive.ssl;
        break;
      case "ping":
        scanOptions["scanReport"].ping = !this.state.scanOptions.scanReport
          .ping;
        break;

      default:
        break;
    }

    await this.props.updateBusinessReportOption({
      _id: businessId,
      availableReports: this.state.scanOptions.scanReport,
    });

    this.setState({ scanOptions }, () => {
      if (reportType === "ping" && this.state.scanOptions.scanReport.ping) {
        this.savePingDetails(this.state.info.host);
      }
    });
  };

  async componentDidMount() {
    // this.textInput.current.focus();
  }

  changeClassNames = () => {
    if (document.getElementById("dashboardSidebar")) {
      document.getElementById("dashboardSidebar").className = "menu-item";
    }

    if (document.getElementById("reportSidebar")) {
      document.getElementById("reportSidebar").className = "menu-item";
    }

    if (document.getElementById("billingSidebar")) {
      document.getElementById("billingSidebar").className = "menu-item";
    }

    if (document.getElementById("accountSidebar")) {
      document.getElementById("accountSidebar").className = "menu-item";
    }

    if (document.getElementById("profileSidebar")) {
      document.getElementById("profileSidebar").className = "menu-item";
    }

    if (document.getElementById("notificationsSidebar")) {
      document.getElementById("notificationsSidebar").className = "menu-item";
    }
  };

  async getAdditionalScanInformation() {
    const scanInfo = await callOnGetAdditionalScanInformation(1);
    this.setState({
      additionalScanInfo: scanInfo,
    });
  }

  isHostReachable = async () => {
    const result = await callIsHostReachable({
      type: "is-host-reachable",
      host: this.state.info.host,
    });

    this.setState({
      isHostReachable:
        result === true
          ? "Domain was successfully reached"
          : "Unfortunately we could not reach this domain",
    });

    setTimeout(() => {
      this.setState({
        isHostReachable: "",
      });
    }, 5000);
  };

  render() {
    const {
      scanOptions,
      userTimeZone,
      beyondSecurityScanInfo,
      userScanTime,
      invalidTimeZone,
      malwareScanOptions,
      subdomains,
    } = this.state;
    const {
      businessDetails,
      goToPageCreateSaq,
      business,
      open,
      index,
    } = this.props;

    let subDomains = null;
    let alert = null;

    const context = this.context.scanOptions[index];

    // if (this.state.open) {
    //   subdomains = <Spinner />;
    // }

    let modalMalwareFrequency = null;

    if (malwareScanOptions.malwareFreqScan.showModal) {
      modalMalwareFrequency = (
        <FrequencyModal
          show={malwareScanOptions.malwareFreqScan.showModal}
          close={this.hideMalwareFrequencyModalHandler}
          save={this.updateMalwareFrequencyHandler}
          onSave={malwareScanOptions.malwareFreqScan.save}
          title="Scan Options"
          message={Config.messages.changeFrequency}
        />
      );
    }

    if (context !== undefined) {
      if (context.isOptionActive) {
        return (
          <>
            {modalMalwareFrequency}
            <div
              id="setscan-details"
              className="adv-setting"
              style={{ display: "block" }}>
              {/* START NOTIFY TOAST MESSAGE */}
              <ToastContainer />
              {/* END NOTIFY TOAST MESSAGE */}
              {/* START SCAN TABLE CONTENT */}
              <ScanTableContent>
                <ScanTableData
                  index={index}
                  showMalwareFrequencyModalHandler={
                    this.showMalwareFrequencyModalHandler
                  }
                />
              </ScanTableContent>
              {/* END SCAN TABLE CONTENT */}
            </div>
          </>
        );
      }
    }

    return null;
  }
}

ScanSetDetails.contextType = SlidePanelContext.drawerContext;

const mapStateToProps = (state) => {
  return {
    domains: state.domainStore.domains,
    business: state.businessStore.businessInfo,
    message: state.accountStore.message,
    messageType: state.accountStore.messageType,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPageInsurance: () => push("/insurance"),
      goToPageCreateSaq: (businessToggledId) =>
        push("/create-saq", businessToggledId),
      addNewDomain,
      getDomainsByBusinessId,
      updateBusiness,
      updateBusinessReportOption,
      getBusiness,
      deleteSubDomain,
      onClickRemoveTrustSealsSettingsDate,
      resetStatus,
      updateDomainPingDetails,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanSetDetails);
