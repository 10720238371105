import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";

import { getUserDecryptedData } from "../../helpers/general";
import { callSendPenetrationTest } from "../../services";

class RequestPenetrationTest extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isAdmin: false,
      name: "",
      email: "",
      phoneNumber: "",
      website: "",
      bestContactTime: "",
      comments: "",
      submitted: false,
    };
  }

  componentDidMount() {
    let userLsData = getUserDecryptedData();

    if (userLsData) {
      this.setState({
        isAdmin: userLsData.isAdmin,
      });
    }
  }

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    this.setState({
      [name]: value,
    });
  }

  async submitToAPI() {
    if (this.state.name.trim().length === 0) {
      toast.error("Name is required");
      return;
    }
    if (this.state.email.trim().length === 0) {
      toast.error("Email is required");
      return;
    }
    if (this.state.phoneNumber.trim().length === 0) {
      toast.error("Phone Number is required");
      return;
    }
    if (this.state.website.trim().length === 0) {
      toast.error("Website is required");
      return;
    }
    if (this.state.bestContactTime.trim().length === 0) {
      toast.error("Best Contact Time is required");
      return;
    }
    if (this.state.comments.trim().length === 0) {
      toast.error("Comments is required");
      return;
    }

    let data = {
      action: "send_request_penetration_test_email",
      name: this.state.name,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      website: this.state.website,
      bestContactTime: this.state.bestContactTime,
      comments: this.state.comments,
    };

    await callSendPenetrationTest(data);

    this.setState({
      name: "",
      email: "",
      phoneNumber: "",
      website: "",
      bestContactTime: "",
      comments: "",
      submitted: true,
    });
  }

  render() {
    const screenHeight = window.innerHeight;

    return (
      <>
        <Header titlePage="Request Penetration Test" bgColor="#F5F6F8" />

        <div id="mcontent-wrapper" style={!0 ? { height: screenHeight } : {}}>
          <div id="control-panel" className="">
            <ToastContainer position="top-right" />
            {!this.state.submitted ? (
              <>
                <div class="alert alert-info" role="alert">
                  <strong>
                    We can also scan your internal network although this process
                    requires us having access to the network. If this is
                    something you are interested in please fill out the form
                    below and we will get back to you as soon as possible.
                  </strong>
                </div>
                <br />

                <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  no-pad">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <label>Name:</label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <input
                        autoFocus
                        type="text"
                        className="form-control"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <label>Email:</label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <label>Phone Number:</label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <label>Website:</label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <input
                        type="text"
                        className="form-control"
                        name="website"
                        value={this.state.website}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <label>Best Contact Time:</label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <input
                        type="text"
                        className="form-control"
                        name="bestContactTime"
                        value={this.state.bestContactTime}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <label>Comments / Questions</label>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <textarea
                        type="text"
                        className="form-control"
                        name="comments"
                        value={this.state.comments}
                        onChange={this.onChangeHandelUserInput.bind(this)}
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" />
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-3">
                      <div className="text-right">
                        <button
                          data-toggle="modal"
                          data-target="#email-ver"
                          className="btn-success btn-md btn"
                          onClick={() => {
                            this.submitToAPI();
                          }}
                          data-testid="btnUserProfileUpdateBrowser"
                        >
                          <i className="fas fa-edit" />&nbsp; Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="alert alert-success" role="alert">
                  <strong>
                    Thank you, your request has been sent and we will get back
                    to you shortly.
                  </strong>
                </div>
                <br />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestPenetrationTest);
