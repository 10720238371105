import { api } from "./api";
import { callApi } from "../helpers/index";
import moment from "moment";

export const callApiGetBusinessSubdomainData = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("domains").find({ query: { businessId: businessId } });
    } else {
      return null;
    }
  });
};

export const callApiCreateBusinessSubdomain = async (domain) => {
  return callApi().then((token) => {
    if (domain) {
      return api.service("api/domains").create(domain);
    }
  });
};

export const callApiGetDomainInfoUsingBusinessId = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("domains").find({ query: { businessId: businessId } });
    }
  });
};

export const callApiGetPrimaryDomain = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("domains").find({
        query: { businessId: businessId, isSubDomain: { $exists: false } },
      });
    }
  });
};

export const callApiPatchDomain = async (businessId, data) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("api/domains").patch(businessId, data);
    }
  });
};

export const callApiDeleteDomain = async (domainId, info) => {
  return callApi().then((token) => {
    return api.service("api/domains").remove(domainId, {
      query: {
        info,
        businessId: info.businessId,
        purpose: "remove_domain",
      },
    });
  });
};

export const callApiUpdateDomainData = (_id) => {
  return callApi().then(async (token) => {
    const domainData = await api.service("domains").get({ _id });
    if (domainData) {
      domainData.sslInfo.nextCheckDate = moment(new Date()).add(-1, "days");
      const sslData = {
        sslInfo: domainData.sslInfo,
      };

      const result = await api.service("domains").patch(_id, sslData);
      return result;
    }

    return null;
  });
};
