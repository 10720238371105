import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { viewUserDashboard } from "../../helpers/general";
import { convertTimeTo } from "../../helpers/timeZone";

import { callApiGetBusinessesSealUrls } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";
import { BUSINESS_USERS_SUCCESS } from "../../modules/types";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class SealsUrlReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      isLoading: true,
      display: "none",
      businesses: [],
      sealReportType: "",
    };
  }

  getSealReports = (display) => {
    this.setState(
      {
        display: display === "block" ? "none" : "block",
      },
      async () => {
        if (display === "none") {
          const tempBusinesses = await callApiGetBusinessesSealUrls();

          if (
            tempBusinesses &&
            tempBusinesses.success &&
            tempBusinesses.data &&
            tempBusinesses.data.length > 0
          ) {
            this.setState({
              isLoading: false,
              businesses: tempBusinesses.data,
            });
          }
        }
      }
    );
  };

  render() {
    const { isLoading, display, businesses } = this.state;

    return (
      <>
        <div className="reportButton">
          <button
            className="btn-success btn-sm"
            style={{ width: "175px !impotant" }}
            onClick={() => {
              this.getSealReports(display);
            }}
          >
            Seals URL
          </button>
        </div>
        <p>All business seals URL List.</p>

        <hr />

        <div className="table-responsive" style={{ display: display }}>
          <table className="table table-striped">
            <thead>
              <tr className="row">
                <th className="col-md-3">Company Name</th>
                <th className="col-md-9">Seals</th>
              </tr>
            </thead>
            <tbody>
              {businesses && businesses.length > 0 ? (
                businesses.map((business, businessIndex) => {
                  return (
                    <tr className="row" key={businessIndex}>
                      <td className="col-md-3">
                        <button
                          className="tg-link"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => viewUserDashboard(business._id)}
                        >
                          {business.primaryDomain}
                        </button>
                      </td>
                      <td className="col-md-9">
                        {business.seals && business.seals.length > 0
                          ? business.seals.map((sv, si) => {
                              return (
                                <p>
                                  <a href={sv.sealUrl} target="_blank">
                                    {sv.sealUrl}
                                  </a>
                                </p>
                              );
                            })
                          : ""}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="2">
                    {isLoading === true ? "Loading ..." : "No records."}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

SealsUrlReport.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealsUrlReport);
