import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../charts/line";

import AppContext from "../../components/context/appContext";
import BuyerProtectionForm from "./buyerProtectionForm";

import {
  callApiGetBuyerProtectionOrdersData,
  callApiGetBuyerProtectionRegistrationsData,
  callApiGetBusinessData,
} from "../../services";
import ContentLoader from "react-content-loader";

class BpStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      businessId: null,
      currentBusiness4xData: {},
      ordersData: [0, 0, 0, 0, 0, 0, 0],
      registeredData: [0, 0, 0, 0, 0, 0, 0],
      last30DaysOrders: 0,
      last30DaysRegistered: 0,
      statisticsActive: false,
    };
  }

  async componentDidMount() {
    this.setState(
      {
        businessId: this.props.businessId,
      },
      async () => {
        let tempBusiness = await callApiGetBusinessData(this.state.businessId);
        await this.get4xAccountData(tempBusiness);
      }
    );
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;

    if (prevState.businessId !== currentBusinessId) {
      this.setState(
        {
          isLoading: true,
          businessId: currentBusinessId,
        },
        async () => {
          let tempBusiness = await callApiGetBusinessData(
            this.state.businessId
          );
          await this.get4xAccountData(tempBusiness);
        }
      );
    }
  }

  async get4xAccountData(currentBusinessData) {
    if (currentBusinessData && currentBusinessData.details4X) {
      let ordersDataCoordinate = [],
        registeredDataCoordinate = [];

      Promise.all([
        Promise.all([
          await callApiGetBuyerProtectionOrdersData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(6, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(6, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionOrdersData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(5, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(5, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionOrdersData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(4, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(4, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionOrdersData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(3, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(3, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionOrdersData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(2, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(2, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionOrdersData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(1, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(1, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionOrdersData({
            businessId: this.state.businessId,
            startDate: moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment()
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
        ]).then((result) => {
          // console.log("result", result);
          result.map((v) => {
            let tempCount = v.success === true ? v.data.count.myCount : 0;
            ordersDataCoordinate.push(tempCount);
            return null;
          });

          return null;
        }),
        Promise.all([
          await callApiGetBuyerProtectionRegistrationsData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(6, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(6, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionRegistrationsData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(5, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(5, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionRegistrationsData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(4, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(4, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionRegistrationsData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(3, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(3, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionRegistrationsData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(2, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(2, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionRegistrationsData({
            businessId: this.state.businessId,
            startDate: moment(
              moment()
                .subtract(1, "months")
                .format("YYYY-MM-DD")
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment(
              moment()
                .subtract(1, "months")
                .format("YYYY-MM-DD")
            )
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
          await callApiGetBuyerProtectionRegistrationsData({
            businessId: this.state.businessId,
            startDate: moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: moment()
              .endOf("month")
              .format("YYYY-MM-DD"),
          }),
        ]).then((result) => {
          result.map((v) => {
            let tempCount = v.success === true ? v.data.count.myCount : 0;
            registeredDataCoordinate.push(tempCount);
            return null;
          });

          return null;
        }),

        await callApiGetBuyerProtectionOrdersData({
          businessId: this.state.businessId,
          startDate: moment(new Date())
            .add(-30, "day")
            .format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        }),
        await callApiGetBuyerProtectionRegistrationsData({
          businessId: this.state.businessId,
          startDate: moment(new Date())
            .add(-30, "day")
            .format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        }),
      ]).then((result) => {
        this.setState({
          isLoading: false,
          currentBusiness4xData:
            currentBusinessData.details4X &&
            currentBusinessData.details4X.merchant
              ? currentBusinessData.details4X.merchant
              : "",
          ordersData: ordersDataCoordinate,
          registeredData: registeredDataCoordinate,
          last30DaysOrders: result[2].data.count.myCount,
          last30DaysRegistered: result[3].data.count.myCount,
        });
      });
    }
  }

  render() {
    const {
      isLoading,
      statisticsActive,
      ordersData,
      registeredData,
      last30DaysOrders,
      last30DaysRegistered,
    } = this.state;

    // console.log("B P S", this.state);

    const xLabels = [
      moment(
        moment()
          .subtract(6, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(5, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(4, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(3, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment(
        moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD")
      ).format("MMMM"),
      moment().format("MMMM"),
    ];

    const yData = [
      {
        label: "Orders",
        data: ordersData,
      },
      {
        label: "Registered",
        data: registeredData,
      },
    ];

    return (
      <div>
        {isLoading === true ? (
          <ContentLoader
            height={550}
            width={989}
            speed={1}
            primaryColor="#f3f3f3"
            secondaryColor="#e1dfdf"
          >
            <rect x="10" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="130" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="250" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="370" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="490" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="610" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="730" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="850" y="22" rx="0" ry="0" width="105" height="350" />
            <rect x="970" y="22" rx="0" ry="0" width="105" height="350" />
          </ContentLoader>
        ) : (
          <>
            <div>
              <h4>Last 30 days</h4>
              <h4>Orders: {last30DaysOrders}</h4>
              <h4>Registered: {last30DaysRegistered}</h4>
            </div>
            <hr />
            <h4>
              Statistics{" "}
              <button className="tg-dashboard-link">
                {statisticsActive ? (
                  <i className="fa fa-angle-up" />
                ) : (
                  <i className="fa fa-angle-down" />
                )}
              </button>
            </h4>
            <>
              <Line
                xLabels={xLabels}
                yData={yData}
                changeColor={[null, "222,214,162"]}
                changeColorByIndex={[1]}
              />
            </>
          </>
        )}
      </div>
    );
  }
}

BpStatistics.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BpStatistics);
