import { callApi } from '../helpers';
import { api } from './api';

function callGetPrivacyPolicyByUserId(userId) {
  const query = {
    query: {
      purpose: 'get_privacy_policy',
      userId: userId
    }
  };
  return callApi().then(token => {
    return api.service('api/privacy-policy').find(query);
  });
}

function callCreatePrivacyPolicy(data) {
  return callApi().then(token => {
    return api.service('api/privacy-policy').create(data);
  });
}

function callUpdatePrivacyPolicy(data) {
  return callApi().then(token => {
    return api.service('api/privacy-policy').create(data);
  });
}

export { callGetPrivacyPolicyByUserId, callCreatePrivacyPolicy, callUpdatePrivacyPolicy };
