import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { createEmailData, updateEmailData } from "../../modules/actions";

class EmailTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      from: "support@trust-guard.com",
      emailTag: "",
      emailName: "",
      subject: "",
      body: "",
      type: "",
      emailId: "",
      edit: false,
      userId: "",
      sendSms: false,
      smsBody: "",
      usersList: [],
      selectedUserId: "",
      selectedUsersBusinesses: [],
      selectedUsersBusinessesId: "",
      sendUserNotification: false,
      userNotificationDetails: "",
      userNotificationRegarding: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.emailData !== this.props.emailData) {
      let { emailData } = this.props;

      this.setState({
        edit: true,
        emailId: emailData._id,
        emailTag: emailData.emailTag,
        emailName: emailData.name,
        from: emailData.from,
        subject: emailData.subject,
        body: emailData.body,
        sendSms: emailData.sendSms,
        smsBody: emailData.smsBody,
        type: emailData.type ? emailData.type : "",
        sendUserNotification: emailData.sendUserNotification,
        userNotificationDetails: emailData.userNotificationDetails
          ? emailData.userNotificationDetails
          : "",
        userNotificationRegarding: emailData.userNotificationRegarding
          ? emailData.userNotificationRegarding
          : "",
      });
    }
  }

  onChangeHandelEmailInput(evt) {
    let name = evt.target.name;
    let value = evt.target.value;

    this.setState({
      [name]: value,
    });
  }

  onClickSaveEmail = () => {
    const emailData = {
      emailTag: this.state.emailTag,
      name: this.state.emailName,
      from: this.state.from,
      subject: this.state.subject,
      body: this.state.body,
      sendSms: this.state.sendSms,
      smsBody: this.state.smsBody,
      type: this.state.type,
      status: "active",
      sendUserNotification: this.state.sendUserNotification,
      userNotificationDetails: this.state.userNotificationDetails,
      userNotificationRegarding: this.state.userNotificationRegarding,
    };

    if (this.state.edit) {
      this.props.updateEmailData(this.state.emailId, emailData);
    } else {
      this.props.createEmailData(emailData);
    }

    this.resetAll();

    this.props.reFetchEmails();
  };

  resetAll = () => {
    this.setState({
      emailTag: "",
      emailName: "",
      subject: "",
      body: "",
      type: "",
      emailId: "",
      edit: false,
      userId: "",
      sendSms: false,
      smsBody: "",
      usersList: [],
      selectedUserId: "",
      selectedUsersBusinesses: [],
      selectedUsersBusinessesId: "",
      sendUserNotification: false,
      userNotificationDetails: "",
      userNotificationRegarding: "",
    });
  };

  render() {
    const {
      emailTag,
      emailName,
      subject,
      type,
      body,
      sendSms,
      sendUserNotification,
      edit,
      smsBody,
      userNotificationDetails,
      userNotificationRegarding,
    } = this.state;

    return (
      <>
        <h4 className="mb-1">Create Email</h4>
        <h5 className="">Email Info</h5>

        <div>
          <div className="Rtable Rtable--6cols Rtable--collapse">
            <div className="Rtable-cell p-sm pl-0">
              <span className="Rparent-label-mobile">Email Tag</span>
              <br />
              <input
                type="text"
                className="form-control p-sm"
                placeholder="Enter email tag"
                name="emailTag"
                value={emailTag}
                onChange={this.onChangeHandelEmailInput.bind(this)}
              />
            </div>
            <div className="Rtable-cell p-sm">
              <span className="Rparent-label-mobile">Email Name</span>
              <br />
              <input
                type="text"
                className="form-control"
                placeholder="Enter an email name"
                name="emailName"
                value={emailName}
                onChange={this.onChangeHandelEmailInput.bind(this)}
              />
            </div>

            <div className="Rtable-cell p-sm">
              <span className="Rparent-label-mobile">Email Subject</span>
              <br />
              <input
                type="text"
                className="form-control"
                placeholder="Subject for email"
                value={subject}
                name="subject"
                onChange={this.onChangeHandelEmailInput.bind(this)}
              />
            </div>

            <div className="Rtable-cell p-sm">
              <span className="Rparent-label-mobile">Email Type</span>
              <br />
              <select
                className="form-control"
                value={type}
                name="type"
                onChange={this.onChangeHandelEmailInput.bind(this)}
              >
                <option select="false">Select email type</option>
                <option value="pci-scan">PCI</option>
                <option value="malware-scan">Malware</option>
                <option value="ssl-scan">SSL</option>
                <option value="tg-messages">System</option>
                <option value="other-messages">Others</option>
              </select>
            </div>
          </div>
          <div>
            <span>Email Body</span>
            <br />
            <textarea
              name="body"
              rows="12"
              placeholder="Enter email body"
              value={body}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{
              marginTop: "15px",
            }}
          >
            <input
              type="checkbox"
              value={sendSms}
              checked={sendSms}
              onChange={() => {
                this.setState({
                  sendSms: !sendSms,
                });
              }}
              style={{ cursor: "pointer" }}
            />{" "}
            <label
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  sendSms: !sendSms,
                });
              }}
            >
              Send SMS
            </label>
          </div>

          <div
            style={{
              marginTop: "15px",
              display: sendSms ? "block" : "none",
            }}
          >
            <span>SMS Body</span>
            <br />
            <textarea
              name="smsBody"
              rows="12"
              placeholder="Enter SMS body"
              value={smsBody}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
          </div>

          <div
            style={{
              marginTop: "15px",
            }}
          >
            <input
              type="checkbox"
              value={sendUserNotification}
              checked={sendUserNotification}
              onChange={() => {
                this.setState({
                  sendUserNotification: !sendUserNotification,
                });
              }}
              style={{ cursor: "pointer" }}
            />{" "}
            <label
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  sendUserNotification: !sendUserNotification,
                });
              }}
            >
              Send User Notification
            </label>
          </div>

          <div
            style={{
              marginTop: "15px",
              display: sendUserNotification ? "block" : "none",
            }}
          >
            <span>User Notification Details</span>
            <br />
            <input
              name="userNotificationDetails"
              value={userNotificationDetails}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <span>User Notification Regarding</span>
            <br />
            <textarea
              name="userNotificationRegarding"
              rows="12"
              placeholder="Enter User Notification regarding"
              value={userNotificationRegarding}
              onChange={this.onChangeHandelEmailInput.bind(this)}
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ display: "block", width: "100%", marginTop: "25px" }}>
            {0 && edit ? (
              <button
                className="btn-danger btn-md btn-140-width"
                data-target="#divSendTestEmailModal"
                data-toggle="modal"
              >
                Send Test Email
              </button>
            ) : (
              ""
            )}

            <button
              className="btn-success btn-md btn-140-width pull-right"
              onClick={() => this.onClickSaveEmail()}
            >
              {edit ? "Update" : "Create Email"}
            </button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createEmailData, updateEmailData }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplateForm);
