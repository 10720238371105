import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import publicIp from "public-ip";
import Aux from "../../components/hoc/containerAux";
import { ToastContainer } from "react-toastify";
import { images } from "../../helpers/images";
import { onClickUpdatePassword } from "../../modules/actions/index";

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: this.props.match.params.code,
      password: "",
      confirmPassword: "",
      newSignUp: false,
      disableButton: false,
    };

    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
    this.onTextKeyPress = this.onTextKeyPress.bind(this);
  }

  onChangeHandelUserInput(evt) {
    const name = evt.target.name;
    const value = evt.target.value;
    this.setState({
      [name]: value,
    });
  }

  onClickUpdatePassword = async () => {
    this.setState({ disableButton: true });

    const clientIp = await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });

    this.props.onClickUpdatePassword({
      code: this.state.code,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      newSignup: this.state.newSignUp,
      clientIp: clientIp,
    });

    setTimeout(() => this.setState({ disableButton: false }), 2500);
  };

  onTextKeyPress(evt) {
    if (evt.key === "Enter") {
      this.onClickUpdatePassword(evt);
    }
  }

  componentWillReceiveProps(newProps) {
    setTimeout(() => {
      window.location.href = "/login";
    }, 2500);
  }

  componentDidMount() {
    if (this.props.location.search === "?signup=new") {
      this.setState({ newSignUp: true });
    }
  }

  render() {
    const { password, confirmPassword, newSignUp, disableButton } = this.state;

    return (
      <Aux>
        <div
          id="login-bg"
          style={{
            backgroundImage: "url(" + images.loginBGFinal + ")",
          }}
        />
        <div id="login-content">
          <div id="login-panel-wrapper">
            <div id="login-panel" className="col-md-12 text-center">
              <ToastContainer />
              <img
                id="login-logo"
                src={images.tgRegularDark}
                alt="Login logo"
              />
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.onChangeHandelUserInput}
                  onKeyPress={this.onTextKeyPress}
                  required
                  data-testid="inpUpdatePassword"
                />
                <input
                  type="password"
                  placeholder=" Re-Type New Password"
                  className="form-control"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.onChangeHandelUserInput}
                  onKeyPress={this.onTextKeyPress}
                  required
                  data-testid="inpConfirmUpdatePassword"
                />
                <br />
                <br />
                <button
                  className="btn"
                  id="login-btn"
                  onClick={() => this.onClickUpdatePassword()}
                  data-testid="btnResetPassword"
                  disabled={disableButton}
                >
                  {newSignUp === true ? "Set" : "Reset"} Password
                </button>
                <div
                  id="login-copy"
                  className="col-md-12 col-sm-12 col-xs-12 no-pad text-center"
                >
                  <p>
                    Privacy Policy<br /> &copy; 2018 Trust-Guard. All Rights
                    Reserved
                  </p>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6  no-pad text-right">
                  <p
                    onClick={() => {
                      this.props.history.push("/login");
                    }}
                    className="login-links"
                  >
                    Login
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    res: state.authStore.res,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onClickUpdatePassword,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePassword);
