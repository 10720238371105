import React from "react";
import ControlButtons from "../controlButtons";
import moment from "moment";
import _ from "lodash";
import { convertTimeTo } from "../../../helpers/timeZone";
function CanceledRunningScans({
  index,
  isLoadingCanceledScans,
  canceledScanActive,
  canceledScans,
  getCanceledScans,
  goToDashboard,
  deactivateScan,
}) {
  return (
    <>
      <div className="reportButton">
        <button
          disabled={isLoadingCanceledScans}
          className="btn-success btn-sm"
          onClick={() => getCanceledScans()}>
          {isLoadingCanceledScans ? "Loading..." : "CANCELEDSCANS"}
        </button>
        <div className="clearfix" />
      </div>
      <p>
        Scans that were canceled but still running on Beyond Security Control
        Panel
      </p>
      <hr />
      {canceledScanActive &&
      !isLoadingCanceledScans &&
      canceledScans.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th />
                <th>Device (ip/url)</th>
                <th>Network ID</th>
                <th>Last Complete Scan</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {canceledScans.map((scan, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <span
                      className="a-pointer"
                      onClick={() => {
                        goToDashboard(scan.businessId);
                      }}>
                      {scan.primaryDomain}
                    </span>
                  </td>
                  <td>{_.upperFirst(scan.networkId)}</td>
                  <td>
                    {convertTimeTo(scan.lastPciScanDate, "America/Denver")}{" "}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        deactivateScan(scan.networkId, scan.primaryDomain);
                      }}
                      class="btn-success"
                      data-toggle="modal"
                      data-target="#falsePositivesPci">
                      <i class="fa fa-plug" aria-hidden="true" /> Deactivate{" "}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default CanceledRunningScans;
