import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { convertTimeTo } from "../../../../helpers/timeZone";

import {
  callApiGetBusinessData,
  callApiGetBusinessSslData,
} from "../../../../services";
import RescanSslModal from "../../../../views/modals/rescanSslModal";

class SslSlidePanelData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: "",
      sslStatus: "pending",
      hostData: [],
      currentDomainId: null,
      enableSslAutoRenew: false,
    };

    this.getSslDetails = this.getSslDetails.bind(this);
  }

  async componentDidMount() {
    await this.getSslDetails(this.props.businessId);
  }

  async getSslDetails(businessId) {
    const businessData = await callApiGetBusinessData(businessId);
    const businessSslData = await callApiGetBusinessSslData({
      businessId: businessId,
    });

    if (businessData && businessSslData) {
      this.setState({
        accountType: businessData.accountType,
        sslStatus: businessSslData.data.businessSslStatus,
        hostData: businessSslData.data.host,
        enableSslAutoRenew:
          (businessData.enableSslAutoRenew &&
            businessData.enableSslAutoRenew) ||
          false,
      });
    }
  }

  render() {
    const {
      accountType,
      sslStatus,
      hostData,
      currentDomainId,
      enableSslAutoRenew,
    } = this.state;
    return (
      <>
        <ToastContainer />
        <div
          className="table-responsive"
          style={{
            border: "4px solid rgb(239, 239, 239)",
            borderCollapse: "collapse",
            borderRadius: "5px",
          }}
        >
          <table className="table">
            <thead className="thead-dark" style={{ fontSize: "14px" }}>
              <tr>
                <th
                  className="text-center"
                  width="18%"
                  style={{ padding: "20px 15px" }}
                >
                  Domain/Subdomains/IPs:
                </th>
                <th
                  className="text-center"
                  width="16%"
                  style={{ padding: "20px 15px" }}
                >
                  Last SSL Validation
                </th>
                <th
                  className="text-center"
                  width="16%"
                  style={{ padding: "20px 15px" }}
                >
                  Frequency
                </th>
                <th
                  className="text-center"
                  width="14%"
                  style={{ padding: "20px 15px" }}
                >
                  SSL Provider
                </th>
                <th
                  className="text-center"
                  width="16%"
                  style={{ padding: "20px 15px" }}
                >
                  Expiration
                </th>
                <th className="text-center" style={{ padding: "20px 15px" }}>
                  Status
                </th>
                <th className="text-center" style={{ padding: "20px 15px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            {hostData && hostData.length > 0 ? (
              hostData.map((v, i) => {
                return (
                  ((v.hasOwnProperty("enableSSL") && v.enableSSL) ||
                    !v.hasOwnProperty("enableSSL")) && (
                    <tbody key={i}>
                      <tr>
                        <td className="text-center">
                          {v.host !== "" ? v.host : "N/A"}
                        </td>
                        <td className="text-center">
                          {v.sslInfo.hasOwnProperty("lastCheckDate") &&
                          v.sslInfo.lastCheckDate !== "" &&
                          v.sslInfo.lastCheckDate !== undefined
                            ? convertTimeTo(
                                v.sslInfo.lastCheckDate,
                                this.state.timezone,
                                "MM/DD/YYYY hh:mm a"
                              )
                            : "N/A"}
                        </td>
                        <td className="text-center">
                          {v.sslInfo &&
                          v.sslInfo.hasOwnProperty("checkFrequency")
                            ? v.sslInfo.checkFrequency !== ""
                              ? v.sslInfo.checkFrequency
                                  .charAt(0)
                                  .toUpperCase() +
                                v.sslInfo.checkFrequency.slice(1)
                              : "N/A"
                            : "N/A"}
                        </td>
                        <td className="text-center">
                          {v.sslInfo.hasOwnProperty("sslProvider") &&
                          v.sslInfo.sslProvider !== ""
                            ? v.sslInfo.sslProvider
                            : "N/A"}
                        </td>
                        <td className="text-center">
                          {v.sslInfo.hasOwnProperty("expireDate") &&
                          v.sslInfo.expireDate &&
                          v.sslInfo.expireDate !== undefined
                            ? convertTimeTo(
                                v.sslInfo.expireDate,
                                this.state.timezone,
                                "MM/DD/YYYY hh:mm a"
                              )
                            : "N/A"}
                        </td>
                        <td className="text-center">
                          <span>
                            {v.sslInfo.hasOwnProperty("nextCheckDate") &&
                            v.sslInfo.nextCheckDate &&
                            moment(v.sslInfo.nextCheckDate).diff(moment()) <=
                              0 ? (
                              "Verifying"
                            ) : v.sslInfo.status !== "" ? (
                              v.sslInfo.status === "failed" &&
                              v.sslInfo.reason !== "" ? (
                                <span>
                                  {v.sslInfo.status.charAt(0).toUpperCase() +
                                    v.sslInfo.status.slice(1)}
                                  <span className="account-title1">
                                    &nbsp;
                                    <span className="roleTooltipV3">
                                      <b
                                        className="fas fa-info-circle text-danger"
                                        style={{ cursor: "help" }}
                                      />
                                      <span
                                        className="roleTooltipTextV3"
                                        style={{
                                          width: "250px",
                                          bottom: "35px",
                                          left: "0px",
                                          padding: "7px",
                                        }}
                                      >
                                        <div style={{ fontSize: "11px" }}>
                                          {v && v.sslInfo && v.sslInfo.reason
                                            ? v.sslInfo.reason
                                            : ""}
                                        </div>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              ) : v && v.sslInfo && v.sslInfo.status ? (
                                v.sslInfo.status.charAt(0).toUpperCase() +
                                v.sslInfo.status.slice(1)
                              ) : (
                                ""
                              )
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </td>
                        <td
                          className="text-center"
                          style={{
                            cursor: "pointer",
                            whiteSpace: "noWrap",
                          }}
                        >
                          {v.sslInfo.hasOwnProperty("nextCheckDate") &&
                          moment(v.sslInfo.nextCheckDate).diff(moment()) <=
                            0 ? (
                            ""
                          ) : accountType === "free" ? (
                            <button
                              onClick={() => {
                                window.open("https://trustguard.com", "_blank");
                              }}
                              className="btn btn-success btn-sm"
                            >
                              <i className="fas fa-search" />&nbsp; Rescan SSL
                              Certificate
                            </button>
                          ) : (
                            <span>
                              {accountType !== "free" ? (
                                <>
                                  {/*                                   {(accountType !== "security" ||
                                    enableSslAutoRenew === true) && (
                                    <button
                                      className="btn btn-success btn-sm"
                                      onClick={() => {
                                        this.props.goToPageGetAutoRenewalSSL(
                                          v.domainId
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#ffc107",
                                        color: "#333333",
                                        borderColor: "#ffc107",
                                        marginRight: "15px",
                                      }}
                                    >
                                      <i className="fas fa-plus" />
                                    </button>
                                  )} */}

                                  <button
                                    data-toggle="modal"
                                    data-target="#rescanSslConfirmationModal"
                                    className="btn btn-success btn-sm"
                                    data-testid="btnDashboardRescanSsl"
                                    onClick={() =>
                                      this.setState({
                                        currentDomainId: v.domainId,
                                      })
                                    }
                                  >
                                    <i className="fas fa-search" />&nbsp; Rescan
                                    SSL Certificate
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          )}
                        </td>

                        {/* {v.sslInfo.hasOwnProperty("status") &&
                  moment(v.sslInfo.nextCheckDate).diff(moment()) <= 0 ? null : v
                    .sslInfo.status !== "" && v.sslInfo.status !== "active" ? (
                    <td style={{ textAlign: "center" }}>
                      <button
                        target="_blank"
                        onClick={() => {
                          this.props.goToPageGetAutoRenewalSSL(v._id);
                        }}
                        className="btn btn-success btn-sm"
                        style={{
                          backgroundColor: "#ffc107",
                          color: "#333333",
                          borderColor: "#ffc107",
                        }}
                      >
                        <i className="fas fa-sync" />&nbsp; Get sslInfo
                        Auto-Renewing SSL{" "}
                      </button>
                    </td>
                  ) : (
                    <td />
                  )} */}
                      </tr>
                    </tbody>
                  )
                );
              })
            ) : (
              <tbody>
                <tr>
                  <td className="text-center" colSpan="7">
                    No domain available for this business.
                  </td>
                </tr>
              </tbody>
            )}
            <tr>
              <th colSpan={7} />
            </tr>
          </table>
        </div>
        <RescanSslModal
          businessId={this.props.businessId}
          domainId={this.state.currentDomainId}
          getSslDetails={this.getSslDetails}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SslSlidePanelData);
