import { api } from "./api";
import { callApi } from "../helpers/index";

const callOnGetBusinessReport = (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        type: param,
      },
    };
    return await api.service("reporting").find(query);
  });
};

const callOnGetMissingScanReport = (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "missing_scans",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnGetReportCancelledSites = (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        type: param,
      },
    };
    return await api.service("beyondsecurity").get(null, query);
  });
};

const callOnGetResultMissingScans = async (param) => {
  let domainID = null;

  const query = {
    query: {
      type: "missing",
      businessID: param.businessID,
      domainID: param.domainID === null ? domainID : param.domainID,
      daysMissed: param.daysMissed,
    },
  };
  return await api.service("api/beyondsecurity").get(null, query);
};

const callOnGetFailedScanReport = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "failed_scans",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnGetFalsePositiveScanReport = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "false_positive_scans",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnGetChangePort = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "change_ports",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnUpdatePort = async (data) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "change_ports",
      },
    };
    return await api.service("api/reporting").patch(data.businessId, data);
  });
};

const callOnGetRunningScanReport = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "running_scans",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnGetCanceledScanReport = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "canceled_running_scans",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnGetScanScheduleReport = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "scan_schedules",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnGetSSLScanDates = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "ssl_dates",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnRefreshScanDates = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "refresh_seals",
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnDeactivateScan = async (networkId, primaryDomain) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        purpose: "deactivate_scan",
        data: { networkId: networkId, primaryDomain: primaryDomain },
      },
    };
    return await api.service("api/reporting").find(query);
  });
};

const callOnGetNonExistentScans = async (param) => {
  return callApi().then(async (response) => {
    const query = {
      query: {
        type: "nonexistent",
      },
    };
    return await api.service("beyondsecurity").get(null, query);
  });
};

const callOnGetNoSealGeneratedCode = async () => {
  return callApi().then(async (response) => {
    return await api.service("business").find({
      query: {
        sealCodeGenerated: false,
        status: { $ne: "canceled" },
        lastMalwareScan: { $ne: null },
        lastPciScanDate: { $ne: null },
      },
    });
  });
};

const callOnUpdateBSTestScan = async (id, label) => {
  return callApi().then(async (response) => {
    return await api.service("beyondsecurityscans").patch(id, {
      label: label,
      updatedAt: Date.now(),
    });
  });
};

const callOnMarkDeleteBSTestScan = async (id) => {
  return callApi().then(async (response) => {
    return await api.service("beyondsecurityscans").patch(id, {
      deleted: true,
      updatedAt: Date.now(),
    });
  });
};

const callDeletableSealsReport = async () => {
  return callApi().then(async (response) => {
    return await api.service("deletable-seals").find({});
  });
};

const callDeleteSeal = async (id) => {
  return callApi().then(async (response) => {
    return await api.service("deletable-seals").remove(id);
  });
};

const callApiGetBusinessesHasNoSeals = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/reporting").get("businesses-has-no-seals");
  });
};

const callApiGetBusinessesHasSeals = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/reporting").get("businesses-has-seals");
  });
};

const callApiGetBusinessesSealUrls = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/reporting").get("business-seal-urls");
  });
};

const callApiGetBusinessesDeletedSeals = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/reporting").get("businesses-deleted-seals");
  });
};

const callApiGetBusinessesHiddenSeals = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/reporting").get("businesses-hidden-seals");
  });
};

const callApiGetRunningSslScans = async () => {
  return callApi().then(async (response) => {
    return await api
      .service("domains")
      .find({ query: { "sslInfo.status": "scanning" } });
  });
};

const callApiGetInvalidSslScans = async () => {
  return callApi().then(async (response) => {
    return await api
      .service("domains")
      .find({ query: { "sslInfo.status": "failed" } });
  });
};

const callApiGetVerifyingSslScans = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/ssls").get("verifying-ssl-scan");
  });
};

const callApiGetPendingSslScans = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/ssls").get("pending-ssl-scan");
  });
};

const callApiGetSslsExpiredInMonth = async () => {
  return callApi().then(async (response) => {
    /* return await api.service("domains").find({
      query: {
        "sslInfo.expireDate": {
          $gte: new Date(),
          $lt: new Date(new Date().setDate(new Date().getDate() + 30)),
        },
        "sslInfo.status": "active",
      },
    }); */

    return await api.service("api/ssls").get("ssl-expire-in-30days");
  });
};

const callApiGetCanceledBusinessSslsScans = async () => {
  return callApi().then(async (response) => {
    const cancelBusinessIds = [];
    const cancelBusinessList = await api
      .service("business")
      .find({ query: { status: "canceled" } });

    if (
      cancelBusinessList &&
      cancelBusinessList.data &&
      cancelBusinessList.data.length > 0
    ) {
      cancelBusinessList.data.map((v, i) => {
        cancelBusinessIds.push(v._id);
      });
    }

    return await api.service("domains").find({
      query: {
        businessId: { $in: cancelBusinessIds },
        "sslInfo.lastCheckDate": {
          $gte: new Date(new Date().setDate(new Date().getDate() - 30)),
          $lt: new Date(),
        },
      },
    });
  });
};

const callApiGetRenewSslsData = async () => {
  return callApi().then(async (response) => {
    return await api.service("api/renew-ssls").get("renewal-applications");
  });
};

const callApiGetCheckScanReportsDomainData = async () => {
  return callApi().then(async (token) => {
    return await api.service("api/reporting").get("check-scan-report");
  });
};

const callApiGet30DaysOldFreeActiveBusinesses = async () => {
  return callApi().then((token) => {
    return api
      .service("api/reporting")
      .get("active-free-30-days-old-businesses");
  });
};

export {
  callOnGetBusinessReport,
  callOnGetReportCancelledSites,
  callOnGetResultMissingScans,
  callOnGetNonExistentScans,
  callOnGetNoSealGeneratedCode,
  callOnUpdateBSTestScan,
  callOnMarkDeleteBSTestScan,
  callDeletableSealsReport,
  callDeleteSeal,
  callOnGetMissingScanReport,
  callOnGetFailedScanReport,
  callOnGetRunningScanReport,
  callOnGetCanceledScanReport,
  callApiGetBusinessesHasNoSeals,
  callApiGetBusinessesHasSeals,
  callApiGetBusinessesDeletedSeals,
  callApiGetBusinessesHiddenSeals,
  callApiGetRunningSslScans,
  callApiGetInvalidSslScans,
  callApiGetPendingSslScans,
  callOnGetFalsePositiveScanReport,
  callApiGetSslsExpiredInMonth,
  callApiGetCanceledBusinessSslsScans,
  callApiGetRenewSslsData,
  callApiGetBusinessesSealUrls,
  callApiGetCheckScanReportsDomainData,
  callApiGet30DaysOldFreeActiveBusinesses,
  callOnGetChangePort,
  callOnUpdatePort,
  callApiGetVerifyingSslScans,
  callOnDeactivateScan,
  callOnGetSSLScanDates,
  callOnRefreshScanDates,
  callOnGetScanScheduleReport,
};
