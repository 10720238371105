import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import config from "../../helpers/config";
import AppContext from "../../components/context/appContext";
import { updateUserProfileData } from "../../modules/actions/index";
import { now } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteSecretConfirmation from "./deleteSecretConfirmation";

class GenerateApiKeyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      apiSecret: null,
      showApiSecret: false,
      businessId: "",
      userData: {},
      userBusinessData: [],
      deletedBusinessId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;

    if (
      prevState.userData !== userData &&
      prevState.userBusinessData !== userBusinessData
    ) {
      this.setState({
        userData: userData,
        userBusinessData: userBusinessData,
        businessId: userBusinessData.length > 0 ? userBusinessData[0]._id : "",
      });
    }
  }

  /* generateUserApiKey() {
    this.setState({
      isDisabled: true,
    });
    let payload = this.context.userData.userDetails;
    payload["secret"] = uuidv4();

    this.props.updateUserProfileData(this.context.userData._id, {
      purpose: "generateUserApiKey",
      userDetails: payload,
    });

    setTimeout(() => {
      this.setState({
        apiSecret: this.context.userData.userDetails.secret,
        showApiSecret: true,
      });
    }, 2500);

    setTimeout(() => {
      this.setState({
        isDisabled: false,
      });
    }, 2500);
  } */

  onClickGenerateApiKey = () => {
    let payload,
      addSecret = false,
      secret = uuidv4();

    this.setState({
      isDisabled: true,
    });

    if (this.context.userData.businessSecretKeys) {
      let existingBusinessSecret = this.context.userData.businessSecretKeys.filter(
        (v, i) => {
          return v.businessId === this.state.businessId && v;
        }
      );

      if (existingBusinessSecret && existingBusinessSecret.length > 0) {
        toast.error("Sceret key already created for this business.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      } else {
        addSecret = true;
        payload = this.context.userData.businessSecretKeys;
        payload.push({
          businessId: this.state.businessId,
          secret: secret,
          secretCreatedAt: now(),
        });
      }
    } else {
      addSecret = true;
      payload = [
        {
          businessId: this.state.businessId,
          secret: secret,
          secretCreatedAt: now(),
        },
      ];
    }

    if (addSecret) {
      this.props.updateUserProfileData(this.context.userData._id, {
        purpose: "generateUserApiKey",
        businessSecretKeys: payload,
      });
    }

    setTimeout(() => {
      this.setState({
        isDisabled: false,
        showApiSecret: true,
        apiSecret: secret,
      });
    }, 2500);
  };

  render() {
    const {
      showApiSecret,
      apiSecret,
      isDisabled,
      userBusinessData,
      businessId,
      deletedBusinessId,
    } = this.state;

    return (
      <>
        <div className="modal fade" id="generateApiKey" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" style={{ paddingLeft: "20px" }}>
                  Get your API key
                </h4>
              </div>

              <div className="modal-body text-center">
                <div className="row">
                  <div className="col-md-12">
                    {showApiSecret && (
                      <>
                        <div className="alert alert-info">
                          Make sure to copy this api key to a secure location as
                          you will not be able to access it again once you close
                          this popup. You can regenerate it at anytime though.
                        </div>
                        <p>{apiSecret}</p>
                      </>
                    )}

                    <div className="table-responsive">
                      <table
                        id="dash-overview-table"
                        className="table table-striped"
                      >
                        <tbody className="scan-status-list">
                          <tr>
                            <td width="40%">
                              <h4 className="f700">Your API Key</h4>
                            </td>
                            <td width="20%" colSpan="3">
                              <select
                                id="dateRange"
                                className="form-control"
                                onChange={(evt) =>
                                  this.setState({
                                    businessId: evt.target.value,
                                  })
                                }
                                value={businessId}
                              >
                                {userBusinessData.map(
                                  (business, businessIndex) => {
                                    return business.status !== "canceled" ? (
                                      <option
                                        key={businessIndex}
                                        value={business._id}
                                      >
                                        {business.primaryDomain}
                                      </option>
                                    ) : (
                                      ""
                                    );
                                  }
                                )}
                              </select>
                              <small>Select business to generate API key</small>
                            </td>
                          </tr>
                          {(this.context.userData &&
                            this.context.userData.businessSecretKeys &&
                            this.context.userData.businessSecretKeys.length >
                              0 &&
                            this.context.userData.businessSecretKeys.map(
                              (v, i) => {
                                return (
                                  <tr>
                                    <td width="40%">
                                      <small
                                        style={{
                                          fontSize: "15px",
                                          display: "block",
                                          clear: "both",
                                        }}
                                      >
                                        {userBusinessData.map(
                                          (business, businessIndex) => {
                                            return business.status !==
                                              "canceled" &&
                                              business._id === v.businessId ? (
                                              <p>{business.primaryDomain}</p>
                                            ) : (
                                              ""
                                            );
                                          }
                                        )}
                                      </small>
                                    </td>
                                    <td width="20%">
                                      {"########-####-####-####-######" +
                                        v.secret.slice(-6)}
                                    </td>
                                    <td width="20%">
                                      {moment(v.secretCreatedAt).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </td>
                                    <td width="20%">
                                      <button
                                        data-toggle="modal"
                                        data-target="#deleteSecretConfirmationModal"
                                        className="btn-sm btn-danger mr-2 btn"
                                        data-dismiss="modal"
                                        onClick={() => {
                                          this.setState({
                                            deletedBusinessId: v.businessId,
                                          });
                                        }}
                                      >
                                        <i className="fa fa-trash" /> Delete
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                            )) || (
                            <tr>
                              <td colSpan="3">
                                <div className="alert alert-info">
                                  No API key found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer text-right">
                <button
                  type="button"
                  disabled={isDisabled}
                  className="btn btn-success btn-140-width btn-md"
                  onClick={() => this.onClickGenerateApiKey()}
                >
                  Generate
                </button>
                <button
                  id="cancelDomainInAdvDismissModal"
                  type="button"
                  className="btn-md btn-tglight2 btn-140-width mr-2 btn"
                  data-dismiss="modal"
                  onClick={() => this.setState({ showApiSecret: false })}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <DeleteSecretConfirmation deletedBusinessId={deletedBusinessId} />
      </>
    );
  }
}

GenerateApiKeyModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfileData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateApiKeyModal);
