import React from "react";
import Pagination from "react-js-pagination";
import "./panels.css";

const tableContent = (props) => {
  let toPCIpageLink = null;

  function displayPagination(isPagination) {
    let pagination = "";
    if (isPagination === true) {
      pagination = (
        <tr>
          <th style={{ padding: "20px" }} />
          <th colSpan="4">
            {props.totalItems > 10 ? (
              <Pagination
                activePage={props.activePage}
                itemsCountPerPage={props.pageLimit}
                totalItemsCount={props.totalItems}
                pageRangeDisplayed={4}
                onChange={props.pageChange}
              />
            ) : null}
          </th>
          <th style={{ padding: "20px" }} className="text-right">
            {!props.hideMoreLink ? toPCIpageLink : null}
          </th>
        </tr>
      );
    } else {
      pagination = (
        <tr>
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "20px" }} />
          <th style={{ padding: "10px 20px 20px" }} className="text-right">
            {!props.hideMoreLink ? toPCIpageLink : null}
          </th>
        </tr>
      );
    }
    return pagination;
  }

  if (props.accountType !== "free") {
    toPCIpageLink = (
      <button
        className="tg-dashboard-link"
        onClick={props.toPCIpage}
        style={{ cursor: "pointer" }}
      >
        <small>
          <u>More Scan Reports</u>
        </small>
      </button>
    );
  }
  return (
    <div style={{ border: "4px solid #EFEFEF", borderRadius: "5px" }}>
      <table className="table table-borderless" style={{ marginBottom: 0 }}>
        <thead className="thead-dark">
          <tr>
            <th />
            <th width="20%" style={{ padding: "20px 15px" }}>
              Last Scanned
            </th>
            <th style={{ padding: "20px 15px" }}>Frequency</th>
            <th
              width="20%"
              className="text-center"
              style={{ padding: "10px 0px 10px" }}
            >
              <div style={{ position: "relative", top: "10px" }}>
                Vulnerabilities
              </div>
              <div
                className="vulnerabilityList"
                style={{ position: "relative" }}
              >
                <div style={{ position: "relative", top: "15px" }}>
                  <span
                    className="bg bg-sm bg-serious"
                    style={{ borderRadius: "0px", padding: "4px 10px" }}
                  >
                    {" "}
                    High{" "}
                  </span>
                  <span
                    className="bg bg-sm bg-medium"
                    style={{ borderRadius: "0px", padding: "4px 10px" }}
                  >
                    {" "}
                    Med{" "}
                  </span>
                  <span
                    className="bg bg-sm bg-green"
                    style={{ borderRadius: "0px", padding: "4px 10px" }}
                  >
                    {" "}
                    Low{" "}
                  </span>
                  <span
                    className="bg bg-sm bg-black"
                    style={{ borderRadius: "0px", padding: "4px 10px" }}
                  >
                    {" "}
                    Total{" "}
                  </span>
                </div>
              </div>
            </th>
            <th width="19%" style={{ padding: "20px 15px" }}>
              Status
            </th>
            <th style={{ padding: "20px 15px" }}>Actions</th>
          </tr>
        </thead>
        <tbody style={{ border: "0px solid #efefef", padding: "20px" }}>
          {props.children}
        </tbody>
        <thead className="thead-dark">
          {displayPagination(props.isPagination)}
        </thead>
      </table>
    </div>
  );
};

export default tableContent;
