import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import ModalDeleteDomain from "../modals/modalDomainDeleteScanOpt";
import ModalAddDomain from "../modals/modalAddDomainScanOpt";
import ModalEditDomain from "../modals/modalEditDomainScanOpt";
import ModalRefreshSeal from "../modals/modalRefreshSeal";
import ModalDetailsUpdate from "../modals/modalDetailsScanOpt";
import ModalAdditionalScanInfo from "../modals/modalAdditionalScanInfo";
import Config from "../../../../helpers/config";
import FrequencyModal from "../../../../components/ui/modal/frequencyModal";
import SubDomains from "./subDomains";
import GDPRswitch from "./reportSwitches/gdprSwitch";
import HIPAAswitch from "./reportSwitches/hipaaSwitch";
import OWASPswitch from "./reportSwitches/owaspSwitch";
import PENTESTswitch from "./reportSwitches/pentestSwitch";
import { convert24HourFormat } from "../../../../helpers/timeZone";
import { checkValidDomain } from "../../../../helpers/manageScanDetails";
import moment from "moment";
import {
  callApiGetBusinessData,
  callOnUpdateBusinessReportOptions,
  callOnUpdateBusinessFrequency,
  callApiGetBusinessSubdomainData,
  callApiUpdateUserProfileData,
  callMalwarePatchBusiness,
  callApiDeleteDomain,
  callOnUpdateSubDomainById,
  callOnGetDomainById,
  callApiGetDomainInfoUsingBusinessId,
  callApiGetTrustSealsData,
  callIsHostReachable,
  callApiOpenSaqApp,
  callApiGetBusinessPrimaryUser,
} from "../../../../services/index";
import AppContext from "../../../../components/context/appContext";
import RefreshSealModal from "../../../../views/modals/refreshSealModal";
import SaqAppAuthModal from "../../../../views/modals/saqAppAuthModal";
class ScanTableData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      invalidTimeZone: false,
      subdomains: [],
      info: {
        host: "",
        alias: "",
      },
      loading: false,
      errorMessage: null,
      submitted: false,
      updated: false,
      userTimeZone: null,
      userScanTime: "24",
      beyondSecurityScanInfo: {
        scanTime: null,
        scanTimeZone: null,
      },
      scanOptions: {
        externalScan: {
          showModal: false,
          hideModal: true,
          confirm: false,
          save: false,
          value: "action",
        },
        scanReport: {
          gdpr: false,
          hippa: false,
          owasp: false,
          pentest: false,
          ping: false,
        },
        scanActive: {
          pci: true,
          malware: true,
          ssl: true,
        },
      },

      malwareScanOptions: {
        malwareFreqScan: {
          showModal: false,
          hideModal: true,
          confirm: false,
          save: false,
          value: "action",
        },
      },
      businessId: null,
      notAllowedToAddSubdomain: null,
      userId: null,
      subDomainId: null,
      pciScanned: false,
      hasCloneUser: true,
      accountType: null,
      notifyType: "",
      notifyMessage: "",
      displayed: false,
      validate: {
        valid: false,
        error: "",
      },
      targetSslScanData: {},
      business: {},
      trustsealData: {},
      isHostReachable: "",
    };

    this.textInput = React.createRef();
  }

  getUserData() {
    const userData = this.context.userData;
    return userData;
  }

  getUserBusinessData() {
    const { index } = this.props;
    const userBusinessData = this.context.userBusinessData[index];
    return userBusinessData;
  }

  async getSslScanData(businessId) {
    const result = await callApiGetDomainInfoUsingBusinessId(businessId);
    return result &&
      result.data &&
      result.data.length > 0 &&
      result.data[0].hasOwnProperty("sslInfo")
      ? result.data[0].sslInfo
      : null;
  }

  async getTrustSealsData(businessId) {
    const thisResult = await callApiGetTrustSealsData(businessId);

    return thisResult.data;
  }

  async getBusinessScanInfo() {
    const userData = this.getUserData();
    this.setState({
      userTimeZone: userData.timezone,
      userScanTime: userData.pciScanTime === null ? "24" : userData.pciScanTime,
      invalidTimeZone:
        userData.timezone === 0 || userData.timezone === null ? true : false,
      userId: userData._id,
    });

    const business = this.getUserBusinessData();
    const scanOptions = { ...this.state.scanOptions };
    const userBusinessData = await callApiGetBusinessData(business._id);

    let accountType = null;
    let hasCloneUser = null;
    hasCloneUser = userBusinessData.hasCloneUser;
    accountType = userBusinessData.accountType;

    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    scanOptions["externalScan"].value = "action";
    this.setState({ subdomains: [], open: true, scanOptions });
    beyondSecurityScanInfo["scanTime"] = userData.pciScanTime;
    beyondSecurityScanInfo["scanTimeZone"] = userData.timezone;
    scanOptions["externalScan"].value = userBusinessData.scanFrequency;
    scanOptions[
      "scanReport"
    ].gdpr = userBusinessData.availableReports.hasOwnProperty("gdpr")
      ? userBusinessData.availableReports.gdpr
      : false;
    scanOptions[
      "scanReport"
    ].hippa = userBusinessData.availableReports.hasOwnProperty("hippa")
      ? userBusinessData.availableReports.hippa
      : false;
    scanOptions[
      "scanReport"
    ].owasp = userBusinessData.availableReports.hasOwnProperty("owasp")
      ? userBusinessData.availableReports.owasp
      : false;
    scanOptions[
      "scanReport"
    ].pentest = userBusinessData.availableReports.hasOwnProperty("pentest")
      ? userBusinessData.availableReports.pentest
      : false;
    scanOptions[
      "scanReport"
    ].ping = userBusinessData.availableReports.hasOwnProperty("ping")
      ? userBusinessData.availableReports.ping
      : false;

    const subdomains = await callApiGetBusinessSubdomainData(
      userBusinessData._id
    );
    this.setState({
      hasCloneUser: hasCloneUser,
      accountType: accountType,
      scanOptions,
      notAllowedToAddSubdomain: userBusinessData.hasOwnProperty(
        "notAllowedToAddSubdomain"
      )
        ? userBusinessData.notAllowedToAddSubdomain
        : true,
      pciScanned: userBusinessData.scanVendor.hasOwnProperty("owner")
        ? true
        : false,
      beyondSecurityScanInfo,
      subdomains: subdomains.data.filter(
        (domain) => domain.host != userBusinessData.primaryDomain
      ),
    });

    const malwareScanOptions = { ...this.state.malwareScanOptions };
    malwareScanOptions["malwareFreqScan"].value = "action";

    malwareScanOptions["malwareFreqScan"].value =
      userBusinessData.malwareFrequency;
    this.setState({
      malwareScanOptions,
    });

    if (userBusinessData) {
      const sslScanData = await this.getSslScanData(userBusinessData._id);
      const trustsealData = await this.getTrustSealsData(userBusinessData._id);

      this.setState({
        business: userBusinessData,
        businessId: userBusinessData._id,
        targetSslScanData: sslScanData,
        trustsealData: trustsealData,
      });
    }
  }
  componentWillReceiveProps() {
    this.getBusinessScanInfo();
  }

  componentDidMount() {
    this.getBusinessScanInfo();
  }

  switchToggleHandler = async (reportType) => {
    const { index } = this.props;
    const scanOptions = { ...this.state.scanOptions };
    const userBusinessData = this.context.userBusinessData[index];
    switch (reportType) {
      case "gdpr":
        scanOptions["scanReport"].gdpr = !this.state.scanOptions.scanReport
          .gdpr;
        break;
      case "hippa":
        scanOptions["scanReport"].hippa = !this.state.scanOptions.scanReport
          .hippa;
        break;
      case "pentest":
        scanOptions["scanReport"].pentest = !this.state.scanOptions.scanReport
          .pentest;
        break;
      case "owasp":
        scanOptions["scanReport"].owasp = !this.state.scanOptions.scanReport
          .owasp;
        break;
      case "ping":
        scanOptions["scanReport"].ping = !this.state.scanOptions.scanReport
          .ping;
        break;
      case "pci":
        scanOptions["scanActive"].pci = !this.state.scanOptions.scanActive.pci;
        break;
      case "malware":
        scanOptions["scanActive"].malware = !this.state.scanOptions.scanActive
          .malware;
        break;
      case "ssl":
        scanOptions["scanActive"].ssl = !this.state.scanOptions.scanActive.ssl;
        break;

      default:
        break;
    }

    await callOnUpdateBusinessReportOptions({
      _id: userBusinessData._id,
      availableReports: scanOptions,
    });

    this.setState({ scanOptions });
  };

  showFrequencyModalHandler = (event) => {
    event.preventDefault();
    const scanOptions = { ...this.state.scanOptions };
    if (event) {
      if (
        event.target.name !== undefined ||
        event.target.name !== null ||
        event.target.name !== ""
      ) {
        scanOptions[event.target.name].value =
          event.target.value !== undefined
            ? event.target.value
            : this.state.scanOptions.externalScan.value;
        scanOptions[event.target.name].showModal = true;
      } else {
        scanOptions["externalScan"].showModal = true;
      }
    }

    this.setState({ scanOptions });
  };

  toggleFrequencyModalHandler = () => {
    const scanOptions = { ...this.state.scanOptions };
    scanOptions["externalScan"].showModal = true;
    this.setState({ scanOptions });
  };

  hideFrequencyModalHandler = () => {
    const scanOptions = { ...this.state.scanOptions };
    scanOptions["externalScan"].showModal = false;
    this.setState({ scanOptions });
  };

  updateFrequencyHandler = async () => {
    const scanOptions = { ...this.state.scanOptions };
    scanOptions["externalScan"].save = true;

    this.setState({ scanOptions });
    const userData = this.getUserData();
    const business = this.getUserBusinessData();
    const businessId = business._id;
    const extraData = { ...business };
    extraData["action"] = "update_bs_frequency";
    extraData["scanFrequency"] = this.state.scanOptions.externalScan.value;
    extraData["timeZone"] = this.state.userTimeZone;
    extraData["timeHour"] =
      this.state.beyondSecurityScanInfo.scanTime === null
        ? 24
        : this.state.beyondSecurityScanInfo.scanTime;

    let frequency = extraData.scanFrequency;
    switch (extraData.scanFrequency) {
      case "daily":
        frequency = "days";
        break;
      case "weekly":
        frequency = "weeks";
        break;
      case "monthly":
        frequency = "months";
        break;
      case "quarterly":
        frequency = "quarters";
        break;
      default:
        break;
    }
    await callOnUpdateBusinessFrequency({
      _id: businessId,
      userId: userData.isAdmin ? this.state.userId : userData._id,
      scanFrequency: this.state.scanOptions.externalScan.value,
      timeZone: this.state.userTimeZone,
      timeHour: this.state.beyondSecurityScanInfo.scanTime,
      pciNextScheduleScanDate: moment()
        .add(1, frequency)
        .add(this.state.beyondSecurityScanInfo.scanTime, "hours"),
      business: extraData,
    });

    fetch(Config.urls.linkUrl + "/trustseal-service/" + businessId, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {});

    this.setState({ submitted: true });
    setTimeout(() => {
      scanOptions["externalScan"].save = false;
      scanOptions["externalScan"].showModal = false;
      this.setState({ scanOptions });
      this.setState({ submitted: false });
    }, 2000);
  };

  handleScanTime = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };

    beyondSecurityScanInfo["scanTime"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
    this.setState({ userScanTime: input.target.value });
  };

  handleScanTimeZone = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTimeZone"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
    this.setState({ userTimeZone: input.target.value });

    if (input.target.value === 0) {
      this.setState({ invalidTimeZone: true });
    } else {
      this.setState({ invalidTimeZone: false });
    }
  };

  setSubDomainId = (id) => {
    this.setState({ subDomainId: id });
  };

  onSetUpdateDomain = async () => {
    const business = this.getUserBusinessData();
    this.setState({ updated: true });
    const subDomainId = this.state.subDomainId;

    const data = {
      subDomainId: subDomainId,
      host: this.state.info.host,
      alias: this.state.info.alias,
      enableSecurityScan: this.state.scanOptions.scanActive.pci,
      enableMalware: this.state.scanOptions.scanActive.malware,
      enableSSL: this.state.scanOptions.scanActive.ssl,
      businessId: business._id,
      scanVendor: business.scanVendor,
      purpose: "update_domain",
    };
    await callOnUpdateSubDomainById(subDomainId, data);
    setTimeout(() => this.setState({ updated: false }), 10000);
    window.location.reload();
  };

  onLostFocus = (event) => {
    const business = this.getUserBusinessData();
    const validIP = checkValidDomain(event, this.state, business.primaryDomain);
    if (validIP) {
      this.setState({
        validate: { valid: true, error: "" },
      });
    } else {
      this.setState({
        validate: {
          valid: false,
          error: Config.messages.inValidURL,
        },
      });
    }
    this.setState({ submitted: false });
  };

  onChangeInput = (event) => {
    const info = { ...this.state.info };
    info[event.target.name] = event.target.value;
    this.setState({ info });
    this.setState({ submitted: false });
  };

  onSetEditDomain = async (id) => {
    const domain = await callOnGetDomainById(id);
    const info = { ...this.state.info };
    const scanOptions = { ...this.state.scanOptions };
    info["host"] = domain.host;
    info["alias"] = domain.alias;

    scanOptions["scanActive"].pci = domain.enableSecurityScan;
    scanOptions["scanActive"].malware = domain.enableMalware;
    scanOptions["scanActive"].ssl = domain.enableSSL;
    this.setState({ info, scanOptions, subDomainId: id });

    this.textInput.current.focus();
  };

  securityOfficer = async () => {
    const user = await callApiUpdateUserProfileData(
      "5f58dec5f382ee303ceb00a5",
      {
        purpose: "updateSecurityOfficer",
        businessId: "5f58dec5f382ee303ceb00a0",
        userId: "5f58dec5f382ee303ceb00a5",
      }
    );
  };

  showMalwareFrequencyModalHandler = (event) => {
    const malwareScanOptions = { ...this.state.malwareScanOptions };
    malwareScanOptions[event.target.name].showModal = true;
    malwareScanOptions[event.target.name].value = event.target.value;
    this.setState({ malwareScanOptions });
  };

  updateMalwareFrequencyHandler = async () => {
    const malwareScanOptions = { ...this.state.malwareScanOptions };
    malwareScanOptions["malwareFreqScan"].save = true;

    this.setState({ malwareScanOptions });
    const business = this.getUserBusinessData();
    const businessId = business._id;

    const malwareFreqQuery = {
      malwareFrequency: this.state.malwareScanOptions.malwareFreqScan.value,
    };

    callMalwarePatchBusiness(businessId, malwareFreqQuery);

    setTimeout(() => {
      malwareScanOptions["malwareFreqScan"].save = false;
      malwareScanOptions["malwareFreqScan"].showModal = false;
      this.setState({ malwareScanOptions });
    }, 2000);
  };

  hideMalwareFrequencyModalHandler = () => {
    const malwareScanOptions = { ...this.state.malwareScanOptions };
    malwareScanOptions["malwareFreqScan"].showModal = false;
    this.setState({ malwareScanOptions });
  };

  onDeleteDomain = async () => {
    let info = {};
    let userData = this.getUserData();
    const business = this.getUserBusinessData();
    if (userData) {
      const businessID = business._id;
      info["businessId"] = businessID;
      info["_id"] = this.state.subDomainId;
      info["isSubDomain"] = true;
      info["scanVendor"] = {
        owner: business.scanVendor.owner,
        organizationID: business.scanVendor.organizationID,
        businessName: business.scanVendor.businessName,
        contactID: business.scanVendor.contactID,
        apiKey: business.scanVendor.apiKey,
      };
      await callApiDeleteDomain(this.state.subDomainId, info);
      window.location.reload();
    }
  };

  goToSAQ = async () => {
    const business = this.getUserBusinessData();
    let saqRegisteringEmail = null;

    const businessPrimaryUser = await callApiGetBusinessPrimaryUser({
      businessId: business._id,
    });

    if (businessPrimaryUser && businessPrimaryUser.data) {
      saqRegisteringEmail = businessPrimaryUser.data.email;
    }

    const userData = this.getUserData();

    const saqApp = await callApiOpenSaqApp({
      purpose: "auth-tg30-request",
      email: saqRegisteringEmail, //userData.email,
      businessId: business._id,
      businessName: business.businessName,
      secret: "U$E45I454*65GhPuF5789sdfs%763sdfs",
    });

    // http://localhost:5050/api/authenticate-tg-user
    // https://api.saqgenerator.com/api/authenticate-tg-user

    await fetch("https://api.saqgenerator.com/api/authenticate-tg-user", {
      method: "POST",
      body: JSON.stringify({
        email: saqRegisteringEmail, //userData.email,
        // token: saqApp.data,
        purpose: "",
        secret: "U$E45I454*65GhPuF5789sdfs%763sdfs",
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && json.success && json.data && json.data.redirectUrl) {
          window.open(json.data.redirectUrl, "_blank", "noopener,noreferrer");
        }
      });
  };

  checkIsHostReachable = async (host) => {
    const result = await callIsHostReachable({
      type: "is-host-reachable",
      host: host,
    });

    this.setState({
      isHostReachable:
        result === true
          ? "Domain was successfully reached"
          : "Unfortunately we could not reach this domain",
    });

    setTimeout(() => {
      this.setState({
        isHostReachable: "",
      });
    }, 5000);
  };

  render() {
    const {
      businessId,
      pciScanned,
      scanOptions,
      beyondSecurityScanInfo,
      userTimeZone,
      userScanTime,
      hasCloneUser,
      accountType,
      business,
      malwareScanOptions,
      invalidTimeZone,
      subdomains,
      targetSslScanData,
      trustsealData,
      isHostReachable,
      notAllowedToAddSubdomain,
    } = this.state;

    const { index, showMalwareFrequencyModalHandler } = this.props;

    let modalFrequency = null;
    if (scanOptions.externalScan.showModal) {
      modalFrequency = (
        <FrequencyModal
          onHandlerTimeZone={this.handleScanTimeZone}
          onHandlerTimeHour={this.handleScanTime}
          timezone={userTimeZone === null ? "America/Denver" : userTimeZone}
          time={
            beyondSecurityScanInfo.scanTime !== null
              ? beyondSecurityScanInfo.scanTime
              : userScanTime
          }
          show={scanOptions.externalScan.showModal}
          close={this.hideFrequencyModalHandler}
          save={this.updateFrequencyHandler}
          onSave={scanOptions.externalScan.save}
          title="Scan Options"
          message={Config.messages.changeFrequency}
          type="pci"
          invalidTimeZone={invalidTimeZone}
        />
      );
    }

    let malwareModal = null;

    if (malwareScanOptions.malwareFreqScan.showModal) {
      malwareModal = (
        <FrequencyModal
          show={malwareScanOptions.malwareFreqScan.showModal}
          close={this.hideMalwareFrequencyModalHandler}
          save={this.updateMalwareFrequencyHandler}
          onSave={malwareScanOptions.malwareFreqScan.save}
          title="Scan Options"
          message={
            "Are you sure you want to change the frequency to " +
            malwareScanOptions.malwareFreqScan.value
          }
        />
      );
    }

    return (
      <>
        {malwareModal}
        {modalFrequency}

        <>
          <div
            className="col-4"
            style={{ fontWeight: "bold", padding: "25px" }}
          >
            <div>
              <b>Subdomains / IPs:</b>
            </div>

            <br />
            <div className="flex-container">
              <SubDomains
                subdomains={subdomains}
                onSetEditDomain={this.onSetEditDomain}
                setSubDomainId={this.setSubDomainId}
              />
            </div>
            <ul
              className="list-unstyled"
              style={{
                lineHeight: "2",
                marginTop: "5px",
              }}
            >
              <hr />
              <li>
                {notAllowedToAddSubdomain ? (
                  <>
                    <button
                      className="btn btn-sm btn-secondary"
                      data-toggle="modal"
                      data-target="#dash-addmodal"
                      style={{ cursor: "pointer" }}
                      data-testid="btnAddSubdomain"
                    >
                      <i className="fas fa-plus-circle" />&nbsp; add subdomains
                      / IPs
                    </button>
                  </>
                ) : null}

                {/* START ADD MODAL */}
                <ModalAddDomain scanOptions={scanOptions} index={index} />
                {/* END ADD MODAL */}

                {/* START DELETE MODAL */}
                <ModalDeleteDomain onDeleteDomain={this.onDeleteDomain} />
                {/* END DELETE MODAL */}

                {/* START EDIT MODAL */}
                <ModalEditDomain
                  onLostFocus={this.onLostFocus}
                  onChangeInput={this.onChangeInput}
                  subdomains={this.state.subdomains}
                  scanOptions={this.state.scanOptions}
                  submitted={this.state.submitted}
                  validate={this.state.validate}
                  info={this.state.info}
                  textInput={this.textInput}
                  onSetUpdateDomain={this.onSetUpdateDomain}
                  switchToggleHandler={this.switchToggleHandler}
                  checkIsHostReachable={this.checkIsHostReachable}
                  isHostReachable={isHostReachable}
                />
                {/* END EDIT MODAL */}
              </li>
            </ul>
          </div>
          <div
            className="col-2"
            style={{
              fontWeight: "bold",
              borderLeft: "1px solid #E5E5E5",
              padding: "25px",
            }}
          >
            <div>
              <b>Scan Frequency</b>
              <br />
              <br />
              <select
                name="externalScan"
                id="pciscan-sel"
                className="btn-success btn-md text-white"
                onChange={this.showFrequencyModalHandler}
                value={scanOptions.externalScan.value}
                disabled={!pciScanned}
                style={
                  !pciScanned ? { backgroundColor: "rgb(127, 127, 127)" } : null
                }
              >
                <option className="bg-white" value="quarterly">
                  Quarterly
                </option>
                <option className="bg-white" value="weekly">
                  Weekly
                </option>
                <option className="bg-white" value="monthly">
                  Monthly
                </option>
                <option className="bg-white" value="daily">
                  Daily
                </option>
              </select>
            </div>
            <br />
            <br />
            <button
              className="tg-dashboard-link"
              name="externalScan"
              onClick={this.toggleFrequencyModalHandler}
              value={scanOptions.externalScan.value}
              style={{ textAlign: "left" }}
            >
              Scan scheduled for{" "}
              {convert24HourFormat(
                beyondSecurityScanInfo.scanTime !== null
                  ? beyondSecurityScanInfo.scanTime
                  : userScanTime
              )}{" "}
              but may run later depending on availability.
            </button>

            {/* <div className="row mt-5">
              <div className="col-sm-9 pl-0">
                <button
                  className="tg-dashboard-link"
                  data-toggle="modal"
                  data-target="#saqAppAuthModal"
                  style={{ marginRight: "7px" }}
                >
                  <i className="text-success fas fa-circle mr-2" /> SAQ APP{" "}
                  <small>(Generator)</small>
                </button>
              </div>
            </div> */}
            <br />
            <button
              className="tg-link"
              style={{ textAlign: "left" }}
              onClick={() => {
                this.props.goToPenetrationTest();
              }}
            >
              Request Penetration Test
            </button>
            <br />

            {accountType !== "free" ? (
              <button className="tg-link" onClick={() => this.goToSAQ()}>
                SAQ <small className="tg-link">(Generator)</small>
              </button>
            ) : (
              <React.Fragment>
                <br />
              </React.Fragment>
            )}
            <br />
            <br />
            {/* START GDPR SWITCH */}
            <GDPRswitch
              scanOptions={scanOptions}
              changeStatus={this.switchToggleHandler}
            />
            {/* END GDPR SWITCH */}

            {/* START HIPAA */}
            <HIPAAswitch
              scanOptions={scanOptions}
              changeStatus={this.switchToggleHandler}
            />
            {/* END HIPAA */}

            {/* START OWASP SWITCH*/}
            <OWASPswitch
              scanOptions={scanOptions}
              changeStatus={this.switchToggleHandler}
            />
            {/* END OWASP SWITCH */}

            {/* START PENTEST SWITCH */}
            {/* <PENTESTswitch
              scanOptions={scanOptions}
              changeStatus={this.switchToggleHandler}
            /> */}
            {/* END PENTEST SWITCH */}

            {business &&
            business.primaryDomain &&
            business.primaryDomain.indexOf("trust-guard.com") !== -1 ? (
              <div className="row">
                <div className="col-sm-12 pl-0">
                  <hr />
                </div>

                <div className="col-sm-12 pl-0">
                  <button
                    className="tg-link"
                    data-toggle="modal"
                    data-target="#additionalScanInfo"
                    onClick={() => {}}
                  >
                    Additional Scan Info
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="col-2"
            style={{
              fontWeight: "bold",
              borderLeft: "1px solid #E5E5E5",
              padding: "25px",
            }}
          >
            <b>Scan Frequency</b>
            <br />
            <br />
            <select
              name="malwareFreqScan"
              className="btn-success btn-md text-white"
              onChange={(event) => this.showMalwareFrequencyModalHandler(event)}
              value={malwareScanOptions.malwareFreqScan.value}
            >
              <option className="bg-white" value="quarterly">
                Quarterly
              </option>
              <option className="bg-white" value="weekly">
                Weekly
              </option>
              <option className="bg-white" value="monthly">
                Monthly
              </option>
              <option className="bg-white" value="daily">
                Daily
              </option>
            </select>
          </div>
          <div
            className="col-2"
            style={{
              fontWeight: "bold",
              borderLeft: "1px solid #E5E5E5",
              padding: "25px",
            }}
          >
            <b>Expiration: </b>
            <br />
            {targetSslScanData && targetSslScanData.hasOwnProperty("sslInfo")
              ? targetSslScanData.sslInfo.hasOwnProperty("expireDate") &&
                targetSslScanData.sslInfo.expireDate
                ? moment(targetSslScanData.sslInfo.expireDate).format(
                    "MM/DD/YYYY"
                  )
                : "N/A"
              : "N/A"}
            <br />
            <br />
            <b>Issued By:</b>
            <br />

            {targetSslScanData && targetSslScanData.hasOwnProperty("sslInfo")
              ? targetSslScanData.sslInfo.hasOwnProperty("sslProvider") &&
                targetSslScanData.sslInfo.sslProvider
                ? targetSslScanData.sslInfo.sslProvider
                : "N/A"
              : "N/A"}
          </div>
          <div
            className="col-2"
            style={{
              fontWeight: "bold",
              borderLeft: "1px solid #E5E5E5",
              padding: "25px",
            }}
          >
            <b>Hard Refresh Seal</b>
            <br />
            <br />
            <button
              className="btn-success btn-md text-white btn-tg-pad"
              data-toggle="modal"
              data-target="#refreshSealModal"
              style={{ marginRight: "7px" }}
              disabled={trustsealData === null ? true : false}
            >
              Refresh
            </button>
          </div>
        </>
        <RefreshSealModal businessId={this.state.businessId} />
        <SaqAppAuthModal />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPageCreateSaq: (businessToggledId) =>
        push("/create-saq", businessToggledId),
      goToPenetrationTest: () => push("/request-penetration-test"),
    },
    dispatch
  );

ScanTableData.contextType = AppContext;
export default connect(
  null,
  mapDispatchToProps
)(ScanTableData);
