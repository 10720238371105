import { api } from "./api";
import { callApi } from "../helpers/index";

export const callApiGetTrustSealsData = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("api/trustseals").get("get-seal-data", {
        query: {
          businessId: businessId,
        },
      });
    } else {
      return null;
    }
  });
};

export const callApiCreateMissingTrustSeals = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("api/trustseals").get("create-missing-seals", {
        query: {
          businessId: businessId,
        },
      });
    } else {
      return null;
    }
  });
};

export const callApiCreateTrustSealsData = async (data) => {
  return callApi().then((token) => {
    if (data && data.businessId) {
      return api.service("api/trustseals").create(data);
    } else {
      return null;
    }
  });
};

export const callApiUpdateTrustSealsData = async (trustSealId, data) => {
  return callApi().then((token) => {
    if (trustSealId && data) {
      return api.service("api/trustseals").update(trustSealId, data);
    } else {
      return null;
    }
  });
};

export const callApiPatchTrustSealsData = async (trustSealId, data) => {
  return callApi().then((token) => {
    if (trustSealId && data) {
      return api.service("api/trustseals").patch(trustSealId, data);
    } else {
      return null;
    }
  });
};

export const callApiRemoveTrustSealsData = async (data) => {
  return callApi().then((token) => {
    if (data && data.userId) {
    } else {
      return null;
    }
  });
};

export const callApiRefreshTrustSealsData = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api.service("api/trustseals").get("refresh-seals", {
        query: {
          businessId: businessId,
        },
      });
    } else {
      return null;
    }
  });
};

export const callApiHideOrDeleteCanceledTrustSeals = async (
  businessId,
  purpose
) => {
  return callApi().then(async (token) => {
    let contextId =
      purpose === "hide"
        ? "hide-seals"
        : purpose === "delete"
          ? "delete-seals"
          : "";

    return api.service("api/trustseals").get(contextId, {
      query: {
        businessId: businessId,
      },
    });

    return null;
  });
};

export const callApiGetCssJsHashList = async (businessId) => {
  return callApi().then((token) => {
    return api.service("upload-seal-scripts").find({});
  });
};

export const callApiGetCertificateData = async (primaryDomain) => {
  return api.service("api/certificate").get("details", {
    query: {
      primaryDomain: primaryDomain,
    },
  });
};

export const callApiRegenerateAllSeals = async (primaryDomain) => {
  return api.service("api/trustseals").get("regenerate-all-seals");
};
