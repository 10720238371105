import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../header';
import TrustGuardAnimation from '../../components/animation/trustGuardAnimation';
import { images } from '../../helpers/images';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SealComponent from './sealComponent';
import CertificateComponent from './certificateComponent';
import { getStorageItem } from '../../helpers/storage';
import config from '../../helpers/config';

import AppContext from '../../components/context/appContext';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('trustguardapiapp');

class TrustSeals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const tablinks = [
      { name: 'Seal Options', target: 'seal' },
      { name: 'Certificate', target: 'certificate' }
    ];

    return (
      <div>
        <Header titlePage={'Trust Seals'} bgColor="#F5F6F8" />

        <ToastContainer />

        <div id="mcontent-top" class="pull-left">
          <div class="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <ul id="dashTab" class="nav menu-tab">
              <li class="active">
                <a data-toggle="tab" href="#seal">
                  Seal Options
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#certificate">
                  Certificate
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div id="mcontent-wrapper">
          <div id="seal" className="seal-content-wrapper tab-pane fade in active">
            <SealComponent />
          </div>

          <div id="certificate" className="seal-content-wrapper tab-pane fade">
            <CertificateComponent />
          </div>
        </div>
      </div>
    );
  }
}

TrustSeals.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrustSeals);
