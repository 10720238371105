import React from "react";
import ControlButtons from "../controlButtons";
import moment from "moment";
import _ from "lodash";

function PortChangeScans({
  index,
  isLoadingPortScans,
  portScanActive,
  ports,
  getPortScans,
  updatePort,
  isUpdatingPortScans,
}) {
  return (
    <>
      <div className="reportButton">
        <button
          disabled={isLoadingPortScans}
          className="btn-success btn-sm"
          onClick={() => getPortScans()}>
          {isLoadingPortScans ? "Loading..." : "CHANGE PORTS"}
        </button>
        <div className="clearfix" />
      </div>
      <p>Businesses that needs to update the ports</p>
      <hr />
      {portScanActive && !isLoadingPortScans && ports.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th>
                  Device (ip/url) {""}
                  Message
                </th>
                <th>Owner</th>
                <th>Organization ID</th>
                <th>Contact ID</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {ports.map((scan, index) => (
                <tr key={index}>
                  <td>{scan.domain}</td>
                  <td style={{ verticalAlign: "top" }}>{scan.owner}</td>
                  <td style={{ verticalAlign: "top" }}>
                    {scan.organizationId}
                  </td>
                  <td style={{ verticalAlign: "top" }}>{scan.contactId}</td>
                  <td style={{ textAlign: "right" }}>
                    <button
                      style={{
                        float: "left",
                        marginRight: "10px",
                      }}
                      className="btn-success"
                      data-toggle="modal"
                      data-target="#falsePositivesPci"
                      disabled={isUpdatingPortScans}
                      onClick={() =>
                        updatePort(
                          scan.businessName,
                          scan.businessId,
                          scan.owner,
                          scan.contactId,
                          scan.organizationId,
                          scan.domain,
                          scan.apiKey
                        )
                      }>
                      <i className="fa fa-bolt" />{" "}
                      {isUpdatingPortScans ? "Updating Port..." : "Change Port"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default PortChangeScans;
