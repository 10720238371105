import React from "react";
import "./ring.css";

const spinner = () => (
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default spinner;
