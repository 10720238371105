import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import config from "../../helpers/config";
import AppContext from "../../components/context/appContext";
import UserSmsTiming from "../modals/userSmsTiming";
import UserSmsInfo from "../modals/userSmsInfo";
import { updateUserProfileData } from "../../modules/actions/index";

class NotificationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisabled: false,
      businessId: "",
      userData: {},
      userBusinessData: [],
      allEmailNotification: false,
      emailNotifications: [],
      pciScanCheckedEmail: false,
      malwareScanCheckedEmail: false,
      sslScanCheckedEmail: false,
      hostCheckedEmail: false,
      otherCheckedEmail: false,
      allSmsNotification: false,
      smsNotifications: [],
      pciScanCheckedSms: false,
      malwareScanCheckedSms: false,
      sslScanCheckedSms: false,
      hostCheckedSms: false,
      otherCheckedSms: false,
      smsInfoPopup: null,
      updateSmsInfoPopup: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;

    if (
      prevState.userData !== userData &&
      prevState.userBusinessData !== userBusinessData
    ) {
      this.setState(
        {
          userData: userData,
          userBusinessData: userBusinessData,
          businessId:
            userBusinessData.length > 0 ? userBusinessData[0]._id : "",
          smsInfoPopup: userData.smsInfoPopup,
        },
        () => {
          this.getUserNotifications(this.state.businessId);
        }
      );
    }
  }

  getUserNotifications = (businessId) => {
    this.setState({
      pciScanCheckedEmail: false,
      malwareScanCheckedEmail: false,
      sslScanCheckedEmail: false,
      pciScanCheckedSms: false,
      malwareScanCheckedSms: false,
      sslScanCheckedSms: false,
      otherCheckedEmail: false,
      hostCheckedEmail: false,
      otherCheckedSms: false,
      hostCheckedSms: false,
    });

    if (this.state.userBusinessData && this.state.userBusinessData.length > 0) {
      const selectedBusinessSettings = this.state.userData.businessDetails.filter(
        (business) => {
          return business.businessId === businessId ? businessId : null;
        }
      );

      /* console.log(
        "getUserNotifications selectedBusinessSettings",
        selectedBusinessSettings
      ); */

      if (selectedBusinessSettings && selectedBusinessSettings.length > 0) {
        const emailNotifications =
          selectedBusinessSettings[0].emailNotifications;
        const smsNotifications = selectedBusinessSettings[0].smsNotifications;

        //if (emailNotifications.length > 0) {
        this.setState({
          emailNotifications: emailNotifications,
        });

        emailNotifications.map((v, i) => {
          switch (v) {
            case "pci-scan":
              this.setState({ pciScanCheckedEmail: true });
              break;
            case "malware-scan":
              this.setState({ malwareScanCheckedEmail: true });
              break;
            case "ssl-scan":
              this.setState({ sslScanCheckedEmail: true });
              break;
            case "host-reachable":
              this.setState({ hostCheckedEmail: true });
              break;
            case "other-messages":
              this.setState({ otherCheckedEmail: true });
              break;
            default:
              break;
          }

          return true;
        });
        //}

        //if (smsNotifications.length > 0) {
        this.setState(
          {
            smsNotifications: smsNotifications,
          },
          () => {
            // console.log("smsNotifications", this.state.smsNotifications);
          }
        );

        smsNotifications.map((v, i) => {
          switch (v) {
            case "pci-scan":
              this.setState({ pciScanCheckedSms: true });
              break;
            case "malware-scan":
              this.setState({ malwareScanCheckedSms: true });
              break;
            case "ssl-scan":
              this.setState({ sslScanCheckedSms: true });
              break;
            case "host-reachable":
              this.setState({ hostCheckedSms: true });
              break;
            case "other-messages":
              this.setState({ otherCheckedSms: true });
              break;
            default:
              break;
          }

          return true;
        });
        //}
      }
    }
  };

  onHandleNotificationCheckboxes = (event, type) => {
    let name = event.target.name;
    let value = event.target.value;

    if (type === "email" || (type === "sms" && !this.state.smsInfoPopup)) {
      this.handleNotificationCheckboxes(type, name, value);
    }

    if (
      type === "sms" &&
      this.state.smsInfoPopup &&
      this.state.smsInfoPopup === true &&
      document.getElementById(value + "-" + type).checked
    ) {
      this.setState(
        {
          updateSmsInfoPopup: true,
        },
        () => {
          document.getElementById("btnDisplaySmsInfo").click();
        }
      );
    }
  };

  handleNotificationCheckboxes = (type, name, value) => {
    let currentStateValue = this.state[name];
    let targetState = type + "Notifications";
    let notifications = this.state[type + "Notifications"];
    let allNotification =
      "all" + type.charAt(0).toUpperCase() + type.slice(1) + "Notification";

    /* console.log(
      "handleNotificationCheckboxes notifications type",
      type,
      notifications
    ); */

    if (currentStateValue) {
      let thisArrayIndex = notifications.indexOf(value);
      if (thisArrayIndex >= 0) {
        notifications.splice(thisArrayIndex, 1);
      }
    } else {
      notifications = [...notifications, value];
    }

    this.setState(
      {
        [name]: !currentStateValue,
        [targetState]: notifications,
      },
      () => {
        if (
          this.state[targetState].indexOf("pci-scan") !== -1 &&
          this.state[targetState].indexOf("malware-scan") !== -1 &&
          this.state[targetState].indexOf("ssl-scan") !== -1
        ) {
          this.setState({
            [allNotification]: true,
          });
        } else {
          this.setState({
            [allNotification]: false,
          });
        }

        if (type === "email") {
          this.updateBusinessNotificationSettings();
        } else {
          if (!this.state.smsInfoPopup) {
            this.updateBusinessNotificationSettings();
          }
        }
      }
    );
  };

  smsInfoModalClose() {
    if (this.state.smsInfoPopup && this.state.updateSmsInfoPopup) {
      this.setState(
        {
          smsInfoPopup: false,
        },
        () => {
          /* this.props.updateUserProfileData(this.state.userData._id, {
            purpose: "smsInfoPopup",
            smsInfoPopup: false,
          }); */
          this.updateBusinessNotificationSettings();
        }
      );
    }
  }

  updateBusinessNotificationSettings = () => {
    this.setState({
      isDisabled: true,
    });

    const userData = this.context.userData;
    if (userData && userData.hasOwnProperty("businessDetails")) {
      userData.businessDetails.map((v, i) => {
        if (v.businessId === this.state.businessId) {
          v.allEmailNotification = this.state.allEmailNotification;
          v.allSmsNotification = this.state.allSmsNotification;
          v.emailNotifications = this.state.emailNotifications;
          v.smsNotifications = this.state.smsNotifications;
        }
      });

      this.props.updateUserProfileData(this.state.userData._id, {
        purpose: "notificationSettings",
        businessDetails: userData.businessDetails,
        smsInfoPopup: this.state.smsInfoPopup,
      });
    }

    setTimeout(() => {
      this.setState({
        isDisabled: false,
      });
    }, 2500);
  };

  render() {
    const {
      userBusinessData,
      businessId,
      pciScanCheckedEmail,
      malwareScanCheckedEmail,
      sslScanCheckedEmail,
      hostCheckedEmail,
      otherCheckedEmail,
      pciScanCheckedSms,
      malwareScanCheckedSms,
      sslScanCheckedSms,
      hostCheckedSms,
      otherCheckedSms,
      isDisabled,
    } = this.state;

    return (
      <>
        <div
          className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad"
          style={{ marginTop: "60px" }}
        >
          <div className="table-responsive">
            <table id="dash-overview-table" className="table table-striped">
              <tbody className="scan-status-list">
                <tr>
                  <td width="60%">
                    <h4 className="f700">NOTIFICATIONS</h4>
                  </td>
                  <td width="40%" colSpan="2">
                    <select
                      id="dateRange"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState(
                          {
                            businessId: evt.target.value,
                          },
                          () => {
                            this.getUserNotifications(this.state.businessId);
                          }
                        )
                      }
                      value={businessId}
                    >
                      {userBusinessData.map((business, businessIndex) => {
                        return business.status !== "canceled" ? (
                          <option key={businessIndex} value={business._id}>
                            {business.primaryDomain}
                          </option>
                        ) : (
                          ""
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td width="60%">
                    <small
                      style={{
                        fontSize: "15px",
                        display: "block",
                        clear: "both",
                      }}
                    >
                      Notifications can be customized for each of your accounts.
                    </small>
                    <small
                      style={{
                        fontSize: "15px",
                        display: "block",
                        clear: "both",
                      }}
                    >
                      SMS messages will be sent to the phone number on your
                      profile account above. (U.S. Phone Numbers Only)
                    </small>
                    <span
                      style={{
                        marginTop: "5px",
                        display: "block",
                        clear: "both",
                      }}
                    >
                      <button
                        className="btn-sm btn-tglight2 mr-2 btn"
                        data-toggle="modal"
                        data-target="#smsTiming"
                      >
                        Set SMS Hours
                      </button>
                    </span>
                  </td>
                  <td width="20%" />
                  <td width="20%" />
                </tr>
                <tr>
                  {/* #1 */}
                  <td width="70%">
                    <h5 className="f700">
                      PCI Scan
                      <small style={{ display: "block", clear: "both" }}>
                        Receive a notification anytime your scan status changes
                        or you run a manual scan.
                      </small>
                    </h5>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="pci-scan-email"
                        value="pci-scan"
                        name="pciScanCheckedEmail"
                        checked={pciScanCheckedEmail}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "email")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      htmlFor="pci-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("pci-scan-email").click();
                      }}
                    >
                      Email
                    </label>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="pci-scan-sms"
                        value="pci-scan"
                        name="pciScanCheckedSms"
                        checked={pciScanCheckedSms}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "sms")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>
                    <label
                      htmlFor="pci-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("pci-scan-sms").click();
                      }}
                    >
                      SMS
                    </label>
                  </td>
                </tr>
                <tr>
                  {/* #2 */}
                  <td width="70%">
                    <h5 className="f700">
                      Malware Scan
                      <small style={{ display: "block", clear: "both" }}>
                        Recieve a notification each time your Malware scan
                        fails.
                      </small>
                    </h5>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="malware-scan-email"
                        value="malware-scan"
                        name="malwareScanCheckedEmail"
                        checked={malwareScanCheckedEmail}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "email")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      htmlFor="mal-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("malware-scan-email").click();
                      }}
                    >
                      Email
                    </label>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="malware-scan-sms"
                        value="malware-scan"
                        name="malwareScanCheckedSms"
                        checked={malwareScanCheckedSms}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "sms")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>
                    <label
                      htmlFor="mal-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("malware-scan-sms").click();
                      }}
                    >
                      SMS
                    </label>
                  </td>
                </tr>
                <tr>
                  {/* #3 */}
                  <td width="70%">
                    <h5 className="f700">
                      SSL Scan
                      <small style={{ display: "block", clear: "both" }}>
                        Recieve a notification 1 week and 1 day before your SSL
                        Certificate expires.
                      </small>
                    </h5>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="ssl-scan-email"
                        value="ssl-scan"
                        name="sslScanCheckedEmail"
                        checked={sslScanCheckedEmail}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "email")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      htmlFor="ssl-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("ssl-scan-email").click();
                      }}
                    >
                      Email
                    </label>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="ssl-scan-sms"
                        value="ssl-scan"
                        name="sslScanCheckedSms"
                        checked={sslScanCheckedSms}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "sms")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>
                    <label
                      htmlFor="ssl-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("ssl-scan-sms").click();
                      }}
                    >
                      SMS
                    </label>
                  </td>
                </tr>
                <tr>
                  {/* #4 */}
                  <td width="70%">
                    <h5 className="f700">
                      Host Reachable
                      <small style={{ display: "block", clear: "both" }}>
                        Recieve notifications when your host either reachable or
                        unreachable.
                      </small>
                    </h5>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="host-reachable-email"
                        value="host-reachable"
                        name="hostCheckedEmail"
                        checked={hostCheckedEmail}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "email")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      htmlFor="host-ping"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("host-reachable-email").click();
                      }}
                    >
                      Email
                    </label>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="host-reachable-sms"
                        value="host-reachable"
                        name="hostCheckedSms"
                        checked={hostCheckedSms}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "sms")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>
                    <label
                      htmlFor="host-ping-sms"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("host-reachable-sms").click();
                      }}
                      data-testid="chkHostSms"
                    >
                      SMS
                    </label>
                  </td>
                </tr>
                <tr>
                  {/* #5 */}
                  <td width="70%">
                    <h5 className="f700">
                      Other Messages
                      <small style={{ display: "block", clear: "both" }}>
                        Recieve miscelleneous notifications.
                      </small>
                    </h5>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="other-messages-email"
                        value="other-messages"
                        name="otherCheckedEmail"
                        checked={otherCheckedEmail}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "email")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>

                    <label
                      htmlFor="ssl-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("other-messages-email").click();
                      }}
                    >
                      Email
                    </label>
                  </td>
                  <td width="15%">
                    <label className="containerCheck">
                      <input
                        type="checkbox"
                        id="other-messages-sms"
                        value="other-messages"
                        name="otherCheckedSms"
                        checked={otherCheckedSms}
                        onChange={(evt) =>
                          this.onHandleNotificationCheckboxes(evt, "sms")
                        }
                        style={{ cursor: "pointer" }}
                        disabled={isDisabled}
                      />
                      <span className="checkmark" />
                    </label>
                    <label
                      htmlFor="ssl-noti"
                      style={{
                        cursor: "pointer",
                        fontSize: "12px",
                        marginLeft: "25px",
                        marginTop: "6px",
                      }}
                      onClick={() => {
                        document.getElementById("other-messages-sms").click();
                      }}
                      data-testid="chkOtherSms"
                    >
                      SMS
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <button
          id="btnDisplaySmsInfo"
          data-toggle="modal"
          data-target="#smsInfo"
          style={{ display: "none" }}
        >
          Sms Alert
        </button>

        {this.state.updateSmsInfoPopup === true ? (
          <UserSmsInfo smsInfoModalClose={this.smsInfoModalClose()} />
        ) : (
          ""
        )}

        <UserSmsTiming />
      </>
    );
  }
}

NotificationSettings.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfileData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettings);
