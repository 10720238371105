import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  FormControl,
  HelpBlock,
  Table,
  ButtonToolbar,
} from "react-bootstrap";
import _ from "lodash";
import Modal from "../../../components/ui/modal/modal";
import Aux from "../../../components/hoc/containerAux";
import ToastMessage from "../../../components/toast/toastMessage";
import "../../../components/ui/general.css";
import { getUserDecryptedData } from "../../../helpers/general";
import { convertTimeTo } from "../../../helpers/timeZone";
import {
  callOnFalsePostiveUserRecords,
  callOnClickSendMail,
} from "../../../services";
import "./fpScrollBar.css";

class FalsePositive extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      isAdmin: false,
      toggledIndex: 0,
      value: "",
      submitted: false,
      host: "",
      viewFalsePositiveToggle: false,
      falsePositives: [],
    };
  }

  getBusinessData = () => {
    this.setState({ value: "" });
    const businesses = this.props.businessDetails;
    const data = _.orderBy(businesses, ["createdAt"], ["asc"]);
    return data;
  };

  getValidationState() {
    const length = this.state.value.length;
    if (length > 10) return false;
    return true;
  }

  async componentWillMount() {
    const data = this.getBusinessData();
    const business = this.props.singlePage
      ? this.props.businessDetails
      : data[this.props.toggledIndex];
    const falsePositive = await callOnFalsePostiveUserRecords(business);
    if (falsePositive.total >= 1) {
      this.setState({
        falsePositives: falsePositive.data[0].falsePositives,
      });
    }
  }

  componentDidUpdate(props, prevState) {
    if (this.props.toggledIndex !== prevState.toggledIndex) {
      this.setState({
        viewFalsePositiveToggle: false,
        falsePositives: [],
        toggledIndex: this.props.toggledIndex,
      });
    }
  }

  getFalsePositiveRecordsHandler = async () => {
    let data = null;
    let toggleBusinessIndex = null;
    let business = null;
    let results = null;
    let falsePositiveData = null;

    if (this.props.vulnerabilityPage) {
      results = await callOnFalsePostiveUserRecords(
        this.props.businessDetails._id
      );
      falsePositiveData = results.data[0] ? results.data[0].falsePositives : [];
    } else {
      data = this.getBusinessData();
      toggleBusinessIndex =
        this.props.toggledIndex === null ? 0 : this.props.toggledIndex;
      business = this.props.singlePage
        ? this.props.businessDetails
        : data[toggleBusinessIndex];
      results = await callOnFalsePostiveUserRecords(business._id);
      falsePositiveData = results.data[0] ? results.data[0].falsePositives : [];
    }

    this.setState({
      viewFalsePositiveToggle: !this.state.viewFalsePositiveToggle,
      falsePositives: [],
    });
    if (results.total >= 1 && this.state.viewFalsePositiveToggle) {
      this.setState({
        falsePositives: falsePositiveData,
      });
    }
  };

  showFalsePosiveRecords = () => {
    if (this.state.viewFalsePositiveToggle) {
      if (this.state.falsePositives) {
        return (
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Message</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.falsePositives.map((result, key) => {
                let message = result.message;
                const regex = /<[^>]*>/g;
                message = message.replace(regex, "");
                const urlCheckRegex = /(http[s]?:\/\/)?[^\s(["<,>]*\.[^\s[",><]*/gm;
                const url = message.match(urlCheckRegex);
                message = message.replace(urlCheckRegex, url[0] + " - ");

                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{message}</td>
                    <td>{result.status}</td>
                    <td>{convertTimeTo(result.date, "UTC", "MM/DD/YYYY")}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );
      }
    }
  };

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  submitFalsePositiveHandler = () => {
    const data = this.getBusinessData();
    let toggledIndex = null;
    let businessDetails = null;
    let business = null;
    const {
      vulnerabilityName,
      vulnerabilityDNS,
      vulnerabilityHost,
    } = this.props;
    const isAdmin = getUserDecryptedData().isAdmin;
    if (Array.isArray(this.props.businessDetails)) {
      toggledIndex =
        this.props.toggledIndex === null ? 0 : this.props.toggledIndex;

      businessDetails = isAdmin
        ? this.props.businessDetails[0]
        : this.props.businessDetails[toggledIndex];

      business =
        this.props.singlePage !== undefined
          ? businessDetails
          : data[toggledIndex];
    } else {
      businessDetails = this.props.businessDetails;

      business = businessDetails;
      if (this.props.vulnerabilityPage) {
        business["vulnerabilityInfo"] = vulnerabilityName;
      }
    }

    const falsePositives = [...this.state.falsePositives];
    falsePositives.push({
      message:
        this.props.singlePage !== undefined
          ? vulnerabilityDNS +
            "(" +
            vulnerabilityHost +
            ") - " +
            this.state.value
          : vulnerabilityDNS +
            "(" +
            vulnerabilityHost +
            ") - " +
            this.state.value,
      status: "pending",
      date: Date.now(),
    });
    this.setState({ falsePositives });

    this.sendMailTag(business, vulnerabilityDNS, vulnerabilityHost);
  };

  sendMailTag = async (business, vulnerabilityDNS, vulnerabilityHost) => {
    const vulnerabilityInfo = business.hasOwnProperty("vulnerabilityInfo")
      ? " Vulnerability Name: " + business.vulnerabilityInfo
      : "";
    let sendToMail = {
      action: "create_email_history",
      emailTag: "false-positive",
      customMessage:
        vulnerabilityDNS +
        "(" +
        vulnerabilityHost +
        ") - " +
        this.state.value +
        vulnerabilityInfo +
        "<br/>" +
        " " +
        "<br/>" +
        this.state.value,
      query: {
        "businessDetails.businessId": business._id,
      },
      businessId: business._id,
    };

    await callOnClickSendMail(sendToMail);
    this.setState({ submitted: true });
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 5000);
  };

  render() {
    let notify = null;
    const { value, submitted, viewFalsePositiveToggle } = this.state;

    if (submitted) {
      notify = (
        <ToastMessage
          type="success"
          notifyMessage="False Positive Submitted Successfully"
        />
      );
    }

    let hideScrollBar = {
      overflowY: "hidden",
    };

    if (viewFalsePositiveToggle) {
      hideScrollBar = {
        overflowY: "scroll",
      };
    }

    return (
      <Aux>
        {notify}
        <Modal
          targetID="dash-false-positive"
          title="Submit False Positive"
          largeModal>
          <div className="scrollbar" id="style-1" style={hideScrollBar}>
            <div className="force-overflow">
              <button
                className={"success"}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.getFalsePositiveRecordsHandler();
                }}>
                {!viewFalsePositiveToggle
                  ? "Click here to view submitted false positives"
                  : "Click here to hide false positives"}
              </button>
              <br />
              <br />
              <HelpBlock>
                <p>
                  False positives may occur if our system detects a
                  vulnerability which is not a real vulnerability. In this case
                  you can waive the vulnerability so it doesn't appear as a
                  medium or high vulnerability.<br />
                  <br />
                  If you believe you have failed the scan due to a false
                  positive please explain why you believe it is a false
                  positive. <br />
                  <br />
                </p>
              </HelpBlock>
              <form>
                <FormGroup>
                  <FormControl
                    componentClass="textarea"
                    value={value}
                    placeholder="Enter reason here..."
                    onChange={this.handleChange}
                    className="form-control"
                    id="falsePositiveTextBox"
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </form>
              {this.showFalsePosiveRecords()}

              <br />
              <div className="modal-footer pt-1 px-0">
                <br />
                <ButtonToolbar>
                  <Button
                    type="button"
                    bsStyle="success"
                    className="btn-success btn-md pull-right btn-140-width"
                    data-dismiss="modal"
                    disabled={this.getValidationState()}
                    onClick={this.submitFalsePositiveHandler}>
                    Submit
                  </Button>
                </ButtonToolbar>
              </div>
            </div>
          </div>
        </Modal>
      </Aux>
    );
  }
}

export default connect()(FalsePositive);
