import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
import {
  onCreateEmail,
  getAllEmails,
  resetStatus,
  onUpdateEmail,
  onDeleteEmail,
  onCreateOneTimeEmail,
  oneTimeEmailList,
  getAllBusinessUsersForOtherMessages,
} from "../../modules/actions/index";
import AppContext from "../../components/context/appContext";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class OneTimeEmailTab extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emails: [],
      emailId: "",
      userId: "",
      totalPendingCount: 0,
      otherMessagesStats: {},
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.messageType === "create-email-success") {
      toast.success("A new email is created successfuly", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.resetAll();
    }
    if (newProps.messageType || newProps.emails) {
      this.setState({ emails: newProps.emails });
    }

    if (newProps.loggedInUser) {
      this.setState({
        userId: newProps.loggedInUser._id,
      });
    }

    if (newProps.oneTimeEmails) {
      this.setState({
        totalPendingCount: newProps.oneTimeEmails.length,
      });
    }

    if (!newProps.messageType && newProps.otherMessagesStats) {
      this.setState({
        otherMessagesStats: newProps.otherMessagesStats,
      });
    }
  }

  componentWillMount() {
    /* removeStorageItem("businessID");
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      let user = JSON.parse(userString);
      this.setState({ userId: user._id });
    }
    let query = {
      query: {
        status: "active",
      },
    };
    this.props.getAllEmails(query); */
  }

  resetAll = () => {
    this.setState({
      emailId: "",
    });
    this.props.resetStatus();
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.controlType !== this.props.controlType &&
      this.props.controlType === "one-time-email"
    ) {
      this.props.oneTimeEmailList();
    }
  }

  onChangeHandelEmailInput(evt) {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.getAllBusinessUsersForOtherMessages();
      }
    );
  }

  onClickSendEmail = async () => {
    let emailData = {
      emailId: this.state.emailId,
      status: "pending",
      createdBy: this.context.userData._id,
    };

    /* console.log("emailData", emailData);
    console.log("this.context.userData._id", this.context.userData._id); */

    await this.props.onCreateOneTimeEmail(emailData);

    this.setState(
      {
        emailId: "",
      },
      () => {
        this.props.getAllEmails();
        this.props.oneTimeEmailList();
      }
    );
  };

  render() {
    const {
      emailId,
      totalPendingCount,
      emails,
      otherMessagesStats,
    } = this.state;

    removeStorageItem("businessID");

    return (
      <div id="one-time-email" className="tab-pane fade">
        <h4 className="mb-1">One Time Email</h4>
        <h5 className="">Send email to everyone in the system</h5>
        <h5 className="">Remaining businesses: {totalPendingCount}</h5>
        <br />

        <div>
          <div className="Rtable Rtable--6cols Rtable--collapse">
            <div className="Rtable-cell p-sm">
              <span className="Rparent-label-mobile">Email Tag</span>
              <br />
              <select
                className="form-control"
                value={emailId}
                name="emailId"
                onChange={this.onChangeHandelEmailInput.bind(this)}
              >
                <option select="false">Select email tag</option>
                {emails.map((email) => {
                  return (email.type === "other-messages" ||
                    email.type === "pci-scan") &&
                    email.status === "active" ? (
                    <option value={email._id}>{email.name}</option>
                  ) : null;
                })}
              </select>
              {otherMessagesStats.emailUsersCount && emailId ? (
                <div>
                  {otherMessagesStats.emailUsersCount +
                    " users wil get the EMAIL"}
                </div>
              ) : (
                ""
              )}
              {otherMessagesStats.smsUsersCount && emailId ? (
                <div>
                  {otherMessagesStats.smsUsersCount + " users wil get the SMS"}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="Rtable-cell p-sm">
              <button
                className="btn-success btn-md btn-140-width pull-left"
                onClick={() => this.onClickSendEmail()}
                style={{ marginTop: "20px" }}
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OneTimeEmailTab.contextType = AppContext;

const mapStateToProps = (state) => {
  return {
    emails: state.accountStore.emails,
    messageType: state.accountStore.messageType,
    loggedInUser: state.accountStore.loggedInUser,
    oneTimeEmails: state.accountStore.oneTimeEmails,
    otherMessagesStats: state.accountStore.otherMessagesStats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onCreateEmail,
      getAllEmails,
      resetStatus,
      onUpdateEmail,
      onDeleteEmail,
      onCreateOneTimeEmail,
      oneTimeEmailList,
      getAllBusinessUsersForOtherMessages,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneTimeEmailTab);
