import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from '../../helpers/config';

class TrustSealsCreatePreset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPresetName: '',
      isDisabled: false
    };
  }

  createSealPreset() {
    this.setState({
      isDisabled: true
    });

    this.props.createSealPreset(this.state.newPresetName);

    setTimeout(() => {
      this.setState({
        newPresetName: '',
        isDisabled: false
      });
    }, 2500);
  }

  render() {
    const { newPresetName } = this.state;
    return (
      <div id="modalCreatePreset" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content col-md-12 seal-preset-create-container">
            <div className="seal-preset-create-header">
              <h4 className="seal-preset-create-title">Preset Title</h4>
              <button
                type="button"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="seal-preset-create-content">
              <input
                type="text"
                className="seal-preset-input"
                name="newPresetName"
                value={newPresetName}
                onChange={evt => this.setState({ newPresetName: evt.target.value })}
              />
            </div>

            <div className="seal-preset-create-action">
              <button
                id="hideModalOnSuccess"
                type="button"
                className="action-btn btn-cancel"
                data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="action-btn btn-create"
                onClick={() => {
                  this.createSealPreset();
                }}
                disabled={this.state.isDisabled}>
                Create Preset
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrustSealsCreatePreset);
