import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";
import { getUserDecryptedData } from "../../helpers/general";

import AppContext from "../../components/context/appContext";

import { callApiGetBusinessData } from "../../services";

class BusinessSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userBusinessData: [],
      businessId: "",
      bpBusinesses: [],
      isCompleted: true,
    };
  }

  async componentDidMount() {
    let userLsData = getUserDecryptedData();

    if (
      userLsData &&
      userLsData.hasOwnProperty("bpBusinessId") &&
      userLsData.bpBusinessId &&
      userLsData.bpBusinessId.length > 0
    ) {
      const tempBusinessList = [];
      userLsData.bpBusinessId.map(async (v, i) => {
        let tempBusinessData = await callApiGetBusinessData(v);
        tempBusinessList.push(tempBusinessData);
      });

      this.setState({
        bpBusinesses: tempBusinessList,
      });
    }
  }

  async componentDidUpdate(prevState, prevProps) {}

  setBusiness = async () => {
    this.context.updateCurrentBusinessId(this.state.businessId);
  };

  render() {
    const { business } = this.props;
    const { businessId, bpBusinesses } = this.state;

    return (
      <>
        {bpBusinesses && bpBusinesses.length > 1 ? (
          <table
            className="table table-striped"
            style={{
              marginBottom: "0px",
            }}
          >
            <tbody className="scan-status-list">
              <tr>
                <td width="60%">
                  <h2 style={{ padding: "0 40px 0 40px " }}>
                    {business.primaryDomain}
                  </h2>
                </td>
                <td width="40%" colSpan="2">
                  <select
                    id="dateRange"
                    className="form-control"
                    onChange={(evt) =>
                      this.setState(
                        {
                          businessId: evt.target.value,
                        },
                        () => {
                          this.setBusiness();
                        }
                      )
                    }
                    value={businessId}
                  >
                    {bpBusinesses.map((v, i) => {
                      return (
                        <option value={v._id} key={i}>
                          {v.primaryDomain}
                        </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          ""
        )}
      </>
    );
  }
}

BusinessSelector.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessSelector);
