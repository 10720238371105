import React, { useEffect, useState } from "react";

import ControlButtons from "../controlButtons";
import moment from "moment";
import _ from "lodash";
import { convertTimeTo } from "../../../helpers/timeZone";
import ScanScheduleModal from "../../modals/scanSchedulesModal";
import {
  callApiBusinessBySchedule,
  callApiBusinessUpdateBySchedule,
} from "../../../services/businessService";
import { callOnGetScanScheduleReport } from "../../../services/reportService";
function ScanSchedules({
  index,
  isLoadingScanSchedule,
  scanScheduleActive,
  scanSchedules,
  getScanSchedule,
  goToDashboard,
}) {
  const [queryString, setQueryString] = useState({});
  const [results, setResults] = useState([]);
  const [schedules, setScanSchedules] = useState([scanSchedules]);
  const queryGetDomainsByScheduleHandler = async (query) => {
    const result = await callApiBusinessBySchedule(query);
    if (result !== undefined) {
      if (result.data.length > 0) {
        const items = [...results];
        result.data.forEach((item, index) => {
          items.push({
            index: index,
            _id: item._id,
            primaryDomain: item.primaryDomain,
            pciScanTime: item.pciScanTime,
            pciScanTimeZone: item.pciScanTimeZone,
            isUpdated: false,
            isReadyForUpdate: false,
          });
        });

        setResults(items);
      }
    }
  };

  const editScheduleHandler = (index, id) => {
    const items = [...results];
    items[index]["isReadyForUpdate"] = true;
    setResults(items);
  };

  const updateScheduleHandler = async (
    index,
    _id,
    pciScanTime,
    pciScanTimeZone
  ) => {
    const items = [...results];
    items[index]["isUpdated"] = true;
    // console.log("query: ", _id, pciScanTime, pciScanTimeZone);

    callApiBusinessUpdateBySchedule({
      pciScanTime: pciScanTime,
      pciScanTimeZone: pciScanTimeZone,
      _id: _id,
    });

    const reportResults = await callOnGetScanScheduleReport();

    setScanSchedules(reportResults.data);
    setResults(items.filter((item) => item._id !== _id));
  };

  const cancelScheduleHandler = (index, id) => {
    const items = [...results];
    items[index]["isReadyForUpdate"] = false;

    setResults(items);
  };

  const scheds = schedules.length > 1 ? schedules : scanSchedules;
  return (
    <>
      <ScanScheduleModal
        domains={results}
        updateScheduleHandler={updateScheduleHandler}
        editScheduleHandler={editScheduleHandler}
        cancelScheduleHandler={cancelScheduleHandler}
      />
      <div className="reportButton">
        <button
          disabled={isLoadingScanSchedule}
          className="btn-success btn-sm"
          onClick={() => getScanSchedule()}>
          {isLoadingScanSchedule ? "Loading..." : "SCAN SCHEDULE"}
        </button>
        <div className="clearfix" />
      </div>
      <p>Getting the multiple scan time and time zone</p>
      <hr />
      {scanScheduleActive && !isLoadingScanSchedule && scheds.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th />
                <th>Scan Time Zone</th>
                <th>Scan Time Hour</th>
                <th>Sites Count</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {scheds.map((scan, index) => {
                const scanSchedule = scan._id;
                const counter = scan.count;
                let numIndex = 0;
                if (scanSchedule) {
                  const status = scanSchedule.status;
                  const pciScanTime = scanSchedule.pciScanTime;
                  const pciScanTimeZone = scanSchedule.pciScanTimeZone;

                  if (
                    status !== "canceled" &&
                    pciScanTime !== null &&
                    pciScanTimeZone !== null
                  ) {
                    return (
                      <tr
                        key={
                          scan._id +
                          (Math.random() + 1).toString(36).substring(7)
                        }>
                        <td>{index + 1}</td>
                        <td>{pciScanTimeZone}</td>
                        <td>{pciScanTime}:00</td>
                        <td>{counter}</td>
                        <td>
                          <button
                            onClick={() =>
                              queryGetDomainsByScheduleHandler({
                                pciScanTime: pciScanTime,
                                pciScanTimeZone: pciScanTimeZone,
                              })
                            }
                            className="btn-success btn-sm"
                            data-toggle="modal"
                            data-target="#scanScheduleReportModal">
                            View Sites
                          </button>
                        </td>
                      </tr>
                    );
                  }
                }
              })}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default ScanSchedules;
