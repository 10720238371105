import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";

import Profile from "./profile";
import NotificationSettings from "./notificationSettings";
import GenerateUserApiKey from "./generateUserApiKey";
import { getStorageItem } from "../../helpers/storage";
import { getUserDecryptedData } from "../../helpers/general";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isAdmin: false,
    };
  }

  componentDidMount() {
    let userLsData = getUserDecryptedData();

    if (userLsData) {
      this.setState({
        isAdmin: userLsData.isAdmin,
      });
    }
  }

  render() {
    const screenHeight = window.innerHeight;
    const { isAdmin } = this.state;

    return (
      <>
        <Header titlePage="Profile" bgColor="#F5F6F8" />

        <div id="mcontent-wrapper" style={!0 ? { height: screenHeight } : {}}>
          <div id="control-panel" className="">
            <ToastContainer />

            <Profile />
            <GenerateUserApiKey />
            {isAdmin !== true ? <NotificationSettings /> : ""}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
