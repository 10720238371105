import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import Header from "../header";
import { getStorageItem } from "../../helpers/storage";
import ToastMessage from "../../components/toast/toastMessage";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import AppContext from "../../components/context/appContext";
import { updateBusinessData } from "../../modules/actions";
import { callApiGetBusinessData } from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class BuyerProtectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: null,
      userData: {},
      userBusinessData: [],
      websiteType: "other",
      /* eslint-disable */
      codeBox:
        '<script type="text/javascript">' +
        'var email = "__EMAIL__";' +
        'var name = "__NAME__";' +
        'var order_number = "";' +
        'var order_amount = "";' +
        'var address1 = "";' +
        'var address2 = "";' +
        'var city = "";' +
        'var state = "";' +
        'var zip = "";' +
        'var country = "";' +
        'var phone = "__PHONE__";' +
        'var key = "__CUSTOMER_KEY__";' +
        'var domain = "https://member.4xbuyerprotection.com/gener/capture.php?email="+email+"&name="+name+"&order_number="+order_number+"&order_amount="+order_amount+"&address1="+address1+"&address2="+address2+"&city="+city+"&state="+state+"&zip="+zip+"&country="+country+"&phone="+phone+"&key="+key;' +
        'document.write(\'<img src=""' +
        " +domain +" +
        '" height="1" width="1" />\');' +
        "</script>",
      /* eslint-enable */
      currentBusinessData: {},
      isDisabled: false,
      integrationStatus: false,
    };
  }

  async componentDidMount() {
    let tempBusiness = await callApiGetBusinessData(this.props.businessId);

    // console.log("B P F CDU", tempBusiness);

    this.setState({
      businessId: this.props.businessId,
      currentBusinessData: tempBusiness,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;

    // console.log("B P F CDU");

    if (prevState.businessId !== currentBusinessId) {
      let tempBusiness = await callApiGetBusinessData(currentBusinessId);

      // console.log("B P F CDU", tempBusiness);

      this.setState({
        businessId: currentBusinessId,
        currentBusinessData: tempBusiness,
      });
    }
  }

  clickWebsiteTypeRadio = (thisValue) => {
    document.getElementById(thisValue).click();
  };

  updateBusinessData = async () => {
    let tempBusinessData = await callApiGetBusinessData(this.state.businessId);
    const details4xData = tempBusinessData.details4X;

    if (details4xData) {
      Object.assign(details4xData, {
        integrationStatus: true,
        websiteType: this.state.websiteType,
      });

      this.props.updateBusinessData(this.state.businessId, {
        purpose: "buyerProtection",
        details4X: details4xData,
      });

      setTimeout(() => {
        window.location.href = "/buyer-protection";
      }, 2500);
    }
  };

  render() {
    const {
      websiteType,
      codeBox,
      currentBusinessData,
      isDisabled,
      integrationStatus,
    } = this.state;

    const apiKey =
      currentBusinessData &&
      currentBusinessData.hasOwnProperty("details4X") &&
      currentBusinessData.details4X &&
      currentBusinessData.details4X.hasOwnProperty("merchant") &&
      currentBusinessData.details4X.merchant &&
      currentBusinessData.details4X.merchant.hasOwnProperty("api_key") &&
      currentBusinessData.details4X.merchant.api_key
        ? currentBusinessData.details4X.merchant.api_key
        : "";

    // console.log("B P F", apiKey);

    return (
      <>
        <p style={{ marginBottom: "25px" }}>
          Welcome to Buyer Protection, where we insure your products. To get
          started please follow the instructions below.
        </p>
        <p>
          Implementing Buyer Protection is usually pretty easy but we need to
          add some code into your thank you page to get started.
        </p>
        <p>
          If you have any questions feel free to reach out to us at
          <b> support@trust-guard.com</b>
        </p>
        <p style={{ marginTop: "25px" }}>
          <b>Please select how your website is created:</b>
        </p>
        <p>
          {/* <input
                        id="rdoWebsiteTypeShopify"
                        type="radio"
                        name="websiteType"
                        value="shopify"
                        checked={websiteType === "shopify" ? true : false}
                        onChange={(evt) => {
                          this.setwebsiteType(evt);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                        onClick={() =>
                          this.clickWebsiteTypeRadio("rdoWebsiteTypeShopify")
                        }
                      >
                        Shopify
                      </label>
                      <br />
                      <br />
                      <input
                        id="rdoWebsiteTypeWooCommerce"
                        type="radio"
                        name="websiteType"
                        value="WooCommerce"
                        checked={websiteType === "WooCommerce" ? true : false}
                        onChange={(evt) => {
                          this.setwebsiteType(evt);
                        }}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        onClick={() =>
                          this.clickWebsiteTypeRadio(
                            "rdoWebsiteTypeWooCommerce"
                          )
                        }
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                      >
                        WooCommerce
                      </label>
                      <br />
                      <br /> */}
          <input
            id="rdoWebsiteTypeOther"
            type="radio"
            name="websiteType"
            value="other"
            checked={websiteType === "other" ? true : false}
            onChange={(evt) => {
              this.setState({
                websiteType: evt.target.value,
              });
            }}
            style={{ cursor: "pointer" }}
          />
          <label
            onClick={() => this.clickWebsiteTypeRadio("rdoWebsiteTypeOther")}
            style={{ cursor: "pointer", marginLeft: "15px" }}
          >
            Custom Website
          </label>
        </p>
        {websiteType === "other" ? (
          <div>
            <p style={{ marginTop: "25px" }}>
              <b>
                Please paste the following code into your websites thank you
                page:
              </b>
            </p>

            <p>
              <b>Note:</b> You will need to update the empty values with the
              customer information.
              <br />
              So for example you may need to update it like this.
              <br />
              <b>OLD:</b>var email = ""
              <br />
              <b>NEW:</b>var email = $customerEmail
            </p>
            <div
              className="well well-lg"
              style={{
                width: "750px",
                backgroundColor: "rgb(255, 255, 255)",
              }}
            >
              <textarea
                style={{
                  width: "100%",
                  height: "350px",
                }}
                readOnly="readonly"
                value={codeBox
                  .replace("__EMAIL__", "")
                  .replace("__NAME__", "")
                  .replace("__CUSTOMER_KEY__", apiKey)
                  .replace("__PHONE__", "")}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <p>
          <button
            className="btn-success btn-md btn"
            onClick={() => {
              this.updateBusinessData();
            }}
          >
            Completed
          </button>
        </p>
      </>
    );
  }
}

BuyerProtectionForm.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateBusinessData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerProtectionForm);
