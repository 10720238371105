import React from "react";
import { isMobile } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "../header";
import ManageYourDomains from "../../components/dashboard/manageYourDomains";
import WelcomePopup from "./welcomePopup";
import ScheduleAppointment from "./scheduleAppointment";
import AppContext from "../../components/context/appContext";
class ManageYourScans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header titlePage="Dashboard" bgColor="#ffffff" />

        <div id="mcontent-wrapper-dash">
          <div id="dash-malware-table">
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad 567">
              <div className="inner-ptab tab-content">
                <ManageYourDomains />
              </div>
            </div>
          </div>
        </div>
        {this.context.modalRescan ? <div className="backDropScan" /> : null}
        <ScheduleAppointment />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
ManageYourScans.contextType = AppContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageYourScans);
