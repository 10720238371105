import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { deleteBusinessUserData } from "../../modules/actions";

class DeleteEmailTemplateConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="modal fade"
        id="deleteEmailTemplateConfirmationModal"
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">Delete Email</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="pt-2 pb-4">
                <h5>Are you sure to delete this email?</h5>
              </div>
              <div className="modal-footer text-right pt-4 px-0 pb-3">
                <button
                  type="button"
                  className="btn-tglight2 btn-140-width btn-md  mr-2"
                  data-dismiss="modal"
                >
                  No
                </button>

                <button
                  type="button"
                  className="btn-success btn-140-width btn-md "
                  data-dismiss="modal"
                  onClick={() => this.props.deleteEmail()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteBusinessUserData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteEmailTemplateConfirmationModal);
