import React from "react";

const AppContext = React.createContext({
  userData: {},
  userBusinessData: [],
  hasPciScan: [],
  modalRescan: false,
  notificationsCount: 0,
  updateUserData: () => {},
  updateUserBusinessData: () => {},
  updateNotificationCount: () => {},
  currentBusinessId: null,
  updateCurrentBusinessId: () => {},
  reportType: null,
  updateReportType: () => {},
  showModalRescan: () => {},
  closeModalRescan: () => {},
  updateUserNotificationData: () => {},
});

export default AppContext;
