import React, { Component } from "react";
import Modal from "../../../ui/modal/modal";
import AppContext from "../../../../components/context/appContext";
import ToastMessage from "../../../../components/toast/toastMessage";
import {
  callFindMalwareVulnerabilityByReportUrl,
  callGetS3Data,
  callOnMalwareCreateEmailHistory,
  callUpdatereviewMalwareVulnerability,
} from "../../../../services/index";
import MalwareVulnerabilitiesData from "./partials/malwareVulnerabilitiesData";
import MalwareCleanData from "./partials/malwareCleanData";
import MalwareContentLoader from "./partials/malwareContentLoader";
import updateMalwareStatus from "../../../../helpers/malwareUpdateStatus";
import _ from "lodash";
import { isArray } from "util";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class ModalMalwareVulnerabilityInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      malwareData: [],
      vulnerabilities: 0,
      malwareInfoData: [],
      reportUrls: [],
      submitted: false,
      viewCodeDump: "none",
      updated: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.reportUrls !== this.state.reportUrls) {
      return true;
    }

    return false;
  }

  async componentWillReceiveProps(nextProps) {
    let reportUrls = [];
    if (nextProps.malwareInfoData !== this.state.malwareData) {
      for (let x = 0; x < nextProps.malwareInfoData.length; x++) {
        const reportUrl = nextProps.malwareInfoData[x].reportUrl;
        reportUrls.push(reportUrl);
      }
      this.setState({
        malwareData: nextProps.malwareInfoData,
        reportUrls: reportUrls,
        updated: reportUrls.length > 0 ? true : false,
      });
    }
  }

  onSubmitMalwareForReview = async (
    actionVul,
    malwareVulnerabilityId,
    businessId,
    reportUrl
  ) => {
    let actionVulToast = actionVul;

    let vulReport = await callFindMalwareVulnerabilityByReportUrl(
      malwareVulnerabilityId,
      businessId
    );

    if (vulReport.data.length > 0) {
      let actionVulnerability = false;

      if (actionVul === "Sent for review") {
        actionVulnerability = true;
      } else {
        actionVulnerability = false;
      }

      const malwareVulnerabilityData = {
        sentForReview: actionVulnerability,
        dateSentForReview: Date.now,
      };

      callUpdatereviewMalwareVulnerability(
        vulReport.data[0]._id,
        malwareVulnerabilityData
      );
    }

    let userId = null;
    let userData = window.localStorage.getItem("userData");
    let userString = cryptr.decrypt(userData);
    let user = JSON.parse(userString);
    if (userData) {
      userId = user._id;
    }

    const awsAddress = "https://tg3malware.s3.amazonaws.com/" + reportUrl;

    let emailHistoryData = {
      action: "create_email_history_from_malware",
      businessId: businessId,
      customMessage: awsAddress,
      userId: user.userId,
      reportUrl: reportUrl,
      malwareVulnerabilityId: malwareVulnerabilityId,
    };

    await callOnMalwareCreateEmailHistory(emailHistoryData);

    if (actionVulToast === "Sent for review") {
      this.setState({
        submitted: true,
        actionValue: "Support " + actionVulToast,
      });
    } else {
      this.setState({
        submitted: false,
        actionValue: "",
      });
    }
  };

  async onChangeSetMalwareVul(
    e,
    reportUrl,
    threat,
    fileName,
    scanStatus,
    malwareHistoryID,
    domainId,
    dumpMD5,
    businessId
  ) {
    await updateMalwareStatus(
      e,
      reportUrl,
      threat,
      fileName,
      scanStatus,
      malwareHistoryID,
      domainId,
      dumpMD5,
      businessId
    );
    window.location.reload();
  }

  render() {
    const { targetID, index, sentForReview, malwareInfoData } = this.props;
    const { malwareData, submitted, actionValue } = this.state;
    const { userBusinessData } = this.context;
    const business = userBusinessData[index];
    let content = <MalwareContentLoader />;
    let alert = "";
    const cleanFiles = [];
    const vulnerabilityFiles = [];

    if (malwareData) {
      if (malwareData.length > 0) {
        malwareData.map((malware, i) => {
          if (malware.status === "clean") {
            content = [];
            cleanFiles.push({
              totalIgnoredFiles: malware.ignoredVulnerabilities.length,
              primaryDomain: malware.host,
              vulnerabilities: malware.vulnerabilityInfo,
              reportUrl: malware.reportUrl,
              malwareHistoryId: targetID,
              malwareRules:
                business.hasOwnProperty("malwareRules") !== undefined
                  ? business.malwareRules
                  : null,
              businessId: business._id,
              domainId: malware.domainId,
            });
          } else {
            if (malware.status !== "clean") {
              vulnerabilityFiles.push({
                businessId: business._id,
                primaryDomain: malware.host,
                domainId: malware.domainId,
                malwareHistoryId: targetID,
                reportUrl: malware.reportUrl,
                status: malware.status,
                totalIgnoredFiles: malware.hasOwnProperty(
                  "ignoredVulnerabilities"
                )
                  ? malware.ignoredVulnerabilities.length
                  : 0,
                index: i,
                malwareInfoData: this.state.malwareData,
                malwareRules:
                  business.hasOwnProperty("malwareRules") !== undefined
                    ? business.malwareRules
                    : null,
                vulnerabilities: malware,
                sentForReview: malware.sentForReview,
                submitMalwareForReview: this.onSubmitMalwareForReview,
                updateMalwareStatus: this.onChangeSetMalwareVul,
              });
            }
          }
        });
      } //end check malwareData.length
    } //end check malwareData

    return (
      <>
        <Modal
          targetID={"malware-modal-" + targetID}
          title="Vulnerabilities"
          largeModal>
          <div className="scrollbar" id="style-1">
            <div className="force-overflow">
              {vulnerabilityFiles.length > 0
                ? vulnerabilityFiles.map((vulnerability, i) => (
                    <MalwareVulnerabilitiesData
                      businessId={vulnerability.businessId}
                      primaryDomain={vulnerability.primaryDomain}
                      domainId={vulnerability.domainId}
                      malwareHistoryId={vulnerability.malwareHistoryId}
                      reportUrl={vulnerability.reportUrl}
                      status={vulnerability.status}
                      totalIgnoredFiles={vulnerability.totalIgnoredFiles}
                      index={vulnerability.index}
                      malwareInfoData={vulnerability.malwareInfoData}
                      malwareRules={vulnerability.malwareRules}
                      vulnerabilities={vulnerability.vulnerabilities}
                      sentForReview={vulnerability.sentForReview}
                      submitMalwareForReview={
                        vulnerability.submitMalwareForReview
                      }
                      updateMalwareStatus={vulnerability.updateMalwareStatus}
                    />
                  ))
                : cleanFiles.length > 0
                  ? cleanFiles.map((malware, i) => (
                      <MalwareCleanData
                        index={i}
                        totalIgnoredFiles={malware.totalIgnoredFiles}
                        primaryDomain={malware.primaryDomain}
                        vulnerabilities={malware.vulnerabilities}
                        reportUrl={malware.reportUrl}
                        malwareHistoryId={malware.malwareHistoryId}
                        malwareRules={malware.malwareRules}
                        businessId={malware.businessId}
                        domainId={malware.domainId}
                      />
                    ))
                  : content}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

ModalMalwareVulnerabilityInfo.contextType = AppContext;
export default ModalMalwareVulnerabilityInfo;
