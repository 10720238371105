import React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import ContentLoader from 'react-content-loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

import config from '../../helpers/config';
import { images } from '../../helpers/images';
import { getStorageItem } from '../../helpers/storage';
import Aux from '../../components/hoc/containerAux';
import Spinner from '../../components/ui/spinner/spinnerRectScan';
import AppContext from '../../components/context/appContext';
import TrustSealsInfo from '../modals/trustSealsInfo';
import TrustSealsCreatePreset from '../modals/trustSealsCreatePreset';
import TrustSealsDeletePresetConfirmation from '../modals/trustSealsDeletePresetConfirmation';
import ColorPicker from '../../components/color/colorPicker';
import SealGoogleTagManagerCnfModal from '../modals/sealGoogleTagManagerCnfModal';
import ToastNotification from '../../components/toast/toastNotification';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';

import {
  callApiGetBusinessData,
  callApiGetTrustSealsData,
  callApiCreateTrustSealsData,
  callApiUpdateTrustSealsData,
  callApiPatchTrustSealsData,
  callApiCreateMissingTrustSeals,
  callApiGetCssJsHashList,
  callApiGetFontColor
} from '../../services';
import { A } from 'glamorous';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('trustguardapiapp');

const sliderStyle = {
  position: 'relative',
  width: '95%',
  height: 80,
  border: 'none'
};

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 8,
  marginTop: 35,
  borderRadius: 5,
  backgroundColor: '#C3C4C6'
};

export function Handle({ handle: { id, value, percent }, getHandleProps, sealType = null }) {
  return (
    <div
      style={{
        left: `${percent - 2}%`,
        position: 'absolute',
        marginLeft: 0,
        marginTop: 26,
        zIndex: 2,
        width: 23,
        height: 23,
        border: 0,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: '#333333',
        color: '#333'
      }}
      {...getHandleProps(id)}>
      {/* <div style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -35 }}>
        {(sealType === 'textShield' &&
          ((value === 0 && 'Small') || (value === 2 && 'Medium') || (value === 4 && 'Large'))) ||
          value + 'px'}
      </div> */}
    </div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: 'absolute',
        height: 10,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: '#333333',
        borderRadius: 5,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`
      }}
      {...getTrackProps()}
    />
  );
}

const renderTooltip = props => (
  <Tooltip id="tooltip" {...props}>
    This will be displayed on your seal for up to three seconds before disappearing, followed by the
    date.
  </Tooltip>
);

class SealComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      businessId: null,
      codeGenerated: false,
      businessData: '',
      certificateURL: 'CERTIFICATE_PATH/certificate/PRIMARY_DOMAIN',
      sealCode: {
        /*eslint-disable*/
        fixed:
          '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART <div _ID class="_TG_SEALCODE_CLASS _ONE"_POSITION>_STANDALONE_ANCHOR_START<img alt="Trust Guard Security Scanned" _IMG_ID class="tgfloat-inner" src="_THREE" style="border: 0; _SIZE " _ONCONTEXTMENU />_STANDALONE_ANCHOR_END</div> _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',

        custom:
          '<a name="trustlink" href="_CERTIFICATE_URL" rel="nofollow" target="_blank" onclick="var nonwin=navigator.appName!=\'Microsoft Internet Explorer\'?\'yes\':\'no\'; window.open(this.href,\'welcome\',\'location=\'+nonwin+\',scrollbars=yes,width=517,height=\'+screen.availHeight+\',menubar=no,toolbar=no\'); return false;" oncontextmenu="var d = new Date(); alert(\'Copying Prohibited by Law - This image and all included logos are copyrighted by trust-guard \\251 \'+d.getFullYear()+\'.\'); return false;" > <img name="trustseal" alt="Security Seals" style="border: 0;" src="_BUSINESS_SEAL" /></a>',
        /*eslint-enable*/
        textboxCode: ''
      },
      trustSealsData: null,
      presetType: null,
      presetName: '',
      label: '',
      color: 'green',
      sealType: 'outer',
      floatSide: 'left',
      width: '225',
      distanceFromEdge: '0',
      colorSettings: {
        green: {
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        black: {
          placeholderShieldOuter: '#ffffff',
          placeholderShieldInner: '#333333',
          placeholderShieldLeft: '#66CC3E', //
          placeholderShieldRight: '#33981F', //
          placeholderBodyLeft: '#333333',
          placeholderBodyRight: '#434343',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#333333',
          placeholderShieldTop: '#333333',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#333333',
          placeholderSealBorder: '#333333',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        white: {
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#f2f2f2',
          placeholderBodyRight: '#ffffff',
          placeholderStatusColor: '#333333',
          placeholderDateColor: '#333333',
          placeholderLabelColor: '#333333',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#333333',
          placeholderSecuredText: '#333333',
          placeholderShieldBg: '#FFFFFF',
          placeholderShieldTop: '#FFFFFF',
          placeholderPrimaryLayer: '#333333',
          placeholderSecondaryLayer: '#333333',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#f5f5f5',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        grey: {
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#f2f2f2',
          placeholderBodyRight: '#ffffff',
          placeholderStatusColor: '#333333',
          placeholderDateColor: '#333333',
          placeholderLabelColor: '#333333',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#333333',
          placeholderSecuredText: '#333333',
          placeholderShieldBg: '#F2F2F2',
          placeholderShieldTop: '#FFFFFF',
          placeholderPrimaryLayer: '#333333',
          placeholderSecondaryLayer: '#333333',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#f5f5f5',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        custom: {
          placeholderShieldOuter: '#ffffff',
          placeholderShieldInner: '#333333',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#333333',
          placeholderBodyRight: '#434343',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#333333',
          placeholderShieldTop: '#333333',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#333333',
          placeholderSealBorder: '#333333',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        }
      },
      selectedPresetId: null,
      newPresetName: '',
      isSealSaved: false,
      trustSealPath: null,
      demoTrustSealPath: null,
      trustSealPathForWebsiteCode: null,
      isTextElementChanged: false,
      isCopied: false,
      trustsealHost: config.seals.sealsHost,
      displayBlock: 'none',
      changingSealParameter: '',
      conditionalNotificationParameters: ['sealType', 'floatSide', 'distanceFromEdge'],
      sealScriptsCss: '',
      sealScriptsJs: '',
      disableFloatSide: false,
      displayOnChangeSealModal: true,
      sealMaxWidth: 175,
      sealMaxWidthOnMobile: 145,
      showMobileSeal: false,
      userInfoText: null,
      sealFontFamily: 'roboto',
      sealFontSize: '16',
      includeGoogleRobotoFont: true,
      sealFontColor: '#0066C0',
      isGoogleTagManagerEnabled: false,
      sealTypeToggle: 'close',
      sealTypeOptions: {
        outer: 'Classic Bump Out Seal',
        inner: 'Classic Seal',
        checkout: 'Banner Checkout',
        side: 'Side Shield',
        insurance: 'Buyer Protection Banner',
        textShield: 'Secure Transaction',
        premium: 'Premium',
        premiumMini: 'Premium Mini',
        premiumCheckout: 'Premium Checkout',
        premiumCheckoutMini: 'Premium Checkout Mini'
      },
      sealFloatSideToggle: 'close',
      sealFloatSideOptions: {
        left: 'Left',
        right: 'Right',
        standAlone: 'Stand Alone'
      },
      sealFontToggle: 'close',
      sealFontOptions: {
        roboto: 'Roboto',
        arial: 'Arial'
      },
      sealFontSizeToggle: 'close',
      sealFontSizeOptions: {
        '12px': '12',
        '13px': '13',
        '14px': '14',
        '15px': '15',
        '16px': '16',
        '17px': '17',
        '18px': '18',
        '19px': '19',
        '20px': '20'
      },
      sealIcon: 'shield',
      sealIconToggle: 'close',
      sealIconOptions: {
        shield: 'Shield',
        pulse: 'Pulse'
      },
      editPresetName: false,
      isDefault: false,
      customColor: null,
      customTextColor: null,
      iconColor: null,
      iconCustomColor: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    // this.handleCustomSelectChange = this.handleCustomSelectChange.bind(this);

    this.saveSealFontColor = this.saveSealFontColor.bind(this);
  }

  async componentDidMount() {
    // console.log('componentDidMount');

    await this.getTrustsealsData();
    // window.addEventListener('DOMContentLoaded', this.handleDocumentClick);
    document.addEventListener('click', this.handleDocumentClick);
    this.setState({
      isLoading: false
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate');

    const { currentBusinessId } = this.context;
    let currentUrl = window.location.href;
    let targetPage = currentUrl.split('/');

    if (targetPage[targetPage.length - 1] !== 'trust-seals') {
      if (currentBusinessId !== null && prevState.businessId !== currentBusinessId) {
        await this.getTrustsealsData();
      }
    } else {
    }
  }

  handleDocumentClick = event => {
    let parentElem, childDropDownImg, childDropUpImg;

    // Check if the clicked element or any of its parents has the exclude class
    let target = event.target;
    while (target && target !== document) {
      if (target.id === 'sealType' || target.id === 'sealIcon' || target.id === 'sealPlacement') {
        // Click occurred on or within an excluded element, so return
        return;
      }

      if (target.classList && target.classList.contains('sealType')) {
        return;
      }

      if (target.classList && target.classList.contains('sealIcon')) {
        return;
      }

      if (target.classList && target.classList.contains('sealPlacement')) {
        return;
      }

      target = target.parentNode;
    }

    if (
      document.getElementById('sealType') &&
      document.getElementById('sealType').style.display !== 'none' &&
      document.getElementById('sealType').style.visibility !== 'hidden'
    ) {
      document.getElementById('sealType').style.display = 'none';

      parentElem = document.getElementsByClassName('sealType')[0];
      childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
      childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

      childDropUpImg.style.display = 'none';
      childDropDownImg.style.display = 'block';

      this.setState({
        sealTypeToggle: 'close'
      });
    }

    if (
      document.getElementById('sealIcon') &&
      document.getElementById('sealIcon').style.display !== 'none' &&
      document.getElementById('sealIcon').style.visibility !== 'hidden'
    ) {
      document.getElementById('sealIcon').style.display = 'none';

      parentElem = document.getElementsByClassName('sealIcon')[0];
      childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
      childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

      childDropUpImg.style.display = 'none';
      childDropDownImg.style.display = 'block';

      this.setState({
        sealIconToggle: 'close'
      });
    }

    if (
      document.getElementById('sealPlacement') &&
      document.getElementById('sealPlacement').style.display !== 'none' &&
      document.getElementById('sealPlacement').style.visibility !== 'hidden'
    ) {
      document.getElementById('sealPlacement').style.display = 'none';

      parentElem = document.getElementsByClassName('sealPlacement')[0];
      childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
      childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

      childDropUpImg.style.display = 'none';
      childDropDownImg.style.display = 'block';

      this.setState({
        sealFloatSideToggle: 'close'
      });
    }

    // console.log('Document clicked outside excluded elements');
  };

  getTrustsealsData = async () => {
    // console.log('calling getTrustsealsData');
    let businessId = null;
    const { currentBusinessId } = this.context;

    if (currentBusinessId) {
      businessId = currentBusinessId;
    } else {
      businessId = getStorageItem('businessId')
        ? cryptr.decrypt(getStorageItem('businessId'))
        : null;
    }

    if (businessId === null || businessId === undefined) {
      this.props.goToDashboard();
    }

    if (businessId) {
      const businessData = await callApiGetBusinessData(businessId);

      if (
        businessData &&
        (businessData.accountType !== 'free' && businessData.accountType !== 'monthly-scan')
      ) {
        // console.log('1.1');

        this.setState({
          businessId: businessId,
          businessData: businessData
        });

        let trustSealsData = await callApiGetTrustSealsData(businessId);

        // console.log(2025, JSON.stringify(trustSealsData.data.sealSettings[5]));

        if (trustSealsData.data === null) {
          // console.log('Certainly NOT Here');

          const sealData = this.populateInitialSealsData();
          trustSealsData = await callApiCreateTrustSealsData({
            businessId,
            sealData
          });

          toast.success('Preset created successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        } else {
          // console.log('Certainly Here');
        }

        const cssJsHashList = await callApiGetCssJsHashList();

        if (cssJsHashList && cssJsHashList.data && cssJsHashList.data.length > 0) {
          let cssData = cssJsHashList.total > 0 ? cssJsHashList.data[0].css : null;
          let jsData = cssJsHashList.total > 0 ? cssJsHashList.data[0].js : null;

          this.setState({
            sealScriptsCss: cssData[cssData.length - 1],
            sealScriptsJs: jsData[jsData.length - 1]
          });
        }

        this.setTrustSealsUiData(trustSealsData);
      } else {
        this.props.goToDashboard();
      }
    }
  };

  setTrustSealsUiData = trustSealsData => {
    // console.log('setTrustSealsUiData', trustSealsData);
    let certificatePathUrl = config.seals.certificateHost;
    let targetIndex = null;
    let selectedPresetId = null;

    targetIndex = trustSealsData ? trustSealsData.data.sealSettings.length - 1 : 0;
    selectedPresetId = trustSealsData ? trustSealsData.data.sealSettings[targetIndex]._id : null;

    if (this.state.selectedPresetId) {
      selectedPresetId = this.state.selectedPresetId;

      if (trustSealsData && trustSealsData.data.sealSettings.length) {
        for (let i = 0; i < trustSealsData.data.sealSettings.length; i++) {
          if (trustSealsData.data.sealSettings[i]._id === this.state.selectedPresetId) {
            targetIndex = i;
          }
        }
      }
    } else {
      /* console.log(
        "Else selectedPresetId",
        selectedPresetId,
        trustSealsData.data.sealSettings[targetIndex].sealFontFamily
      ); */
    }

    /* console.log(
      "trustSealsData.data.sealSettings[targetIndex].sealFontColor",
      trustSealsData.data.sealSettings[targetIndex].sealFontColor
    ); */

    // console.log("trustSealsData", trustSealsData);

    /* if (
      trustSealsData.data.sealWidth &&
      trustSealsData.data.sealWidth.desktopWidth
    ) {
      console.log(
        "trustSealsData.data.sealWidth.desktopWidth",
        trustSealsData.data.sealWidth.desktopWidth
      );
    } else {
      console.log("ELSE", trustSealsData.data.sealSettings[targetIndex]);
    } */

    let tempColorSettings = this.state.colorSettings;

    tempColorSettings[trustSealsData.data.sealSettings[targetIndex].color.title] =
      trustSealsData.data.sealSettings[targetIndex].color;

    this.setState(
      {
        trustSealsData: trustSealsData.data,
        selectedPresetId: selectedPresetId,
        trustSealPath: null,
        demoTrustSealPath: null,
        isDefault: trustSealsData.data.sealSettings[targetIndex].isDefault,
        presetType: trustSealsData.data.sealSettings[targetIndex].presetType,
        presetName: trustSealsData.data.sealSettings[targetIndex].presetName,
        label: trustSealsData.data.sealSettings[targetIndex].label,
        color: trustSealsData.data.sealSettings[targetIndex].color.title,
        colorSettings: tempColorSettings,
        sealType: trustSealsData.data.sealSettings[targetIndex].sealType,
        floatSide: trustSealsData.data.sealSettings[targetIndex].floatSide,
        width: trustSealsData.data.sealSettings[targetIndex].width,
        distanceFromEdge: trustSealsData.data.sealSettings[targetIndex].distanceFromEdge,
        isSealSaved: false,
        certificateURL: this.state.certificateURL.replace(
          'PRIMARY_DOMAIN',
          this.state.businessData.primaryDomain
        ),
        sealMaxWidth:
          trustSealsData.data.sealSettings[targetIndex].sealWidth &&
          trustSealsData.data.sealSettings[targetIndex].sealWidth.desktopWidth
            ? trustSealsData.data.sealSettings[targetIndex].sealWidth.desktopWidth
            : (trustSealsData.data.sealSettings[targetIndex].sealType === 'textShield' && 0) || 175,
        sealMaxWidthOnMobile:
          trustSealsData.data.sealSettings[targetIndex].sealWidth &&
          trustSealsData.data.sealSettings[targetIndex].sealWidth.mobileWidth
            ? trustSealsData.data.sealSettings[targetIndex].sealWidth.mobileWidth
            : 145,
        sealFontFamily:
          (trustSealsData.data.sealSettings[targetIndex].sealFontFamily &&
            trustSealsData.data.sealSettings[targetIndex].sealFontFamily) ||
          '',
        sealFontSize:
          (trustSealsData.data.sealSettings[targetIndex].sealFontSize &&
            trustSealsData.data.sealSettings[targetIndex].sealFontSize) ||
          '16',
        sealFontColor:
          (trustSealsData.data.sealSettings[targetIndex].sealFontColor &&
            trustSealsData.data.sealSettings[targetIndex].sealFontColor) ||
          '#0066C0',
        includeGoogleRobotoFont:
          ((trustSealsData.data.sealSettings[targetIndex].includeGoogleRobotoFont &&
            trustSealsData.data.sealSettings[targetIndex].includeGoogleRobotoFont) === true &&
            true) ||
          null,
        iconColor: trustSealsData.data.sealSettings[targetIndex].iconColor,
        iconCustomColor: trustSealsData.data.sealSettings[targetIndex].iconCustomColor,
        customColor: trustSealsData.data.sealSettings[targetIndex].color.placeholderShieldBg
      },
      () => {
        // console.log("state ...", this.state);
      }
    );

    setTimeout(() => {
      let tempPresetSealImage =
        trustSealsData.data.sealSettings[targetIndex]._id + '.svg?v=' + new Date().getTime();

      let tempPresetSealImageForWebsiteCode =
        trustSealsData.data.sealSettings[targetIndex]._id + '.svg';

      this.setState(
        {
          trustSealPath:
            this.state.trustsealHost +
            '/sites/' +
            this.state.businessData.primaryDomain +
            '/' +
            tempPresetSealImage,
          trustSealPathForWebsiteCode:
            this.state.trustsealHost +
            '/sites/' +
            this.state.businessData.primaryDomain +
            '/' +
            tempPresetSealImageForWebsiteCode,
          demoTrustSealPath:
            this.state.trustsealHost +
            '/sites/' +
            this.state.businessData.primaryDomain +
            '/' +
            'demo-' +
            tempPresetSealImage,
          certificateURL: this.state.certificateURL.replace('CERTIFICATE_PATH', certificatePathUrl)
        },
        () => {
          this.generateSealWebsiteCode();
        }
      );
    }, 2500);
  };

  handleSwitchChange(event) {
    this.setState({ showMobileSeal: !this.state.showMobileSeal });
  }

  handleInputChange(name, value) {
    let runCommonUpdate = true;
    /* if (name === "includeGoogleRobotoFont") {
      console.log("includeGoogleRobotoFont");
      console.log("value", value);
      console.log("name", name);
    } */

    // console.log('handleInputChange name value', name, value);

    // character limit for website name on seal
    if (name === 'label' && value.length > 25) {
      // console.log(5555);

      toast.error('Website name can not be greater than 25 characters.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
        toastId: 'customId'
      });

      value = value.slice(0, 25);
    }

    let thisUserInfoText = '';

    if (name === 'sealType') {
      this.setState({
        sealMaxWidth: (this.state.sealType === 'textShield' && 0) || 175
      });
    }

    if (name === 'sealType' && (value === 'checkout' || value === 'textShield')) {
      this.setState({
        floatSide: 'right'
      });
    }

    if (name === 'sealType' && value === 'insurance') {
      this.setState(
        {
          disableFloatSide: true,
          floatSide: 'none',
          sealMaxWidth: 225
        },
        () => {
          // console.log("textShield", this.state.floatSide);
        }
      );
    } else {
      this.setState({
        disableFloatSide: false
      });
    }

    if (this.state.conditionalNotificationParameters.indexOf(name) !== -1) {
      thisUserInfoText =
        'Your seal has been updated. Please note you must update the code on your website for the seal to display properly.';
    }

    if (name === 'color' && (value === 'black' || value === 'white')) {
      this.setState({
        sealFontColor: '#ffffff',
        iconColor: 'green'
      });
    } else {
      this.setState({
        sealFontColor: '#0066C0'
      });
    }

    if (name === 'color' && (value === 'black' || value === 'white' || value === 'grey')) {
      this.setState({
        iconColor: 'green'
      });
    }

    if (
      name === 'sealType' &&
      (value === 'premium' || value === 'premiumMini') &&
      this.state.color === 'green'
    ) {
      this.setState({
        color: 'black'
      });
    }

    if (
      name === 'color' &&
      (value !== 'green' && value !== 'black' && value !== 'white' && value !== 'grey')
    ) {
      runCommonUpdate = false;
      this.setState(
        {
          color: 'custom',
          customColor: value,
          userInfoText: thisUserInfoText,
          isTextElementChanged: true,
          changingSealParameter: name
        },
        () => {
          this.saveSeal();
        }
      );
    }

    if (name === 'iconColor' && (value !== 'green' && value !== 'blue' && value !== 'black')) {
      // console.log('INSIDE ICON CUSTOM COLOR', value);

      runCommonUpdate = false;
      this.setState(
        {
          iconColor: 'custom',
          iconCustomColor: value,
          userInfoText: thisUserInfoText,
          isTextElementChanged: true,
          changingSealParameter: name
        },
        () => {
          // console.log('ICON COLOR', this.state.iconColor);
          // console.log('ICON CUSTOM COLOR', this.state.iconCustomColor);

          this.saveSeal();
        }
      );
    }

    if (name === 'iconColor' && value === 'blue') {
      value = '#2E90FA';
    }

    if (runCommonUpdate === true) {
      // console.log('handle input change Hello Mridul ....RUN COMMON UPDATE TRUE');

      this.setState(
        {
          [name]: value,
          iconCustomColor: '',
          userInfoText: thisUserInfoText,
          isTextElementChanged: true,
          changingSealParameter: name
        },
        () => {
          // console.log("this.state", this.state);
          switch (name) {
            case 'color':
            case 'sealType':
            case 'sealFontFamily':
            case 'sealFontSize':
            case 'floatSide':
            case 'sealIcon':
            case 'iconColor':
              this.saveSeal();
              break;
            default:
              break;
          }

          if (name === 'sealType' || name === 'floatSide') {
            document.getElementById('btnDisplayModalSealInfo').click();
          }
        }
      );
    }
  }

  showActivePresetContent = (sealSettings, i) => {
    // console.log('showActivePresetContent sealSettings', i, sealSettings);
    var ul = document.getElementById('sealPresetUl');
    var items = ul.getElementsByTagName('li');

    for (var i = 0; i < items.length; ++i) {
      items[i].classList.remove('active');
    }

    this.setState(
      {
        isDefault: sealSettings.isDefault,
        presetType: sealSettings.presetType,
        presetName: sealSettings.presetName,
        trustSealPath:
          this.state.trustsealHost +
          '/sites/' +
          this.state.businessData.primaryDomain +
          '/' +
          sealSettings._id +
          '.svg?v=' +
          new Date().getTime(),
        trustSealPathForWebsiteCode:
          this.state.trustsealHost +
          '/sites/' +
          this.state.businessData.primaryDomain +
          '/' +
          sealSettings._id +
          '.svg',
        demoTrustSealPath:
          this.state.trustsealHost +
          '/sites/' +
          this.state.businessData.primaryDomain +
          '/' +
          'demo-' +
          sealSettings._id +
          '.svg',
        selectedPresetId: sealSettings._id,
        label: sealSettings.label,
        color: sealSettings.color.title,
        sealType: sealSettings.sealType,
        floatSide: sealSettings.floatSide,
        width: sealSettings.width,
        distanceFromEdge: sealSettings.distanceFromEdge,
        // SET SEAL MAX WIDTH
        sealMaxWidth: sealSettings.sealWidth && sealSettings.sealWidth.desktopWidth,
        sealFontFamily: (sealSettings.sealFontFamily && sealSettings.sealFontFamily) || 'arial',
        sealFontSize: (sealSettings.sealFontSize && sealSettings.sealFontSize) || '16',
        sealFontColor: (sealSettings.sealFontColor && sealSettings.sealFontColor) || '#0066C0',
        includeGoogleRobotoFont:
          (sealSettings.includeGoogleRobotoFont && sealSettings.includeGoogleRobotoFont) || false
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );

    document.getElementById(sealSettings._id).classList.add('active');
  };

  generateSealWebsiteCode = () => {
    /* console.log(
      "generateSealWebsiteCode this.state.sealMaxWidth",
      this.state.sealMaxWidth
    ); */
    let websiteSealCode = null;

    switch ('fixed') {
      case 'fixed':
        websiteSealCode = this.state.sealCode.fixed;
        let sealOffset = '';
        let tgScript = 'tgscript-v' + this.state.sealScriptsJs.version;
        let tgScriptHash = 'sha384-' + this.state.sealScriptsJs.hash;

        let tgCss = config.seals.seal_css_version.replace(
          '_STYLE',
          'cert-style-v' + this.state.sealScriptsCss.version + '.css'
        );
        let tgCssHash = 'sha384-' + this.state.sealScriptsCss.hash;

        let sealPlacement = '';

        /* console.log("====================================");
        console.log(this.state.floatSide);
        console.log("===================================="); */

        if (
          (this.state.floatSide === 'left' || this.state.floatSide === 'right') &&
          this.state.floatSide !== 'standAlone' &&
          this.state.sealType !== 'textShield'
        ) {
          //console.log("IF");
          /* if (this.state.sealType !== "checkout") { */
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART',
            '<link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous"><style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx !important; }}</style>'
          );
          /* } */
          websiteSealCode = websiteSealCode.replace(
            '_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',
            '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" _INTEGRITY_SCRIPT crossorigin="anonymous"></script><script>var tg_div = document.createElement("div");tg_div.innerHTML = _TG_SCRIPT_FOR_DOCUMENT_WRITE;document.body.appendChild(tg_div);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script>'
          );
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace('_TG_SCRIPT', tgScript + '.js');
          /* websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_HASH",
            tgScriptHash
          ); */
          websiteSealCode = websiteSealCode.replace('_BUSINESS_ID', this.state.businessId);

          websiteSealCode = websiteSealCode.replace('_TG_CSS', tgCss);
          //websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);
          websiteSealCode = websiteSealCode.replace('_CERTIFICATE_URL', '');

          let floatSideType = this.state.floatSide;
          let documentScript = '';
          let placementType = '';

          switch (this.state.sealType) {
            case 'outer':
              if (this.state.floatSide === 'left') {
                floatSideType = 'bleft';
                sealPlacement = 'tgBleft';
                documentScript = 'tg_script_bottom_left';
                placementType = 'bottom_left';
              } else if (this.state.floatSide === 'right') {
                floatSideType = 'bright';
                sealPlacement = 'tgBright';
                documentScript = 'tg_script_bottom_right';
                placementType = 'bottom_right';
              }

              break;
            case 'inner':
              if (this.state.floatSide === 'left') {
                floatSideType = 'bleft';
                sealPlacement = 'tgBleft';
                documentScript = 'tg_script_bottom_left';
                placementType = 'bottom_left';
              } else if (this.state.floatSide === 'right') {
                floatSideType = 'bright';
                sealPlacement = 'tgBright';
                documentScript = 'tg_script_bottom_right';
                placementType = 'bottom_right';
              }

              break;

            case 'side':
              if (this.state.floatSide === 'left') {
                floatSideType = 'left';
                sealPlacement = 'tgLeft';
                documentScript = 'tg_script_side_left';
                placementType = 'side_left';
              } else if (this.state.floatSide === 'right') {
                floatSideType = 'right';
                sealPlacement = 'tgRight';
                documentScript = 'tg_script_side_right';
                placementType = 'side_right';
              }

              websiteSealCode = websiteSealCode.replace(
                '_IMG_ID',
                'id="tg_1-' + floatSideType + '"'
              );

              break;

            default:
              break;
          }

          websiteSealCode = websiteSealCode.replace('_PLACEMENT_TYPE', placementType);
          /*eslint-disable*/

          /* websiteSealCode = websiteSealCode.replace(
            "_IMG_ID",
            'id="tg_1-' + floatSideType + '"'
          ); */

          websiteSealCode = websiteSealCode.replace('_IMG_ID', '');

          if (this.state.sealType !== 'side') {
            websiteSealCode = websiteSealCode.replace(
              'class="tgfloat-inner"',
              'class="tgfloat-inner ' + 'tg_1-' + floatSideType + '"'
            );
          }
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace(
            '_TG_SCRIPT_FOR_DOCUMENT_WRITE',
            documentScript
          );
        } else {
          //console.log("ELSE");
          if (this.state.sealType !== 'checkout' && this.state.sealType !== 'textShield') {
            // console.log("INSIDE TEXT SHIELD 1");
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ',
              '<style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx; }}</style>'
            );
            /*eslint-enable*/
          } else {
            if (this.state.sealType === 'checkout' || this.state.sealType === 'textShield') {
              // console.log("INSIDE TEXT SHIELD ****");

              /*eslint-disable*/
              // <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto">
              if (this.state.sealType !== 'checkout' && this.state.includeGoogleRobotoFont) {
                // console.log("Inside includeGoogleRobotoFont includeGoogleRobotoFont");
                websiteSealCode = websiteSealCode.replace(
                  '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART',
                  '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
                    this.state.sealFontFamily.charAt(0).toUpperCase() +
                    this.state.sealFontFamily.slice(1) +
                    '"><link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous">'
                );
              } else {
                websiteSealCode = websiteSealCode.replace(
                  '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART',
                  '<link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous">'
                );
              }

              websiteSealCode = websiteSealCode.replace(
                '_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',
                '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" _INTEGRITY_SCRIPT crossorigin="anonymous"></script><script>var tg_div = document.createElement("div");tg_div.innerHTML = _TG_SCRIPT_FOR_DOCUMENT_WRITE;document.body.appendChild(tg_div);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script>'
              );

              websiteSealCode = websiteSealCode.replace(' _IMG_ID', ' id="tgfloat-img"');
              /*eslint-enable*/
            } else {
              websiteSealCode = websiteSealCode.replace(
                '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ',
                ''
              );
            }
          }

          websiteSealCode = websiteSealCode.replace(' _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART', '');

          websiteSealCode = websiteSealCode.replace('_CERTIFICATE_URL', this.state.certificateURL);

          websiteSealCode = websiteSealCode.replace(' _IMG_ID', '');
        }

        if (this.state.sealType !== 'checkout') {
          websiteSealCode = websiteSealCode.replace(
            '_SEAL_WIDTH_FOR_MOBILE',
            this.state.sealMaxWidthOnMobile
          );
        }

        if (this.state.sealType !== 'side') {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, 'width');
        } else {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, 'height');
        }

        websiteSealCode = websiteSealCode.replace('_ONE', sealPlacement);

        if (this.state.floatSide === 'left') {
          websiteSealCode = websiteSealCode.replace('_TWO', 'left');
          websiteSealCode = websiteSealCode.replace(
            '_THREE',
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace('_FOUR', 'left:');
          websiteSealCode = websiteSealCode.replace('_FIVE', this.state.distanceFromEdge + 'px;');
          websiteSealCode = websiteSealCode.replace('_SIX', this.state.width + 'px;');

          sealOffset = 'left:' + this.state.distanceFromEdge + 'px;';
        } else {
          websiteSealCode = websiteSealCode.replace('_TWO', 'right');
          websiteSealCode = websiteSealCode.replace(
            '_THREE',
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace('_FOUR', 'right:');
          websiteSealCode = websiteSealCode.replace('_FIVE', this.state.distanceFromEdge + 'px;');
          websiteSealCode = websiteSealCode.replace('_SIX', this.state.width + 'px;');

          sealOffset = 'right:' + this.state.distanceFromEdge + 'px;';
        }

        if (
          (this.state.sealType === 'outer' || this.state.sealType === 'inner') &&
          this.state.floatSide === 'standAlone'
        ) {
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'width: ' + this.state.sealMaxWidth + 'px;'
          );
        }

        if (
          (this.state.sealType === 'outer' ||
            this.state.sealType === 'inner' ||
            this.state.sealType === 'checkout') &&
          this.state.floatSide !== 'standAlone'
        ) {
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_START', '');
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '');
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'width: ' + this.state.sealMaxWidth + 'px;'
          );

          if (this.state.sealType !== 'checkout') {
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              '_POSITION',
              ' style="position:fixed;bottom:0px;z-index: 999999;text-align:left;' +
                sealOffset +
                '"'
            );
            /*eslint-enable*/
          }
        }

        if (this.state.sealType === 'side' && this.state.floatSide !== 'standAlone') {
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'height: ' + this.state.sealMaxWidth + 'px'
          );
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_POSITION',
            ' style="position:fixed;top:150px;z-index:999999;text-align:left;' + sealOffset + '"'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_START', '');
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '');
        }

        if (this.state.sealType === 'side' && this.state.floatSide === 'standAlone') {
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'height: ' + this.state.sealMaxWidth + 'px'
          );
        }

        if (this.state.floatSide === 'standAlone') {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_STANDALONE_ANCHOR_START',
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );

          websiteSealCode = websiteSealCode.replace('_TG_SEALCODE_CLASS', 'tgbanner');
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '</a>');
          websiteSealCode = websiteSealCode.replace('_POSITION', '');
          websiteSealCode = websiteSealCode.replace('_SIZE ', '');
        } else {
          /* if (this.state.sealType === "textShield") {
            console.log("Changing class textShield", this.state.floatSide);
            websiteSealCode = websiteSealCode.replace(
              "_TG_SEALCODE_CLASS",
              "tgfloat tgStackSeal tgB" + this.state.floatSide
            );

            // TODO - replace font family and font size
            websiteSealCode = websiteSealCode.replace("_POSITION", "");
          } else {
            websiteSealCode = websiteSealCode.replace(
              "_TG_SEALCODE_CLASS",
              "tgfloat"
            );
          } */
        }

        // console.log("BEFORE", websiteSealCode);

        if (this.state.sealType === 'checkout' || this.state.sealType === 'textShield') {
          // console.log( "INSIDE textShield",this.state.sealType,this.state.floatSide);

          // console.log("Changing class textShield", this.state.floatSide);

          let fSide;

          if (this.state.floatSide !== 'none') {
            fSide = this.state.floatSide;
          } else {
            fSide = 'right';
          }

          websiteSealCode = websiteSealCode.replace(
            '_TG_SEALCODE_CLASS',

            'tgfloat tgStackSeal tgB' + fSide + ' tg_1-b' + fSide
          );

          //let testShieldID = "id=" + '"tg_1-b' + this.state.floatSide + '"';
          // console.log("testShieldID", testShieldID);

          /* websiteSealCode = websiteSealCode.replace(
            "_ID",
            testShieldID)
          ); */
          websiteSealCode = websiteSealCode.replace('_ID', '');
          websiteSealCode = websiteSealCode.replace(
            '_STANDALONE_ANCHOR_START',
            '<table class="tgBlockText" cellspacing="0" cellpadding="0" style="display:none;"><tr><td class="tgBlockImg">'
          );

          websiteSealCode = websiteSealCode.replace('_TG_CSS', tgCss);
          // websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);

          websiteSealCode = websiteSealCode.replace('_TG_SCRIPT', tgScript + '.js');
          // websiteSealCode = websiteSealCode.replace( "_TG_SCRIPT_HASH", tgScriptHash);

          websiteSealCode = websiteSealCode.replace('_BUSINESS_ID', this.state.businessId);

          websiteSealCode = websiteSealCode.replace(
            '_TG_SCRIPT_FOR_DOCUMENT_WRITE',
            'tg_script_bottom_' + fSide
          );

          websiteSealCode = websiteSealCode.replace(
            '_PLACEMENT_TYPE")',
            'bottom_' +
              fSide +
              '");tgCheckSeal' +
              fSide.charAt(0).toUpperCase() +
              fSide.slice(1) +
              '()'
          );

          if (this.state.sealType !== 'checkout') {
            websiteSealCode = websiteSealCode.replace(
              '_STANDALONE_ANCHOR_END',
              '</td><td class="tgBlockSecure" style="font-family: ' +
                this.state.sealFontFamily +
                '; font-size:' +
                this.state.sealFontSize +
                'px; color:' +
                this.state.sealFontColor +
                '">Secure Transaction </td></tr/></table>'
            );

            websiteSealCode = websiteSealCode.replace(
              '_SIZE',
              'max-width: ' +
                parseInt(parseInt(this.state.sealMaxWidth) + parseInt(this.state.sealFontSize)) +
                'px;'
            );
          }

          websiteSealCode = websiteSealCode.replace('_POSITION', '');
        } else {
          websiteSealCode = websiteSealCode.replace('_TG_SEALCODE_CLASS', 'tgfloat');
          websiteSealCode = websiteSealCode.replace('_ID', '');
        }

        if (
          /* this.state.sealType === "checkout" || */
          this.state.sealType === 'insurance'
        ) {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_STANDALONE_ANCHOR_START',
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '</a>');
          websiteSealCode = websiteSealCode.replace('_POSITION', '');
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'width: ' + this.state.sealMaxWidth + 'px;'
          );
        }

        if (this.state.isGoogleTagManagerEnabled === true) {
          websiteSealCode = websiteSealCode.replace('_ONCONTEXTMENU', '');
          websiteSealCode = websiteSealCode.replace('_INTEGRITY_CSS', '');
          websiteSealCode = websiteSealCode.replace('_INTEGRITY_SCRIPT', '');
        } else {
          websiteSealCode = websiteSealCode.replace('_INTEGRITY_CSS', 'integrity="_TG_CSS_HASH"');
          websiteSealCode = websiteSealCode.replace('_TG_CSS_HASH', tgCssHash);

          websiteSealCode = websiteSealCode.replace(
            '_INTEGRITY_SCRIPT',
            'integrity="_TG_SCRIPT_HASH"'
          );
          websiteSealCode = websiteSealCode.replace('_TG_SCRIPT_HASH', tgScriptHash);

          websiteSealCode = websiteSealCode.replace(
            '_ONCONTEXTMENU',
            "oncontextmenu=\"var d = new Date(); alert('Copying Prohibited by Law - This image and all included logos are copyrighted by Trust Guard '+d.getFullYear()+'.'); return false;\""
          );
        }

        this.setState({
          sealCode: { ...this.state.sealCode, textboxCode: websiteSealCode }
        });

        break;
      case 'custom':
        break;

      default:
        break;
    }
  };

  generateSealCode = async () => {
    await callApiPatchTrustSealsData(this.state.trustSealsData._id, {
      lastGeneratedDate: Date.now(),
      businessId: this.state.businessId
    });

    this.setState({
      codeGenerated: true
    });

    toast.success('TrustSeal code generated successfully.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });
  };

  createSealPreset = async newPresetName => {
    if (newPresetName && typeof newPresetName !== 'undefined') {
      const newSealsData = await this.populateSealsData(newPresetName, 'create');
      document.getElementById('hideModalOnSuccess').click();
      this.setState({
        selectedPresetId: ''
      });

      const updatednewSealsData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
        businessId: this.state.businessId,
        sealData: newSealsData
      });

      toast.success('Preset created successfully.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500
      });

      this.setTrustSealsUiData(updatednewSealsData);
    } else {
      toast.error('Please enter preset name.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500
      });
    }
  };

  saveSeal = async () => {
    this.setState({
      isSealSaved: true,
      trustSealPath: null
    });

    const updatableSealData = await this.populateSealsData(
      this.state.presetName,
      'update',
      this.state.selectedPresetId
    );

    // console.log('saveSeal updatableSealData', updatableSealData);

    const updatedSealsData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
      businessId: this.state.businessId,
      sealData: updatableSealData
    });

    toast.success('Preset updated successfully.', {
      className: 'foo',
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });

    this.setTrustSealsUiData(updatedSealsData);
  };

  removeSeal = async () => {
    const removableSealData = await this.populateSealsData(
      this.state.presetName,
      'remove',
      this.state.selectedPresetId
    );

    const currentSealData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
      businessId: this.state.businessId,
      sealData: removableSealData,
      removableSealSettingsId: this.state.selectedPresetId
    });

    toast.success('Preset removed successfully.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });

    this.setTrustSealsUiData(currentSealData);
  };

  populateInitialSealsData() {
    let trustSealId = null;
    let sealData = null;
    let presetSettings = [
      {
        presetType: null,
        presetName: 'Secure Transaction Seal',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'textShield',
        floatSide: 'left',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      },
      {
        presetType: null,
        presetName: 'Checkout Banner',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'checkout',
        floatSide: 'none',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      },
      {
        presetType: null,
        presetName: ' Standard Seal',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'inner',
        floatSide: 'standAlone',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      },
      {
        presetType: null,
        presetName: 'Floating Seal',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'outer',
        floatSide: 'left',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      }
    ];

    sealData = {
      businessId: this.state.businessId,
      lastGeneratedDate: null,
      nextGenerateDate: null,
      status: 'active',
      sealSettings: presetSettings
    };

    // console.log("Seal Data", sealData);
    return sealData;
  }

  async populateSealsData(presetName = null, purpose = null, sealSettingsId = null) {
    let trustSealId = null;
    let sealData = null;
    let presetSettings = {
      presetType: null,
      presetName: 'Default',
      label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
      sealType: 'premium',
      sealIcon: 'shield',
      iconColor: 'green',
      iconCustomColor: null,
      floatSide: 'left',
      width: '225',
      distanceFromEdge: 0,
      color: {
        title: 'white',
        placeholderShieldOuter: '#333333',
        placeholderShieldInner: '#FFFFFF',
        placeholderShieldLeft: '#66CC3E',
        placeholderShieldRight: '#33981F',
        placeholderBodyLeft: '#f2f2f2',
        placeholderBodyRight: '#ffffff',
        placeholderStatusColor: '#333333',
        placeholderDateColor: '#333333',
        placeholderLabelColor: '#333333',
        placeholderKeyHole: '#ffffff',
        placeholderTrustGuardText: '#333333',
        placeholderSecuredText: '#333333',
        placeholderShieldBg: '#FFFFFF',
        placeholderShieldTop: '#FFFFFF',
        placeholderPrimaryLayer: '#333333',
        placeholderSecondaryLayer: '#333333',
        placeholderCheckoutSeparator: '#e6e6e5',
        placeholderShieldInnerBorder: '#ffffff',
        placeholderSealBorder: '#f5f5f5',

        placeholderInsuranceBorder: '#e5e5e5',
        placeholderInsuredText: '#25aae1',
        placeholderGeneralText: '#333333',
        placeholderActiveText: '#404041',
        placeholderInsuranceSeparator: '#e5e5e5',
        placeholderInsuranceInfoBorder: '#25aae1',
        placeholderInnerShieldLeftTop: '#98d6f0',
        placeholderInnerShieldLeftBottom: '#7fcced',
        placeholderInnerShieldRightBottom: '#29aae1',
        placeholderInnerShieldRightTop: '#0c6d94',
        placeholderInnerShieldMiddleOne: '#84de65',
        placeholderInnerShieldMiddleTwo: '#7fcced'
      },
      sealWidth: {
        desktopWidth: this.state.sealMaxWidth,
        mobileWidth: this.state.sealMaxWidthOnMobile
      },
      sealFontFamily: this.state.sealFontFamily,
      sealFontSize: this.state.sealFontSize,
      sealFontColor: '#0066C0',
      includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
      updateDate: new Date().toISOString
    };

    let sealSettingsData = null;

    if (this.state.sealType === 'checkout') {
      this.setState({
        floatSide: 'none'
      });
    }

    if (this.state.sealType === 'insurance') {
      this.setState({
        floatSide: 'none'
      });
    }

    if (this.state.sealType === 'textShield') {
      this.setState({
        floatSide: this.state.floatSide
      });
    }

    switch (purpose) {
      case 'create':
        trustSealId = this.state.trustSealsData._id;
        presetSettings.presetName = presetName;
        sealSettingsData = [...this.state.trustSealsData.sealSettings, presetSettings];
        break;

      case 'update':
        let sealLabel = this.state.label;
        if (sealLabel === '') {
          sealLabel = this.state.businessData.primaryDomain;
        }

        let tgSealOuterShieldColor = '';

        if (
          this.state.floatSide === 'right' &&
          this.state.sealType === 'side' &&
          (this.state.color === 'black' || this.state.color === 'whiteAlt')
        ) {
          tgSealOuterShieldColor = '#dddddd';
        } else {
          tgSealOuterShieldColor = this.state.colorSettings[this.state.color]
            .placeholderShieldOuter;
        }

        let tgShieldLeftColor, tgShieldRightColor;

        if (
          (this.state.sealType === 'premium' || this.state.sealType === 'premiumMini') &&
          this.state.iconColor !== ''
        ) {
          // console.log('INSIDE CUSTOM COLOR SAVE SEAL', this.state.iconColor);

          tgShieldLeftColor =
            this.state.iconColor === 'custom' ? this.state.iconCustomColor : this.state.iconColor;
          tgShieldRightColor =
            this.state.iconColor === 'custom' ? this.state.iconCustomColor : this.state.iconColor;
        } else {
          // console.log('ELSE ICON COLOR SAVE SEAL', this.state.iconColor);
          tgShieldLeftColor = this.state.colorSettings[this.state.color].placeholderShieldLeft;
          tgShieldRightColor = this.state.colorSettings[this.state.color].placeholderShieldRight;
        }

        let tgSealPlaceholderBG, customFontColor;

        if (this.state.color === 'custom') {
          tgSealPlaceholderBG = this.state.customColor ? this.state.customColor : '';
          customFontColor = await this.getContrastingFontColor(this.state.customColor);
        } else {
          tgSealPlaceholderBG = this.state.colorSettings[this.state.color].placeholderShieldBg;
          customFontColor = this.state.colorSettings[this.state.color].placeholderSecuredText;
        }

        trustSealId = this.state.trustSealsData ? this.state.trustSealsData._id : null;

        sealSettingsData =
          this.state.trustSealsData && this.state.trustSealsData.sealSettings
            ? this.state.trustSealsData.sealSettings.map((v, i) => {
                if (v._id === sealSettingsId) {
                  v.presetName = this.state.presetName;
                  v.label = sealLabel.toUpperCase();
                  v.sealType = this.state.sealType;
                  v.sealIcon = this.state.sealIcon;
                  v.iconColor = this.state.iconColor;
                  v.iconCustomColor = this.state.iconCustomColor;
                  v.floatSide =
                    this.state.sealType !== 'checkout' && this.state.sealType !== 'insurance'
                      ? this.state.floatSide !== 'none'
                        ? this.state.floatSide
                        : 'left'
                      : 'none';
                  v.width = '225';
                  v.distanceFromEdge = this.state.distanceFromEdge;
                  v.color = {
                    title: this.state.color,
                    placeholderShieldOuter: tgSealOuterShieldColor,
                    placeholderShieldInner: this.state.colorSettings[this.state.color]
                      .placeholderShieldInner,
                    placeholderShieldLeft: tgShieldLeftColor,
                    placeholderShieldRight: tgShieldRightColor,
                    placeholderBodyLeft: this.state.colorSettings[this.state.color]
                      .placeholderBodyLeft,
                    placeholderBodyRight: this.state.colorSettings[this.state.color]
                      .placeholderBodyRight,
                    placeholderStatusColor: this.state.colorSettings[this.state.color]
                      .placeholderStatusColor,
                    placeholderDateColor: this.state.colorSettings[this.state.color]
                      .placeholderDateColor,
                    placeholderLabelColor: this.state.colorSettings[this.state.color]
                      .placeholderLabelColor,
                    placeholderKeyHole: this.state.colorSettings[this.state.color]
                      .placeholderKeyHole,
                    placeholderTrustGuardText: this.state.colorSettings[this.state.color]
                      .placeholderTrustGuardText,
                    placeholderSecuredText: customFontColor,

                    placeholderShieldBg: tgSealPlaceholderBG,

                    placeholderShieldTop: this.state.colorSettings[this.state.color]
                      .placeholderShieldTop,

                    placeholderPrimaryLayer: this.state.colorSettings[this.state.color]
                      .placeholderPrimaryLayer,

                    placeholderSecondaryLayer: this.state.colorSettings[this.state.color]
                      .placeholderSecondaryLayer,
                    placeholderCheckoutSeparator: this.state.colorSettings[this.state.color]
                      .placeholderCheckoutSeparator,
                    placeholderShieldInnerBorder: this.state.colorSettings[this.state.color]
                      .placeholderShieldInnerBorder,
                    placeholderSealBorder: this.state.colorSettings[this.state.color]
                      .placeholderSealBorder,

                    placeholderInsuranceBorder: this.state.colorSettings[this.state.color]
                      .placeholderInsuranceBorder,

                    placeholderInsuredText: this.state.colorSettings[this.state.color]
                      .placeholderInsuredText,

                    placeholderGeneralText: this.state.colorSettings[this.state.color]
                      .placeholderGeneralText,

                    placeholderActiveText: this.state.colorSettings[this.state.color]
                      .placeholderActiveText,

                    placeholderInsuranceSeparator: this.state.colorSettings[this.state.color]
                      .placeholderInsuranceSeparator,

                    placeholderInsuranceInfoBorder: this.state.colorSettings[this.state.color]
                      .placeholderInsuranceInfoBorder,

                    placeholderInnerShieldLeftTop: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldLeftTop,

                    placeholderInnerShieldLeftBottom: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldLeftBottom,

                    placeholderInnerShieldRightBottom: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldRightBottom,

                    placeholderInnerShieldRightTop: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldRightTop,

                    placeholderInnerShieldMiddleOne: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldMiddleOne,

                    placeholderInnerShieldMiddleTwo: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldMiddleTwo
                  };
                  v.sealWidth = {
                    desktopWidth: this.state.sealMaxWidth,
                    mobileWidth: this.state.sealMaxWidthOnMobile
                  };
                  v.includeGoogleRobotoFont = this.state.includeGoogleRobotoFont;
                  v.sealFontFamily = this.state.sealFontFamily;
                  v.sealFontSize = this.state.sealFontSize;
                  v.sealFontColor = this.state.sealFontColor;
                  v.updateDate = new Date().toISOString();
                }

                return v;
              })
            : null;
        break;

      case 'remove':
        trustSealId = this.state.trustSealsData ? this.state.trustSealsData._id : null;
        sealSettingsData = this.state.trustSealsData
          ? this.state.trustSealsData.sealSettings.filter(eachData => {
              return eachData._id !== sealSettingsId;
            })
          : null;
        break;

      default:
        // console.log("populateSealsData default switch", presetSettings);
        presetSettings.presetType = 'Default';
        sealSettingsData = presetSettings;
        break;
    }

    sealData = {
      businessId: this.state.businessId,
      lastGeneratedDate: null,
      nextGenerateDate: null,
      status: 'active',
      sealSettings: sealSettingsData
    };

    // console.log("Seal Data", sealData);
    return sealData;
  }

  async getContrastingFontColor(backgroundColor) {
    const fontColor = await callApiGetFontColor(backgroundColor);

    if (fontColor && fontColor.success && fontColor.success === true) {
      return fontColor.data;
    }
  }

  onUpdate = values => {
    // console.log("Updating...");
    this.setState(
      {
        sealMaxWidth: Array.isArray(values) ? values[0] : values
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onUpdateMobile = values => {
    this.setState(
      {
        sealMaxWidthOnMobile: values[0]
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onSealSizeUpdate = async prevState => {
    const onUpdateSealData = await this.populateSealsData(
      this.state.presetName,
      'update',
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(
      this.state.trustSealsData ? this.state.trustSealsData._id : null,
      {
        businessId: this.state.businessId,
        sealData: onUpdateSealData
      }
    );
  };

  saveSealFontColor = async color => {
    // console.log("saveSealFontColor color", color);

    // console.log(color);

    this.setState({
      sealFontColor: color,
      isSealSaved: true,
      trustSealPath: null
    });

    const updatableSealData = await this.populateSealsData(
      this.state.presetName,
      'update',
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
      businessId: this.state.businessId,
      sealData: updatableSealData
    });

    this.setTrustSealsUiData(updatedSealsData);
  };

  toggleGoogleTagManager = async status => {
    this.setState(
      {
        isGoogleTagManagerEnabled: status
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );
  };

  sealTypeClick = async event => {
    let parentElem = document.getElementsByClassName('sealType')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    /* console.log('parentElem', parentElem);
    console.log('childDropDownImg', childDropDownImg);
    console.log('childDropUpImg', childDropUpImg); */

    if (this.state.sealTypeToggle === 'close') {
      // console.log(event.target.className);
      document.getElementById('sealType').style.display = 'block';

      if (childDropDownImg) {
        childDropDownImg.style.display = 'none';
      }

      if (childDropUpImg) {
        childDropUpImg.style.display = 'block';
      }

      this.setState({
        sealTypeToggle: 'open'
      });
    } else {
      document.getElementById('sealType').style.display = 'none';
      childDropDownImg.style.display = 'block';
      childDropUpImg.style.display = 'none';

      this.setState({
        sealTypeToggle: 'close'
      });
    }
  };

  setSelectType = async (event, value) => {
    document.getElementById('sealType').style.display = 'none';
    let parentElem = document.getElementsByClassName('sealType')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];
    childDropDownImg.style.display = 'block';
    childDropUpImg.style.display = 'none';

    event.stopPropagation();
    this.setState({
      sealType: value,
      sealTypeToggle: 'close'
    });

    this.handleInputChange('sealType', value);
  };

  sealIconClick = async event => {
    let parentElem = document.getElementsByClassName('sealIcon')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    /* console.log('parentElem', parentElem);
    console.log('childDropDownImg', childDropDownImg);
    console.log('childDropUpImg', childDropUpImg); */
    // console.log('BEFORE', this.state.sealIconToggle);

    if (this.state.sealIconToggle === 'close') {
      // console.log(event.target.className);
      document.getElementById('sealIcon').style.display = 'block';

      if (childDropDownImg) {
        childDropDownImg.style.display = 'none';
      }

      if (childDropUpImg) {
        childDropUpImg.style.display = 'block';
      }

      this.setState({
        sealIconToggle: 'open'
      });
    } else {
      document.getElementById('sealIcon').style.display = 'none';
      childDropDownImg.style.display = 'block';
      childDropUpImg.style.display = 'none';

      this.setState({
        sealIconToggle: 'close'
      });
    }

    // console.log('AFTER', this.state.sealIconToggle);
  };

  setSealIcon = async (event, value) => {
    document.getElementById('sealIcon').style.display = 'none';
    let parentElem = document.getElementsByClassName('sealIcon')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];
    childDropDownImg.style.display = 'block';
    childDropUpImg.style.display = 'none';

    event.stopPropagation();
    this.setState({
      sealIcon: value,
      sealIconToggle: 'close'
    });

    this.handleInputChange('sealIcon', value);
  };

  sealFloatSideClick = async event => {
    let parentElem = document.getElementsByClassName('sealPlacement')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    if (this.state.sealFloatSideToggle === 'close') {
      document.getElementById('sealPlacement').style.display = 'block';
      childDropDownImg.style.display = 'none';
      childDropUpImg.style.display = 'block';

      this.setState({
        sealFloatSideToggle: 'open'
      });
    } else {
      document.getElementById('sealPlacement').style.display = 'none';
      childDropDownImg.style.display = 'block';
      childDropUpImg.style.display = 'none';

      this.setState({
        sealFloatSideToggle: 'close'
      });
    }
  };

  setFloatSide = async (event, value) => {
    document.getElementById('sealPlacement').style.display = 'none';
    let parentElem = document.getElementsByClassName('sealPlacement')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    childDropDownImg.style.display = 'block';
    childDropUpImg.style.display = 'none';

    event.stopPropagation();

    this.setState({
      floatSide: value,
      sealFloatSideToggle: 'close'
    });

    this.handleInputChange('floatSide', value);
  };

  sealFontClick = async event => {
    let parentElem = document.getElementsByClassName('sealFont')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    if (this.state.sealTypeToggle === 'close') {
      document.getElementById('sealFont').style.display = 'block';
      childDropDownImg.style.display = 'none';
      childDropUpImg.style.display = 'block';

      this.setState({
        sealFontToggle: 'open'
      });
    } else {
      document.getElementById('sealFont').style.display = 'none';
      childDropDownImg.style.display = 'block';
      childDropUpImg.style.display = 'none';

      this.setState({
        sealFontToggle: 'close'
      });
    }
  };

  setFont = async (event, value) => {
    document.getElementById('sealFont').style.display = 'none';
    let parentElem = document.getElementsByClassName('sealFont')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    childDropDownImg.style.display = 'block';
    childDropUpImg.style.display = 'none';

    event.stopPropagation();
    this.setState({
      sealFontFamily: value,
      sealFontToggle: 'close'
    });

    this.handleInputChange('sealFontFamily', value);
  };

  sealFontSizeClick = async event => {
    let parentElem = document.getElementsByClassName('sealFontSize')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    if (this.state.sealTypeToggle === 'close') {
      document.getElementById('sealFontSize').style.display = 'block';
      childDropDownImg.style.display = 'none';
      childDropUpImg.style.display = 'block';

      this.setState({
        sealFontSizeToggle: 'open'
      });
    } else {
      document.getElementById('sealFontSize').style.display = 'none';
      childDropDownImg.style.display = 'block';
      childDropUpImg.style.display = 'none';

      this.setState({
        sealFontSizeToggle: 'close'
      });
    }
  };

  setSealFontSize = async (event, value) => {
    let parentElem = document.getElementsByClassName('sealFontSize')[0];

    let childDropDownImg = parentElem.querySelectorAll('.arrow-drop-down')[0];
    let childDropUpImg = parentElem.querySelectorAll('.arrow-drop-up')[0];

    childDropDownImg.style.display = 'block';
    childDropUpImg.style.display = 'none';

    document.getElementById('sealFontSize').style.display = 'none';

    event.stopPropagation();
    this.setState({
      sealFontSize: value,
      sealFontSizeToggle: 'close'
    });

    this.handleInputChange('sealFontSize', value);
  };

  showContentLoader() {
    return (
      <ContentLoader
        height={260}
        width={989}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#e1dfdf">
        <rect x="21" y="22" rx="0" ry="0" width="240" height="15" />
        <rect x="300" y="22" rx="0" ry="0" width="650" height="15" />

        <rect x="21" y="66" rx="0" ry="0" width="240" height="15" />
        <rect x="300" y="66" rx="0" ry="0" width="650" height="15" />

        <rect x="21" y="110" rx="0" ry="0" width="240" height="15" />
        <rect x="300" y="110" rx="0" ry="0" width="650" height="15" />

        <rect x="21" y="154" rx="0" ry="0" width="240" height="15" />
        <rect x="300" y="154" rx="0" ry="0" width="650" height="15" />

        <rect x="21" y="198" rx="0" ry="0" width="240" height="15" />
        <rect x="300" y="198" rx="0" ry="0" width="650" height="15" />

        <rect x="21" y="242" rx="0" ry="0" width="240" height="15" />
        <rect x="300" y="242" rx="0" ry="0" width="650" height="15" />

        {/* <rect x="228" y="18" rx="0" ry="0" width="90" height="15" />
        <rect x="431" y="19" rx="0" ry="0" width="90" height="15" />
        <rect x="647" y="20" rx="0" ry="0" width="90" height="15" />
        <rect x="841" y="20" rx="0" ry="0" width="90" height="15" />
        <rect x="30" y="160" rx="0" ry="0" width="900" height="11" />
        <rect x="29" y="218" rx="0" ry="0" width="900" height="11" />
        <rect x="30" y="181" rx="0" ry="0" width="900" height="11" />
        <rect x="29" y="200" rx="0" ry="0" width="900" height="11" /> */}
      </ContentLoader>
    );
  }
  render() {
    const {
      businessId,
      codeGenerated,
      businessData,
      certificateURL,
      sealCode,
      trustSealsData,
      presetType,
      presetName,
      label,
      color,
      sealType,
      floatSide,
      width,
      distanceFromEdge,
      colorSettings,
      selectedPresetId,
      newPresetName,
      isSealSaved,
      trustSealPath,
      demoTrustSealPath,
      trustSealPathForWebsiteCode,
      isTextElementChanged,
      isCopied,
      trustsealHost,
      displayBlock,
      changingSealParameter,
      conditionalNotificationParameters,
      sealScriptsCss,
      sealScriptsJs,
      disableFloatSide,
      displayOnChangeSealModal,
      sealMaxWidth,
      sealMaxWidthOnMobile,
      showMobileSeal,
      userInfoText,
      sealFontFamily,
      sealFontSize,
      includeGoogleRobotoFont,
      sealFontColor,
      isGoogleTagManagerEnabled,
      sealTypeOptions,
      sealFloatSideOptions,
      sealFontOptions,
      sealFontSizeOptions,
      sealIcon,
      sealIconOptions,
      editPresetName,
      isDefault,
      customColor,
      iconColor,
      iconCustomColor,
      isLoading
    } = this.state;

    let businessPciStatus = null;

    switch (businessData.pciStatus) {
      case 'pass':
        businessPciStatus = businessData.pciStatus;
        break;
      case 'fail':
        businessPciStatus = businessData.pciStatus;
        break;
      default:
        businessPciStatus = businessData.lastPciStatus;
        break;
    }

    return (
      <Aux>
        {isLoading === true ? (
          this.showContentLoader()
        ) : (
          <>
            <div className="top-header-wrapper">
              <div className="top-header-title">
                <a
                  onClick={() =>
                    this.props.goToDashboard({
                      accountAdminView: false
                    })
                  }>
                  <img src={images.keyboardReturn} />
                </a>

                <h4>SEAL OPTIONS</h4>
              </div>

              <div className="top-header-intro">
                <div className="top-header-intro-details">
                  <h2 className="top-header-intro-domain-name">
                    {this.state.businessData ? this.state.businessData.primaryDomain : ''}
                  </h2>
                  <p className="top-header-intro-text">
                    Below you will find the options to control the position of your Trust Guard
                    trust seal. Use the controls to modify the position and copy the code when you
                    are done and simply paste it into the desired webpage or in the universal footer
                    to show on all pages.
                  </p>
                  <p className="top-header-intro-subtext">
                    Note: You can use presets to display different seals on different pages.
                  </p>
                </div>

                <img className="top-header-intro-image" src={images.secureGroup} />
              </div>
            </div>

            <div id="seal-page-table" className="mt-35">
              <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
                <div className="tab-content">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 tg-seals-well">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 col-xxs-12 left">
                      <h5 className="seal-preset">Presets</h5>
                      <div className="menu-list">
                        {trustSealsData !== null ? (
                          <ul id="sealPresetUl" className="list-group">
                            {trustSealsData.hasOwnProperty('sealSettings')
                              ? trustSealsData.sealSettings
                                  .map((v, i) => {
                                    return (
                                      <li
                                        id={v._id}
                                        key={i}
                                        onClick={() => {
                                          this.showActivePresetContent(v, i);
                                        }}
                                        className={
                                          v._id === selectedPresetId
                                            ? 'list-group-item active'
                                            : 'list-group-item'
                                        }>
                                        {v.presetName}
                                      </li>
                                    );
                                  })
                                  .reverse()
                              : ''}
                          </ul>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="create-new-preset-container">
                        <button
                          className="btn-tgdark-black-outline"
                          data-toggle="modal"
                          data-target="#modalCreatePreset">
                          <i className="fas fa-plus mar-r-15" /> Create New Preset
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-xxs-12 right">
                      <div className="flex-parent pad-y-15">
                        <div className="flex-left flex-margin-right-auto seal-page-action">
                          <div
                            className={`${
                              editPresetName === false ? 'display-flex' : 'display-none'
                            }`}>
                            <h4 className="seal-preset-name">{presetName}</h4>{' '}
                            <img
                              className="seal-preset-action-edit-img"
                              src={images.modeEditOutline}
                              alt="mode edit outline"
                              onClick={event => {
                                this.setState({
                                  editPresetName: !editPresetName
                                });
                              }}
                            />
                          </div>
                          {/* Preset Name */}
                          <div
                            className={`${
                              editPresetName === true ? 'display-block' : 'display-none'
                            }`}>
                            <input
                              type="text"
                              className="seal-preset-input seal-preset-delete-input"
                              name="presetName"
                              value={presetName}
                              onChange={event => {
                                this.handleInputChange('presetName', event.target.value);
                              }}
                              onBlur={evt => {
                                if (isTextElementChanged) {
                                  this.saveSeal();
                                  this.setState({
                                    isTextElementChanged: false
                                  });
                                }
                                this.setState({
                                  editPresetName: false
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex-right seal-preset-action-remove">
                          {presetType === null && isDefault === false ? (
                            <button
                              className="btn-tgdark2-outline mar-r-8"
                              data-toggle="modal"
                              data-target="#modalDeletePreset">
                              <img src={images.newDelete} />&nbsp; Delete Preset
                            </button>
                          ) : (
                            ''
                          )}

                          <button
                            className="btn-tgdark2 btn-md"
                            onClick={event => {
                              this.saveSeal();
                            }}>
                            Save Preset
                          </button>
                        </div>
                      </div>
                      <hr className="seal-preset-hr" />
                      <div>
                        <div className="well well-lg seal-page-user-info display-none">
                          Your seals are currently inactive and will not be displayed on your
                          website until you've passed your PCI, Malware and SSL scans. <br />While
                          your seals are inactive we have created a placeholder seal for you to
                          preview. <br />Note that your active seal will display the website name
                          you enter below instead of "Demo".
                        </div>

                        <div className="seal-content">
                          <div className="seal-content-column pr-10">
                            <h2 className="seal-page-customize-text">Customize Your Seal</h2>

                            {/* Seal Type */}
                            <div className="list-box-container custom-gap">
                              <p>
                                <label className="">Type</label>
                              </p>
                              <div className="sealType" onClick={this.sealTypeClick}>
                                {sealTypeOptions[sealType]}
                                <img
                                  className="arrow-drop-down"
                                  src={images.arrowDropDown}
                                  alt="arrow drop down"
                                  onClick={event => {
                                    event.stopPropagation();
                                    this.sealTypeClick(event);
                                  }}
                                />
                                <img
                                  className="arrow-drop-up"
                                  src={images.arrowDropUp}
                                  alt="arrow drop up"
                                  onClick={event => {
                                    event.stopPropagation();
                                    this.sealTypeClick(event);
                                  }}
                                />
                              </div>
                              <div id="sealType" className="list-box">
                                <ul>
                                  {(businessId === '5e275432648cdf39caed2fa8' ||
                                    businessId === '67d218b0ba3a314cc90f1749' ||
                                    businessId === '63989e843f5ce60dcdbdcaa7') && (
                                    <>
                                      {' '}
                                      <li
                                        onClick={event => {
                                          this.setSelectType(event, 'premium');
                                        }}>
                                        Premium
                                      </li>
                                      <li
                                        onClick={event => {
                                          this.setSelectType(event, 'premiumMini');
                                        }}>
                                        Premium Mini
                                      </li>
                                      <li
                                        onClick={event => {
                                          this.setSelectType(event, 'premiumCheckout');
                                        }}>
                                        Premium Checkout
                                      </li>
                                      <li
                                        onClick={event => {
                                          this.setSelectType(event, 'premiumCheckoutMini');
                                        }}>
                                        Premium Checkout Mini
                                      </li>
                                    </>
                                  )}
                                  <li
                                    onClick={event => {
                                      this.setSelectType(event, 'inner');
                                    }}>
                                    Classic Seal
                                  </li>
                                  <li
                                    onClick={event => {
                                      this.setSelectType(event, 'outer');
                                    }}>
                                    Classic Bump Out Seal
                                  </li>
                                  <li
                                    onClick={event => {
                                      this.setSelectType(event, 'checkout');
                                    }}>
                                    Banner Checkout
                                  </li>
                                  {/* <li
                                onClick={event => {
                                  this.setSelectType(event, 'side');
                                }}>
                                Side Shield
                              </li> */}

                                  {businessData.hasOwnProperty('details4X') &&
                                    businessData.details4X && (
                                      <li
                                        onClick={event => {
                                          this.setSelectType(event, 'insurance');
                                        }}>
                                        Buyer Protection Banner
                                      </li>
                                    )}
                                  <li
                                    onClick={event => {
                                      this.setSelectType(event, 'textShield');
                                    }}>
                                    Secure Transaction
                                  </li>
                                </ul>
                              </div>
                            </div>

                            {/* Background Color */}
                            {sealType !== 'textShield' && (
                              <div className="custom-gap">
                                <p>
                                  <label className="">
                                    {(sealType === 'outer' ||
                                      sealType === 'inner' ||
                                      sealType === 'checkout' ||
                                      sealType === 'side' ||
                                      sealType === 'premiumCheckout' ||
                                      sealType === 'premiumCheckoutMini') &&
                                      'Color'}

                                    {sealType !== 'outer' &&
                                      sealType !== 'inner' &&
                                      sealType !== 'checkout' &&
                                      sealType !== 'side' &&
                                      sealType === 'premiumCheckout' &&
                                      sealType === 'premiumCheckoutMini' &&
                                      'Background Color'}
                                  </label>
                                </p>
                                <div className="radio-container">
                                  {(sealType === 'outer' ||
                                    sealType === 'inner' ||
                                    sealType === 'checkout' ||
                                    sealType === 'side' ||
                                    sealType === 'premiumCheckout' ||
                                    sealType === 'premiumCheckoutMini') && (
                                    <>
                                      <input
                                        type="radio"
                                        id="option1"
                                        name="color"
                                        className=""
                                        value="green"
                                        onChange={event => {
                                          this.handleInputChange('color', event.target.value);
                                        }}
                                        checked={color === 'green' ? true : false}
                                      />
                                      <label for="option1" className="radio-label green-bg" />
                                    </>
                                  )}
                                  <input
                                    type="radio"
                                    id="option3"
                                    name="color"
                                    className=""
                                    value="white"
                                    onChange={event => {
                                      this.handleInputChange('color', event.target.value);
                                    }}
                                    checked={color === 'white' ? true : false}
                                  />
                                  <label for="option3" className="radio-label white-bg" />
                                  <input
                                    type="radio"
                                    id="option2"
                                    name="color"
                                    className=""
                                    value="black"
                                    onChange={event => {
                                      this.handleInputChange('color', event.target.value);
                                    }}
                                    checked={color === 'black' ? true : false}
                                  />
                                  <label for="option2" className="radio-label black-bg" />

                                  <>
                                    <input
                                      type="radio"
                                      id="option4"
                                      name="color"
                                      value="grey"
                                      onChange={event => {
                                        this.handleInputChange('color', event.target.value);
                                      }}
                                      checked={color === 'grey' ? true : false}
                                    />
                                    <label for="option4" className="radio-label gray-bg" />
                                  </>

                                  {sealType !== 'outer' &&
                                    sealType !== 'inner' &&
                                    sealType !== 'checkout' &&
                                    sealType !== 'side' &&
                                    sealType !== 'textShield' &&
                                    sealType !== 'premiumCheckout' &&
                                    sealType !== 'premiumCheckoutMini' && (
                                      <div className="custom-color">
                                        <input
                                          className="icon-color"
                                          type="color"
                                          id="colorPicker"
                                          name="color"
                                          onBlur={event => {
                                            this.handleInputChange('color', event.target.value);
                                          }}
                                        />
                                        {color === 'custom' && <label for="colorPicker" />}
                                        <label
                                          className="custom-color-text"
                                          role="link"
                                          onClick={() => {
                                            document.getElementById('colorPicker').click();
                                          }}
                                          for="colorPicker">
                                          {color === 'custom' && customColor}
                                          {color !== 'custom' && 'Custom Color'}
                                        </label>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}

                            {/* Seal Placement */}
                            {sealType !== 'premium' &&
                              sealType !== 'premiumMini' && (
                                <div
                                  className={`${
                                    sealType === 'checkout' ||
                                    sealType === 'premiumCheckout' ||
                                    sealType === 'premiumCheckoutMini' ||
                                    sealType === 'insurance'
                                      ? 'display-none'
                                      : 'list-box-container display-block custom-gap'
                                  }`}>
                                  <p>
                                    <label className="">
                                      {(sealType === 'textShield' && 'Popup Placement') ||
                                        'Placement'}
                                    </label>
                                  </p>
                                  <div className="sealPlacement" onClick={this.sealFloatSideClick}>
                                    {sealFloatSideOptions[floatSide]}
                                    <img
                                      className="arrow-drop-down"
                                      src={images.arrowDropDown}
                                      alt="arrow drop down"
                                    />
                                    <img
                                      className="arrow-drop-up"
                                      src={images.arrowDropUp}
                                      alt="arrow drop up"
                                    />
                                  </div>
                                  <div id="sealPlacement" className="list-box">
                                    <ul>
                                      <li
                                        onClick={event => {
                                          this.setFloatSide(event, 'left');
                                        }}>
                                        Left
                                      </li>
                                      <li
                                        onClick={event => {
                                          this.setFloatSide(event, 'right');
                                        }}>
                                        Right
                                      </li>
                                      {sealType !== 'textShield' && (
                                        <li
                                          onClick={event => {
                                            this.setFloatSide(event, 'standAlone');
                                          }}>
                                          Stand Alone
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              )}

                            {/* Icon */}
                            {(sealType === 'premium' || sealType === 'premiumMini') && (
                              <div className="list-box-container custom-gap">
                                <p>
                                  <label className="">Icon</label>
                                </p>
                                <div className="sealIcon" onClick={this.sealIconClick}>
                                  {sealIconOptions[sealIcon]}
                                  <img
                                    className="arrow-drop-down"
                                    src={images.arrowDropDown}
                                    alt="arrow drop down"
                                  />
                                  <img
                                    className="arrow-drop-up"
                                    src={images.arrowDropUp}
                                    alt="arrow drop up"
                                  />
                                </div>
                                <div id="sealIcon" className="list-box">
                                  <ul>
                                    <li
                                      onClick={event => {
                                        this.setSealIcon(event, 'shield');
                                      }}>
                                      Shield
                                    </li>
                                    <li
                                      onClick={event => {
                                        this.setSealIcon(event, 'pulse');
                                      }}>
                                      Pulse
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}

                            {/* Icon Color */}
                            {sealType !== 'textShield' &&
                              sealType !== 'checkout' &&
                              sealType !== 'outer' &&
                              sealType !== 'inner' &&
                              sealType !== 'side' &&
                              sealType !== 'premiumCheckout' &&
                              sealType !== 'premiumCheckoutMini' && (
                                <div className="custom-gap">
                                  <p>
                                    <label className="">Icon Color</label>
                                  </p>
                                  <div className="radio-container">
                                    {(color === 'white' ||
                                      color === 'black' ||
                                      color === 'grey') && (
                                      <>
                                        <input
                                          type="radio"
                                          id="option20"
                                          name="iconColor"
                                          className=""
                                          value="green"
                                          onChange={event => {
                                            // console.log(999);
                                            this.handleInputChange('iconColor', event.target.value);
                                          }}
                                          checked={iconColor === 'green' ? true : false}
                                        />
                                        <label for="option20" className="radio-label green-bg" />
                                      </>
                                    )}

                                    {color === 'white' && (
                                      <>
                                        <input
                                          type="radio"
                                          id="option21"
                                          name="iconColor"
                                          className=""
                                          value="blue"
                                          onChange={event => {
                                            this.handleInputChange('iconColor', event.target.value);
                                          }}
                                          checked={
                                            iconColor !== 'custom' && iconColor === '#2E90FA'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label for="option21" className="radio-label blue-bg" />
                                      </>
                                    )}

                                    {(color === 'white' || color === 'grey') && (
                                      <>
                                        <input
                                          type="radio"
                                          id="option22"
                                          name="iconColor"
                                          className=""
                                          value="black"
                                          onChange={event => {
                                            this.handleInputChange('iconColor', event.target.value);
                                          }}
                                          checked={iconColor === 'black' ? true : false}
                                        />
                                        <label for="option22" className="radio-label black-bg" />
                                      </>
                                    )}

                                    {(color === 'white' || color === 'custom') && (
                                      <>
                                        <div className="custom-color">
                                          <input
                                            className="icon-color"
                                            type="color"
                                            id="iconColorPicker"
                                            name="iconColor"
                                            onBlur={event => {
                                              this.handleInputChange(
                                                'iconColor',
                                                event.target.value
                                              );
                                            }}
                                          />
                                          {iconColor &&
                                            iconColor === 'custom' && (
                                              <label for="iconColorPicker" />
                                            )}
                                          <label
                                            className="custom-color-text"
                                            role="link"
                                            onClick={() => {
                                              document.getElementById('iconColorPicker').click();
                                            }}
                                            for="iconColorPicker">
                                            {iconColor && iconColor === 'custom' && iconCustomColor}
                                            {iconColor && iconColor !== 'custom' && 'Custom Color'}
                                          </label>
                                        </div>
                                      </>
                                    )}

                                    {color === 'black' && (
                                      <>
                                        <input
                                          type="radio"
                                          id="option23"
                                          name="iconColor"
                                          className=""
                                          value="white"
                                          onChange={event => {
                                            this.handleInputChange('iconColor', event.target.value);
                                          }}
                                          checked={iconColor === 'white' ? true : false}
                                        />
                                        <label for="option23" className="radio-label white-bg" />
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}

                            {/* Desktop or Mobile Version Switch */}
                            <div
                              className={`${
                                sealType === 'insurance' ||
                                sealType === 'premium' ||
                                sealType === 'premiumMini'
                                  ? 'display-none'
                                  : 'display-block custom-gap mar-t-12'
                              }`}>
                              <p>
                                <label className="">
                                  {showMobileSeal ? 'Mobile Version' : 'Desktop Version'}
                                </label>
                              </p>

                              <Switch
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onChange={this.handleSwitchChange}
                                checked={showMobileSeal}
                                height={20}
                                width={60}
                                offColor="#c3c4c6"
                                onColor="#333"
                              />
                            </div>

                            {/*  Desktop seal slider along with side seal height and width */}
                            <div
                              className={`${
                                showMobileSeal ||
                                (sealType === 'insurance' ||
                                  sealType === 'premium' ||
                                  sealType === 'premiumMini')
                                  ? 'display-none'
                                  : 'display-block custom-gap mar-t-12'
                              }`}>
                              <p>
                                <label className="">
                                  {sealType === 'side'
                                    ? 'Max Height'
                                    : (sealType === 'textShield' && 'Shield Size') || 'Max Width'}
                                </label>
                              </p>
                              {/* Slider */}
                              <div className="slider-container">
                                <Slider
                                  rootStyle={sliderStyle}
                                  domain={
                                    sealType === 'checkout' || sealType === 'insurance'
                                      ? [225, 400]
                                      : (sealType === 'textShield' && [0, 4]) || [120, 250]
                                  }
                                  step={(sealType === 'textShield' && 2) || 1}
                                  mode={2}
                                  values={[sealMaxWidth]}
                                  onUpdate={this.onUpdate}>
                                  <Rail>
                                    {({ getRailProps }) => (
                                      <div style={railStyle} {...getRailProps()} />
                                    )}
                                  </Rail>
                                  <Handles>
                                    {({ handles, getHandleProps }) => (
                                      <div className="slider-handles">
                                        {handles.map(handle => (
                                          <Handle
                                            key={handle.id}
                                            handle={handle}
                                            getHandleProps={getHandleProps}
                                            sealType={sealType}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </Handles>
                                  <Tracks right={false}>
                                    {({ tracks, getTrackProps }) => (
                                      <div className="slider-tracks">
                                        {tracks.map(({ id, source, target }) => (
                                          <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </Tracks>
                                </Slider>
                                <input
                                  type="text"
                                  className="slider-input"
                                  name="sealMaxWidth"
                                  value={sealMaxWidth + ' px'}
                                  disabled={true}
                                  /* onChange={evt => {
                              console.log(evt.target.value);
                              this.onUpdate(evt.target.value);
                            }} */
                                />
                              </div>
                            </div>

                            {/* Mobile Seal Slider */}
                            <div
                              className={`${
                                sealType !== 'insurance' &&
                                sealType !== 'premium' &&
                                sealType !== 'premiumMini' &&
                                showMobileSeal
                                  ? 'display-block custom-gap'
                                  : 'display-none'
                              }`}>
                              <p>
                                <label className="">
                                  {sealType === 'side'
                                    ? 'Max Height on Mobile'
                                    : 'Max Width on Mobile'}
                                </label>
                              </p>
                              <div className="slider-container">
                                <Slider
                                  rootStyle={sliderStyle}
                                  domain={[120, 175]}
                                  step={1}
                                  mode={2}
                                  values={[30]}
                                  onUpdate={this.onUpdateMobile}>
                                  <Rail>
                                    {({ getRailProps }) => (
                                      <div style={railStyle} {...getRailProps()} />
                                    )}
                                  </Rail>
                                  <Handles>
                                    {({ handles, getHandleProps }) => (
                                      <div className="slider-handles">
                                        {handles.map(handle => (
                                          <Handle
                                            key={handle.id}
                                            handle={handle}
                                            getHandleProps={getHandleProps}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </Handles>
                                  <Tracks right={false}>
                                    {({ tracks, getTrackProps }) => (
                                      <div className="slider-tracks">
                                        {tracks.map(({ id, source, target }) => (
                                          <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </Tracks>
                                </Slider>
                                <input
                                  type="text"
                                  className="slider-input"
                                  name="sealMaxWidth"
                                  value={sealMaxWidth + ' px'}
                                  disabled={true}
                                  /* onChange={evt => {
                              console.log(evt.target.value);
                              this.onUpdate(evt.target.value);
                            }} */
                                />
                              </div>
                            </div>

                            {/* Website Name on Trust Seal */}
                            <div className="custom-gap">
                              <p
                                className={`${
                                  sealType === 'textShield'
                                    ? 'display-none'
                                    : 'display-block website-info'
                                }`}>
                                <label> Website Name on Trust Seal</label>
                                <OverlayTrigger placement="right" overlay={renderTooltip()}>
                                  <span className="tooltip-span">
                                    <img src={images.info} alt="" />
                                  </span>
                                </OverlayTrigger>
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="website-name"
                                  name="label"
                                  value={label}
                                  onChange={event => {
                                    this.handleInputChange('label', event.target.value);
                                  }}
                                  onBlur={evt => {
                                    if (isTextElementChanged) {
                                      this.saveSeal();
                                      this.setState({
                                        isTextElementChanged: false
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>

                            {/* Offset Placement */}
                            <div
                              className={`${
                                sealType === 'premium' ||
                                sealType === 'premiumMini' ||
                                (floatSide === 'standAlone' || sealType === 'textShield')
                                  ? 'display-none'
                                  : 'display-block'
                              }`}>
                              <p>
                                <label> Offset Placement ( px )</label>
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="offer-placement"
                                  name="distanceFromEdge"
                                  value={distanceFromEdge}
                                  onChange={event => {
                                    this.handleInputChange('distanceFromEdge', event.target.value);
                                  }}
                                  onBlur={() => {
                                    if (isTextElementChanged) {
                                      this.saveSeal();
                                      this.setState({
                                        isTextElementChanged: false
                                      });

                                      document.getElementById('btnDisplayModalSealInfo').click();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            {/** */}
                          </div>
                          <div className="seal-content-column pl-10">
                            {/* Seal Image */}
                            <h2 className="seal-preview custom-gap">Preview</h2>

                            {/* desktop seal */}
                            <div
                              style={{
                                display:
                                  sealType !== 'checkout' &&
                                  sealType !== 'insurance' &&
                                  sealType !== 'textShield'
                                    ? 'block'
                                    : 'none'
                              }}>
                              {!showMobileSeal && trustSealPath ? (
                                <img
                                  className="
                                            my-2
                                          "
                                  src={trustSealPath}
                                  alt="Seal"
                                  style={
                                    sealType === 'side'
                                      ? {
                                          maxHeight: '250px',
                                          minHeight: sealMaxWidth + 'px',
                                          cursor: 'pointer',
                                          display: showMobileSeal ? 'none' : 'block'
                                        }
                                      : sealType === 'checkout' ||
                                        sealType === 'insurance' ||
                                        sealType === 'textShield'
                                        ? {
                                            display: 'none'
                                          }
                                        : {
                                            maxWidth: sealMaxWidth + 'px',
                                            height: 'auto',
                                            cursor: 'pointer',
                                            display: showMobileSeal ? 'none' : 'block'
                                          }
                                  }
                                  onClick={() => window.open(certificateURL, '_blank')}
                                />
                              ) : (
                                <div
                                  style={{
                                    display: showMobileSeal ? 'none' : 'block'
                                  }}>
                                  <Spinner />
                                </div>
                              )}
                            </div>

                            {/* Mobile seal */}
                            <div
                              style={{
                                display:
                                  sealType !== 'checkout' &&
                                  sealType !== 'insurance' &&
                                  sealType !== 'textShield'
                                    ? 'block'
                                    : 'none'
                              }}>
                              {showMobileSeal && trustSealPath ? (
                                <img
                                  className="
                                            my-2
                                          "
                                  src={trustSealPath}
                                  /* src={
                                  businessPciStatus !== "pass"
                                    ? demoTrustSealPath
                                    : trustSealPath
                                } */
                                  alt="Seal"
                                  onError={() =>
                                    this.setState({
                                      trustSealPath: images.tgSealDefault
                                    })
                                  }
                                  style={
                                    sealType === 'side'
                                      ? {
                                          maxHeight: '250px',
                                          minHeight: sealMaxWidthOnMobile + 'px',
                                          cursor: 'pointer',
                                          display: showMobileSeal ? 'block' : 'none'
                                        }
                                      : sealType === 'checkout' ||
                                        sealType === 'insurance' ||
                                        sealType === 'textShield'
                                        ? {
                                            display: 'none'
                                          }
                                        : {
                                            maxWidth: sealMaxWidthOnMobile + 'px',
                                            height: 'auto',
                                            cursor: 'pointer',
                                            display: showMobileSeal ? 'block' : 'none'
                                          }
                                  }
                                  onClick={() => window.open(certificateURL, '_blank')}
                                />
                              ) : (
                                <div
                                  style={{
                                    display: showMobileSeal ? 'block' : 'none'
                                  }}>
                                  <Spinner />
                                </div>
                              )}
                            </div>

                            {/* Checkout and Banner seal */}
                            <div
                              style={{
                                display:
                                  sealType === 'checkout' ||
                                  sealType === 'insurance' ||
                                  sealType === 'textShield'
                                    ? 'block'
                                    : 'none'
                              }}>
                              {sealType === 'textShield' && trustSealPath ? (
                                <div
                                  style={{
                                    width: '235px',
                                    padding: '10px',
                                    borderRadius: '3px',
                                    background:
                                      ((color === 'black' || color === 'white') && '#000000') ||
                                      '#ffffff'
                                  }}>
                                  <table border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                      <td
                                        width={
                                          parseInt(sealMaxWidth) + parseInt(sealFontSize) + 'px'
                                        }>
                                        <img
                                          className="
                                        my-2
                                      "
                                          src={trustSealPath}
                                          /* src={
                                        businessPciStatus !== "pass"
                                          ? demoTrustSealPath
                                          : trustSealPath
                                      } */
                                          alt="Seal"
                                          style={
                                            sealType === 'side'
                                              ? {
                                                  maxHeight: '250px',
                                                  minHeight: sealMaxWidth + 'px',
                                                  cursor: 'pointer',
                                                  display: showMobileSeal ? 'none' : 'block'
                                                }
                                              : sealType === 'checkout' || sealType === 'insurance'
                                                ? {
                                                    display: 'none'
                                                  }
                                                : sealType === 'textShield'
                                                  ? {
                                                      maxWidth:
                                                        parseInt(sealMaxWidth) +
                                                        parseInt(sealFontSize) +
                                                        'px',
                                                      paddingBottom:
                                                        ((sealFontSize === '12' ||
                                                          sealFontSize === '16' ||
                                                          sealFontSize === '18') &&
                                                          '2px') ||
                                                        '0'
                                                    }
                                                  : {
                                                      maxWidth: sealMaxWidth + 'px',
                                                      height: 'auto',
                                                      cursor: 'pointer',
                                                      display: showMobileSeal ? 'none' : 'block'
                                                    }
                                          }
                                          onClick={() => window.open(certificateURL, '_blank')}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          paddingLeft: '5px',
                                          fontSize: sealFontSize + 'px',
                                          verticalAlign: 'middle',
                                          color: sealFontColor
                                        }}>
                                        Secure Transaction
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              ) : trustSealPath ? (
                                <img
                                  className="my-2"
                                  src={trustSealPath}
                                  /* src={
                                    businessPciStatus !== "pass"
                                      ? demoTrustSealPath
                                      : trustSealPath
                                  } */
                                  alt="Seal"
                                  onError={() =>
                                    this.setState({
                                      trustSealPath: images.tgSealDefault
                                    })
                                  }
                                  onClick={() => window.open(certificateURL, '_blank')}
                                  style={{
                                    maxWidth: sealMaxWidth + parseInt(100) + 'px',
                                    cursor: 'pointer',
                                    verticalAlign: 'middle'
                                  }}
                                />
                              ) : (
                                <div>
                                  <Spinner />
                                </div>
                              )}
                            </div>
                          </div>

                          {sealCode.textboxCode && (
                            <>
                              <div className="seal-code-textbox">
                                <div>
                                  <p className="seal-code-copy-pase-text">
                                    Copy and Paste Your Seal Code
                                  </p>
                                  <div className="seal-code">{sealCode.textboxCode}</div>
                                </div>
                                <div className="seal-code-actions">
                                  <div>
                                    {(isGoogleTagManagerEnabled === true && (
                                      <button
                                        className="btn-tgdark-black-outline btn-md"
                                        onClick={() => this.toggleGoogleTagManager(false)}>
                                        <b className="fas fa-undo" />&nbsp; Back
                                      </button>
                                    )) || (
                                      <button
                                        className="btn-tgdark-black-outline btn-md"
                                        data-toggle="modal"
                                        data-target="#sealGoogleTagManagerCnfModal">
                                        <b className="far fa-tag" />&nbsp; Google Tag Manager
                                      </button>
                                    )}
                                  </div>
                                  <div>
                                    <CopyToClipboard
                                      text={sealCode.textboxCode}
                                      onCopy={() =>
                                        this.setState(
                                          {
                                            isCopied: true
                                          },
                                          () => {
                                            this.setState({
                                              isCopied: false
                                            });
                                            toast.success('Seal code copied successfully.', {
                                              position: toast.POSITION.TOP_RIGHT,
                                              autoClose: 2500
                                            });
                                          }
                                        )
                                      }>
                                      <button className="btn-tgdark-black-outline btn-md">
                                        <b className="far fa-copy" />&nbsp; Copy to Clipboard
                                      </button>
                                    </CopyToClipboard>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <TrustSealsInfo />

        <TrustSealsCreatePreset createSealPreset={this.createSealPreset} />

        <TrustSealsDeletePresetConfirmation removeSeal={this.removeSeal} />

        <button
          id="btnDisplayModalSealInfo"
          data-toggle="modal"
          data-target="#modalSealInfo"
          style={{ display: 'none' }}>
          Seal Info
        </button>

        <SealGoogleTagManagerCnfModal toggleGoogleTagManager={this.toggleGoogleTagManager} />
      </Aux>
    );
  }
}

SealComponent.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      goToDashboard: () => push('/')
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealComponent);
