import React from "react";
import { Modal, Button } from "react-bootstrap";
import config from "../../../helpers/config";

const frequencyModal = (props) => {
  const textLoading = "Updating...";
  let buttonText = "Update";
  let isDisabledButton = false;
  if (props.onSave || props.invalidTimeZone) {
    buttonText = textLoading;
    if (props.invalidTimeZone) {
      buttonText = "Update";
    }
    isDisabledButton = true;
  }
  return (
    <Modal show={props.show} onHide={props.close}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <button
          type="button"
          onClick={props.close}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="pt-2 pb-4">
          <p>{props.message}</p>
          <div className="row">
            {props.type === "pci" ? (
              <>
                <div className="col-sm">
                  <div className="form-group">
                    <label htmlFor="timeZoneSelect">Time Zone</label>
                    <select
                      className="form-control"
                      id="timeZoneSelect"
                      onChange={props.onHandlerTimeZone}
                      value={props.timezone}>
                      <option value="0">Please Select Timezone</option>
                      {config.timezones.map((v, i) => {
                        return (
                          <option key={i} value={v.timezone}>
                            {v.timezone}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm" style={{ marginLeft: "6.5rem" }}>
                  <div className="form-group">
                    <label htmlFor="timeZoneSelect">Scan Time</label>
                    <select
                      className="form-control col-sm-12"
                      onChange={props.onHandlerTimeHour}
                      id="timeZoneSelect"
                      value={props.time}>
                      <option value="24">12:00 AM</option>
                      <option value="1">01:00 AM</option>
                      <option value="2">02:00 AM</option>
                      <option value="3">03:00 AM</option>
                      <option value="4">04:00 AM</option>
                      <option value="5">05:00 AM</option>
                      <option value="6">06:00 AM</option>
                      <option value="7">07:00 AM</option>
                      <option value="8">08:00 AM</option>
                      <option value="9">09:00 AM</option>
                      <option value="10">10:00 AM</option>
                      <option value="11">11:00 AM</option>
                      <option value="12">12:00 PM</option>
                      <option value="13">01:00 PM</option>
                      <option value="14">02:00 PM</option>
                      <option value="15">03:00 PM</option>
                      <option value="16">04:00 PM</option>
                      <option value="17">05:00 PM</option>
                      <option value="18">06:00 PM</option>
                      <option value="19">07:00 PM</option>
                      <option value="20">08:00 PM</option>
                      <option value="21">09:00 PM</option>
                      <option value="22">10:00 PM</option>
                      <option value="23">11:00 PM</option>
                    </select>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <div style={{ padding: "0 1rem" }}>
        <Modal.Footer>
          <Button
            style={{ float: "right", marginLeft: "10px" }}
            bsStyle="success"
            onClick={props.save}
            disabled={isDisabledButton}>
            {buttonText}
          </Button>
          <Button style={{ float: "right" }} onClick={props.close}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default frequencyModal;
