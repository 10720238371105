import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getStorageItem,
  setStorageItem,
  removeStorageItem,
  removeAllStorage,
} from "../../../helpers/storage";
import ContentLoader from "react-content-loader";
import AppContext from "../../../components/context/appContext";
import { callApiGetBusinessStatistics } from "../../../services";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class SealsResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: null,
      businessPciStatus: null,
      businessStatistics: null,
    };
  }

  async componentDidMount() {
    const businessData = await callApiGetBusinessStatistics({
      businessId: this.props.businessId,
    });

    this.setState({
      accountType: businessData.data.accountType,
      businessPciStatus:
        businessData.data.accountType !== "free" &&
        businessData.data.accountType !== "monthly-scan"
          ? businessData.data.pciScanStatus
          : "upgrade",
      businessStatistics: businessData.data,
    });
  }

  goToPageTrustSealsPage() {
    this.context.updateCurrentBusinessId(this.props.businessId);
    setStorageItem("businessId", cryptr.encrypt(this.props.businessId));
    this.props.goToPageTrustSeals();
  }

  render() {
    const { businessPciStatus, accountType } = this.state;
    let sealTextStatus, sealLinkClass, sealIconClass, sealStyle;

    switch (businessPciStatus) {
      case "pass":
        sealTextStatus = "Active";
        sealLinkClass = "tg-dashboard-passed-link";
        sealIconClass = "fa fa-check-circle h1-icon";
        sealStyle = { color: "#33981f" };
        break;
      case "passed":
        sealTextStatus = "Active";
        sealLinkClass = "tg-dashboard-passed-link";
        sealIconClass = "fa fa-check-circle h1-icon";
        sealStyle = { color: "#33981f" };
        break;
      case "upgrade":
        sealTextStatus = "Upgrade";
        sealLinkClass = "tg-dashboard-passed-link";
        sealIconClass = "fa fa fa-clock h1-icon";
        sealStyle = { color: "orange" };
        break;
      default:
        sealTextStatus = "Inactive";
        sealLinkClass = "tg-dashboard-failed-link";
        sealIconClass = "fa fa-clock h1-icon";
        sealStyle = { color: "#7f7f7f" };
        break;
    }

    return (
      /* <button
            data-toggle={businessDetail.accountType === "free" ? "modal" : ""}
            data-target={
              businessDetail.accountType === "free" ? "#limitedScanPopup" : ""
            }
            onClick={() => {
              businessDetail.accountType === "free"
                ? void 0
                : goToPageTrustSeals();
            }}
            className={sealLinkClass}
            style={sealStyle}
            data-testid="dashboardSslButton"
          >
            <i className={sealIconClass} />
            <span
              style={{
                display: "block",
                clear: "both",
                fontSize: "12px",
                fontWeight: "200",
                marginTop: "20px",
                sealStyle,
              }}
            >
              {sealTextStatus}
            </span>
          </button> */

      <button
        data-toggle={
          accountType === "free" || accountType === "monthly-scan"
            ? "modal"
            : ""
        }
        data-target={
          accountType === "free" || accountType === "monthly-scan"
            ? "#limitedScanModal"
            : ""
        }
        onClick={() => {
          accountType === "free" || accountType === "monthly-scan"
            ? void 0
            : this.goToPageTrustSealsPage();
        }}
        className={sealLinkClass}
        style={sealStyle}
        data-testid="dashboardSslButton"
      >
        <i className={sealIconClass} />
        <span
          style={{
            display: "block",
            clear: "both",
            fontSize: "12px",
            fontWeight: "200",
            marginTop: "20px",
            sealStyle,
          }}
        >
          {sealTextStatus}
        </span>
      </button>
    );
  }
}

SealsResult.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPageTrustSeals: () => push("/trust-seals"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealsResult);
