import React from "react";
import VerificationReports from "./verificationReports";
// import PciReports from "../../views/reports/reports";
import PciReports from "./pciReports";
import MalwareReports from "../../components/malware/malware-reports/malwareReports";
import SealReports from "./sealReports";
import SslScanReports from "./sslScanReports";
import OthersReport from "./othersReport";
import EmailStatisticsReport from "./emailStatisticsReport";
import SmsStatisticsReport from "./smsStatisticsReport";
import InsuranceStatisticsReport from "./insuranceStatisticsReport";
import SslRenewalReports from "./sslRenewalReports";
import SignupStatisticsReport from "./signupStatisticsReport";

class ReportsTabBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="tab-content" id="reportTabContent">
        <div
          className="tab-pane fade in active"
          id="verification-report"
          role="tabpanel"
          aria-labelledby="verification-report-tab"
        >
          <VerificationReports reportType={this.props.reportType} />
        </div>
        <div
          className="tab-pane fade"
          id="pci-scan-report"
          role="tabpanel"
          aria-labelledby="pci-scan-report-tab"
        >
          <PciReports reportType={this.props.reportType} />
        </div>
        <div
          className="tab-pane fade"
          id="external-malware-scan"
          role="tabpanel"
          aria-labelledby="external-malware-scan-tab"
        >
          <MalwareReports reportType={this.props.reportType} />
        </div>

        <div
          className="tab-pane fade"
          id="seals-report"
          role="tabpanel"
          aria-labelledby="seals-report-tab"
        >
          <SealReports reportType={this.props.reportType} />
        </div>
        <div
          className="tab-pane fade"
          id="ssl-scan-report"
          role="tabpanel"
          aria-labelledby="ssl-scan-report-tab"
        >
          <SslScanReports reportType={this.props.reportType} />
        </div>

        <div
          className="tab-pane fade"
          id="ssl-renewal-app"
          role="tabpanel"
          aria-labelledby="ssl-renewal-app-tab"
        >
          <SslRenewalReports reportType={this.props.reportType} />
        </div>

        <div
          className="tab-pane fade"
          id="active-old-free-businesses"
          role="tabpanel"
          aria-labelledby="active-old-free-businesses-tab"
        >
          <OthersReport reportType={this.props.reportType} />
        </div>

        <div
          className="tab-pane fade"
          id="email-statistics"
          role="tabpanel"
          aria-labelledby="email-statistics"
        >
          <EmailStatisticsReport reportType={this.props.reportType} />
        </div>

        <div
          className="tab-pane fade"
          id="sms-statistics"
          role="tabpanel"
          aria-labelledby="sms-statistics"
        >
          <SmsStatisticsReport reportType={this.props.reportType} />
        </div>

        <div
          className="tab-pane fade"
          id="insurance-statistics"
          role="tabpanel"
          aria-labelledby="insurance-statistics"
        >
          <InsuranceStatisticsReport reportType={this.props.reportType} />
        </div>

        <div
          className="tab-pane fade"
          id="signup-statistics"
          role="tabpanel"
          aria-labelledby="signup-statistics"
        >
          <SignupStatisticsReport reportType={this.props.reportType} />
        </div>
      </div>
    );
  }
}

export default ReportsTabBody;
