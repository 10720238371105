import { api } from "./api";
import { callApi } from "../helpers/index";

function callCreateInsurance(insuranceData) {
  return callApi().then((token) => {
    return api.service("insurance").create(insuranceData);
  });
}

function callGetInsurance(businessId) {
  return callApi().then((token) => {
    return api.service("insurance").find({ query: { businessId: businessId } });
  });
}

function callOnCreateAutoRenewingSSL(data) {
  return callApi().then((token) => {
    return api.service("renewDomainSSLs").create(data);
  });
}

function callOnGetAutoRenewingSSL(query) {
  return callApi().then((token) => {
    return api.service("renewDomainSSLs").find(query);
  });
}

function callApiGetInsuranceDocument(businessId) {
  return callApi().then((token) => {
    return api
      .service("api/insurance")
      .get("insurance-data", { query: { businessId: businessId } });
  });
}

function callApiActivateInsurance(businessId, insuranceId) {
  api.service("business").patch(businessId, { insuranceStatus: "completed" });

  return api.service("insurance").patch(insuranceId, {
    insuranceStatus: "completed",
  });
}

export {
  callCreateInsurance,
  callGetInsurance,
  callOnCreateAutoRenewingSSL,
  callOnGetAutoRenewingSSL,
  callApiGetInsuranceDocument,
  callApiActivateInsurance,
};
