import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { callOnGetPciScanData } from "../../../../services/scanHistoryService";
import ScanStatusButton from "../scanStatusButton";
import ModalDownloadOption from "../modals/modalDownloadOption";
import ModalRescan from "../modals/modalRescan";
import { Modal } from "react-bootstrap";
import { capitalize } from "../../../../helpers/capitalize";
import AppContext from "../../../../components/context/appContext";
import SlidePanelContext from "../../../../components/context/slidePanelContexts";
import RouteContext from "../../../../components/context/routeContext";
import Spinner from "../../../../components/ui/spinner/spinnerRectScan";
import fileDownload from "js-file-download";
import {
  callOnGetBusinessById,
  callDownloadPCIreport,
  callCreateBeyondScan,
  callOnHostsResultsByScanId,
} from "../../../../services/index";
import moment from "moment";
import momentz from "moment-timezone";
import { callDownloadFLreport } from "../../../../services/frontlineService";

class PciSlidePanelData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanData: [],
      scanNumber: [],
      reportType: "pci",
      reportFormat: "pdf",
      isDownloading: false,
      isGenerating: false,
      currentButtonIndexClicked: null,
      scanning: false,
      rescansubmitted: false,
      showPCIscanToast: false,
      showModalRescan: false,
      scanTimeDetails: {
        scanTime: null,
        scanTimeZone: null,
      },
      hostScanResults: [],
      activePage: 1,
    };
  }

  async componentWillReceiveProps() {
    const noLimit = this.props.noLimit;
    const scanHistory = await callOnGetPciScanData(
      this.props.businessId,
      noLimit
    );
    this.getCurrentScanHistory(this.props.activePage, scanHistory);
  }

  getCurrentScanHistory = (pageNumber = 1, scanHistory) => {
    if (this.props.activePage) {
      const pageResults = scanHistory.data;
      const pageLimit = 10;
      const currentPage = pageNumber;

      const offset = (currentPage - 1) * pageLimit;
      let scanHistories = null;

      if (pageResults.length > 10) {
        scanHistories = pageResults.slice(offset, offset + pageLimit);
      } else {
        scanHistories = pageResults;
      }

      this.setState({
        activePage: this.props.activePage,
        scanData: scanHistories,
      });
    }
  };

  async getScanHistory() {
    const noLimit = this.props.noLimit;
    const scanHistory = await callOnGetPciScanData(
      this.props.businessId,
      noLimit
    );

    if (this.props.activePage) {
      this.getCurrentScanHistory(this.props.activePage, scanHistory);
    } else {
      this.setState({
        scanData: scanHistory.data,
      });
    }
  }

  componentDidMount() {
    this.getScanHistory();
  }

  downloadFLreport = async (index, report) => {
    this.setState({ isDownloading: true, currentButtonIndexClicked: index });
    const reportData = {
      file: report.report_file.split("/")[1],
      purpose: "download_scan_report",
    };

    const url = await callDownloadFLreport(reportData);
    this.setState({ isDownloading: false });
    console.log("url: ", url.data);
    return (window.location.href = url.data);
  };

  getDownloadReport = async (index, data) => {
    const toggledBusinessIndex = this.props.index;
    const businessData = this.context.userBusinessData[toggledBusinessIndex];
    const toggledBusinessId = businessData._id;
    const accountId = businessData.scanVendor.accountID;
    this.setState({ isDownloading: true });
    console.log("business: ", businessData);

    console.log("report clicked: ", index);
    console.log("data: ", data);

    const userBusiness = await callOnGetBusinessById(toggledBusinessId);
    const scanVendor = userBusiness.scanVendor;
    const networkID = scanVendor.owner;
    const api = scanVendor.apiKey;
    const businessName = businessData.primaryDomain;
    const ReportResponse = await callDownloadPCIreport({
      networkID: networkID,
      api: api,
      businessName: businessName,
      format: this.state.reportFormat,
      type: this.state.reportType,
      scanNumber: this.state.scanNumber,
      scanDate: this.state.scanDate,
    });
    await fileDownload(ReportResponse.data, ReportResponse.filename);
    this.setState({ isDownloading: false });
  };

  generateReport = async (index) => {
    this.setState({ currentButtonIndexClicked: index });
  };

  getHostsScanResult = async (owner, scanNumber) => {
    this.setState({ hostScanResults: [] });
    const results = await callOnHostsResultsByScanId(owner, scanNumber);
    if (results.data.data.length > 0) {
      this.setState({ hostScanResults: results.data.data });
    }
  };

  reportTypeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  selectedReport = (index, scanNumber, scanDate) => {
    this.setState({ scanDate: scanDate, scanNumber: scanNumber });
  };

  getVulnerabilityDetail = async (owner, id, scandate, severity) => {};

  scanHistoryContent() {
    const { scanData, reportFormat, reportType, hostScanResults } = this.state;

    const { index } = this.props;
    const business = this.context.userBusinessData[index];
    let highCountStyle = {
      display: "block",
      width: "34px",
      background: "rgba(255, 255, 255, 0.1)",
      color: "black",
      border: "1px solid #d8d4d4",
    };

    let mediumCountStyle = {
      display: "block",
      width: "34px",
      background: "rgba(255, 255, 255, 0.1)",
      color: "black",
      border: "1px solid #d8d4d4",
    };

    let lowCountStyle = {
      display: "block",
      width: "34px",
      background: "rgba(255, 255, 255, 0.1)",
      color: "black",
      border: "1px solid #d8d4d4",
    };

    return scanData.map((scan, i) => {
      const vendor = scan.vendor;
      const high = +scan.high;
      const medium = +scan.medium;
      const low = +scan.low;
      const status = scan.status;
      const scanNumber = scan.scanNumber;
      const wasStatus = scan.frontlineWasStatus;
      const reportStatus = scan.hasOwnProperty("reportStatus")
        ? scan.reportStatus
        : null;

      let generateStatusButton = "Generate Report";
      let reportButtonDisabled = false;
      if (reportStatus !== null) {
        if (reportStatus === "generating") {
          generateStatusButton = "Generating";
          reportButtonDisabled = true;
        }
      }

      let scanDate = null;

      if (scan.scanDate !== null) {
        scanDate = momentz(scan.scanDate)
          .tz(
            business.pciScanTimeZone !== undefined &&
            business.pciScanTimeZone !== null &&
            business.pciScanTimeZone !== ""
              ? business.pciScanTimeZone
              : "America/Chicago"
          )
          .format("MM/DD/YYYY hh:mm a");
      } else {
        scanDate = "N/A";
      }

      const owner = scan.owner;
      const pciScanId = scan.scanIdStamped;
      const wasScanId = scan.webScanIdStamped;

      const scanId = scan._id;
      const frequency =
        scan.flag === "manual" ? "Rescanned" : scan.scanFrequency;
      let rescanButton = null;
      let statusButton = null;

      const riskFactors = [
        high > 0 ? 8 : 0,
        medium > 0 ? 4 : 0,
        low > 0 ? 1 : 0,
      ];

      let icnClass = "";
      let reportGeneratedButton = "";
      if (scan.reportStatus) {
        if (scan.reportDetails.report_status) {
          icnClass =
            scan.reportDetails.report_status === "generating"
              ? "fa fa-clock icn-disabled"
              : "fa fa-download icn";

          reportGeneratedButton = scan.reportStatus ? (
            <button
              className={
                scan.reportDetails.report_status === "generating"
                  ? "reportGeneratedBtn"
                  : "reportReadyBtn"
              }
              disabled={scan.reportDetails.report_status === "generating"}
              onClick={() => this.downloadFLreport(i, scan.reportDetails)}
            >
              {scan.reportDetails.report_status === "generating"
                ? "Report Generated"
                : "Download Report"}{" "}
              <span className={icnClass} />
            </button>
          ) : null;
        }
      }

      const modalShowDownloadReportOpt = (
        <ModalDownloadOption
          reports={[]}
          targetID={"dash-download-modal-" + i}
          defaultValueFormat={reportFormat}
          defaultValueType={reportType}
          reportTypeHandler={this.reportTypeHandler}
          downloadReport={() =>
            this.getDownloadReport(i, {
              pciScanId: pciScanId,
              wasScanId: wasScanId,
            })
          }
          vendor={vendor}
          scanNumber={scanNumber}
          showRemediation={status === "fail"}
          hostScanResults={hostScanResults}
        />
      );

      statusButton = (
        <ScanStatusButton
          status={scan.status}
          selectedReport={() =>
            this.selectedReport(index, scanNumber, scanDate)
          }
          index={i}
        />
      );

      if (
        status !== "pending" &&
        status !== "running" &&
        status !== "finalizing"
      ) {
        let falsePositiveButton = false;
        if (high >= 1 || medium >= 1) {
          falsePositiveButton = true;
        }
        if (business.accountType === "free") {
          if (i === 1) {
            rescanButton = (
              <a
                rel="noopener noreferrer"
                href="https://trustguard.com/home"
                target="_blank"
                className="btn btn-success btn-sm"
              >
                <i className="fas fa-search" />&nbsp; Rescan
              </a>
            );
          }
        } else {
          if (i === 0) {
            rescanButton = (
              <>
                <RouteContext.Consumer>
                  {(value) => {
                    return (
                      <ModalRescan
                        index={i + 1}
                        showModal={this.showModal}
                        closeModalRescan={this.closeModalRescan}
                        toggledIndex={index}
                        scanData={{ _id: business._id }}
                        rescan={this.startPCIscan}
                        goToPageVulnerabilities={() =>
                          value.goToPageVulnerabilities(
                            scan._id,
                            owner,
                            riskFactors,
                            scanDate,
                            business
                          )
                        }
                        owner={owner}
                        resultId={scanId}
                        scandate={scanDate}
                        high={high}
                        medium={medium}
                        low={low}
                      />
                    );
                  }}
                </RouteContext.Consumer>
              </>
            );
          }
        }
      } else {
        if (i === 1) {
          if (
            status === "running" ||
            status === "pending" ||
            status === "finalizing"
          )
            rescanButton = (
              <button
                type="button"
                className="btn btn-success btn-sm"
                disabled={true}
              >
                <i className="fas fas-sync" />&nbsp; Rescan
              </button>
            );
        }
      }

      let downloadReportButton = (
        <span style={{ textTransform: "capitalize" }}>{statusButton}</span>
      );

      if (
        status !== "pending" &&
        status !== "running" &&
        status !== "finalizing"
      ) {
        if (vendor !== "frontline") {
          downloadReportButton = (
            <button
              onClick={() => this.getHostsScanResult(owner, scanNumber)}
              className="tg-dashboard-link"
              data-toggle="modal"
              data-target={"#dash-download-modal-" + i}
              style={{ cursor: "pointer", textTransform: "capitalize" }}
            >
              {statusButton}
            </button>
          );
        } else {
          downloadReportButton = (
            <button
              onClick={() => this.getHostsScanResult(owner, scanNumber)}
              className="tg-dashboard-link"
              data-toggle={wasStatus === "completed" ? "" : ""}
              data-target={"#dash-download-modal-" + i}
              style={{ cursor: "pointer", textTransform: "capitalize" }}
            >
              {statusButton}
            </button>
          );
        }
      }

      let generateReportButton = (
        <button
          onClick={() => this.generateReport(i)}
          className="btn"
          data-toggle="modal"
          data-target={"#dash-download-modal-" + i}
          style={{ cursor: "pointer", textTransform: "capitalize" }}
          disabled={reportButtonDisabled}
        >
          {i === this.state.currentButtonIndexClicked
            ? "Generating Report"
            : generateStatusButton}
        </button>
      );

      const total = high + medium + low;
      if (high > 0) {
        highCountStyle = {
          display: "block",
          width: "34px",
          cursor: "pointer",
        };
      }

      if (medium > 0) {
        mediumCountStyle = {
          display: "block",
          width: "34px",
          cursor: "pointer",
        };
      }

      if (low > 0) {
        lowCountStyle = {
          display: "block",
          width: "34px",
          cursor: "pointer",
        };
      }

      return (
        <RouteContext.Consumer>
          {(value) => (
            <tr className="pciTable 123">
              <td />

              <td>{scanDate}</td>
              <td style={{ textTransform: "capitalize" }}>
                {frequency !== null
                  ? status !== "invalid"
                    ? frequency
                    : "Invalid URL"
                  : ""}
              </td>
              <td className="text-center px-0">
                <span
                  style={{
                    textAlign: "center",
                    width: "42px",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                >
                  {high > 0 ? (
                    <span
                      className="bg bg-sm bg-serious"
                      style={highCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }
                    >
                      {" "}
                      {high}{" "}
                    </span>
                  ) : (
                    <span
                      className="bg bg-sm bg-serious"
                      style={highCountStyle}
                    >
                      {" "}
                      {high}{" "}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    width: "43px",
                    display: "inline-block",
                    paddingLeft: "6px",
                  }}
                >
                  {medium > 0 ? (
                    <span
                      className="bg bg-sm bg-medium"
                      style={mediumCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }
                    >
                      {" "}
                      {medium}{" "}
                    </span>
                  ) : (
                    <span
                      className="bg bg-sm bg-medium"
                      style={mediumCountStyle}
                    >
                      {" "}
                      {medium}{" "}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    width: "43px",
                    display: "inline-block",
                    paddingLeft: "6px",
                  }}
                >
                  {low > 0 ? (
                    <span
                      className="bg bg-sm bg-green"
                      style={lowCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }
                    >
                      {" "}
                      {low}{" "}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-green" style={lowCountStyle}>
                      {" "}
                      {low}{" "}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: "center",
                    width: "43px",
                    display: "inline-block",
                    paddingLeft: "6px",
                  }}
                >
                  {total > 0 ? (
                    <span
                      className="bg bg-sm bg-green"
                      style={lowCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }
                    >
                      {" "}
                      {total}{" "}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-green" style={lowCountStyle}>
                      {" "}
                      {total}{" "}
                    </span>
                  )}
                </span>
              </td>
              <td className="pr-0">
                {downloadReportButton}
                {modalShowDownloadReportOpt}
              </td>
              {/* {rescanButton} */}

              <td className="text-success scan-btn">
                {" "}
                {reportGeneratedButton}
              </td>
            </tr>
          )}
        </RouteContext.Consumer>
      );
    });
  }

  startPCIscan = async () => {
    const flag = "manual";
    const user = this.context.userData;
    const business = this.context.userBusinessData[this.props.index];
    this.setState({ showModalRescan: false });
    if (user) {
      let userEmail = user.email;
      let userId = user._id;
      if (user.userDetails.isAdmin) {
        userEmail = user.userDetails.email;
        userId = user.userDetails._id;
      }

      const submitData = {
        _id: business._id,
        userId: userId !== undefined ? userId : null,
        loginEmail: userEmail,
        supportEmail: business.supportEmail,
        businessName: business.businessName,
        primaryDomain: business.primaryDomain,
        scanFrequency: business.scanFrequency,
        supportPhone: business.supportPhone,
        state: business.state,
        zip: business.zip,
        address: business.address,
        address2: business.address2,
        city: business.state,
        country: business.country,
        flag: flag,
        purpose: "create-scan",
        pciScanTime: this.state.scanTimeDetails.scanTime,
        pciScanTimeZone: this.state.scanTimeDetails.scanTimeZone,
      };
      const beyondsecurity = await callCreateBeyondScan(submitData);
      if (beyondsecurity.success) {
        window.location.reload();
      }
    }
  };

  showModal = () => {
    this.context.showModalRescan();
  };

  closeModalRescan = () => {
    this.context.closeModalRescan();
  };
  render() {
    const { isDownloading, showModalRescan } = this.state;
    return (
      <>
        <Modal
          show={isDownloading}
          backdropClassName="modalBackdrop"
          size="lg"
          dialogClassName="modalBoxDialog"
        >
          <Modal.Body>
            <p className="textNotify">
              One moment, we are generating your report.
            </p>
            <Spinner />
          </Modal.Body>
        </Modal>

        {this.scanHistoryContent()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
PciSlidePanelData.contextType = AppContext;
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PciSlidePanelData);
