import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onClickSendMail } from "../../modules/actions";

class RstPasCnfMdl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {}

  sendResetPasswordEmail = async () => {
    if (this.props.resetPasswordEmail) {
      let sendToMail = {
        action: "create_email_history",
        emailTag: "reset-password",
        query: {
          email: this.props.resetPasswordEmail,
        },
      };
      this.props.onClickSendMail(sendToMail);

      document.getElementById("btnOpnAccScrtMdl").click();

      toast.success("Reset password email sent successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  render() {
    return (
      <div className="modal fade" id="rstPasCnfMdl" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">Reset Password Email</h4>
              <button
                id="btnClsRstPasCnfMdl"
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => {
                  document.getElementById("btnOpnAccScrtMdl").click();
                }}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="pt-2 pb-4">
                <h5>Are you sure to send reset password email to this user?</h5>
              </div>
              <div className="modal-footer text-right pt-4 px-0 pb-3">
                <button
                  type="button"
                  className="btn-tglight2 btn-140-width btn-md  mr-2"
                  data-dismiss="modal"
                  onClick={() => {
                    document.getElementById("btnOpnAccScrtMdl").click();
                  }}
                >
                  No
                </button>

                <button
                  type="button"
                  className="btn-success btn-140-width btn-md "
                  data-dismiss="modal"
                  onClick={() => {
                    this.sendResetPasswordEmail();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onClickSendMail }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RstPasCnfMdl);
