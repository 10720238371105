import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { createBusinessUserData } from '../../modules/actions/index';
import AppContext from '../../components/context/appContext';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const AddCardModal = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { data, error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href
      }
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)

      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      console.log('stripe payment success data', data);
    }
  };

  return (
    <div id="billingAddCardModal" className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title f800">Add Card</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <PaymentElement />
              <button className="btn-success btn-md  btn-140-width pull-right" disabled={!stripe}>
                Submit
              </button>
            </form>
          </div>
          <div className="modal-footer text-danger">
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCardModal;
