import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { callApiRegenerateAllSeals } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class RegenerateSealsReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {};

    this.regenerateSeals = this.regenerateSeals.bind(this);
  }

  async regenerateSeals() {
    await callApiRegenerateAllSeals();

    toast.success("Seals are regenerated successfully.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500,
    });
  }

  render() {
    const { display } = this.state;

    return (
      <>
        <div className="reportButton">
          <button
            className="btn-primary btn-sm"
            onClick={() => {
              this.regenerateSeals();
            }}
          >
            Regenerate All
          </button>
        </div>
        <p>Regenerate all seals.</p>

        <hr />
      </>
    );
  }
}

RegenerateSealsReport.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegenerateSealsReport);
