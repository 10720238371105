import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import Header from "../header";
import { convertTimeTo } from "../../helpers/timeZone";
import AppContext from "../../components/context/appContext";
import { updateUserProfileData } from "../../modules/actions";
import { callDownloadAttachmentNotification } from "../../services/downloadReportService";
import fileDownload from "js-file-download";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      notifications: [],
      timezone: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;

    if (prevState.notifications !== userData.notifications) {
      this.setState(
        {
          notifications: userData.notifications,
          isLoading: false,
        },
        () => {
          const unseenNotifications =
            userData &&
            userData.hasOwnProperty("notifications") &&
            userData.notifications.length > 0
              ? userData.notifications.filter((v, i) => {
                  return v.status === "unseen" ? v : null;
                })
              : null;

          if (unseenNotifications && unseenNotifications.length > 0) {
            userData.notifications.map((v, i) => {
              v.status = "seen";
            });

            this.props.updateUserProfileData(userData._id, {
              purpose: "unseenNotificationCountUpdate",
              notifications: userData.notifications,
            });

            this.context.updateNotificationCount();
          }
        }
      );
    }
  }

  async onDownloadReport(e, data) {
    e.preventDefault();
    const downloadData = await callDownloadAttachmentNotification(data);
    return fileDownload(downloadData, data.name);
  }
  showContent(isLoading, notifications, timezone) {
    const screenHeight = window.innerHeight,
      screenWidth = window.innerWidth;

    return isLoading === true ? (
      <div style={{ marginTop: "40px" }}>
        <ContentLoader
          height={260}
          width={989}
          speed={1}
          primaryColor="#f3f3f3"
          secondaryColor="#e1dfdf">
          <rect x="21" y="22" rx="0" ry="0" width="250" height="20" />
          <rect x="371" y="22" rx="0" ry="0" width="250" height="20" />
          <rect x="722" y="22" rx="0" ry="0" width="250" height="20" />

          <rect x="21" y="72" rx="0" ry="0" width="250" height="10" />
          <rect x="371" y="72" rx="0" ry="0" width="250" height="10" />
          <rect x="722" y="72" rx="0" ry="0" width="250" height="10" />

          <rect x="21" y="122" rx="0" ry="0" width="250" height="10" />
          <rect x="371" y="122" rx="0" ry="0" width="250" height="10" />
          <rect x="722" y="122" rx="0" ry="0" width="250" height="10" />

          <rect x="21" y="172" rx="0" ry="0" width="250" height="10" />
          <rect x="371" y="172" rx="0" ry="0" width="250" height="10" />
          <rect x="722" y="172" rx="0" ry="0" width="250" height="10" />

          <rect x="21" y="222" rx="0" ry="0" width="250" height="10" />
          <rect x="371" y="222" rx="0" ry="0" width="250" height="10" />
          <rect x="722" y="222" rx="0" ry="0" width="250" height="10" />

          <rect x="21" y="272" rx="0" ry="0" width="250" height="10" />
          <rect x="371" y="272" rx="0" ry="0" width="250" height="10" />
          <rect x="722" y="272" rx="0" ry="0" width="250" height="10" />

          <rect x="21" y="322" rx="0" ry="0" width="250" height="10" />
          <rect x="371" y="322" rx="0" ry="0" width="250" height="10" />
          <rect x="722" y="322" rx="0" ry="0" width="250" height="10" />
        </ContentLoader>
      </div>
    ) : (
      <div id="mcontent-wrapper">
        <div id="PCI-scan" className="">
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <div className="inner-ptab tab-content">
              <div id="seal" className="tab-pane fade in active">
                {notifications !== undefined && notifications.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Details</th>
                        <th scope="col">Regarding</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications
                        .map((notification) => {
                          return (
                            <tr key={notification._id}>
                              <td>
                                {" "}
                                {convertTimeTo(
                                  notification.date,
                                  timezone,
                                  "MMM Do YYYY"
                                )}
                              </td>
                              <td>
                                {notification.details} &nbsp;
                                {notification.attachment ? (
                                  <a
                                    onClick={(e) =>
                                      this.onDownloadReport(e, {
                                        bucketName: "tg3notifications",
                                        name: notification.attachment,
                                      })
                                    }
                                    href={""}>
                                    <i className="fas fa-paperclip" />&nbsp;
                                    Download Attachment
                                  </a>
                                ) : null}
                              </td>
                              <td>{notification.regarding}</td>
                            </tr>
                          );
                        })
                        .reverse()}
                    </tbody>
                  </table>
                ) : (
                  <div
                    style={{
                      display: "block",
                      width: screenWidth - 330,
                      height: screenHeight - 160,
                      paddingTop: screenHeight / 2 - 200,
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: 28,
                      textAlign: "center",
                      color: "#cccccc",
                      verticalAlign: "center",
                    }}>
                    <div style={{ height: 210 }}>
                      <i className="fa fa-bell bellIcon" />
                      <div
                        style={{
                          display: "block",
                          clear: "both",
                          paddingTop: 20,
                        }}>
                        No notifications.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading, notifications, timezone } = this.state;

    return (
      <>
        <Header titlePage="Notifications" bgColor="#F5F6F8" />

        {this.showContent(isLoading, notifications, timezone)}
      </>
    );
  }
}

Notifications.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateUserProfileData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
