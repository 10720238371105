import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import moment from "moment";
import Table from "../../components/ui/table/table";
import Aux from "../../components/hoc/containerAux";
import ControlButtons from "./controlButtons";
import { convert24HourFormat } from "../../helpers/timeZone";
import { viewUserBusinessAccountDashboard } from "../../helpers/general";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
import config from "../../helpers/config";
import { callOnGetUserByBusinessId } from "../../services";
import {
  getBusiness,
  resetStatus,
  getUserById,
  getDomains,
  getBusinesses,
  createNote,
  cancelOrActiveDomain,
  getUserListUsingBusinessId,
  updateBusinessAccountType,
} from "../../modules/actions/index";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class AdvancedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      domains: [],
      pciToolTipActive: false,
      pciToolTipText: "",
      userId: "",
      businesses: [],
      firstName: "",
      userLocked: false,
      notesInBusiness: [],
      selectedBusinessId: "",
      selectedBusinessName: "",
      noteType: "",
      note: "",
      customerNotification: false,
      taskOnDomain: "",
      selectedBusinessStatusLog: [],
      customerNoteModalComponent: null,
      businessUserList: null,
      businessId: null,
    };
  }

  componentDidMount() {
    let user = {};
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      user = JSON.parse(userString);
    }
    this.setState({
      userId: user._id,
    });
    var ID = getStorageItem("businessID");
    if (ID) {
      let businessID = cryptr.decrypt(ID);
      this.setState({
        businessId: businessID,
      });
      this.props.getBusiness();
      this.props.getUserById(user._id);
    }
  }

  componentWillMount() {
    removeStorageItem("businessID");
    this.populateBusinesses();
  }

  populateBusinesses() {
    let businessQuery;
    businessQuery = {
      action: "get-businesses-and-domains",
      status: {
        $in: ["active", "invalid"],
      },
      limit: this.props.resultLimit,
    };

    this.props.getBusinesses(businessQuery);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.domains) {
      this.setState({
        domains: newProps.domains,
      });
    }

    if (newProps.businesses) {
      this.setState(
        {
          businesses: newProps.businesses,
        },
        () => {}
      );
    }
  }

  onClickSetBusinessId = (
    businessId,
    taskOnDomain,
    businessName = null,
    businessStatusLog = null
  ) => {
    this.setState({
      selectedBusinessId: businessId,
      taskOnDomain: taskOnDomain,
      selectedBusinessName: businessName,
      selectedBusinessStatusLog: businessStatusLog,
    });

    this.props.getBusinessDetails(businessId);
  };

  onClickCancelOrActiveDomain = () => {
    if (this.state.selectedBusinessId) {
      this.props.cancelOrActiveDomain(
        this.state.selectedBusinessId,
        this.state.userId,
        this.state.taskOnDomain
      );
    }
  };

  changeBusinessAccountType = (
    userId,
    businessId,
    currentStatus,
    newStatus
  ) => {
    this.props.updateBusinessAccountType(
      userId,
      businessId,
      currentStatus,
      newStatus
    );

    this.populateBusinesses();
  };

  viewUserDashboard = (businessId, accountType) => {
    viewUserBusinessAccountDashboard(businessId, accountType);
    window.open(config.urls.dashboardUrl, "_blank");
  };

  getUserPCIscanTime = async (businessId) => {
    const userInfo = await callOnGetUserByBusinessId(businessId);

    const user = userInfo.data[0];
    const pciScanTime = user.pciScanTime !== null ? user.pciScanTime : "12";
    const pciScanTimeZone =
      user.timezone !== null ? user.timezone : "America/Denver";

    const pciTimeText =
      convert24HourFormat(pciScanTime) + " - " + pciScanTimeZone;
    this.setState({ pciToolTipActive: true, pciToolTipText: pciTimeText });
  };

  iconStatus = (pciStatus, malwareStatus, sslStatus, business) => {
    const iconStyle = { width: "24px", height: "24px" };
    let pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-gray.png";
    let malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-gray.png";
    let sealIcon = "https://tg3images.s3.us-east-2.amazonaws.com/ssl-gray.png";
    switch (pciStatus) {
      case "pass":
        pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-pass.png";
        break;
      case "fail":
        pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-fail.png";
        break;
      default:
        pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-gray.png";
        break;
    }

    switch (malwareStatus) {
      case "clean":
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-pas.png";
        break;
      case "potentially suspicious":
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-fail.png";
        break;
      case "suspicious":
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-fail.png";
        break;
      default:
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-gray.png";
        break;
    }

    switch (sslStatus) {
      case "active":
        sealIcon = "https://tg3images.s3.us-east-2.amazonaws.com/ssl-pass.png";
        break;
      default:
        sealIcon = "https://tg3images.s3.us-east-2.amazonaws.com/ssl-gray.png";
        break;
    }

    let showPCIToolTip = "";
    let showPCIToolTipStyle = {
      display: "none",
    };

    if (this.state.pciToolTipActive) {
      showPCIToolTip = "roleTooltipTextV3";
      showPCIToolTipStyle = {
        width: "250px",
        bottom: "35px",
        left: "0px",
        padding: "7px",
      };
    }

    let pciScanToolTip = "This business has never been scanned";

    if (business.lastPciStatus !== null && business.scanFrequency) {
      pciScanToolTip =
        "PCI Scan : " +
        business.scanFrequency.charAt(0).toUpperCase() +
        business.scanFrequency.slice(1) +
        " @ " +
        this.state.pciToolTipText;
    }

    return (
      <span>
        <span>
          <span className="account-title1">
            &nbsp;
            <span
              className="roleTooltipV3"
              onMouseEnter={() => this.getUserPCIscanTime(business._id)}
            >
              <img style={iconStyle} src={pciIcon} alt="PCI scan frequency" />
              <span className={showPCIToolTip} style={showPCIToolTipStyle}>
                <div style={{ fontSize: "11px" }}>
                  <strong>{pciScanToolTip}</strong>
                </div>
              </span>
            </span>
          </span>
        </span>

        <span>
          <span className="account-title1">
            &nbsp;
            <span className="roleTooltipV3">
              <img alt="malware-icon" style={iconStyle} src={malIcon} />
              <span
                className="roleTooltipTextV3"
                style={{
                  width: "250px",
                  bottom: "35px",
                  left: "0px",
                  padding: "7px",
                }}
              >
                <div style={{ fontSize: "11px" }}>
                  <strong>
                    {"Next Malware Scan Date : " +
                      moment(business.nextMalwareScan).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                  </strong>
                </div>
              </span>
            </span>
          </span>
        </span>

        <span>
          <span className="account-title1">
            &nbsp;
            <span className="roleTooltipV3">
              <img alt="sealIcon" style={iconStyle} src={sealIcon} />
              <span
                className="roleTooltipTextV3"
                style={{
                  width: "250px",
                  bottom: "35px",
                  left: "0px",
                  padding: "7px",
                }}
              >
                <div style={{ fontSize: "11px" }}>
                  <strong>
                    {business && business.domains && business.domains.sslInfo
                      ? "Next SSL Scan Date : " +
                        moment(business.domains.sslInfo.nextCheckDate).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                      : "Not scanned yet"}
                  </strong>
                </div>
              </span>
            </span>
          </span>
        </span>
      </span>
    );
  };

  render() {
    const { userId, businesses } = this.state;
    const { populateDelayModalData, populateBusinessUsersData } = this.props;

    removeStorageItem("businessID");
    const AdvanceSearchHeaderColumns = [
      { name: "Domain/IP" },
      { name: "Email" },
      { name: "Business" },
      { name: "" },
      { name: "Package Type" },
      { name: "Created" },
      { name: "Status" },
      { name: "Action" },
    ];

    return (
      <Aux>
        <Table
          headers={AdvanceSearchHeaderColumns}
          emptyHeaderColumnsCount="6"
          className="bg-torq-light"
        >
          <tbody className="scan-status-list">
            {businesses.length > 0 ? (
              businesses.map((business) => {
                return (
                  <tr key={business._id}>
                    <td>
                      {business.businessName ? (
                        <button
                          className="text-link tg-link"
                          data-testid="viewUserDashboard"
                          onClick={() => {
                            this.viewUserDashboard(
                              business._id,
                              business.accountType
                            );
                          }}
                        >
                          {business.primaryDomain}
                        </button>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      <a
                        href="/"
                        data-toggle="modal"
                        data-target="#primaryUsersModal"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          populateBusinessUsersData(business._id);
                        }}
                      >
                        View
                      </a>
                    </td>
                    <td>
                      {business.businessName} <br />
                      {business._id}
                    </td>
                    <td>
                      {this.iconStatus(
                        business.pciStatus,
                        business.currentMalwareScanResult,
                        business.sealStatus,
                        business
                      )}
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          className="btn btn-default dropdown-toggle btn-sm"
                          type="button"
                          data-toggle="dropdown"
                        >
                          {business.accountType !== ""
                            ? business.accountType === "basic"
                              ? "Full"
                              : business.accountType.charAt(0).toUpperCase() +
                                business.accountType.slice(1)
                            : "Option"}
                          {"  "}
                          <span className="caret" />
                        </button>
                        <ul className="dropdown-menu">
                          <li
                            className="pointer"
                            style={{
                              display:
                                business.accountType === "free"
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.changeBusinessAccountType(
                                  userId,
                                  business._id,
                                  business.accountType,
                                  "free"
                                );
                              }}
                            >
                              Free
                            </a>
                          </li>
                          <li
                            className="pointer"
                            style={{
                              display:
                                business.accountType === "security"
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.changeBusinessAccountType(
                                  userId,
                                  business._id,
                                  business.accountType,
                                  "security"
                                );
                              }}
                            >
                              Security
                            </a>
                          </li>
                          <li
                            className="pointer"
                            style={{
                              display:
                                business.accountType === "basic"
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.changeBusinessAccountType(
                                  userId,
                                  business._id,
                                  business.accountType,
                                  "basic"
                                );
                              }}
                            >
                              Full
                            </a>
                          </li>
                          <li
                            className="pointer"
                            style={{
                              display:
                                business.accountType === "90-day-trial"
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.changeBusinessAccountType(
                                  userId,
                                  business._id,
                                  business.accountType,
                                  "90-day-trial"
                                );
                              }}
                            >
                              90 Day Trial
                            </a>
                          </li>

                          <li
                            className="pointer"
                            style={{
                              display:
                                business.accountType === "60-day-trial"
                                  ? "none"
                                  : "block",
                            }}
                          >
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                this.changeBusinessAccountType(
                                  userId,
                                  business._id,
                                  business.accountType,
                                  "60-day-trial"
                                );
                              }}
                            >
                              60 Day Trial
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      {moment(business.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>

                    <td>
                      <a
                        href="/"
                        onClick={(e) => e.preventDefault()}
                        className="text-link"
                      >
                        {business.status === "active"
                          ? "Active"
                          : business.status === "invalid"
                            ? "Invalid"
                            : "Canceled"}
                      </a>
                    </td>
                    <td>
                      <ControlButtons
                        business={business}
                        userId={userId}
                        populateDelayModalData={populateDelayModalData}
                        populateBusinessUsersData={populateBusinessUsersData}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="8">
                  <div className="alert alert-info">No records!</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businesses: state.accountStore.businesses,
    userAccount: state.accountStore.userAccount,
    businessUserList: state.accountStore.businessUserList,
    message: state.accountStore.message,
    messageType: state.accountStore.messageType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBusiness,
      getUserById,
      getDomains,
      getBusinesses,
      createNote,
      cancelOrActiveDomain,
      getUserListUsingBusinessId,
      updateBusinessAccountType,
      resetStatus,
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearch);
