import { api } from "./api";
import { callApi } from "../helpers/index";

const callDownloadPCIreport = (data) => {
  return callApi().then((token) => {
    return api.service("download-report").create(data);
  });
};

const callDownloadAttachmentNotification = (data) => {
  return callApi().then((token) => {
    return api.service("download-report").get(data);
  });
};

export { callDownloadPCIreport, callDownloadAttachmentNotification };
