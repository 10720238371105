import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ControlPanelTabHeader from "./controlPanelTabHeader";
import ControlPanelTabBody from "./controlPanelTabBody";
import ControlPanelModals from "./controlPanelModals";

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlType: "",
    };
  }

  updateControlTab = (controlType) => {
    this.setState({
      controlType: controlType,
    });
  };

  render() {
    const { controlType } = this.state;
    return (
      <>
        <Header titlePage="Control Panel" bgColor="#F5F6F8" />
        <ToastContainer />
        <ControlPanelTabHeader updateControlTab={this.updateControlTab} />
        <ControlPanelTabBody controlType={controlType} />

        <ControlPanelModals />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPanel);
