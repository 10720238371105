import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  callApiGetBusinessSubdomainData,
  callApiUpdateDomainData,
} from "../../services/index";
import {
  GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS,
  GET_BUSINESS_SUBDOMAIN_DATA_FAILURE,
  UPDATE_DOMAIN_SSL_DATA_SUCCESS,
  UPDATE_DOMAIN_SSL_DATA_FAILURE,
} from "../types/index";

export const getBusinessSubdomainData = (businessId) => {
  return async (dispatch) => {
    return callApiGetBusinessSubdomainData(businessId)
      .then((data) => {
        dispatch({
          type: GET_BUSINESS_SUBDOMAIN_DATA_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BUSINESS_SUBDOMAIN_DATA_FAILURE,
        });
      });
  };
};

export const rescanSslCertificate = (id) => {
  return async (dispatch) => {
    return callApiUpdateDomainData(id)
      .then((domain) => {
        dispatch({
          type: UPDATE_DOMAIN_SSL_DATA_SUCCESS,
          payload: domain,
        });

        toast.success("SSL Scan Started. Please check back in a few minutes.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_DOMAIN_SSL_DATA_FAILURE,
        });
      });
  };
};
