import { api } from "./api";
import { callApi } from "../helpers/index";
import { LOGIN_ACCOUNT_LOCKED } from "../modules/types";

export const callApiCreateBuyerProtectionAccount = async (businessId) => {
  return callApi().then((token) => {
    if (businessId) {
      return api
        .service("api/buyer-protection")
        .create({ businessId: businessId });
    } else {
      return null;
    }
  });
};

export const callApiGetBuyerProtectionOrdersData = async (params) => {
  return callApi().then((token) => {
    if (params) {
      // console.log("4x-orders 123");
      return api.service("api/buyer-protection").get("4x-orders", {
        query: {
          businessId: params.businessId,
          startDate: params.startDate,
          endDate: params.endDate,
        },
      });
    } else {
      return null;
    }
  });
};

export const callApiGetBuyerProtectionRegistrationsData = async (params) => {
  return callApi().then((token) => {
    if (params) {
      // console.log("4x-registered");
      return api.service("api/buyer-protection").get("4x-registered", {
        query: {
          businessId: params.businessId,
          startDate: params.startDate,
          endDate: params.endDate,
        },
      });
    } else {
      return null;
    }
  });
};

export const callApiGetBuyerProtectionAccountStatus = async (params) => {
  return callApi().then((token) => {
    if (params) {
      return api
        .service("api/buyer-protection")
        .get("is-buyer-protection-account", {
          query: {
            businessId: params.businessId,
          },
        });
    } else {
      return null;
    }
  });
};
