import { api } from "./api";
import { callApi } from "../helpers/index";
import moment from "moment/moment";
import _ from "lodash";

const callCreateBeyondScan = (data) => {
  return callApi().then((token) => {
    // // console.log("creating scan for frontline!");
    api.service("api/beyondsecurity").timeout = 1200000;
    return api.service("api/beyondsecurity").create(data);

    // return api.service("api/frontline").create(data);
  });
};

const callOnGetBusinessInfo = (id) => {
  if (id !== null || id !== undefined) {
    return callApi()
      .then(async (token) => {
        const business = await api.service("business").get(id);
        const businessCleanData = _.omit(business, [
          "scanVendor",
          "clone",
          "notes",
          "certificates",
          "supportEmail",
          "billingEmail",
          "address",
          "address2",
          "domains.sslInfo",
        ]);
        return businessCleanData;
      })
      .catch((e) => {
        console.log(e);
      });
  }
};

const callOnGetScanHistoryByOwner = (owner) => {
  return callApi()
    .then((token) => {
      return api.service("scanhistory").find({
        query: {
          owner: owner,
          flag: { $ne: "hidden" },
          $sort: { createdAt: -1 },
        },
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

const callOnGetDomainByHostName = (hostname) => {
  return callApi()
    .then((token) => {
      return api.service("domains").find({
        query: {
          host: hostname,
        },
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

const callOnGetScanHistoryByOwnerAndDateRange = (owner, start, end) => {
  return callApi()
    .then((token) => {
      return api.service("scanhistory").find({
        query: {
          owner: owner,
          flag: { $ne: "hidden" },
          scanDate: {
            $gte: start,
            $lte: end,
          },
          $sort: { createdAt: -1 },
        },
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

const callOnUpdateScanRefreshyById = (id) => {
  return callApi().then((token) => {
    return api.service("scanhistory").patch(id, {
      done: "n/a",
    });
  });
};

const callOnGetScanHistoriesByOwner = (owner, businessId) => {
  return callApi().then((token) => {
    return api.service("scanhistory").find({
      query: {
        owner: owner,
        businessId: businessId,
        $sort: { createdAt: -1 },
        $limit: 10,
      },
    });
  });
};

const callOnGetSubDomains = (id) => {
  return callApi().then(async (token) => {
    const result = await api.service("domains").find({
      query: {
        businessId: id,
        deleted: false,
      },
    });

    return result;
  });
};
const callOnGetDomainById = (id) => {
  return callApi().then(async (token) => {
    const result = await api.service("domains").get(id);
    return result;
  });
};
const callOnUpdateDomainUsingId = (_id) => {
  return callApi().then(async (token) => {
    const domainData = await api.service("domains").find({
      query: {
        _id: _id,
      },
    });

    domainData.data[0].sslInfo.nextCheckDate = moment(new Date()).add(
      -1,
      "days"
    );

    const sslData = {
      sslInfo: domainData.data[0].sslInfo,
    };

    const result = await api.service("domains").patch(_id, sslData);
    return result;
  });
};

const callOnUpdateSubDomainById = (id, data, info) => {
  return callApi().then(async (token) => {
    const result = await api.service("api/domains").patch(id, data, {
      query: {
        deleted: false,
      },
    });
    return result;
  });
};

const callOnUpdateDomainPingDetails = (id, data) => {
  return callApi().then(async (token) => {
    const result = await api.service("domains").patch(id, data);
    return result;
  });
};

const callOnUpdateContact = (data) => {
  return callApi().then(async (token) => {
    await api.service("beyondsecurity").update(1, data);
  });
};

const callOnFalsePostiveUserRecords = (businessId) => {
  return callApi().then(async (response) => {
    return await api.service("business").find({
      query: {
        _id: businessId,
        falsePositives: { $exists: true },
      },
    });
  });
};

const callOnUpdateSecurityOfficer = (data) => {
  return callApi().then(async (response) => {
    return await api.service("beyondsecurity").updateSecurityOfficer(data);
  });
};

const callOnSetFalseActiveSecurityOfficer = (userId) => {
  return callApi().then(async (response) => {
    return await api.service("users").patch(userId, {
      "userDetails.isSecurityOfficer": false,
    });
  });
};

const callOnUpdateUserWithBSData = (userId) => {
  return callApi().then(async (response) => {
    return await api.service("users").patch(userId, {
      "userDetails.isSecurityOfficer": true,
    });
  });
};

const callOnUpdateDomainAndScanVendor = (data) => {
  return callApi().then(async (response) => {
    await api.service("domains").patch(data.id, {
      host: data.domain,
    });

    const params = {
      query: {
        businessId: data.id,
        status: "invalid",
      },
    };

    await api.service("scanhistory").remove(null, params);

    return await api.service("business").patch(data.businessId, {
      primaryDomain: data.domain,
      scanVendor: {},
      pciProgressStatus: null,
    });
  });
};

const callOnUpdateUserTimePrefs = (data) => {
  return callApi().then(async (response) => {
    if (data.hasOwnProperty("scanTimeDetails")) {
      const user = await api.service("users").find({
        query: {
          $limit: 1,
          "businessDetails.businessId": data._id,
          "businessDetails.primaryUser": true,
        },
      });

      return await api.service("users").patch(user.data[0]._id, {
        timezone: data.scanTimeDetails.scanTimeZone,
        pciScanTime: data.scanTimeDetails.scanTime,
      });
    }
  });
};

const callOnUpdateBusinessFalsePositive = (
  falsePositives,
  fId,
  status,
  indexMain
) => {
  return callApi().then((token) => {
    const updatedStatus = status;
    return api.service("business").patch(
      null,
      {},
      {
        query: {
          falsePositiveId: fId,
          status: updatedStatus,
        },
      }
    );
  });
};

const callOnBusinessRemoveHistory = (domainId, businessId) => {
  return callApi().then(async (token) => {
    await api.service("business").patch(businessId, {
      isValidDomain: true,
      skipDomainValidation: true,
      pciProgressStatus: null,
    });
    if (domainId !== null) {
      return api.service("scanhistory").remove(domainId);
    }
  });
};

const callOnGetMissingScanResults = async (param) => {
  return callApi().then(async (response) => {
    let domainId = null;
    if (param.domainId === null) {
      const domain = await api.service("domains").find({
        query: { businessId: param.businessId, isSubDomain: { $ne: true } },
      });
      domainId = domain.data[0]._id;
    }

    const query = {
      query: {
        type: "missing",
        businessID: param.businessId,
        domainID: param.domainId === null ? domainId : param.domainId,
        daysMissed: param.daysMissed,
      },
    };
    return await api.service("beyondsecurity").get(null, query);
  });
};

export {
  callCreateBeyondScan,
  callOnGetDomainById,
  callOnGetBusinessInfo,
  callOnGetSubDomains,
  callOnGetScanHistoryByOwner,
  callOnGetScanHistoryByOwnerAndDateRange,
  callOnGetDomainByHostName,
  callOnGetScanHistoriesByOwner,
  callOnUpdateScanRefreshyById,
  callOnUpdateDomainUsingId,
  callOnUpdateSubDomainById,
  callOnUpdateContact,
  callOnFalsePostiveUserRecords,
  callOnUpdateSecurityOfficer,
  callOnSetFalseActiveSecurityOfficer,
  callOnUpdateUserWithBSData,
  callOnUpdateUserTimePrefs,
  callOnUpdateBusinessFalsePositive,
  callOnUpdateDomainAndScanVendor,
  callOnBusinessRemoveHistory,
  callOnUpdateDomainPingDetails,
  callOnGetMissingScanResults,
};
