import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  callApiGetTrustSealsData,
  callApiCreateTrustSealsData,
  callApiUpdateTrustSealsData,
  callApiRemoveTrustSealsData,
  callApiHideOrDeleteCanceledTrustSeals,
  callApiGetCssJsHashList,
} from "../../services/index";
import {
  GET_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
  GET_BUSINESS_TRUSTSEALS_DATA_FAILURE,
  CREATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
  CREATE_BUSINESS_TRUSTSEALS_DATA_FAILURE,
  UPDATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
  UPDATE_BUSINESS_TRUSTSEALS_DATA_FAILURE,
  REMOVE_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
  REMOVE_BUSINESS_TRUSTSEALS_DATA_FAILURE,
  DELETE_CANCELED_TRUSTSEALS_SUCCESS,
  DELETE_CANCELED_TRUSTSEALS_FAILURE,
  GET_CSS_JS_HASH_SUCCESS,
  GET_CSS_JS_HASH_FAILURE,
} from "../types/index";

export const getBusinessTrustSealsData = (data) => {
  return async (dispatch) => {
    return callApiGetTrustSealsData(data)
      .then((resData) => {
        dispatch({
          type: GET_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BUSINESS_TRUSTSEALS_DATA_FAILURE,
        });
      });
  };
};

export const createBusinessTrustSealsData = (data) => {
  return async (dispatch) => {
    return callApiCreateTrustSealsData(data)
      .then((resData) => {
        dispatch({
          type: CREATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_BUSINESS_TRUSTSEALS_DATA_FAILURE,
        });
      });
  };
};

export const updateBusinessTrustSealsData = (userId, data) => {
  return async (dispatch) => {
    return callApiUpdateTrustSealsData(userId, data)
      .then((resData) => {
        dispatch({
          UPDATE_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
          payload: resData,
        });

        if (resData.success && resData.message != "") {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_BUSINESS_TRUSTSEALS_DATA_FAILURE,
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      });
  };
};

export const removeBusinessTrustSealsData = (userId, data) => {
  return async (dispatch) => {
    return callApiRemoveTrustSealsData(userId, data)
      .then((resData) => {
        dispatch({
          type: REMOVE_BUSINESS_TRUSTSEALS_DATA_SUCCESS,
          payload: resData,
        });

        if (resData.success && resData.message != "") {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: REMOVE_BUSINESS_TRUSTSEALS_DATA_FAILURE,
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      });
  };
};

export const hideOrDeleteCanceledTrustSeals = (businessId, purpose) => {
  return async (dispatch) => {
    let decision;
    return callApiHideOrDeleteCanceledTrustSeals(businessId, purpose)
      .then((resData) => {
        dispatch({
          type: DELETE_CANCELED_TRUSTSEALS_SUCCESS,
          payload: resData,
        });

        if (resData.success && resData.message != "") {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_CANCELED_TRUSTSEALS_FAILURE,
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      });
  };
};

export const getCssJsHashList = () => {
  return async (dispatch) => {
    return callApiGetCssJsHashList()
      .then((data) => {
        dispatch({
          type: GET_CSS_JS_HASH_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CSS_JS_HASH_FAILURE,
        });
      });
  };
};
