import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { getUserDecryptedData } from "../../../helpers/general";
import SlideContext from "../../context/slidePanelContexts";
import AppContext from "../../context/appContext";
import Spinner from "../../ui/spinner/spinnerRectScan";
import moment from "moment";
import momentz from "moment-timezone";
import {
  callApiGetUserBusinessData,
  callApiGetBusinessData,
  callApiGetBusinessSubdomainData,
} from "../../../services";

class MalwareScanResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userBusinessData: [],
      business: {},
      malwareScanLinkClass: "tg-dashboard-passed-link pciscan-toggle",
      malwareScanStyle: {},
      displayArrowDown: "none",
      displayArrowUp: "none",
      isMalwareActive: null,
    };
  }

  async componentDidMount() {
    this.malwareScanDecision();
    this.slideArrowUpDownDecision();
  }

  componentWillReceiveProps(props) {
    const { businessId, index } = this.props;
    const { malware } = this.context;
    const malwareContextBusinessId = malware[index].businessId;
    const malwareContextIsActive = malware[index].isMalwareActive;

    if (malware.length === 1) {
      if (malwareContextIsActive) {
        this.setState({
          isMalwareActive: true,
          displayArrowDown: "none",
          displayArrowUp: "none",
        });
      } else {
        this.setState({
          isMalwareActive: !malwareContextIsActive,
          displayArrowDown: "none",
          displayArrowUp: "block",
        });
      }
    } else {
      if (businessId === malwareContextBusinessId) {
        this.setState({ isMalwareActive: malwareContextIsActive });
      }
    }

    this.setState({ isMalwareActive: malwareContextIsActive });
    this.checkMalwarePanelIsActive();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isMalwareActive !== this.state.isMalwareActive) {
      this.checkMalwarePanelIsActive();
    }
  }

  checkMalwarePanelIsActive() {
    const { isMalwareActive } = this.state;

    if (isMalwareActive && isMalwareActive !== null) {
      this.setState({
        displayArrowDown: "none",
        displayArrowUp: "block",
      });
    } else {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none",
      });
    }
  }

  async malwareScanDecision() {
    const businessData = await callApiGetBusinessData(this.props.businessId);
    this.setState({
      business: businessData,
    });

    if (
      businessData.lastPciStatus === "fail" ||
      businessData.lastPciStatus === "invalid"
    ) {
      this.setState({
        malwareScanLinkClass: "tg-dashboard-failed-link pciscan-toggle",
        malwareScanStyle: { color: "#C01818" },
      });
    }

    if (businessData.lastPciStatus === "clean") {
      this.setState({
        malwareScanLinkClass: "tg-dashboard-passed-link pciscan-toggle",
        malwareScanStyle: { color: "rgb(51, 152, 31)" },
      });
    }
  }

  async slideArrowUpDownDecision() {
    const userBusinessData = await callApiGetUserBusinessData(
      getUserDecryptedData()
    );

    /* console.log("====================================");
    console.log("userBusinessData", userBusinessData);
    console.log("===================================="); */

    if (userBusinessData.data.length > 1) {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none",
      });
    }
  }

  render() {
    const {
      isLoading,
      business,
      malwareScanLinkClass,
      malwareScanStyle,
      displayArrowUp,
      displayArrowDown,
    } = this.state;
    const malwareContext = this.context;
    const { businessId, index } = this.props;

    return (
      <AppContext.Consumer>
        {(app) => {
          let statusContent =
            app.userBusinessData[index] !== undefined
              ? app.userBusinessData[index].lastMalwareScan !== null
                ? app.userBusinessData[index].lastMalwareScanResult +
                  " " +
                  momentz(app.userBusinessData[index].lastMalwareScan)
                    .tz(
                      app.userBusinessData[index].pciScanTimeZone !==
                        undefined &&
                      app.userBusinessData[index].pciScanTimeZone !== null &&
                      app.userBusinessData[index].pciScanTimeZone !== ""
                        ? app.userBusinessData[index].pciScanTimeZone
                        : "America/Chicago"
                    )
                    .format("MM/DD/YYYY")
                : "Scan Pending"
              : "Loading";

          let status =
            app.userBusinessData[index] !== undefined
              ? app.userBusinessData[index].lastMalwareScanResult
              : "pending";

          if (app.userBusinessData[index] !== undefined) {
            if (app.userBusinessData[index].malwareStatusReport === "running") {
              status = "running";
            }
          }
          let styleStatus = {
            color: "rgb(127, 127, 127)",
            fontSize: "12px",
            textTransform: "capitalize",
          };

          let iconStatus = "fa fa-clock h1-icon";

          if (status === "malicious") {
            styleStatus = {
              color: "#C01818",
              fontSize: "12px",
              textTransform: "capitalize",
            };

            iconStatus = "fa fa-exclamation-circle h1-icon";
          }

          if (status === "invalid") {
            iconStatus = "fa fa-exclamation-circle h1-icon";
          }

          if (status === "running" || status === "undef") {
            styleStatus = {
              color: "#33981f",
              fontSize: "12px",
              textTransform: "capitalize",
            };
            iconStatus = "fa fa-check-circle h1-icon";
            statusContent = "Running";
          }

          if (status === "potentially suspicious" || status === "suspicious") {
            styleStatus = {
              color: "orange",
              fontSize: "12px",
              textTransform: "capitalize",
            };
            iconStatus = "fa fa-exclamation-circle h1-icon";
          }

          if (status === "clean") {
            styleStatus = {
              color: "rgb(51, 152, 31);",
              fontSize: "12px",
              textTransform: "capitalize",
            };

            iconStatus = "fa fa-check-circle h1-icon";
          }
          let statusIconContent = (
            <i className={iconStatus} aria-hidden="true" style={styleStatus} />
          );

          return (
            <SlideContext.drawerContext.Consumer>
              {(value) => {
                return (
                  <button
                    className="tg-dashboard-passed-link malwarescan-toggle"
                    style={{ position: "relative" }}
                    onClick={() =>
                      value.malware[index].openMalwarePanel(businessId, index)
                    }
                  >
                    {app.userBusinessData[index] !== undefined ? (
                      app.userBusinessData[index].lastMalwareScan !== null ? (
                        app.userBusinessData[index].lastMalwareScanResult ===
                          "running" ||
                        app.userBusinessData[index].malwareStatusReport ===
                          "running" ||
                        app.userBusinessData[index].lastMalwareScanResult ===
                          "undef" ? (
                          (statusIconContent = <Spinner />)
                        ) : (
                          (statusIconContent = (
                            <>
                              <i
                                className={iconStatus}
                                aria-hidden="true"
                                style={styleStatus}
                              />
                              <br />
                            </>
                          ))
                        )
                      ) : (
                        <>
                          {statusIconContent} <br />
                        </>
                      )
                    ) : (
                      "Loading"
                    )}
                    <br />

                    <span style={styleStatus}>{statusContent}</span>

                    <i
                      className="false mal fas fa-chevron-down hidden-sm hidden-xs"
                      aria-hidden="true"
                      style={{
                        display:
                          value.malware[index] !== undefined
                            ? value.malware[index].isMalwareActive
                              ? "none"
                              : "block"
                            : null,
                      }}
                    />
                    <i
                      className="mal-arrow-ident fas fa-sort-up 1 hidden-sm hidden-xs"
                      aria-hidden="true"
                      style={{
                        display:
                          value.malware[index] !== undefined
                            ? value.malware[index].isMalwareActive
                              ? "block"
                              : "none"
                            : null,
                      }}
                    />
                  </button>
                );
              }}
            </SlideContext.drawerContext.Consumer>
          );
        }}
      </AppContext.Consumer>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

MalwareScanResult.contextType = SlideContext.drawerContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareScanResult);
