import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  callApiFindEmailData,
  callApiCreateEmailData,
  callApiUpdateEmailData,
  callApiDeleteEmailData,
} from "../../services/index";
import {
  GET_EMAIL_DATA_SUCCESS,
  GET_EMAIL_DATA_FAILURE,
  CREATE_EMAIL_DATA_SUCCESS,
  CREATE_EMAIL_DATA_FAILURE,
  UPDATE_EMAIL_DATA_SUCCESS,
  UPDATE_EMAIL_DATA_FAILURE,
} from "../types/index";

export const findEmailData = (data) => {
  return async (dispatch) => {
    return callApiFindEmailData(data)
      .then((resData) => {
        dispatch({
          type: GET_EMAIL_DATA_SUCCESS,
          payload: resData,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_EMAIL_DATA_FAILURE,
        });
      });
  };
};

export const createEmailData = (data) => {
  return async (dispatch) => {
    return callApiCreateEmailData(data)
      .then((resData) => {
        toast.success("Email created successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      })
      .catch((err) => {
        const fieldsIndex = ["emailTag", "name", "subject", "type", "body"];

        for (let i = 0; i < fieldsIndex.length; i++) {
          if (
            err.errors[fieldsIndex[i]] &&
            err.errors[fieldsIndex[i]]["message"] !== ""
          ) {
            toast.error(err.errors[fieldsIndex[i]]["message"], {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2500,
            });

            break;
          }
        }
      });
  };
};

export const updateEmailData = (id, data) => {
  return async (dispatch) => {
    return callApiUpdateEmailData(id, data)
      .then((resData) => {
        toast.success("Email updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      })
      .catch((err) => {
        const fieldsIndex = ["emailTag", "name", "subject", "type", "body"];

        for (let i = 0; i < fieldsIndex.length; i++) {
          if (
            err.errors[fieldsIndex[i]] &&
            err.errors[fieldsIndex[i]]["message"] !== ""
          ) {
            toast.error(err.errors[fieldsIndex[i]]["message"], {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2500,
            });

            break;
          }
        }
      });
  };
};

export const deleteEmailData = (id) => {
  return async (dispatch) => {
    return callApiDeleteEmailData(id)
      .then((resData) => {
        toast.success("Email deleted successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      })
      .catch((err) => {
        toast.error("Email delete was unsuccessfull.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      });
  };
};
