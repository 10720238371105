import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { hideOrDeleteCanceledTrustSeals } from "../../modules/actions/index";
import AppContext from "../../components/context/appContext";

class DeleteTrustsealsModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisable: false,
      businessId: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState({
        businessId: currentBusinessId,
      });
    }
  }

  async onClickDeleteTrustSeals(businessId) {
    this.props.hideOrDeleteCanceledTrustSeals(businessId, "delete");
  }

  async onClickHideTrustSeals(businessId) {
    this.props.hideOrDeleteCanceledTrustSeals(businessId, "hide");
  }

  render() {
    const { businessId } = this.state;

    return (
      <div id="deleteSeals" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title f800">Hide / Delete Trust Seals</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                <strong>
                  Please confirm your action for this business trustseals.
                </strong>
              </p>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn btn-danger btn-140-width btn-md"
                data-dismiss="modal"
              >
                No
              </button>

              <button
                type="button"
                className="btn btn-warning btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.onClickHideTrustSeals(businessId);
                }}
              >
                Hide
              </button>
              <button
                type="button"
                className="btn btn-success btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.onClickDeleteTrustSeals(businessId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeleteTrustsealsModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ hideOrDeleteCanceledTrustSeals }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTrustsealsModal);
