import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApiGetSentEmailsListForUser } from "../../services/index";
import AppContext from "../../components/context/appContext";

class SntEmlMdl extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emails: [],
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      const tempEmails = await callApiGetSentEmailsListForUser(
        this.props.userId
      );

      if (tempEmails && tempEmails.data && tempEmails.data.length > 0) {
        this.setState({
          emails: tempEmails.data,
        });
      }
    }
  }

  render() {
    const { emails } = this.state;

    return (
      <div id="sntEmlMdl" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div
            className="modal-content"
            style={{
              width: "950px",
              height: "750px",
              overflowY: "scroll",
            }}
          >
            <div className="modal-header">
              <h4 className="modal-title f800">Sent Emails</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  document.getElementById("btnOpnAccScrtMdl").click();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <div className="table-responsive" />
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>
                        <label>Subject</label>
                      </th>
                      <th>
                        <label>Email</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {emails && emails.length > 0
                      ? emails.map((eachValue, eachIndex) => {
                          return (
                            <tr key={eachIndex}>
                              <td className="col-md-2">{eachValue.subject}</td>
                              <td className="col-md-8">
                                {eachValue.body
                                  .replace(/<\s*\/?br\s*[\/]?>/gi, "\n")
                                  .replace(/&/g, "&amp;")
                                  .replace(/</g, "&lt;")
                                  .replace(/>/g, "&gt;")
                                  .replace(/"/g, "&quot;")}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  document.getElementById("btnOpnAccScrtMdl").click();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SntEmlMdl.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SntEmlMdl);
