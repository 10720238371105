import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  callApiGetBusinessUsersList,
  callApiUpdateUserProfileData,
  callOnGetMissingScanResults,
} from "../../services";

class SealGoogleTagManagerCnfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { toggleGoogleTagManager } = this.props;

    return (
      <div
        className="modal fade"
        id="sealGoogleTagManagerCnfModal"
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">Google Tag Manager</h4>
              <button
                id="btnClsSealGoogleTagManagerCnfModal"
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="pt-2 pb-4">
                <h5>
                  Note: Google Tag Manager does not support SRI hashes, which is
                  an extra security feature that we use that allows us to force
                  the code to break if it is ever compromised. While this
                  scenario is very unlikely, it's important that you fully
                  understand the risks involved if you choose to use our code
                  with GTM.
                </h5>
              </div>
              <div className="modal-footer text-right pt-4 px-0 pb-3">
                <button
                  type="button"
                  className="btn-tglight2 btn-140-width btn-md  mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn-success btn-140-width btn-md "
                  data-dismiss="modal"
                  onClick={() => toggleGoogleTagManager(true)}
                >
                  I agree
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealGoogleTagManagerCnfModal);
