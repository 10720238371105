import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import Aux from "../../components/hoc/containerAux";
import SealComponent from "../trust-seals/sealComponent";
import Line from "../../views/charts/line";

import AppContext from "../../components/context/appContext";
import BuyerProtectionForm from "./buyerProtectionForm";
import BuyerProtectionStats from "./buyerProtectionStats";
import BusinessSelector from "./businessSelector";
import { getStorageItem, removeAllStorage } from "../../helpers/storage";
import { getUserDecryptedData } from "../../helpers/general";
import { setStorageItem } from "../../helpers/storage";
import {
  callApiGetBusinessData,
  callApiGetBuyerProtectionAccountStatus,
} from "../../services";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class BuyerProtection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: null,
      business: {},
      integrationStatus: false,
      userBusinessData: [],
      buyerProtectionStatus: [],
    };
  }

  async componentDidMount() {
    let userLsData = getUserDecryptedData(),
      isBpAccount = false,
      targetBusinessId = null;

    if (
      userLsData &&
      userLsData.hasOwnProperty("bpBusinessId") &&
      userLsData.bpBusinessId &&
      userLsData.bpBusinessId.length > 0
    ) {
      setStorageItem("businessId", cryptr.encrypt(userLsData.bpBusinessId[0]));
      targetBusinessId = userLsData.bpBusinessId[0];
      isBpAccount = true;
    } else {
      if (userLsData && userLsData.isAdmin === true) {
        let tempBpAccountStatus = await callApiGetBuyerProtectionAccountStatus({
          businessId: cryptr.decrypt(getStorageItem("businessId")),
        });

        if (
          tempBpAccountStatus &&
          tempBpAccountStatus.data &&
          tempBpAccountStatus.data.bpAccount === true
        ) {
          targetBusinessId = cryptr.decrypt(getStorageItem("businessId"));
          isBpAccount = true;
        }
      }
    }

    if (isBpAccount === true) {
      this.setState(
        {
          businessId: targetBusinessId,
        },
        async () => {
          this.context.updateCurrentBusinessId(this.state.businessId);
          let tempBusinessData = await callApiGetBusinessData(
            this.state.businessId
          );

          if (
            tempBusinessData &&
            tempBusinessData.hasOwnProperty("details4X") &&
            tempBusinessData.details4X.hasOwnProperty("integrationStatus") &&
            tempBusinessData.details4X.integrationStatus
          ) {
            this.setState({
              business: tempBusinessData,
              integrationStatus: tempBusinessData.details4X.integrationStatus,
            });
          }
        }
      );
    } else {
      this.props.goToDashboard();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;

    let integrationStatus = false;

    if (prevState.businessId !== currentBusinessId) {
      let tempBusinessData = await callApiGetBusinessData(currentBusinessId);

      if (
        tempBusinessData &&
        tempBusinessData.hasOwnProperty("details4X") &&
        tempBusinessData.details4X.hasOwnProperty("integrationStatus") &&
        tempBusinessData.details4X.integrationStatus
      ) {
        integrationStatus = tempBusinessData.details4X.integrationStatus;
      }

      if (tempBusinessData) {
        this.setState({
          businessId: currentBusinessId,
          business: tempBusinessData,
          integrationStatus: integrationStatus,
        });
      }
    }
  }

  render() {
    const { businessId, business, integrationStatus } = this.state;

    /* console.log(
      "integrationStatus",
      businessId,
      business.primaryDomain,
      integrationStatus
    ); */

    return (
      <>
        <Header titlePage="Trust Guard Buyer Protection" bgColor="#F5F6F8" />
        <ToastContainer />

        <BusinessSelector business={business} />
        {integrationStatus === false ? (
          <div id="mcontent-wrapper">
            <div>
              <BuyerProtectionForm businessId={businessId} />
            </div>
          </div>
        ) : (
          <BuyerProtectionStats businessId={businessId} />
        )}
      </>
    );
  }
}

BuyerProtection.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToDashboard: () => push("/"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyerProtection);
