import React, { Component } from "react";
import AppContext from "../../components/context/appContext";
import TableHeader from "../../components/dashboard/partials/tableContent";
import PciSlidePanelData from "../../components/dashboard/partials/slidePanels/pciSlidePanelData";
import { callOnGetPciScanData } from "../../services/scanHistoryService";
class PCIScan extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      activePage: 1,
      scanHistoryResults: 0,
    };
  }

  async componentDidMount() {
    await this.getCurrentScanHistories();
  }

  async getCurrentScanHistories(pageNumber) {
    const { toggledIndex, business } = this.props.location.state;
    const businessId = business[toggledIndex]._id;
    const scanHistory = await callOnGetPciScanData(businessId, true);
    this.setState({
      activePage: pageNumber,
      scanHistoryResults: scanHistory.data,
    });
  }

  render() {
    const { toggledIndex, business } = this.props.location.state;
    const { scanHistoryResults } = this.state;
    const businessId = business[toggledIndex]._id;
    const totalItems = scanHistoryResults.length;
    return (
      <TableHeader
        hideMoreLink={true}
        isPagination={true}
        totalItems={totalItems}
        pageLimit={10}
        activePage={this.state.activePage}
        pageChange={this.getCurrentScanHistories.bind(this)}>
        <PciSlidePanelData
          index={toggledIndex}
          businessId={businessId}
          activePage={this.state.activePage}
          noLimit
        />
      </TableHeader>
    );
  }
}

PCIScan.contextType = AppContext;
export default PCIScan;
