import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { createBusinessUserData } from "../../modules/actions/index";
import AppContext from "../../components/context/appContext";

class SaqAppAuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisable: false,
      businessId: "",
      password: "",
    };

    this.onClickGenerateSaqUser = this.onClickGenerateSaqUser.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    //console.log("currentBusinessId", this.context);
    if (prevState.businessId !== currentBusinessId) {
      this.setState({
        businessId: currentBusinessId,
      });
    }
  }

  closeModal() {
    this.setState({
      password: "",
    });
  }

  onClickGenerateSaqUser() {
    //console.log("password", this.state.password, this.state.businessId);

    this.setState({
      isDisable: true,
    });

    setTimeout(() => {
      this.setState(
        {
          isDisable: false,
        },
        () => {}
      );
    }, 2500);

    if (this.state.password) {
      this.setState(
        {
          password: "",
        },
        () => {
          document.getElementById("closeModal").click();
        }
      );
    }
  }

  render() {
    const { password, isDisable } = this.state;

    return (
      <div id="saqAppAuthModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Please authenticate yourself</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <div className="col-lg-12 col-md-12 col-sm-12 pl-0 pr-0">
                  <h5>Password</h5>
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control p-sm"
                    name="password"
                    value={password}
                    onChange={(evt) => {
                      this.setState({
                        password: evt.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-success btn-md  btn-140-width"
                onClick={this.onClickGenerateSaqUser}
                disabled={isDisable}
                data-testid="btnSaveBusinessUser"
              >
                Authenticate
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SaqAppAuthModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createBusinessUserData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaqAppAuthModal);
