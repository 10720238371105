import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import config from "../../helpers/config";
import AppContext from "../../components/context/appContext";
import UserSmsTiming from "../modals/userSmsTiming";
import GenerateApiKeyModal from "../modals/generateApiKeyModal";
import { updateUserProfileData } from "../../modules/actions/index";
import { now } from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class GenerateUserApiKey extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisabled: false,
      businessId: "",
      userData: {},
      userBusinessData: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData, userBusinessData } = this.context;

    if (
      prevState.userData !== userData &&
      prevState.userBusinessData !== userBusinessData
    ) {
      this.setState({
        userData: userData,
        userBusinessData: userBusinessData,
        businessId: userBusinessData.length > 0 ? userBusinessData[0]._id : "",
        smsInfoPopup: userData.smsInfoPopup,
      });
    }
  }

  render() {
    const { userBusinessData, businessId } = this.state;

    return (
      <>
        <div
          className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad"
          style={{ marginTop: "60px" }}
        >
          <div className="table-responsive">
            <table id="dash-overview-table" className="table table-striped">
              <tbody className="scan-status-list">
                <tr>
                  <td width="60%">
                    <h4 className="f700">Your API Key</h4>
                  </td>
                  <td width="20%" />
                  <td width="20%" />
                </tr>
                <tr>
                  <td width="60%">
                    <small
                      style={{
                        fontSize: "15px",
                        display: "block",
                        clear: "both",
                      }}
                    >
                      Generate your business API key
                    </small>
                    <span
                      style={{
                        marginTop: "5px",
                        display: "block",
                        clear: "both",
                      }}
                    >
                      <button
                        className="btn-sm btn-tglight2 mr-2 btn"
                        data-toggle="modal"
                        data-target="#generateApiKey"
                      >
                        Generate API Key
                      </button>
                    </span>
                  </td>
                  <td width="20%" />
                  <td width="20%" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <GenerateApiKeyModal />
      </>
    );
  }
}

GenerateUserApiKey.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfileData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateUserApiKey);
