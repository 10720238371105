import React from "react";
import moment from "moment";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";
import { viewUserDashboard, camelize } from "../../helpers/general";
import { convertTimeTo } from "../../helpers/timeZone";

import { callApiGetRunningSslScans } from "../../services/index";
import AppContext from "../../components/context/appContext";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class RunningSslScanReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      businesses: [],
      display: "none",
    };
  }

  getSslScanReport = (display) => {
    this.setState(
      {
        display: display === "block" ? "none" : "block",
      },
      async () => {
        if (display === "none") {
          const tempBusinesses = await callApiGetRunningSslScans();

          this.setState({
            businesses: tempBusinesses,
          });
        }
      }
    );
  };

  render() {
    const { businesses, display } = this.state;

    return (
      <>
        <div className="reportButton">
          <button
            className="btn-success btn-sm"
            onClick={() => {
              this.setState({
                display: display === "block" ? "none" : "block",
              });
            }}
          >
            Running Scans
          </button>
        </div>
        <p>
          Running Scans{" "}
          <b>
            {businesses && businesses.data && businesses.data.length > 0
              ? "( " + businesses.data.length + " )"
              : ""}{" "}
          </b>
        </p>

        <hr />

        <div className="table-responsive" style={{ display: display }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Domain/Subdomains/IPs</th>
                <th>Last SSL Validation</th>
                <th>Frequency</th>
                <th>SSL Provider</th>
                <th>Expiration</th>
                <th>Status</th>
                <th>Email Client</th>
                <th>Rescan</th>
              </tr>
            </thead>
            <tbody className="scan-status-list">
              {businesses && businesses.data && businesses.data.length > 0 ? (
                businesses.data.map((value, vIndex) => {
                  return (
                    <tr key={vIndex}>
                      <td>
                        {value.host !== "" ? (
                          <button
                            className="tg-link"
                            onClick={(e) => viewUserDashboard(value.businessId)}
                          >
                            {value.host}
                          </button>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {value.sslInfo.hasOwnProperty("lastCheckDate")
                          ? value.sslInfo.lastCheckDate !== ""
                            ? convertTimeTo(
                                value.sslInfo.lastCheckDate,
                                this.state.timezone,
                                "MMM Do YYYY"
                              )
                            : "N/A"
                          : "N/A"}
                      </td>
                      <td className="text-left">
                        {value.sslInfo.hasOwnProperty("checkFrequency")
                          ? value.sslInfo.checkFrequency !== ""
                            ? value.sslInfo.checkFrequency
                            : "N/A"
                          : "N/A"}
                      </td>
                      <td className="text-left">
                        {value.sslInfo.hasOwnProperty("sslProvider")
                          ? value.sslInfo.sslProvider !== ""
                            ? value.sslInfo.sslProvider
                            : "N/A"
                          : "N/A"}
                      </td>
                      <td className="text-left">
                        {value.sslInfo.hasOwnProperty("expireDate")
                          ? value.sslInfo.expireDate
                            ? moment(value.sslInfo.expireDate).format(
                                "MMM Do YYYY"
                              )
                            : "N/A"
                          : "N/A"}
                      </td>
                      <td className="text-left">
                        {value.sslInfo.hasOwnProperty("status")
                          ? value.sslInfo.status !== ""
                            ? value.sslInfo.status
                            : "N/A"
                          : "N/A"}
                      </td>
                      <td className="text-left">
                        <button className="btn-success btn-sm">
                          <i className="fa fa-envelope" />
                        </button>
                      </td>
                      <td className="col-md-1 text-left">
                        <button
                          data-toggle="modal"
                          data-target="#domainRescanSslConfirmationModal"
                          className="btn btn-success btn-sm"
                          data-testid="btnDashboardRescanSsl"
                          onClick={() =>
                            this.props.updateCurrentDomainId(value._id)
                          }
                        >
                          <i className="fas fa-search" />&nbsp; Rescan SSL
                          Certificate
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7">No records.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

RunningSslScanReport.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RunningSslScanReport);
