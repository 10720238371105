import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getStorageItem,
  setStorageItem,
  removeStorageItem,
  removeAllStorage,
} from "../../../helpers/storage";
import ContentLoader from "react-content-loader";
import { getUserDecryptedData } from "../../../helpers/general";
// import SlideContext from "../../context/slidePanelContexts";
import { callApiGetBusinessStatistics } from "../../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class InsuranceResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: null,
      businessInsuranceStatus: null,
      businessStatistics: null,
    };
  }

  async componentDidMount() {
    const businessData = await callApiGetBusinessStatistics({
      businessId: this.props.businessId,
    });

    this.setState({
      accountType: businessData.data.accountType,
      businessInsuranceStatus:
        businessData.data.accountType !== "free" &&
        businessData.data.accountType !== "monthly-scan"
          ? businessData.data.insuranceStatus
          : "upgrade",
      businessStatistics: businessData.data,
    });
  }

  goToInsurancePage() {
    setStorageItem("businessId", cryptr.encrypt(this.props.businessId));
    this.props.goToPageInsurance(this.state.businessStatistics);
  }

  render() {
    const { businessInsuranceStatus, accountType } = this.state;
    const { businessId, index } = this.props;

    let businessInsuranceTextStatus,
      businessInsuranceLinkClass,
      businessInsuranceIconClass,
      businessInsuranceStyle;

    switch (businessInsuranceStatus) {
      case "completed":
        businessInsuranceTextStatus = "Completed";
        businessInsuranceLinkClass = "tg-dashboard-passed-link";
        businessInsuranceIconClass = "fa fa-check-circle h1-icon";
        businessInsuranceStyle = { color: "#33981f" };
        break;
      case "submitted":
        businessInsuranceTextStatus = "Submitted";
        businessInsuranceLinkClass = "tg-dashboard-passed-link";
        businessInsuranceIconClass = "fa fa fa-clock h1-icon";
        businessInsuranceStyle = { color: "orange" };
        break;
      case "upgrade":
        businessInsuranceTextStatus = "Upgrade";
        businessInsuranceLinkClass = "tg-dashboard-passed-link";
        businessInsuranceIconClass = "fa fa fa-clock h1-icon";
        businessInsuranceStyle = { color: "orange" };
        break;
      default:
        businessInsuranceTextStatus = "Incomplete";
        businessInsuranceLinkClass = "tg-dashboard-failed-link";
        businessInsuranceIconClass = "fa fa-clock h1-icon";
        businessInsuranceStyle = { color: "#7f7f7f" };
        break;
    }

    return (
      <button
        data-toggle={
          accountType === "security" ||
          accountType === "free" ||
          accountType === "90-day-trial" ||
          accountType === "60-day-trial" ||
          accountType === "pci-malware" ||
          accountType === "monthly-scan"
            ? "modal"
            : ""
        }
        data-target={
          accountType === "security" ||
          accountType === "free" ||
          accountType === "90-day-trial" ||
          accountType === "60-day-trial" ||
          accountType === "monthly-scan"
            ? "#limitedScanModal"
            : accountType === "pci-malware"
              ? "#pciMalwareInsuranceModal"
              : ""
        }
        onClick={() => {
          accountType === "security" ||
          accountType === "free" ||
          accountType === "90-day-trial" ||
          accountType === "60-day-trial" ||
          accountType === "pci-malware" ||
          accountType === "monthly-scan"
            ? void 0
            : this.goToInsurancePage();
        }}
        style={businessInsuranceStyle}
        className={businessInsuranceLinkClass}
      >
        <i className={businessInsuranceIconClass} />
        <br />
        <br />
        <span
          data-testid="spnDashboardInsuranseStatus"
          style={{
            fontSize: "12px",
            fontWeight: "200",
          }}
        >
          {businessInsuranceStatus === "completed"
            ? businessInsuranceTextStatus
            : accountType === "security" || accountType === "pci-malware"
              ? "Upgrade Now"
              : businessInsuranceTextStatus}
          <br />
        </span>
        <br />
      </button>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPageInsurance: (businesDetails) => push("/insurance", businesDetails),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceResult);
