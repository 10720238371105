import React from "react";

const falsePositiveNotificationBar = (props) => (
  <div className="panel-group " id="accordion">
    <div className="panel panel-danger ">
      <div className="panel-heading col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h4
          className="panel-title"
          style={{
            margin: "10px 0 10px 0",
            fontSize: "14px",
            textTransform: "none",
          }}
        >
          <div className="col-lg-10 text-left">
            <div
              className="text-center h4 m-0"
              style={{
                width: "3%",
                float: "left",
              }}
            >
              <b className="fas fa-info-circle" />
            </div>
            <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 pb-2">
              You currently have{" "}
              <strong>high or medium vulnerabilities for {props.url}</strong>.
              For more information on the steps needed in order to resolve your
              vulnerabilities
            </div>
          </div>
          <div
            className="col-lg-2 col-md-12 col-sm-12 col-xs-12 text-right"
            style={{ top: -5 }}
          >
            <a
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapseOne"
              style={{ color: "#444444" }}
            >
              <button className="btn btn-danger btn-sm">Click Here</button>
            </a>
          </div>
        </h4>
      </div>
      <div
        id="collapseOne"
        className="panel-collapse collapse col-lg-12 col-md-12 col-sm-12 col-xs-12 no-pad"
      >
        <div className="panel-body">
          <div style={{ marginBottom: "15px" }}>
            <p
              style={{
                fontWeight: "bold",
                lineHeight: "14px",
                fontSize: "14px",
              }}
            >
              Step 1. Examine your latest scan.
            </p>
            <span
              style={{
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "12px",
              }}
            >
              You can do this by clicking on the 'Failed Scan Report' button
              below next to the failed scan, and downloading the 'Detailed' PDF
              report type from the drop down menu.
              <br />
              When the PDF opens, scroll down until you find the vulnerability,
              which will be marked as either 'High' or 'Medium'. Once you find
              the High and/or Medium vulnerabilities in the report, review the
              information.
            </span>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <p
              style={{
                fontWeight: "bold",
                lineHeight: "14px",
                fontSize: "14px",
              }}
            >
              Step 2. Resolve the 'High' and/or 'Medium' vulnerabilities in one
              of three ways:
            </p>
            <div
              style={{
                fontSize: "12px",
                lineHeight: "1.5",
                paddingLeft: "20px",
              }}
            >
              A. If you know how to fix the vulnerability, you can resolve the
              issue and rescan your site by clicking on the 'Rescan' button next
              to the failed scan.
              <br />
              <br />
              B. If you’re not sure how to fix the issue, send the Detailed
              Report that you downloaded via email, to your hosting provider or
              server administrator. Once your hosting company or server admin
              reviews your vulnerability, they will either fix the
              vulnerability, at which point you can rescan your site, or they
              will deem it to be a false positive. (Note: 90% of vulnerabilities
              are fixed by the hosting provider.)
              <br />
              <br />
              C. If your hosting company thinks it's a false positive, then
              simply click on the 'Submit False Positive' button next to the
              failed scan to submit their findings, and we will review them
              within 24 business hours.
            </div>
          </div>

          <div style={{ marginBottom: "15px" }}>
            <p
              style={{
                fontWeight: "bold",
                lineHeight: "14px",
                fontSize: "14px",
              }}
            >
              Step 3. Click the 'Rescan' button to run another scan to see if
              the vulnerabilities have been fixed.
            </p>
            <span
              style={{
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "12px",
              }}
            >
              Note: You can use the 'Rescan' button as much as needed to help
              you get to a passed status, however, the rescan button will only
              run one scan at a time.
              <br />
              Also, if you submit any false positives, we will rescan your site
              for you. This can take up to 24 business hours.
              <br />
              If you have any issues or questions regarding your scans, please
              email our PCI Support staff at <b>pci@trustguard.com</b>, or call
              our toll-free number at <b>1 (877) 848-2731 ext 3</b>, and they
              will be happy to assist you.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default falsePositiveNotificationBar;
