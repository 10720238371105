import React from "react";
import _ from "lodash";
import moment from "moment/moment";
import { Modal, Button } from "react-bootstrap";
import ToastMessage from "../../components/toast/toastMessage";
import { convertTimeTo } from "../../helpers/timeZone";
import { getUserDecryptedData } from "../../helpers/general";
import updateMalwareStatus from "../../helpers/malwareUpdateStatus";
import { malwareStatus } from "../../helpers/malwareStatus";
import { getStorageItem, removeStorageItem } from "../../helpers/storage";
import MalwareVulnerabilityOption1 from "./malwareVulnerabilityOption1";
import MalwareVulnerabilityOption2 from "./malwareVulnerabilityOption2";
import MalwareVulnerabilityOption3 from "./malwareVulnerabilityOption3";
import {
  callOnUpdateBusinessMalwareRule,
  callOnRemoveBusinessMalwareRule,
} from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class MalwareScannedFilesInfo extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      actionValue: "",
      selectedOption: "default",
      ignoreToSubmit: false,
      showModal: false,
      submitted: false,
      spanViewMoreDetails: false,
      viewMoreDetails: "none",
      viewCodeDump: "none",
      spanCodeDump: false,
      displayCodeDump: null,
      userId: null,
      showRule: false,
      isIgnoredByRule: this.props.isIgnoredByRule,
      removingRule: false,
      ruleValue: "",
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    removeStorageItem("businessID");
    let user = {};
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      user = JSON.parse(userString);
      if (this._isMounted) {
        this.setState({
          userId: user._id,
        });
      }
    }
    if (
      this.props.dump === "" ||
      this.props.dump === null ||
      this.props.dump === undefined
    ) {
      if (this._isMounted) {
        this.setState({
          displayCodeDump: "none",
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          displayCodeDump: "block",
        });
      }
    }

    this.setState({
      isIgnoredByRule: this.hasIgnoredRule(),
    });
  }

  async onChangeSetMalwareVul(
    e,
    reportUrl,
    threat,
    fileName,
    scanStatus,
    malwareHistoryID,
    domainId,
    dumpMD5,
    businessId
  ) {
    let actionVul = e.target.value;
    let actionVulToast = e.target.value;
    const { onUpdateMalwareStatus, index } = this.props;

    if (e.target.value === "updaterule") {
      this.optionMalwareRule(e, true);
    } else {
      if (actionVul === "Unignored" || actionVul === "Cancelled for review") {
        actionVul = "Action needed";
      }

      await updateMalwareStatus(
        e,
        reportUrl,
        threat,
        fileName,
        scanStatus,
        malwareHistoryID,
        domainId,
        dumpMD5,
        businessId
      );
      onUpdateMalwareStatus(index, actionVul);

      this.setState(
        {
          submitted: true,
          actionValue: "File " + actionVulToast,
        },
        () => {
          setTimeout(() => {
            this.setState({ submitted: false }, () => {});
            window.location.href = "/";
          }, 2000);
        }
      );
    }
  }

  getOptions(status) {
    let listMalwareVul = "";
    if (status === "Sent for review") {
      listMalwareVul = <MalwareVulnerabilityOption3 />;
    } else if (status === "Ignored") {
      listMalwareVul = <MalwareVulnerabilityOption2 />;
    } else {
      listMalwareVul = <MalwareVulnerabilityOption1 />;
    }
    return listMalwareVul;
  }

  viewMoreDetailsOnCLick() {
    if (!this.state.spanViewMoreDetails === false) {
      this.setState({
        viewMoreDetails: "none",
      });
    } else {
      this.setState({
        viewMoreDetails: "block",
      });
    }
    this.setState({
      spanViewMoreDetails: !this.state.spanViewMoreDetails,
    });
  }

  viewCodeDump() {
    if (!this.state.spanCodeDump === false) {
      this.setState({
        viewCodeDump: "none",
      });
    } else {
      this.setState({
        viewCodeDump: "block",
      });
    }

    this.setState({
      spanCodeDump: !this.state.spanCodeDump,
    });
  }

  async removeRule(data) {
    this.setState({
      removingRule: true,
    });
    await callOnRemoveBusinessMalwareRule(data);
  }

  optionMalwareRule = (e, submitIgnore = false) => {
    if (e.target.value === "updaterule") {
      this.setState({
        showModal: true,
        ignoreToSubmit: submitIgnore,
        selectedOption: e.target.value,
      });
    }
  };

  onChangeIgnoredByRule = async (e) => {
    this.optionMalwareRule(e);
    if (e.target.value === "removerule") {
      await this.removeRule({
        id: this.props.businessId,
        rule:
          this.props.dumpMD5 !== null
            ? _.find(
                this.props.setRule,
                (r) => r.dumpMD5 !== null && r.dumpMD5 === this.props.dumpMD5
              )
            : this.props.setRule,
        dumpMD5: this.props.dumpMD5,
      });
    }
  };

  onUpdateMalwareRule = async (e) => {
    await callOnUpdateBusinessMalwareRule({
      id: this.props.businessId,
      rule: this.state.ruleValue,
      newRule: false,
    });
  };

  onSaveMalwareRule = async () => {
    await callOnUpdateBusinessMalwareRule({
      id: this.props.businessId,
      rule: this.state.ruleValue,
      newRule: true,
      dump: this.props.dumpMD5,
    });
  };

  hasIgnoredRule() {
    const hasIgnoredRule = _.find(
      this.props.setRule,
      (r) => r.dumpMD5 === this.props.dumpMD5
    );

    return hasIgnoredRule;
  }

  getIgnoreStatus(isbutton = false) {
    let ignoreRuleStatus = "";
    const rules = this.props.setRule;

    const rule = [];
    if (rules !== null) {
      if (rules.length > 0) {
        for (let i = 0; i < rules.length; i++) {
          rule.push(rules[i].rule);
        }
      }
    }

    const identifiedRules = rule.length > 0 ? rule.join(", ") : null;

    if (!isbutton) {
      if (this.state.isIgnoredByRule) {
        ignoreRuleStatus = (
          <>
            <button
              className="tg-dashboard-link"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showRule: !this.state.showRule });
              }}>
              Ignored by Rule
            </button>{" "}
            {this.state.showRule ? (
              <>
                <br /> {identifiedRules}{" "}
              </>
            ) : null}
          </>
        );
      } else {
        ignoreRuleStatus = this.props.actionStatus;
        if (this.state.removingRule) {
          ignoreRuleStatus = "Removing Last Rule...";
        }
      }
    } else {
      if (this.hasIgnoredRule() !== undefined) {
        ignoreRuleStatus = this.hasIgnoredRule();
      } else {
        ignoreRuleStatus = false;
      }
    }

    return ignoreRuleStatus;
  }

  handleClose = () =>
    this.setState({ showModal: false, selectedOption: "default" });

  render() {
    const {
      actionValue,
      submitted,
      viewMoreDetails,
      viewCodeDump,
      displayCodeDump,
      ruleValue,
      showModal,
      selectedOption,
      removingRule,
    } = this.state;

    const {
      dump,
      fileTypeName,
      setRule,
      showAction,
      scanFinish,
      fileName,
      details,
      reason,
      actionStatus,
      scanStatus,
      malwareHistoryID,
      domainID,
      reportUrl,
      dumpMD5,
      businessId,
    } = this.props;

    let malwareRule = "";

    if (_.isArray(setRule)) {
      malwareRule = setRule.length > 0 ? setRule[0].rule : "";
    }

    let alert = null;

    if (submitted) {
      alert = <ToastMessage type="success" notifyMessage={actionValue} />;
    }
    const userData = getUserDecryptedData();
    const timeZone = userData.hasOwnProperty("timezone")
      ? userData.timezone
      : null;

    let saveButton = this.ignoreToSubmit ? (
      <Button
        variant="primary"
        className="success"
        onClick={() => this.onUpdateMalwareRule()}>
        Save Changes
      </Button>
    ) : (
      <Button
        variant="primary"
        className="success"
        onClick={() => this.onSaveMalwareRule()}>
        Add Rule
      </Button>
    );
    return (
      <>
        <Modal show={showModal}>
          <Modal.Header>
            <Modal.Title>Update Malware Ignore Rule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={ruleValue}
                onChange={(e) => this.setState({ ruleValue: e.target.value })}
                placeholder={malwareRule}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              Close
            </Button>
            {saveButton}
          </Modal.Footer>
        </Modal>
        <tr>
          <td>
            {alert}
            <strong
              className="pointer"
              onClick={this.viewMoreDetailsOnCLick.bind(this)}
              style={{ color: "#268226" }}>
              {fileName}
            </strong>
            <div className="row">
              <div style={{ display: viewMoreDetails }} className="col-sm-12">
                <br />
                <br />
                <strong>Details:</strong> {details}
                <br />
                <br />
                <strong>Reason:</strong> {reason}
                <br />
                <br />
                <strong
                  style={{
                    color: "#268226",
                    display: displayCodeDump,
                  }}
                  className="pointer"
                  onClick={this.viewCodeDump.bind(this)}>
                  Code Dump:
                </strong>{" "}
                <div
                  style={{
                    display: viewCodeDump,
                    overflowY: "scroll",
                    overflowX: "scroll",
                    maxWidth: "400px",
                    maxHeight: "400px",
                  }}>
                  {dump}
                </div>
                <hr />
              </div>
            </div>
          </td>

          <td>{fileTypeName}</td>
          <td>{this.getIgnoreStatus()}</td>
          <td>
            {this.props.isIgnoredByRule ? (
              <select
                className="btn-default btn-md form-control"
                onChange={(e) => this.onChangeIgnoredByRule(e)}
                style={{ display: showAction }}
                value={selectedOption}>
                <option value="default">-------</option>
                <option value="updaterule" id="modal-edit-rule">
                  Update Rule
                </option>
                <option value="removerule">Remove Rule</option>
              </select>
            ) : (
              <select
                className="btn-default btn-md form-control"
                onChange={(e) =>
                  this.onChangeSetMalwareVul(
                    e,
                    reportUrl,
                    scanStatus,
                    fileName,
                    scanStatus,
                    malwareHistoryID,
                    domainID,
                    dumpMD5,
                    businessId
                  )
                }
                style={{ display: showAction }}
                disabled={actionStatus === "No Action Needed" ? true : false}>
                {this.getOptions(actionStatus)}
                <option value="updaterule">Ignore if Contains</option>
              </select>
            )}
          </td>
          <td>
            {convertTimeTo(scanFinish, timeZone, "MM/DD/YYYY hh:mm a", false)}
          </td>
          <td>{malwareStatus(scanStatus, actionStatus)}</td>
        </tr>
      </>
    );
  }
}

export default MalwareScannedFilesInfo;
