import React from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Slide from "../../../../components/animation/slide";
import SlideContext from "../../../../components/context/slidePanelContexts";
import AppContext from "../../../../components/context/appContext";
import TableHeader from "../../../../components/dashboard/partials/tableContent";
import Spinner from "../../../../components/ui/spinner/spinnerRect";
import PciSlidePanelInvalidDomain from "./pciSlidePanelInvalidDomain";
import StartScanButton from "../startScanButton";
class PciSlidePanel extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {(appValue) => {
          return (
            <>
              <SlideContext.drawerContext.Consumer>
                {(value) => {
                  let willOpen = false;
                  if (value.pci.length > 1) {
                    if (value.pci[this.props.index] !== undefined) {
                      if (value.pci.length === 1) {
                        willOpen = true;
                      } else {
                        willOpen = value.pci[this.props.index].isPciActive
                          ? this.props.businessId ===
                            value.pci[this.props.index].businessId
                            ? true
                            : false
                          : false;
                      }
                    }
                  }

                  if (value.pci.length === 1) {
                    willOpen = value.pci[this.props.index].isPciActive;
                  }

                  return (
                    <Slide open={willOpen}>
                      <div
                        id="pciscan-details"
                        style={{
                          display: "block",
                          position: "relative",
                          background: "#ffffff",
                        }}
                      >
                        <>
                          {appValue.userBusinessData.length === 0 ? (
                            ""
                          ) : value.pci.length >= 1 &&
                          appValue.userBusinessData[
                            this.props.index
                          ].scanVendor.hasOwnProperty("owner") ? (
                            value.pci[this.props.index].scanCount > 0 ? (
                              <TableHeader
                                toPCIpage={() =>
                                  this.props.goToPagePCIScanning(
                                    appValue.userBusinessData[this.props.index]
                                      .scanVendor.owner,
                                    this.context,
                                    value.pci[this.props.index].scanCount,
                                    this.props.index
                                  )
                                }
                              >
                                {this.props.children}
                              </TableHeader>
                            ) : this.props.isLoading ? (
                              <div id="start-scan-button">
                                <Spinner />
                              </div>
                            ) : (
                              <StartScanButton
                                businessId={this.props.businessId}
                                index={this.props.index}
                                startScanDomainHandler={
                                  value.pci[this.props.index].startPCIscan
                                }
                                isScanning={
                                  value.pci[this.props.index].isPciScanning
                                }
                              />
                            )
                          ) : value.pci[this.props.index] !== undefined ? (
                            value.pci[this.props.index].isPciScanning ? (
                              <div className="row" id="start-scan-button">
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "1.5em",
                                  }}
                                >
                                  Please wait while we create a new scan for
                                  this site.
                                </p>
                              </div>
                            ) : appValue.userBusinessData[this.props.index]
                              .pciProgressStatus === "invalid" ? (
                              <PciSlidePanelInvalidDomain
                                primaryDomain={
                                  appValue.userBusinessData[this.props.index]
                                    .primaryDomain
                                }
                                businessId={
                                  appValue.userBusinessData[this.props.index]
                                    ._id
                                }
                              />
                            ) : (
                              <StartScanButton
                                businessId={this.props.businessId}
                                index={this.props.index}
                                startScanDomainHandler={
                                  value.pci[this.props.index].startPCIscan
                                }
                                isScanning={
                                  value.pci[this.props.index].isPciScanning
                                }
                              />
                            )
                          ) : null}
                        </>
                      </div>
                    </Slide>
                  );
                }}
              </SlideContext.drawerContext.Consumer>
            </>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPagePCIScanning: (owner, context, historyCount, index) =>
        push({
          pathname: "/pci-scanning/" + owner,
          state: {
            business: context.userBusinessData,
            user: context.userData,
            scanCount: historyCount,
            toggledIndex: index,
          },
        }),
    },
    dispatch
  );

PciSlidePanel.contextType = AppContext;

export default connect(
  null,
  mapDispatchToProps
)(PciSlidePanel);
