import React from "react";

function malwareStatus(status, actionStatus) {
  let detectionColor = "";
  status = "" + status;
  if (status !== null || status !== undefined || status !== "") {
    if (status.toLowerCase() === "malicious") {
      detectionColor = (
        <span className="text-danger">
          <i className="fas fa-check-circle" /> Malicious
        </span>
      );
      if (actionStatus !== undefined) {
        if (actionStatus.toLowerCase() === "ignored") {
          detectionColor = (
            <span style={{ color: "#33981f" }}>
              <i className="text-success fas fa-check-circle " /> Clean
            </span>
          );
        }
      }
    } else if (status.toLowerCase() === "potentially suspicious") {
      detectionColor = (
        <span style={{ color: "orange" }}>
          <i className="fas fa-check-circle" /> Potentially Suspicious
        </span>
      );

      if (actionStatus !== undefined) {
        if (actionStatus.toLowerCase() === "ignored") {
          detectionColor = (
            <span style={{ color: "#33981f" }}>
              <i className="text-success fas fa-check-circle " /> Clean
            </span>
          );
        }
      }
    } else if (status.toLowerCase() === "suspicious") {
      detectionColor = (
        <span style={{ color: "orange" }}>
          <i className="fas fa-check-circle" /> Suspicious
        </span>
      );

      if (actionStatus !== undefined) {
        if (actionStatus.toLowerCase() === "ignored") {
          detectionColor = (
            <span style={{ color: "#33981f" }}>
              <i className="text-success fas fa-check-circle " /> Clean
            </span>
          );
        } else {
          detectionColor = (
            <span style={{ color: "orange" }}>
              <i className="fas fa-check-circle" /> Suspicious
            </span>
          );
        }
      } else {
        detectionColor = (
          <span style={{ color: "orange" }}>
            <i className="fas fa-check-circle" /> Suspicious
          </span>
        );
      }
    } else if (status.toLowerCase() === "clean") {
      detectionColor = (
        <span style={{ color: "#33981f" }}>
          <i className="text-success fas fa-check-circle " /> Clean
        </span>
      );
    } else if (status.toLowerCase() === "running") {
      detectionColor = (
        <span style={{ color: "#7f7f7f" }}>
          <i className="fas fa-check-circle" /> Running
        </span>
      );
    } else if (status.toLowerCase() === "running") {
      detectionColor = (
        <span style={{ color: "#000" }}>
          <i className="fas fa-check-circle" /> N/A
        </span>
      );
    } else if (status.toLowerCase() === null) {
      detectionColor = <span style={{ color: "#000" }}>&nbsp;</span>;
    }
  }
  return detectionColor;
}

export { malwareStatus };
