import { api } from "./api";
import { callApi } from "../helpers/index";

const callOnFindVulnerabilitiesByScanId = (
  id,
  owner,
  scanNumber,
  severity,
  date
) => {
  return callApi().then(async (response) => {
    return await api.service("api/vulnerabilities").find({
      query: {
        id: id,
        scanNumber: scanNumber,
        owner: owner,
        severity: severity,
        date: date,
        purpose: "get-vulnerability-details",
      },
    });
  });
};

const callOnHostsResultsByScanId = (owner, scanNumber) => {
  return callApi().then(async (response) => {
    return await api.service("api/vulnerabilities").find({
      query: {
        scanNumber: scanNumber,
        owner: owner,
        purpose: "get-hosts-results",
        apiKey: null,
      },
    });
  });
};

const callOnIgnoreVulnerabilityContains = (
  id,
  value,
  malHistoryId,
  businessId,
  domainId,
  dump,
  fileName,
  reportUrl
) => {
  return callApi().then(async (response) => {
    const params = {
      query: {
        id: id,
        value: value,
        malwareHistoryId: malHistoryId,
        businessId: businessId,
        domainId: domainId,
        dump: dump,
        fileName,
        reportUrl: reportUrl,
        purpose: "update_ignore_rule",
      },
    };

    return await api.service("vulnerabilities").get(id, params);
  });
};

const callOnIgnoreAllVulnerability = (
  id,
  malHistoryId,
  domainId,
  businessId,
  reportUrl
) => {
  return callApi().then(async (response) => {
    const params = {
      query: {
        id,
        malwareHistoryId: malHistoryId,
        domainId: domainId,
        businessId: businessId,
        reportUrl: reportUrl,
        ignoreAll: true,
      },
    };
    return await api.service("vulnerabilities").get(id, params);
  });
};

export {
  callOnFindVulnerabilitiesByScanId,
  callOnIgnoreVulnerabilityContains,
  callOnIgnoreAllVulnerability,
  callOnHostsResultsByScanId,
};
