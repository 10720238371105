import React from "react";
import Sidebar from "../sidebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppContext from "../../components/context/appContext";
import RouteContext from "../../components/context/routeContext";
import {
  getUserDecryptedData,
  getBusinessDecryptedData,
} from "../../helpers/general";
import {
  callGetBusiness,
  callApiGetUserData,
  callApiGetUserBusinessData,
  callApiGetBusinessData,
  callApiGetBusinessUsersList,
  callApiUpdateUserProfileData,
} from "../../services/";

class PostLogin extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      isAdmin: false,
      isMounted: false,
      userLsData: {},
      userData: {},
      userBusinessData: [],
      notificationsCount: 0,
      modalRescan: false,
      updateUserData: this.updateUserData,
      updateUserBusinessData: this.updateUserBusinessData,
      updateNotificationCount: this.updateNotificationCount,
      currentBusinessId: null,
      updateCurrentBusinessId: this.updateCurrentBusinessId,
    };
  }

  async componentDidMount() {
    this.setState({
      userLsData: getUserDecryptedData(),
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.userLsData !== this.state.userLsData) {
      await this.updateUserData();
      await this.updateUserBusinessData();
      await this.updateNotificationCount();
    }
  }

  updateUserBusinessData = async () => {
    let userLsdata = this.state.user;
    if (userLsdata === null || userLsdata === undefined) {
      userLsdata = getUserDecryptedData();
    }

    if (!userLsdata.isAdmin) {
      const businessData = await callApiGetUserBusinessData(userLsdata);

      if (businessData) {
        this.setState({
          userBusinessData: businessData.data,
          isMounted: true,
        });
      }
    } else {
      let businessLsId = getBusinessDecryptedData();
      if (businessLsId !== null && businessLsId !== undefined) {
        const businessId = businessLsId;
        const tempBusinessData = [];
        tempBusinessData.push(await callApiGetBusinessData(businessId));

        this.setState({
          userBusinessData: tempBusinessData,
          isMounted: true,
        });
      } else {
        this.setState({
          userBusinessData: [],
          isMounted: true,
        });
      }
    }
  };

  updateUserData = async () => {
    let userLsdata = this.state.user;
    if (userLsdata === null || userLsdata === undefined) {
      userLsdata = getUserDecryptedData();
    }

    const userData = await callApiGetUserData(userLsdata);

    if (userData) {
      this.setState({
        isAdmin: userLsdata.isAdmin,
        userData: userData,
        isMounted: true,
      });
    }
  };

  updateNotificationCount = async () => {
    const userNotifications =
      this.state.userData &&
      this.state.userData.hasOwnProperty("notifications") &&
      this.state.userData.notifications.length > 0
        ? this.state.userData.notifications
        : null;

    const unseenNotifications = userNotifications
      ? userNotifications.filter((v, i) => {
          return v.status === "unseen" ? v : null;
        })
      : null;

    this.setState({
      notificationsCount: unseenNotifications ? unseenNotifications.length : 0,
    });
  };

  updateUserNotificationData = async () => {
    const userNotifications =
      this.state.userData &&
      this.state.userData.hasOwnProperty("notifications") &&
      this.state.userData.notifications.length > 0
        ? this.state.userData.notifications
        : null;

    if (userNotifications) {
      const unseenNotifications = userNotifications
        ? userNotifications.filter((v, i) => {
            return v.status === "unseen" ? v : null;
          })
        : null;

      if (unseenNotifications.length > 0) {
        userNotifications.map((v, i) => {
          v.status = v.status === "unseen" ? "seen" : v.status;
        });

        await callApiUpdateUserProfileData(this.state.userData._id, {
          purpose: "unseenNotificationCountUpdate",
          notifications: unseenNotifications,
        });
      }
    }
  };

  updateCurrentBusinessId = async (businessId) => {
    if (businessId) {
      this.setState({
        currentBusinessId: businessId,
      });
    }
  };

  showModalRescan = () => {
    console.log("showing modal!!");
    this.setState({
      modalRescan: true,
    });
  };

  closeModalRescan = () => {
    this.setState({
      modalRescan: false,
    });
  };
  render() {
    const {
      userData,
      userBusinessData,
      currentBusinessId,
      notificationsCount,
      businessUsersList,
      modalRescan,
    } = this.state;

    const contextValue = {
      userData,
      userBusinessData: userBusinessData,
      notificationsCount,
      showBackDrop: this.context.showBackDrop,
      modalRescan: this.state.modalRescan,
      closeVulnerabilities: this.context.closeVulnerabilities,
      updateUserData: this.updateUserData,
      updateUserBusinessData: this.updateUserBusinessData,
      updateNotificationCount: this.updateNotificationCount,
      updateUserNotificationData: this.updateUserNotificationData,
      currentBusinessId,
      updateCurrentBusinessId: this.updateCurrentBusinessId,
      showModalRescan: this.showModalRescan,
      closeModalRescan: this.closeModalRescan,
    };

    return (
      <div>
        <AppContext.Provider value={contextValue}>
          <div id="main-container" className="col-lg-8">
            {this.props.pageContent}
          </div>
          <Sidebar />
        </AppContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
PostLogin.contextType = RouteContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostLogin);
