import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ToastContainer } from "react-toastify";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header";
import { getStorageItem } from "../../helpers/storage";
import { images } from "../../helpers/images";
import AppContext from "../../components/context/appContext";

import {
  getAutoRenewingSSL,
  onCreateAutoRenewingSSL,
  resetStatus,
} from "../../modules/actions/index";
import {
  callApiGetBusinessData,
  callOnGetAutoRenewingSSL,
  callOnCreateAutoRenewingSSL,
} from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class GetAutoRenewingSsl extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      businessId: null,
      domainId: null,
      name: "",
      email: "",
      hostingPackage: "",
      typeOfServer: "",
      hostingPlanWithSSH: "",
      smw: "",
      lastPassPasswordManager: "",
      discussBefore: "",
      notifyType: "",
      notifyMessage: "",
      isSubmitted: false,
      hostingProvider: "",
      isDisable: false,
    };
  }

  async componentDidMount() {
    let businessId = getStorageItem("businessId");

    if (businessId) {
      const businessData = await callApiGetBusinessData(
        cryptr.decrypt(businessId)
      );

      if (
        businessData.accountType === "basic" ||
        businessData.accountType === "security"
      ) {
        this.setState(
          {
            businessId: cryptr.decrypt(businessId),
            domainId: this.props.history.location.state,
          },
          async () => {
            let query = {
              query: {
                $or: [
                  { businessId: cryptr.decrypt(businessId) },
                  { domainId: this.props.history.location.state },
                ],
              },
            };
            let tempRenewalSslData = await callOnGetAutoRenewingSSL(query);

            if (
              tempRenewalSslData &&
              tempRenewalSslData &&
              tempRenewalSslData.data.length > 0
            ) {
              this.setState({
                isSubmitted: true,
              });
            }
          }
        );
      } else {
        this.props.goToDashboard();
      }
    }
  }

  disableSubmit = () => {
    this.setState(
      {
        isDisable: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isDisable: false,
          });
        }, 2500);
      }
    );
  };

  onClickSaveAutoRenewingSSL = async () => {
    this.disableSubmit();

    const emailType = /^\w+([\D.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.name.length <= 0) {
      toast.error("Enter a name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    } else if (
      this.state.email.length <= 0 ||
      emailType.test(this.state.email) !== true
    ) {
      toast.error("Enter a name.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    } else if (this.state.hostingProvider === "") {
      toast.error("Select your web hosting provider.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    } else if (this.state.hostingPackage === "") {
      toast.error("Enter a your hosting plan or package.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    } else if (this.state.typeOfServer === "") {
      toast.error("Choose your type of server.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    } else if (this.state.smw === "") {
      toast.error("Choose how SMW convert your site.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    } else if (this.state.discussBefore === "") {
      toast.error(
        "Choose your option for a discussion before converting your site.",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        }
      );
    } else if (this.state.hostingPlanWithSSH === "") {
      toast.error("Choose your option for hosting plan SSH.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (this.state.lastPassPasswordManager === "") {
      toast.error("Choose your option for LastPass password manager.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    } else {
      const data = {
        businessId: this.state.businessId,
        domainId: this.state.domainId,
        name: this.state.name,
        email: this.state.email,
        hostingProvider: this.state.hostingProvider,
        hostingPackage: this.state.hostingPackage,
        typeOfServer: this.state.typeOfServer,
        hostingPlanWithSSH: this.state.hostingPlanWithSSH,
        smw: this.state.smw,
        lastPassPasswordManager: this.state.lastPassPasswordManager,
        discussBefore: this.state.discussBefore,
      };

      if (data) {
        await callOnCreateAutoRenewingSSL(data);

        toast.success(
          "Thank you for submitting your SSL information. Someone will contact you regarding the setup for you SSL soon.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          }
        );

        this.setState(
          {
            name: "",
            email: "",
            hostingProvider: "",
            hostingPackage: "",
            typeOfServer: "",
            hostingPlanWithSSH: "",
            smw: "",
            lastPassPasswordManager: "",
            discussBefore: "",
          },
          () => {
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          }
        );
      }
    }
  };

  render() {
    const screenHeight = window.innerHeight,
      screenWidth = window.innerWidth;

    const {
      name,
      email,
      hostingPackage,
      notifyType,
      notifyMessage,
      isSubmitted,
      hostingProvider,
      isDisable,
    } = this.state;

    return (
      <div>
        <Header titlePage={"Get Auto Renewing SSL"} bgColor="#F5F6F8" />
        <ToastContainer />

        <div id="mcontent-wrapper">
          <div id="control-panel">
            {isSubmitted ? (
              <div
                style={{
                  display: "block",
                  width: screenWidth - 330,
                  height: screenHeight - 160,
                  paddingTop: screenHeight / 2 - 200,
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontSize: 28,
                  textAlign: "center",
                  color: "#cccccc",
                  verticalAlign: "center",
                }}
              >
                <div style={{ height: 210 }}>
                  <i className="fa fa-bell bellIcon" />
                  <div
                    style={{
                      display: "block",
                      clear: "both",
                      paddingTop: 20,
                    }}
                  >
                    SSL information <br />has been <br /> submitted
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
                {/* <form
                  enctype="multipart/form-data"
                  method="post"
                  onSubmit={() => this.onClickSaveAutoRenewingSSL}
                  class="frm-show-form  frm_js_validate  frm_pro_form  frm_ajax_submit"
                  id="form_h6saa"
                > */}
                <div
                  id="renewFormSSL"
                  className="col-md-12 col-sm-12 col-xs-12 text-left px-0"
                >
                  <input type="hidden" name="frm_action" value="create" />
                  <input type="hidden" name="form_id" value="12" />
                  <input
                    type="hidden"
                    name="frm_hide_fields_12"
                    id="frm_hide_fields_12"
                    value="['frm_field_155_container']"
                  />
                  <input type="hidden" name="form_key" value="h6saa" />
                  <input type="hidden" name="item_meta[0]" value="" />
                  <input
                    type="hidden"
                    id="frm_submit_entry_12"
                    name="frm_submit_entry_12"
                    value="0b1546a621"
                  />
                  <input
                    type="hidden"
                    name="_wp_http_referer"
                    value="/trust-guard-start/"
                  />
                  <input type="hidden" name="item_key" value="" />
                  <input
                    type="hidden"
                    name="item_meta[156]"
                    id="field_zh18n"
                    value="trustguard"
                    data-sectionid="145"
                    data-frmval="trustguard"
                    data-invmsg="Partner is invalid"
                    class="form-control"
                  />

                  <div className="col-lg-3  col-md-3 col-sm-12 col-xs-12 no-pad">
                    <div id="formBrandSSL">
                      <div className="col-md-12 col-sm-12 col-xs-12 text-center mb-4">
                        <img
                          id="tg-ssl-logo"
                          src={images.tgRegularDark}
                          alt="Trust Guard Logo"
                          className="pt-0"
                        />
                        <div className="col-lg-6 text-right">
                          <label className="tg-inpartnership">
                            In Partnership with
                          </label>
                        </div>
                        <div className="col-lg-6 text-left pt-1">
                          <img
                            id="tg-smw-logo"
                            src={images.sslsecuremysite}
                            alt="SSL - Secure My Site"
                          />
                        </div>
                      </div>
                      <hr />
                      <p className="p-0 m-0">
                        Trust Guard has partnered with Secure My Website to
                        provide you with everything you need to convert your
                        website to auto-renewing SSL. The Secure My Website team
                        are SSL experts, and will install your new Auto-Renewing
                        SSL, fix any mixed-content errors, add proper redirects
                        for SEO, and resubmit to the Search Engines.
                      </p>
                    </div>

                    <div id="formBrandSSL-well" className="">
                      This service is included as part of your Trust Guard
                      membership. Simply fill out the form to the right to get
                      started.
                    </div>
                  </div>

                  <div
                    id="formContainerSSL"
                    className="col-lg-9  col-md-9 col-sm-12 col-xs-12 pr-0"
                  >
                    <div className="col-lg-12">
                      <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <label>Name:</label>
                        <input
                          autoFocus
                          type="text"
                          className="form-control"
                          name="item_meta[142]"
                          value={name}
                          onChange={(evt) =>
                            this.setState({ name: evt.target.value })
                          }
                        />
                        <label>Email:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="item_meta[141]"
                          value={email}
                          onChange={(evt) =>
                            this.setState({ email: evt.target.value })
                          }
                        />
                        <label>Web Hosting Provider:</label>
                        <select
                          className="form-control"
                          name="item_meta[154]"
                          value={hostingProvider}
                          onChange={(e) => {
                            this.setState({
                              hostingProvider: e.target.value,
                            });
                          }}
                        >
                          <option value="" selected="selected">
                            Select hosting provider
                          </option>
                          <option value="Amazon AWS" class="">
                            {" "}
                            Amazon AWS
                          </option>
                          <option value="Bluehost" class="">
                            {" "}
                            Bluehost
                          </option>
                          <option value="Digital Ocean" class="">
                            {" "}
                            Digital Ocean
                          </option>
                          <option value="DreamHost" class="">
                            {" "}
                            DreamHost
                          </option>
                          <option value="GoDaddy" class="">
                            {" "}
                            GoDaddy
                          </option>
                          <option value="Google Cloud" class="">
                            {" "}
                            Google Cloud
                          </option>
                          <option value="Microsoft Azure" class="">
                            {" "}
                            Microsoft Azure
                          </option>
                          <option value="WP Engine" class="">
                            {" "}
                            WP Engine
                          </option>
                          <option value="I DON'T KNOW" class="">
                            {" "}
                            I DON'T KNOW
                          </option>
                          <option value="Other" class="frm_other_trigger">
                            {" "}
                            Other
                          </option>
                        </select>
                        <label>Name of your Hosting Plan / Package:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="item_meta[131]"
                          value={hostingPackage}
                          onChange={(evt) =>
                            this.setState({
                              hostingPackage: evt.target.value,
                            })
                          }
                        />
                        <label>What type of server do you have? </label>
                        <p>
                          <input
                            type="radio"
                            name="item_meta[132]"
                            value="Shared Server"
                            onChange={(evt) =>
                              this.setState({
                                typeOfServer: evt.target.value,
                              })
                            }
                          />{" "}
                          Shared Server <br />
                          <input
                            type="radio"
                            name="item_meta[132]"
                            value="Virtual Private Server"
                            onChange={(evt) =>
                              this.setState({
                                typeOfServer: evt.target.value,
                              })
                            }
                          />{" "}
                          Virtual Private Server <br />
                          <input
                            type="radio"
                            name="item_meta[132]"
                            value="Dedicated Server"
                            onChange={(evt) =>
                              this.setState({
                                typeOfServer: evt.target.value,
                              })
                            }
                          />{" "}
                          Dedicated Server <br />
                          <input
                            type="radio"
                            name="item_meta[132]"
                            value="I Don't Know"
                            onChange={(evt) =>
                              this.setState({
                                typeOfServer: evt.target.value,
                              })
                            }
                          />{" "}
                          I Don't Know
                        </p>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <label>
                          How should Secure My Website (SMW) convert your site?{" "}
                        </label>
                        <p>
                          <input
                            type="radio"
                            name="item_meta[135]"
                            value="No"
                            onChange={(evt) =>
                              this.setState({ smw: evt.target.value })
                            }
                          />{" "}
                          SMW takes care of everything, and I will securely send
                          hosting access credentials. <br />
                          <input
                            type="radio"
                            name="item_meta[135]"
                            value="Yes"
                            onChange={(evt) =>
                              this.setState({ smw: evt.target.value })
                            }
                          />{" "}
                          SMW guides me over a web meeting, so I don't need to
                          share any passwords. <br />
                        </p>

                        <label>
                          Would you like to discuss anything else before we
                          convert your site?
                        </label>
                        <p>
                          <input
                            type="radio"
                            name="item_meta[140]"
                            value="Yes"
                            onChange={(evt) =>
                              this.setState({
                                discussBefore: evt.target.value,
                              })
                            }
                          />{" "}
                          Yes <br />
                          <input
                            type="radio"
                            name="item_meta[140]"
                            value="No"
                            onChange={(evt) =>
                              this.setState({
                                discussBefore: evt.target.value,
                              })
                            }
                          />{" "}
                          No <br />
                        </p>
                        <label>
                          Does your hosting plan come with SSH access?{" "}
                        </label>
                        <p>
                          <input
                            type="radio"
                            name="item_meta[134]"
                            value="No"
                            onChange={(evt) =>
                              this.setState({
                                hostingPlanWithSSH: evt.target.value,
                              })
                            }
                          />{" "}
                          No <br />
                          <input
                            type="radio"
                            name="item_meta[134]"
                            value="Yes"
                            onChange={(evt) =>
                              this.setState({
                                hostingPlanWithSSH: evt.target.value,
                              })
                            }
                          />{" "}
                          Yes <br />
                          <input
                            type="radio"
                            name="item_meta[134]"
                            value="I Don't Know"
                            onChange={(evt) =>
                              this.setState({
                                hostingPlanWithSSH: evt.target.value,
                              })
                            }
                          />{" "}
                          I Don't Know
                        </p>

                        <label>Do you use LastPass Password Manager?</label>
                        <p>
                          <input
                            type="radio"
                            name="item_meta[136]"
                            value="No"
                            onChange={(evt) =>
                              this.setState({
                                lastPassPasswordManager: evt.target.value,
                              })
                            }
                          />{" "}
                          No <br />
                          <input
                            type="radio"
                            name="item_meta[136]"
                            value="Yes"
                            onChange={(evt) =>
                              this.setState({
                                lastPassPasswordManager: evt.target.value,
                              })
                            }
                          />{" "}
                          Yes <br />
                          <br />
                        </p>
                      </div>
                    </div>

                    <br />
                    <br />
                    <hr />
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 px-0">
                      <button
                        className="btn btn-md btn-success pull-right"
                        onClick={() => this.onClickSaveAutoRenewingSSL()}
                        disabled={isDisable === true ? "disabled" : false}
                      >
                        <b className="fas fa-paper-plane" />&nbsp; Submit
                      </button>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
                {/* </form> */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

GetAutoRenewingSsl.contextType = AppContext;

const mapStateToProps = (state) => ({
  messageType: state.insuranceStore.messageType,
  message: state.insuranceStore.message,
  renewalInformations: state.insuranceStore.renewalInformations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAutoRenewingSSL,
      onCreateAutoRenewingSSL,
      resetStatus,
      goToDashboard: () => push("/"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetAutoRenewingSsl);
