import React from "react";
import ControlButtons from "../controlButtons";
import moment from "moment";
import _ from "lodash";
import { convertTimeTo } from "../../../helpers/timeZone";
function RunningScans({
  index,
  isLoadingRunningScans,
  runningScanActive,
  runningScans,
  getRunningScans,
  goToDashboard,
}) {
  return (
    <>
      <div className="reportButton">
        <button
          disabled={isLoadingRunningScans}
          className="btn-success btn-sm"
          onClick={() => getRunningScans()}>
          {isLoadingRunningScans ? "Loading..." : "RUNNING SCANS"}
        </button>
        <div className="clearfix" />
      </div>
      <p>Scans currently running</p>
      <hr />
      {runningScanActive &&
      !isLoadingRunningScans &&
      runningScans.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th />
                <th>Device (ip/url)</th>
                <th>Frequency</th>
                <th>Last Complete Scan</th>
                <th># of Hrs from Last Scan</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {runningScans.map((scan, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <span
                      className="a-pointer"
                      onClick={() => {
                        goToDashboard(scan.businessId);
                      }}>
                      {scan.businessName}
                    </span>
                  </td>
                  <td>{_.upperFirst(scan.businessFrequency)}</td>
                  <td>
                    {convertTimeTo(scan.dateLastScanned, scan.pciScanTimeZone)}{" "}
                  </td>
                  <td>
                    {moment()
                      .utc()
                      .diff(
                        moment(
                          convertTimeTo(
                            scan.dateLastScanned,
                            scan.pciScanTimeZone
                          )
                        ),
                        "hours"
                      )}{" "}
                    hr(s)
                  </td>
                  <td>
                    <ControlButtons businessId={scan.businessId} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default RunningScans;
