import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { convertTimeTo } from "../../../helpers/timeZone";
import { getUserDecryptedData } from "../../../helpers/general";
import { malwareStatus } from "../../../helpers/malwareStatus";

class MalwareTypeOfReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  statusOrHours() {
    if (this.props.malwareScanStatus === undefined) {
      return (
        <td>
          {this.props.diffHours}
          {/* {this.props.malwareReportType === "missing" ? (
            <button className={["primary", "btn-success"].join(" ")}>
              Immediate Scan
            </button>
          ) : null} */}
        </td>
      );
    } else {
      return malwareStatus(
        this.props.malwareScanStatus,
        this.props.malwareScanStatus
      );
    }
  }

  render() {
    const {
      businessId,
      viewUserAccountDashboard,
      host,
      number,
      frequency,
      lastMalwareScan,
      malwareReportType,
      isCanceled,
      malwareStatusReport,
    } = this.props;
    const userData = getUserDecryptedData();
    let malwareReportTable = null;

    if (userData !== undefined) {
      const timeZone = userData.hasOwnProperty("timezone")
        ? userData.timezone
        : null;

      const hostLink =
        viewUserAccountDashboard !== undefined ? (
          <a
            href="/"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              viewUserAccountDashboard(businessId);
            }}>
            {host}
          </a>
        ) : (
          host
        );

      malwareReportTable = (
        <tr>
          <td>{number}</td>
          <td>{hostLink}</td>
          <td>{frequency}</td>
          <td>
            {malwareStatusReport === "rescan" ? "Pending" : malwareStatusReport}
          </td>
          <td>
            {convertTimeTo(
              lastMalwareScan,
              timeZone,
              "MM/DD/YYYY hh:mm a",
              false
            )}{" "}
          </td>

          {isCanceled ? null : this.statusOrHours()}

          {malwareReportType !== "missing" ? (
            <td style={{ textAlign: "right" }}>
              <button
                className="btn-success"
                data-toggle="modal"
                data-target={"#" + malwareReportType + "Malware"}
                onClick={() => {
                  this.props.setMalwareSelectedBusinessId(businessId);
                }}>
                <i className="fa fa-envelope" />
              </button>
            </td>
          ) : malwareReportType === "missing" ? (
            <td style={{ textAlign: "right" }}>
              {malwareStatusReport === "rescan" ? (
                "On Queue for Scanning"
              ) : (
                <button
                  className="btn-success"
                  onClick={() => {
                    this.props.setMalwareSelectedBusinessId(businessId);
                  }}>
                  Rescan
                </button>
              )}
            </td>
          ) : null}
        </tr>
      );
    }

    return malwareReportTable;
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareTypeOfReport);
