import { api } from './api';
import { callApi } from '../helpers/index';

export const callApiGetFontColor = async backgroundColor => {
  return callApi().then(token => {
    if (backgroundColor) {
      return api.service('api/get-font-color').create({ backgroundColor: backgroundColor });
    } else {
      return null;
    }
  });
};
