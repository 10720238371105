import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  callApiGetBusinessData,
  callApiUpdateBusinessData,
} from "../../services/index";
import AppContext from "../../components/context/appContext";

class AddSealUrlModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isDisable: false,
      sealUrl: "",
      businessId: "",
      urlList: [],
    };

    this.onClickAddSealNotes = this.onClickAddSealNotes.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      const tempBusinessData = await callApiGetBusinessData(currentBusinessId);

      this.setState({
        businessId: currentBusinessId,
        urlList:
          tempBusinessData && tempBusinessData.sealNotes
            ? tempBusinessData.sealNotes
            : [],
      });
    }
  }

  closeModal() {
    this.setState({
      sealUrl: "",
      businessId: "",
    });
  }

  async onClickAddSealNotes() {
    this.setState({
      isDisable: true,
    });

    const sealNotes = this.state.urlList;
    sealNotes.push(this.state.sealUrl);

    const updateResult = await callApiUpdateBusinessData(
      this.state.businessId,
      {
        purpose: "sealNotes",
        sealNotes: sealNotes,
      }
    );

    if (updateResult && updateResult.success) {
      toast.success(updateResult.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {
        this.setState(
          {
            isDisable: false,
          },
          () => {}
        );
      }, 2500);

      this.setState(
        {
          sealUrl: "",
          businessId: "",
        },
        () => {
          document.getElementById("addSealUrlCloseModal").click();
        }
      );
    } else {
      toast.error(updateResult.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  render() {
    const { sealUrl, urlList, isDisable } = this.state;

    return (
      <div id="addSealUrl" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Seal URL</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="addSealUrlCloseModal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <p>Add seals URL here</p>
              </div>

              <div className="col-lg-9 col-md-9 text-left p-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Seal URL"
                  value={sealUrl}
                  onChange={(evt) => {
                    this.setState({
                      sealUrl: evt.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-lg-3 col-md-3 text-right p-0">
                <button
                  type="button"
                  className="btn-success btn-md  btn-140-width"
                  onClick={() => {
                    this.onClickAddSealNotes();
                  }}
                  disabled={isDisable}
                >
                  Add
                </button>
              </div>

              <div
                className="col-lg-12 col-md-12 text-left p-0"
                style={{ marginTop: "35px" }}
              >
                {urlList && urlList.length > 0
                  ? urlList.map((v, i) => {
                      return (
                        <p key={i}>
                          #{i + 1}{" "}
                          <a href={v} target="_blank">
                            {v}
                          </a>
                        </p>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddSealUrlModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSealUrlModal);
