import React from "react";
import { Alert } from "react-bootstrap";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import AppContext from "../../components/context/appContext";
import { updateUserProfileData } from "../../modules/actions/index";

class DeleteSecretConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: "",
      userData: {},
      userBusinessData: [],
    };
  }

  async onClickDeleteApiKey(businessId) {
    let payload,
      existingBusinessSecret,
      deleteSecret = false;

    this.setState({
      isDisabled: true,
    });

    if (this.context.userData.businessSecretKeys) {
      existingBusinessSecret = this.context.userData.businessSecretKeys.filter(
        (v, i) => {
          return (
            JSON.stringify(v.businessId) !== JSON.stringify(businessId) && v
          );
        }
      );

      deleteSecret = true;
    }

    if (deleteSecret) {
      this.props.updateUserProfileData(this.context.userData._id, {
        purpose: "removeGeneratedUserApiKey",
        businessSecretKeys: existingBusinessSecret,
      });

      this.context.updateUserData();
    }

    setTimeout(() => {
      this.setState({
        isDisabled: false,
      });
    }, 2500);
  }

  render() {
    const { deletedBusinessId } = this.props;

    return (
      <div
        className="modal fade"
        id="deleteSecretConfirmationModal"
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">Delete Secret</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="pt-2 pb-4">
                <h5>Are you sure to delete this secret?</h5>
              </div>
              <div className="modal-footer text-right pt-4 px-0 pb-3">
                <button
                  type="button"
                  className="btn-tglight2 btn-140-width btn-md  mr-2"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#generateApiKey"
                >
                  No
                </button>

                <button
                  type="button"
                  className="btn-success btn-140-width btn-md "
                  data-dismiss="modal"
                  onClick={() => {
                    this.onClickDeleteApiKey(deletedBusinessId);
                  }}
                  data-toggle="modal"
                  data-target="#generateApiKey"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeleteSecretConfirmation.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserProfileData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSecretConfirmation);
