import React from 'react';

class ToastNotification extends React.Component {
  render() {
    return (
      <div className="toast-notification-container">
        <div className="toast-close" />
        <div className="toast-content">
          <i className="toast-icon" />
          <div className="toast-message">
            <div className="toast-message-title">Preset Created Successfully</div>
            <div className="toast-message-subtitle">Today 10:30 PM</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ToastNotification;
