import React from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import Header from "../header";
import ToastMessage from "../../components/toast/toastMessage";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { getStorageItem } from "../../helpers/storage";
import { images } from "../../helpers/images";
import { validate } from "../../helpers/validate";

import {
  resetStatus,
  getBusinessDetailsById,
  createInsurance,
  getInsurance,
  getInsuranceIndustryList,
  postInsuranceData,
} from "../../modules/actions/index";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class InsuranceApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      business: "",
      businessId: "",
      notifyType: "",
      notifyMessage: "",
      insuranceData: "",
      insuranceStatus: "",
      insuredName: "",
      contactName: "",
      contactPhoneNumber: "",
      contactEmail: "",
      revenue: "",
      industry: null,
      streetAddressLine1: "",
      streetAddressLine2: "",
      city: "",
      state: "",
      zip: "",
      existingPolicy: "",
      pastBreach: "",
      preExistingCircumstances: "",
      deviceProtectedPast: "",
      deviceProtectedFuture: "",
      pastBreachExplanation: "",
      preExistingCircumstancesExplanation: "",
      collectCcPiiPhi: "",
      piiOrPhiDetails: "",
      ccDetails: "",
      industryList: null,
      numberOfEmployee: "",
      value: "",
      suggestions: [],
      insuranceDisplayName: "",
      testData: "",
      specialStates: [
        "OH",
        "WV",
        "MA",
        "KS",
        "NE",
        "CT",
        "FL",
        "DE",
        "RI",
        "NY",
        "WY",
      ],
      signatureName: "",
      disableSubmit: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    let businessId = cryptr.decrypt(getStorageItem("businessId"));
    if (businessId) {
      this.props.getInsuranceIndustryList();
      this.props.getBusinessDetailsById(businessId);
      this.props.getInsurance(businessId);

      this.setState({
        businessId: businessId,
      });
    } else {
      this.props.goToInsurancePage();
    }

    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 2500);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.industryData) {
      this.setState({
        industryList: newProps.industryData,
      });
    }

    if (
      newProps.insuranceData &&
      newProps.insuranceData.success === true &&
      Object.keys(newProps.insuranceData.data).length > 0
    ) {
      this.setState({
        insuranceData: newProps.insuranceData.data,
        insuranceStatus: true,
      });
    }

    if (newProps.insurances && newProps.insurances.length > 0) {
      this.setState({
        insuranceData: newProps.insurances[0],
        insuranceStatus: true,
      });
    }

    if (newProps.currentBusiness) {
      this.setState({
        business: newProps.currentBusiness,
        businessId: newProps.currentBusiness._id,
      });
    }

    if (newProps.messageType === "create-success") {
      this.setState(
        {
          notifyType: "success",
          notifyMessage: "Insurance application submitted successfully",
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();
    }

    if (newProps.insuranceData && newProps.insuranceData.success === false) {
      this.setState(
        {
          notifyType: "error",
          notifyMessage: JSON.stringify(newProps.insuranceData.data),
        },
        () => this.setState({ notifyType: "", notifyMessage: "" })
      );
      this.props.resetStatus();

      this.setState({
        disableSubmit: false,
      });
    }
  }

  onClickSaveInsurance = () => {
    this.setState({
      disableSubmit: true,
    });

    let requiredFields = [
      {
        displayName: "Company Name of Insured",
        name: "insuredName",
        rule: "required",
      },
      {
        displayName: "Contact Name is required.",
        name: "contactName",
        rule: "required",
      },
      {
        displayName: "Contact Phone Number is required.",
        name: "contactPhoneNumber",
        rule: "required|phone",
      },
      {
        displayName: "Contact Email is required.",
        name: "contactEmail",
        rule: "required|email",
      },
      {
        displayName: "Street Address 1 is required.",
        name: "streetAddressLine1",
        rule: "required",
      },
      { displayName: "City is required.", name: "city", rule: "required" },
      { displayName: "State is required.", name: "state", rule: "required" },
      { displayName: "Zip is required.", name: "zip", rule: "required" },
      {
        displayName: "Industry is required.",
        name: "industry",
        rule: "required",
      },
      {
        displayName: "Number of Employees is required.",
        name: "numberOfEmployee",
        rule: "required",
      },
      {
        displayName: "Expected Revenue is required.",
        name: "revenue",
        rule: "required|number",
      },
      {
        displayName: "Question 1 is required.",
        name: "pastBreach",
        rule: "required",
      },
      {
        displayName: "Past-breach explanation is required.",
        name: "pastBreachExplanation",
        rule: "required",
        if: { name: "pastBreach", value: "Yes" },
      },
      {
        displayName: "Question 2 is required.",
        name: "preExistingCircumstances",
        rule: "required",
      },
      {
        displayName: "Pre-existing circumstances is required.",
        name: "preExistingCircumstancesExplanation",
        rule: "required",
        if: { name: "preExistingCircumstances", value: "Yes" },
      },
      {
        displayName: "Question 3 is required.",
        name: "deviceProtectedPast",
        rule: "required",
      },
      {
        displayName: "Question 4 is required.",
        name: "collectCcPiiPhi",
        rule: "required",
      },
      {
        displayName: "PII or PHI details is required.",
        name: "piiOrPhiDetails",
        rule: "required",
        if: { name: "collectCcPiiPhi", value: "Yes" },
      },
      {
        displayName: "CC details is required.",
        name: "ccDetails",
        rule: "required",
        if: { name: "collectCcPiiPhi", value: "Yes" },
      },
      {
        displayName: "Signature is required.",
        name: "signatureName",
        rule: "required",
      },
    ];

    for (let counter = 0; counter < requiredFields.length; counter++) {
      let checkStatus = true;

      if (requiredFields[counter].hasOwnProperty("if")) {
        let ifData = requiredFields.filter((v, i) => {
          return v.name === requiredFields[counter].if.name;
        })[0];

        let ifValid = validate({
          rule: ifData.rule,
          value: this.state[ifData.name],
          field: ifData.displayName,
        });

        if (!ifValid.success) {
          this.setState(
            {
              notifyType: "error",
              notifyMessage: ifValid.message,
            },
            () => {
              this.setState({ notifyType: "", notifyMessage: "" });
            }
          );

          this.setState({
            disableSubmit: false,
          });

          return false;
        }

        if (this.state[ifData.name] !== requiredFields[counter].if.value) {
          checkStatus = false;
        } else {
        }
      }

      if (checkStatus) {
        let thisParams = {
          rule: requiredFields[counter].rule,
          value: this.state[requiredFields[counter].name],
          field: requiredFields[counter].displayName,
        };

        let decision = validate(thisParams);
        if (!decision.success) {
          this.setState(
            {
              notifyType: "error",
              notifyMessage: decision.message,
            },
            () => {
              this.setState({ notifyType: "", notifyMessage: "" });
            }
          );

          this.setState({
            disableSubmit: false,
          });

          return false;
        }
      }
    }

    this.onCreateInsurance();
  };

  onCreateInsurance() {
    if (this.state.businessId) {
      let packageData = {
        insurance_market: "SURPLUS",
        company_name: this.state.insuredName,
        domain_names: [this.state.business.primaryDomain],
        company_address: {
          street_line_1: this.state.streetAddressLine1,
          city: this.state.city,
          state: this.state.state,
          postcode: this.state.zip,
        },
        company_industry: {
          id: this.state.industry,
        },
        company_employee_count: this.state.numberOfEmployee,
        company_revenue: parseInt(this.state.revenue),
        effective_date: new Date().toISOString().split(".")[0] + "Z",
        end_date:
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            .toISOString()
            .split(".")[0] + "Z", // 1 year ahead
        retroactive_date: new Date().toISOString().split(".")[0] + "Z", // same as
        application_responses: {
          prior_claims: this.state.pastBreach,
          prior_claims_explanation:
            this.state.pastBreachExplanation === ""
              ? "N/A"
              : this.state.pastBreachExplanation,
          aware_of_new_claims: this.state.preExistingCircumstances,
          aware_of_new_claims_explanation:
            this.state.preExistingCircumstancesExplanation === ""
              ? "N/A"
              : this.state.preExistingCircumstancesExplanation,
          cc_customer_count:
            this.state.ccDetails === "" ? "No records" : this.state.ccDetails,
          encrypts_data: this.state.deviceProtectedPast, // #4
          pii_phi: this.state.collectCcPiiPhi,
          pii_phi_count:
            this.state.piiOrPhiDetails === ""
              ? "No records"
              : this.state.piiOrPhiDetails,
          content_complaints: "No",
          reviews_content: "Yes",
          backup_and_restore: "Yes",
          dual_control: "Yes",
        },
        intended_policyholder: {
          name: this.state.contactName,
          email: this.state.contactEmail,
          phone_number: this.state.contactPhoneNumber,
          address: {
            street_line_1: this.state.streetAddressLine1,
            street_line_2: this.state.streetAddressLine2,
            city: this.state.city,
            state: this.state.state,
            postcode: this.state.zip,
          },
        },
        default_retention: 15000,
        aggregate_limit: 100000,
        coverage_instances: {
          network: {},
          regulatory_defense: {},
          pci: { sublimit: 25000, subretention: 5000 },
          crisis_management: { sublimit: 25000, subretention: 5000 },
          extortion: { sublimit: 25000, subretention: 5000 },
          funds_transfer: { sublimit: 25000, subretention: 5000 },
          breach_response: {},
          reputational_harm: {},
        },
        businessId: this.state.businessId,
        signatureName: this.state.signatureName,
      };

      this.props.postInsuranceData(packageData);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });

    let selectedId = this.state.industryList.filter((i) => {
      if (i.display_name === newValue) {
        this.setState({
          industry: i.id,
          insuranceDisplayName: i.display_name,
        });
      }
      return i.id;
    });

    return selectedId;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    if (escapedValue === "") {
      return [];
    }
    const regex = new RegExp("\\b" + escapedValue, "i");
    return (
      this.state.industryList &&
      this.state.industryList.filter((person) =>
        regex.test(this.getSuggestionValue(person))
      )
    );
  }

  getSuggestionValue(suggestion) {
    return `${suggestion.display_name}`;
  }

  renderSuggestion(suggestion, { query }) {
    const suggestionText = `${suggestion.display_name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className={"suggestion-content"}>
        <span className="">
          {parts.map((part, index) => {
            const className = part.highlight ? "highlight" : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </span>
    );
  }

  showContentLoader() {
    return (
      <ContentLoader
        height={260}
        width={900}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#e1dfdf"
      >
        <rect x="5" y="5" rx="0" ry="0" width="200" height="450" />

        <rect x="225" y="5" rx="0" ry="0" width="200" height="15" />

        <rect x="225" y="35" rx="0" ry="0" width="200" height="15" />
        <rect x="465" y="35" rx="0" ry="0" width="200" height="15" />
        <rect x="700" y="35" rx="0" ry="0" width="200" height="15" />

        <rect x="225" y="70" rx="0" ry="0" width="200" height="15" />
        <rect x="465" y="70" rx="0" ry="0" width="200" height="15" />
        <rect x="700" y="70" rx="0" ry="0" width="200" height="15" />

        <rect x="225" y="105" rx="0" ry="0" width="200" height="15" />
        <rect x="465" y="105" rx="0" ry="0" width="200" height="15" />
        <rect x="700" y="105" rx="0" ry="0" width="200" height="15" />

        <rect x="225" y="140" rx="0" ry="0" width="200" height="15" />
        <rect x="465" y="140" rx="0" ry="0" width="200" height="15" />
        <rect x="700" y="140" rx="0" ry="0" width="200" height="15" />

        <rect x="225" y="175" rx="0" ry="0" width="900" height="15" />
        <rect x="225" y="210" rx="0" ry="0" width="900" height="15" />
        <rect x="225" y="245" rx="0" ry="0" width="900" height="15" />
        <rect x="225" y="280" rx="0" ry="0" width="900" height="15" />
        <rect x="225" y="315" rx="0" ry="0" width="900" height="15" />
        <rect x="225" y="350" rx="0" ry="0" width="900" height="15" />
      </ContentLoader>
    );
  }

  render() {
    const {
      isLoading,
      notifyType,
      notifyMessage,
      insuranceData,
      insuranceStatus,
      insuredName,
      contactName,
      contactPhoneNumber,
      contactEmail,
      revenue,
      streetAddressLine1,
      streetAddressLine2,
      city,
      state,
      zip,
      pastBreach,
      preExistingCircumstances,
      pastBreachExplanation,
      preExistingCircumstancesExplanation,
      collectCcPiiPhi,
      piiOrPhiDetails,
      ccDetails,
      numberOfEmployee,
      value,
      suggestions,
      insuranceDisplayName,
      specialStates,
      signatureName,
      disableSubmit,
    } = this.state;

    /*eslint-disable*/
    const inputProps = {
      placeholder: "Type industry",
      value,
      onChange: this.onChange,
    };
    /*eslint-enable*/

    const pointerStyle = {
      cursor: "pointer",
    };

    return (
      <div>
        <Header titlePage="Breach Protection Insurance" bgColor="#F5F6F8" />
        {!isLoading ? (
          <>
            <ToastMessage type={notifyType} notifyMessage={notifyMessage} />

            <div id="mcontent-wrapper">
              <div id="PCI-scan" className="">
                <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
                  {insuranceStatus ? (
                    specialStates.indexOf(
                      insuranceData.company_address.state
                    ) !== -1 ? (
                        <div>
                          <div
                            className="col-md-6 col-md-offset-3 col-sm-12 col-xs-12 no-pad"
                            style={{
                              boxShadow: "0 0 5px 0px #ddd",
                              borderRadius: "5px 5px",
                              border: "1px solid #ddd",
                              marginTop: 100,
                              marginBottom: 300,
                            }}
                          >
                            <div
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                fontSize: 28,
                                textAlign: "center",
                                padding: 45,
                                paddingBottom: 20,
                                float: "left",
                              }}
                            >
                              <div>
                                <h1
                                  style={{ fontWeight: "bold", color: "#268226" }}
                                >
                                  CONGRATULATIONS!
                              </h1>
                                <div className="col-lg-12 text-center mt-2 mb-2">
                                  <img
                                    id="tg-insurance-ty"
                                    src={images.tgRegularDark}
                                    alt="Trust Guard Logo"
                                    className=""
                                  />
                                </div>
                                <div className="col-lg-4 text-center pt-1 mt-3">
                                  <img
                                    id="tg-coalition-ty"
                                    src={images.thecoalitionblack}
                                    alt="SSL - Secure My Site"
                                  />
                                </div>
                                <div className="col-lg-4 text-center pt-1 mt-3">
                                  <img
                                    id="tg-rps-logo-ty"
                                    src={images.rpsLogo}
                                    alt="SSL - Secure My Site"
                                  />
                                </div>
                                <div className="col-lg-4 text-center pt-1 mt-3">
                                  <img
                                    id="tg-buckner-ty"
                                    src={images.bucknerLogoHd}
                                    alt="SSL - Secure My Site"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "block",
                                    clear: "both",
                                    paddingTop: 20,
                                    float: "left",
                                  }}
                                />
                              </div>
                            </div>
                            <div id="formBrandInsurance-well" className="">
                              <h4
                                className="text-center mb-4"
                                style={{
                                  fontSize: 21,
                                }}
                              >
                                {" "}
                              Your application has been successfully submitted
                              to Coalition for approval.
                            </h4>
                              <div className="col-lg-10 text-left my-1">
                                <h5>
                                  <b style={{ color: "yellow" }}>Final Step:</b>{" "}
                                Sometime within the next business day,{" "}
                                  <b>
                                    you will receive two (2) emails that require
                                    digital signatures
                                </b>{" "}
                                in order to fully activate your insurance
                                policy.<br />
                                  <br />
                                  <ul>
                                    <li>
                                      <u>The first email</u> is from Coalition,
                                    the Insurance carrier. This email includes
                                    the terms of your policy.
                                  </li>
                                    <br />
                                    <li>
                                      <u>The second email</u> is from Buckner, the
                                    Insurance broker. This email includes either
                                    a surplus lines statement or affidavit
                                    depending on your state regulations.
                                  </li>
                                  </ul>
                                Once you've opened and digitally signed both
                                documents, your Cyber Insurance and your Breach
                                Response Insurance will become active.
                              </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            className="col-md-6 col-md-offset-3 col-sm-12 col-xs-12 no-pad"
                            style={{
                              boxShadow: "0 0 5px 0px #ddd",
                              borderRadius: "5px 5px",
                              border: "1px solid #ddd",
                              marginTop: 100,
                              marginBottom: 20,
                            }}
                          >
                            <div
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                fontSize: 28,
                                textAlign: "center",
                                padding: 45,
                                paddingBottom: 20,
                                float: "left",
                              }}
                            >
                              <div>
                                <h1
                                  style={{ fontWeight: "bold", color: "#268226" }}
                                >
                                  CONGRATULATIONS!
                              </h1>
                                <div className="col-lg-12 text-center mt-2 mb-2">
                                  <img
                                    id="tg-insurance-ty"
                                    src={images.tgRegularDark}
                                    alt="Trust Guard Logo"
                                    className=""
                                  />
                                </div>
                                <div className="col-lg-4 text-center pt-1 mt-3">
                                  <img
                                    id="tg-coalition-ty"
                                    src={images.thecoalitionblack}
                                    alt="SSL - Secure My Site"
                                  />
                                </div>
                                <div className="col-lg-4 text-center pt-1 mt-3">
                                  <img
                                    id="tg-rps-logo-ty"
                                    src={images.rpsLogo}
                                    alt="SSL - Secure My Site"
                                  />
                                </div>
                                <div className="col-lg-4 text-center pt-1 mt-3">
                                  <img
                                    id="tg-buckner-ty"
                                    src={images.bucknerLogoHd}
                                    alt="SSL - Secure My Site"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "block",
                                    clear: "both",
                                    paddingTop: 20,
                                    float: "left",
                                  }}
                                />
                              </div>
                            </div>
                            <div id="formBrandInsurance-well" className="">
                              <h4
                                className="text-center mb-4"
                                style={{
                                  fontSize: 21,
                                }}
                              >
                                {" "}
                              Your application has been successfully submitted
                              to Coalition for approval.
                            </h4>
                              <div className="col-lg-2 text-center" />
                              <div className="col-lg-10 text-left my-1">
                                <h5>
                                  <b style={{ color: "yellow" }}>Final Step:</b>{" "}
                                You will receive your final documents in an
                                email from Coalition that includes the terms of
                                your policy, and comes from
                                welcome@thecoalition.com in case you need to
                                check your filters.<br />
                                  <br />
                                Once you've opened and digitally signed the
                                documents from Coalition, your Cyber Insurance
                                and your Breach Response Insurance will become
                                active.
                              </h5>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-md-offset-3 col-sm-12 col-xs-12 no-pad">
                            <div
                              style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                fontSize: 10,
                                textAlign: "left",
                                paddingBottom: 20,
                                float: "left",
                                color: "#bbbbbb",
                              }}
                            >
                              Coalition’s insurance products are offered with the
                              financial security of Swiss Re Corporate Solutions*
                              and Argo Pro US** (A+/A ratings by A.M. Best).
                            <br />
                              <br />
                            Coalition, Inc. | Licensed in all 50 states and D.C.
                            | CA License # 0L76155
                            <br />
                              <br />
                            *Insurance products may be underwritten by North
                            American Capacity Insurance Company, North American
                            Specialty Insurance Company, or an affiliated
                            company, which are members of Swiss Re Corporate
                            Solutions. **Insurance products may be underwritten
                            by Peleus Insurance Company, Colony Specialty
                            Insurance Company, or an affiliated company, which
                            are members of Argo Group US, Inc.
                          </div>
                          </div>
                        </div>
                      )
                  ) : (
                      <div id="insurance-app" className="tab-pane fade in active">
                        <div className="col-lg-3  col-md-3 col-sm-12 col-xs-12 no-pad">
                          <div id="formBrandInsurance">
                            <div className="col-md-12 col-sm-12 col-xs-12 text-center mb-4">
                              <img
                                id="tg-ssl-logo"
                                src={images.tgRegularDark}
                                alt="Trust Guard Logo"
                                className="pt-0"
                              />
                              <div className="col-lg-12 text-center mb-1">
                                <label className="tg-inpartnership">
                                  Cyber Insurance services provided<br />and
                                serviced by:
                              </label>
                              </div>
                              <div className="col-lg-4 text-right p-0">
                                <img
                                  id="tg-coalition-logo"
                                  src={images.thecoalitionblack}
                                  alt="Coalition - Logo"
                                />
                              </div>

                              <div className="col-lg-4 text-center p-0">
                                <img
                                  id="rpsLogo"
                                  src={images.rpsLogo}
                                  alt="RPS - Logo"
                                />
                              </div>
                              <div className="col-lg-4 text-left p-0">
                                <img
                                  id="bucknerLogo"
                                  src={images.bucknerLogo}
                                  alt="Buckner - Logo"
                                />
                              </div>
                            </div>
                            <hr />
                            <p>
                              <span
                                className="f700 mb-4 pt-2 text-center"
                                style={{ display: "block", clear: "both" }}
                              >
                                INSURANCE APPLICATION
                            </span>
                              <span>
                                Cyber Insurance allows you to fully protect your
                                business should you ever experience a security
                                breach.
                            </span>
                            </p>
                          </div>
                          <div id="formBrandInsurance-well" className="">
                            In order to activate your comprehensive insurance
                            policy, please fill out the short application form to
                            the right.
                        </div>
                        </div>
                        <div
                          id="formContainerInsurance"
                          className="col-lg-9 col-md-9 col-sm-12 col-xs-12 pr-0"
                        >
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-left">
                            <h5
                              className="f700 pl-3"
                              style={{ marginBottom: "25px", fontSize: "18px" }}
                            >
                              COMPANY INFORMATION
                          </h5>
                            <div className="col-lg-3">
                              <label>Company Name of Insured</label>
                              <br />
                              <input
                                type="text"
                                className="form-control p-sm"
                                placeholder="Company Name"
                                name="insuredName"
                                value={insuredName}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>Contact Name</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Name"
                                name="contactName"
                                value={contactName}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>Contact Phone Number</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Phone Number"
                                name="contactPhoneNumber"
                                value={contactPhoneNumber}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>Contact Email</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="example@gmail.com"
                                name="contactEmail"
                                value={contactEmail}
                                onChange={this.handleInputChange}
                              />
                            </div>

                            <div className="col-lg-3">
                              <label>Street Address 1</label>
                              <br />
                              <input
                                type="text"
                                className="form-control p-sm"
                                placeholder="Street Address 1"
                                name="streetAddressLine1"
                                value={streetAddressLine1}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>Street Address 2</label>
                              <br />
                              <input
                                type="text"
                                className="form-control p-sm"
                                placeholder="Street Address 2"
                                name="streetAddressLine2"
                                value={streetAddressLine2}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>City</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={city}
                                onChange={this.handleInputChange}
                                placeholder="City"
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>State</label>
                              <br />
                              <select
                                name="state"
                                className="form-control"
                                onChange={this.handleInputChange}
                                value={state}
                              >
                                <option>Select State</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                              </select>
                            </div>

                            <div className="col-lg-3">
                              <label>Zip</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                name="zip"
                                value={zip}
                                onChange={this.handleInputChange}
                                placeholder="Zip"
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>
                                Select Industry<span className="account-title1">
                                  &nbsp;
                                <span className="roleTooltipV2">
                                    <b
                                      className="fas fa-info-circle text-danger"
                                      style={{ cursor: "help" }}
                                    />
                                    <span
                                      className="roleTooltipTextV2"
                                      style={{
                                        width: "350px",
                                        bottom: "-97px",
                                        left: "90px",
                                        padding: "7px",
                                      }}
                                    >
                                      <div style={{ fontSize: "11px" }}>
                                        Type the main keyword for your particular
                                        industry into the field below, and then
                                        select the industry type the best matches
                                        your business.
                                      <br />
                                        <br />
                                      Please note: The following types of
                                      businesses can not be insured under this
                                      type of policy: Adult Entertainment,
                                      Cannabis, Credit Card Processors, Debt
                                      Collections, Business Process Outsourcing,
                                      and Social Media Companies. If you would
                                      like to explore your coverage options,
                                      please contact Brandon Robertson at
                                      brandon@buckner.com
                                    </div>
                                    </span>
                                  </span>
                                </span>
                              </label>
                              <br />
                              <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={
                                  this.onSuggestionsFetchRequested
                                }
                                onSuggestionsClearRequested={
                                  this.onSuggestionsClearRequested
                                }
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}
                              />
                            </div>
                            <div className="col-lg-3">
                              <label>Number of Employees</label>
                              <br />
                              <select
                                className="form-control"
                                name="numberOfEmployee"
                                onChange={this.handleInputChange}
                                value={numberOfEmployee}
                              >
                                <option>Select Numer of Employees</option>
                                <option value="1-25">1-25</option>
                                <option value="26-50">26-50</option>
                                <option value="51-250">51-250</option>
                                <option value="251-1000">251-1000</option>
                                <option value="1001+">1001+</option>
                              </select>
                            </div>
                            <div className="col-lg-3">
                              <label>Expected Revenue (next 12 months)</label>
                              <br />
                              <input
                                type="text"
                                className="form-control p-sm"
                                name="revenue"
                                value={revenue}
                                onChange={this.handleInputChange}
                                placeholder="Expected Revenue"
                              />
                            </div>
                            {insuranceDisplayName
                              .toLocaleLowerCase()
                              .indexOf("software") !== -1 ||
                              insuranceDisplayName
                                .toLocaleLowerCase()
                                .indexOf("hardware") !== -1 ? (
                                <div className="col-lg-12">
                                  <div
                                    className="alert alert-info"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      borderTopLeftRadius: "0",
                                      borderBottomLeftRadius: "0",
                                      borderLeft: "4px solid",
                                    }}
                                  >
                                    Based on yours industry selection it appears
                                    that you sell software or hardware, which means
                                    that in addition to Cyber Insurance, you need
                                    Technical Errors & Ommissions Insurance.
                                <br />
                                Please contact Brandon Robertson at
                                brandon@buckner.com to find out what additional
                                Tech E&O coverage you need. He can help you with
                                your policy.
                                <br />
                                (The Cyber portion of your insurance premium is
                                already covered in your monthly payment, so you
                                will just need to pay the additional cost for
                                the Tech E&O)
                              </div>
                                </div>
                              ) : (
                                ""
                              )}

                            <div className="col-lg-12">
                              <h5
                                className="f700"
                                style={{
                                  marginTop: "45px",
                                  marginBottom: "25px",
                                  fontSize: "18px",
                                }}
                              >
                                INSURANCE INFORMATION
                            </h5>

                              <p>
                                <label>
                                  1. Within the last 3 years, has your company
                                  suffered a cyber incident resulting in an
                                  insurance claim in excess of $25,000?
                              </label>
                                <br />
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("pastBreachYes")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="pastBreachYes"
                                    type="radio"
                                    value="Yes"
                                    name="pastBreach"
                                    onChange={this.handleInputChange}
                                    checked={pastBreach === "Yes" ? true : false}
                                  />{" "}
                                Yes{" "}
                                </span>
                                <br />
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("pastBreachNo")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="pastBreachNo"
                                    type="radio"
                                    value="No"
                                    name="pastBreach"
                                    onChange={this.handleInputChange}
                                    checked={pastBreach === "No" ? true : false}
                                  />{" "}
                                No{" "}
                                </span>

                                <span
                                  style={
                                    pastBreach === "Yes"
                                      ? {
                                        display: "block",
                                        marginTop: "25px",
                                        cursor: "pointer",
                                      }
                                      : { display: "none", cursor: "pointer" }
                                  }
                                >
                                  <input
                                    style={pointerStyle}
                                    type="text"
                                    className="form-control"
                                    value={pastBreachExplanation}
                                    name="pastBreachExplanation"
                                    onChange={this.handleInputChange}
                                    placeholder="Where possible, details should include: Date of event (MM/DD/YY), date noticed to insurer, insurer name, law enforcement report, and description of event."
                                  />
                                </span>
                              </p>

                              <p>
                                <label>
                                  2. Are you aware of any pre-existing
                                  circumstances that could give rise to a claim
                                  under this insurance policy?
                              </label>
                                <br />
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        "preExistingCircumstancesYes"
                                      )
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="preExistingCircumstancesYes"
                                    type="radio"
                                    value="Yes"
                                    name="preExistingCircumstances"
                                    onChange={this.handleInputChange}
                                  />{" "}
                                Yes{" "}
                                </span>
                                <br />
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        "preExistingCircumstancesNo"
                                      )
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="preExistingCircumstancesNo"
                                    type="radio"
                                    value="No"
                                    name="preExistingCircumstances"
                                    onChange={this.handleInputChange}
                                  />{" "}
                                No{" "}
                                </span>

                                <span
                                  style={
                                    preExistingCircumstances === "Yes"
                                      ? {
                                        display: "block",
                                        marginTop: "25px",
                                        cursor: "pointer",
                                      }
                                      : { display: "none", cursor: "pointer" }
                                  }
                                >
                                  <input
                                    style={pointerStyle}
                                    type="text"
                                    className="form-control"
                                    value={preExistingCircumstancesExplanation}
                                    name="preExistingCircumstancesExplanation"
                                    onChange={this.handleInputChange}
                                    placeholder="Where possible, details should include: Date of event (MM/DD/YY), date noticed to insurer, insurer name, law enforcement report, and description of event."
                                  />
                                </span>
                              </p>

                              <p>
                                <label>
                                  3. Does your company enable disk encryption on
                                  laptops, desktops, and other portable media
                                  devices?
                              </label>
                                <br />
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("deviceProtectedPastYes")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="deviceProtectedPastYes"
                                    type="radio"
                                    value="Yes"
                                    name="deviceProtectedPast"
                                    onChange={this.handleInputChange}
                                  />{" "}
                                Yes{" "}
                                </span>
                                <br />

                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("deviceProtectedPastNo")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="deviceProtectedPastNo"
                                    type="radio"
                                    value="No"
                                    name="deviceProtectedPast"
                                    onChange={this.handleInputChange}
                                  />{" "}
                                No{" "}
                                </span>
                                <br />

                                <span
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        "deviceProtectedPastSometimes"
                                      )
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="deviceProtectedPastSometimes"
                                    type="radio"
                                    value="Sometimes"
                                    name="deviceProtectedPast"
                                    onChange={this.handleInputChange}
                                  />{" "}
                                Sometimes{" "}
                                </span>
                              </p>

                              <p>
                                <label>
                                  4. Does your company accept credit cards or
                                  collect Personally Indentifiable Informations
                                  (PII) or Protected Health Information (PHI) from
                                  customers?
                              </label>
                                <br />
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("collectCcPiiPhiYes")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="collectCcPiiPhiYes"
                                    type="radio"
                                    value="Yes"
                                    name="collectCcPiiPhi"
                                    onChange={this.handleInputChange}
                                    checked={
                                      collectCcPiiPhi === "Yes" ? true : false
                                    }
                                  />{" "}
                                Yes{" "}
                                </span>{" "}
                                <br />
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("collectCcPiiPhiNo")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <input
                                    style={pointerStyle}
                                    id="collectCcPiiPhiNo"
                                    type="radio"
                                    value="No"
                                    name="collectCcPiiPhi"
                                    onChange={this.handleInputChange}
                                    checked={
                                      collectCcPiiPhi === "No" ? true : false
                                    }
                                  />{" "}
                                No{" "}
                                </span>
                              </p>

                              <p
                                style={
                                  collectCcPiiPhi === "Yes"
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <label>
                                  How many PII or PHI records does your company
                                  have for customers?
                              </label>
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        "piiOrPhiDetailsLessThan100K"
                                      )
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    style={pointerStyle}
                                    id="piiOrPhiDetailsLessThan100K"
                                    type="radio"
                                    value="<100,000"
                                    name="piiOrPhiDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      piiOrPhiDetails === "<100,000"
                                        ? true
                                        : false
                                    }
                                  />{" "}
                                  {"<100K  "}
                                </span>{" "}
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("piiOrPhiDetails100Kto500K")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    style={pointerStyle}
                                    id="piiOrPhiDetails100Kto500K"
                                    type="radio"
                                    value="100,000 - 500,000"
                                    name="piiOrPhiDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      piiOrPhiDetails === "100,000 - 500,000"
                                        ? true
                                        : false
                                    }
                                  />{" "}
                                100,000-500,000{" "}
                                </span>{" "}
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById(
                                        "piiOrPhiDetails500Kto1000K"
                                      )
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    style={pointerStyle}
                                    id="piiOrPhiDetails500Kto1000K"
                                    type="radio"
                                    value="500,000 - 1,000,000"
                                    name="piiOrPhiDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      piiOrPhiDetails === "500,000 - 1,000,000"
                                        ? true
                                        : false
                                    }
                                  />{" "}
                                500,000-1000,000{" "}
                                </span>{" "}
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("piiOrPhiDetails>1M")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    style={pointerStyle}
                                    id="piiOrPhiDetails>1M"
                                    type="radio"
                                    value=">1,000,000"
                                    name="piiOrPhiDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      piiOrPhiDetails === ">1,000,000"
                                        ? true
                                        : false
                                    }
                                  />{" "}
                                  {" >1M  "}
                                </span>{" "}
                              </p>

                              <p
                                style={
                                  collectCcPiiPhi === "Yes"
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <label>
                                  How many payment card numbers (credit cards,
                                  debit cards, etc.) does your company store,
                                  process, transmit, or have access to?
                              </label>
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("ccDetailsLessThan100K")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    style={pointerStyle}
                                    id="ccDetailsLessThan100K"
                                    type="radio"
                                    value="<100,000"
                                    name="ccDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      ccDetails === "<100,000" ? true : false
                                    }
                                  />{" "}
                                  {"<100K  "}
                                </span>{" "}
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("ccDetails100Kto500K")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    style={pointerStyle}
                                    id="ccDetails100Kto500K"
                                    type="radio"
                                    value="100,000 - 500,000"
                                    name="ccDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      ccDetails === "100,000 - 500,000"
                                        ? true
                                        : false
                                    }
                                  />{" "}
                                100,000-500,000{" "}
                                </span>{" "}
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("ccDetails500Kto1000K")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    style={pointerStyle}
                                    id="ccDetails500Kto1000K"
                                    type="radio"
                                    value="500,000 - 1,000,000"
                                    name="ccDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      ccDetails === "500,000 - 1,000,000"
                                        ? true
                                        : false
                                    }
                                  />{" "}
                                500,000-1000,000{" "}
                                </span>{" "}
                                <span
                                  onClick={() => {
                                    document
                                      .getElementById("ccDetails>1M")
                                      .click();
                                  }}
                                  style={pointerStyle}
                                >
                                  <br />
                                  <input
                                    id="ccDetails>1M"
                                    type="radio"
                                    value=">1,000,000"
                                    name="ccDetails"
                                    onChange={this.handleInputChange}
                                    checked={
                                      ccDetails === ">1,000,000" ? true : false
                                    }
                                    style={pointerStyle}
                                  />{" "}
                                  {" >1M  "}
                                </span>{" "}
                              </p>

                              <div
                                id="signatureWrapper"
                                style={{ marginTop: "15px" }}
                              >
                                <br />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <hr />
                            In relation to the Trust Guard Cyber Insurance
                            program offered through Buckner (the insurance
                            agent) and Coalition (the insurance company), you,
                            as a Trust Guard client, hereby appoint Trust Guard
                            as your attorney-in-fact, with full power and
                            authority to pay any applicable insurance premiums
                            and associated fees or costs, and/or cancel the
                            insurance policy on your behalf due to cancellation
                            or non-payment of Trust Guard services. Upon
                            cancellation of Trust Guard services, the insurance
                            company and/or its agents listed in this agreement
                            are hereby authorized and directed, upon request of
                            Trust Guard to cancel said policy and to pay to the
                            order of the carrier and or insurance agency all
                            gross and unearned premiums due and payable.
                          </div>

                            <div
                              className="col-lg-6"
                              style={{ marginTop: "15px" }}
                            >
                              <label>Signature</label>
                              <br />
                              <input
                                type="text"
                                className="form-control p-sm"
                                name="signatureName"
                                value={signatureName}
                                onChange={this.handleInputChange}
                                placeholder="Name"
                              />
                            </div>
                            <div className="col-lg-12">
                              <p>
                                Type your name above (I agree that the signature
                                will be the electronic representation of my
                                signature for all purposes, including legally
                                binding contracts - just the same as a
                                pen-and-paper signature)
                            </p>
                            </div>

                            <div
                              className="col-lg-12"
                              style={{ marginTop: "5px" }}
                            >
                              <button
                                className="btn btn-md btn-success"
                                onClick={() => this.onClickSaveInsurance()}
                                disabled={disableSubmit}
                              >
                                <b className="fas fa-paper-plane" />&nbsp; Submit
                            </button>

                              <p
                                style={{
                                  color: "#b3b3b3",
                                  display: disableSubmit ? "block" : "none",
                                }}
                              >
                                Submitting Application. Please note, this may take
                                a minute.
                            </p>

                              <div style={{ marginTop: "65px" }}>
                                <hr />
                                <p
                                  className="pt-2"
                                  style={{ color: "#B1B1B1", fontSize: "11px" }}
                                >
                                  Trust Guard works directly with a team of
                                  Insurance providers, including Coalition, RPS
                                  and Buckner to provide their clients with
                                  affordable Cyber Insurance solutions; however,
                                  Trust Guard is not a commercial insurance
                                  carrier or an insurance agency, and does not
                                  issue any policies directly. Any Trust Guard
                                  clients who want insurance will be required to
                                  fill out an application and sign a Cyber
                                  Insurance policy issued directly from the
                                  Insurance carrier.
                              </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        ) : (
            <div id="mcontent-wrapper">{this.showContentLoader()}</div>
          )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentBusiness: state.accountStore.currentBusiness,
  industryData: state.accountStore.industryData,
  insuranceData: state.accountStore.insuranceData,
  messageType: state.accountStore.messageType,
  insurances: state.insuranceStore.insurances,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStatus,
      getBusinessDetailsById,
      createInsurance,
      getInsurance,
      getInsuranceIndustryList,
      postInsuranceData,
      goToInsurancePage: () => push("insurance"),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceApplication);
