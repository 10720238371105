import React from "react";

const scanTableContent = (props) => {
  return (
    <div
      className="row"
      style={{
        border: "2px solid #E5E5E5",
        borderRadius: "5px",
      }}
    >
      <div
        className="col-12"
        style={{
          backgroundColor: "#E5E5E5",
          padding: "25px",
        }}
      >
        <div className="row">
          <div
            className="col-4"
            style={{ fontWeight: "bold", paddingLeft: "25px" }}
          >
            Add Subdomains / IPs:
          </div>
          <div
            className="col-2"
            style={{ fontWeight: "bold", paddingLeft: "25px" }}
          >
            External Scan Options
          </div>
          <div
            className="col-2"
            style={{ fontWeight: "bold", paddingLeft: "25px" }}
          >
            Malware Scan Options
          </div>
          <div
            className="col-2"
            style={{ fontWeight: "bold", paddingLeft: "25px" }}
          >
            SSL Options
          </div>
          <div
            className="col-2"
            style={{ fontWeight: "bold", paddingLeft: "25px" }}
          >
            Seal Options
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {props.children}
      </div>
      <div
        className="col-12"
        style={{
          backgroundColor: "#E5E5E5",
          padding: "25px",
        }}
      />
    </div>
  );
};

export default scanTableContent;
