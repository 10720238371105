import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { callApiGetCheckScanReportsDomainData } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class VerificationReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      fetchData: false,
      pendingDomains: [],
    };
  }

  async componentDidMount() {
    await this.getCheckScanReports();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.reportType !== this.props.reportType &&
      this.props.reportType === "check-scan"
    ) {
      await this.getCheckScanReports();
    }
  }

  async getCheckScanReports() {
    const pendingDomains = await callApiGetCheckScanReportsDomainData();

    if (
      pendingDomains &&
      pendingDomains.data &&
      pendingDomains.data.length > 0
    ) {
      this.setState({
        pendingDomains: pendingDomains.data,
      });
    }
  }

  render() {
    const { pendingDomains } = this.state;

    return (
      <>
        <div style={{ margin: "15px" }}>
          <hr />
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Business</th>
                  <th>Reason</th>
                  <th>PCI</th>
                  <th>Malware</th>
                  <th>SSL</th>
                  <th>Insurance</th>
                  <th>Email Client</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="scan-status-list">
                {pendingDomains &&
                pendingDomains.length > 0 &&
                pendingDomains.filter((v, i) => {
                  return (
                    v.businessData &&
                    v.businessData.status !== "canceled" &&
                    v.businessData.accountType !== "free"
                  );
                }).length > 0 ? (
                  pendingDomains.map((domain, domainIndex) => {
                    return (
                      <tr key={domainIndex}>
                        <td>
                          {domain.businessData ? (
                            <button
                              className="tg-link"
                              onClick={() => {
                                setStorageItem(
                                  "businessId",
                                  cryptr.encrypt(domain.businessId)
                                );
                                window.open("/");
                              }}
                            >
                              {domain.businessData.primaryDomain}
                            </button>
                          ) : (
                            "N/A"
                          )}
                        </td>

                        <td>
                          {domain && domain.details && domain.details.length > 0
                            ? domain.details && domain.details.length > 0
                              ? domain.details.map((v, i) => {
                                  return (
                                    <p key={i}>
                                      <button
                                        className="tg-link"
                                        data-toggle="modal"
                                        data-target={"#resolvedIssueModal"}
                                        style={{
                                          boxShadow: "none",
                                          color: "#268226",
                                          whiteSpace: "pre-wrap",
                                          textAlign: "left",
                                        }}
                                      >
                                        {v.reason}
                                      </button>
                                    </p>
                                  );
                                })
                              : ""
                            : ""}
                        </td>

                        <td>
                          <button
                            className={
                              domain &&
                              domain.businessData &&
                              domain.businessData.hasOwnProperty(
                                "lastPciStatus"
                              )
                                ? domain.businessData.lastPciStatus ===
                                    "passed" ||
                                  domain.businessData.lastPciStatus === "pass"
                                  ? "btn status-box btn-success"
                                  : domain.businessData.lastPciStatus === "fail"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                domain &&
                                domain.businessData &&
                                domain.businessData.hasOwnProperty(
                                  "lastPciStatus"
                                )
                                  ? domain.businessData.lastPciStatus ===
                                      "passed" ||
                                    domain.businessData.lastPciStatus === "pass"
                                    ? "fa fa-check"
                                    : domain.businessData.lastPciStatus ===
                                      "fail"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              domain &&
                              domain.businessData &&
                              domain.businessData.hasOwnProperty(
                                "lastMalwareScanResult"
                              )
                                ? domain.businessData.lastMalwareScanResult ===
                                  "clean"
                                  ? "btn status-box btn-success"
                                  : domain.businessData
                                      .lastMalwareScanResult === "malicious" ||
                                    domain.businessData
                                      .lastMalwareScanResult === "suspicious"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                domain &&
                                domain.businessData &&
                                domain.businessData.hasOwnProperty(
                                  "lastMalwareScanResult"
                                )
                                  ? domain.businessData
                                      .lastMalwareScanResult === "clean"
                                    ? "fa fa-check"
                                    : domain.businessData
                                        .lastMalwareScanResult ===
                                        "malicious" ||
                                      domain.businessData
                                        .lastMalwareScanResult === "suspicious"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              domain &&
                              domain.domainData &&
                              domain.domainData.hasOwnProperty("sslInfo")
                                ? domain.domainData.sslInfo.status === "active"
                                  ? "btn status-box btn-success"
                                  : domain.domainData.sslInfo.status ===
                                    "failed"
                                    ? "btn status-box btn-success status-box-red"
                                    : "btn status-box btn-success status-box-grey"
                                : "btn status-box btn-success status-box-grey"
                            }
                          >
                            <i
                              className={
                                domain &&
                                domain.domainData &&
                                domain.domainData.hasOwnProperty("sslInfo")
                                  ? domain.domainData.sslInfo.status ===
                                    "active"
                                    ? "fa fa-check"
                                    : domain.domainData.sslInfo.status ===
                                      "failed"
                                      ? "fa fa-times"
                                      : "fa fa-question"
                                  : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={
                              domain &&
                              domain.insuranceData &&
                              domain.insuranceData.insuranceStatus ===
                                "completed"
                                ? "btn status-box btn-success"
                                : domain &&
                                  domain.insuranceData &&
                                  domain.insuranceData.insuranceStatus ===
                                    "submitted"
                                  ? "btn status-box status-box-grey btn-warning"
                                  : "btn status-box status-box-grey btn-success"
                            }
                          >
                            <i
                              className={
                                domain &&
                                domain.insuranceData &&
                                domain.insuranceData.insuranceStatus ===
                                  "completed"
                                  ? "fa fa-check"
                                  : domain &&
                                    domain.insuranceData &&
                                    domain.insuranceData.insuranceStatus ===
                                      "submitted"
                                    ? "fa fa-check"
                                    : "fa fa-question"
                              }
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn-success"
                            data-toggle="modal"
                            data-target="#failedScanEmailCLient"
                          >
                            <i className="fa fa-envelope" />
                          </button>
                        </td>
                        <td>
                          <ControlButtons businessId={domain.businessId} />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8">No records.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

VerificationReports.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationReports);
